import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white'
  },
  header: {
    paddingHorizontal: 28
  },

  //
  loginFrom: {
    // paddingHorizontal: 28,
    marginBottom: 100
  },
  other: {}
});
