import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import { TwCard } from '@tw/tw-components-rn';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { FC, useMemo } from 'react';
import { WmsPickingDTO } from '@tw/lock-api-gen/dist/types/wms';
import { WmsDocSubmitPickingAppliedInfoPage } from '@/wms/stores/doc-submit-picking-applied-info/wms-doc-submit-picking-applied-info-page';
import dayjs from 'dayjs';

export const PickingInfoCard: FC<TwBfFieldRcProps<any>> = (props) => {
  const page = useBfPageStore<WmsDocSubmitPickingAppliedInfoPage>();
  const infoData: WmsPickingDTO = useStoreState(page, (state) => state.data);
  const timeText = useMemo(
    () => (infoData?.createTime ? dayjs(infoData.createTime).format('YYYY-MM-DD HH:mm') : ''),
    [infoData?.createTime]
  );
  const text = useMemo(() => {
    if (!infoData || !infoData.type) {
      return null;
    }
    // 申领
    if (infoData.type.code === 'apply') {
      return `${infoData.applicant?.name ?? '--'} 从 ${infoData.sourceWarehouseId?.name ?? '--'} ${timeText}`;
    }
    // 退回
    if (infoData.type.code === 'returns') {
      return `${infoData.applicant?.name ?? '--'} 到 ${infoData.targetWarehouseId?.name ?? '--'} ${timeText}`;
    }
    //采购
    if (infoData.type.code === 'purchase_in') {
      return `${infoData.applicant?.name ?? '--'} 到 ${infoData.targetWarehouseId?.name ?? '--'} ${timeText}`;
    }
    return `${infoData.applicant?.name ?? '--'} 从 ${infoData.sourceWarehouseId?.name ?? '--'} 到 ${
      infoData.targetWarehouseId?.name ?? '--'
    } ${timeText}`;
  }, [infoData, timeText]);
  if (!infoData) {
    return null;
  }
  return (
    <TwCard title={infoData.type?.name} status={<TwCard.HeaderStatus value={infoData.executiveStatus?.name} />}>
      <TwCard.Content content={<TwCard.ContentText value={text} />} />
    </TwCard>
  );
};
