import { BfFormElement } from '@tw/tw-platform-react';
import { newSectionElement } from '../common/new-section';
import {
  newAddressElement,
  newAssignElement,
  newBusinessElement,
  newDeliveryTimeElement,
  newCustomerInfoElement,
  newLinkmanGenderElement,
  newLinkmanPhoneElement,
  newOrderConsultElement,
  newRemarkElement
} from '@/sale/pages/in-call-order/viewparts/common/elements';

export const getNewOrderElements = (): BfFormElement[] => {
  return [
    newSectionElement({
      id: 'orderInfo',
      name: '工单联系人',
      widgetOptions: {
        //@ts-ignore
        showTitle: false
      },
      elements: [
        newCustomerInfoElement(false, false),
        newAddressElement(true),
        newOrderConsultElement(),
        newRemarkElement()
      ]
    }),
    newSectionElement({
      id: 'consult',
      name: '工单信息',
      widgetOptions: {
        //@ts-ignore
        showTitle: false
      },
      elements: [newBusinessElement(), newDeliveryTimeElement()]
    }),
    newSectionElement({
      id: 'assign',
      name: '工单分配',
      widgetOptions: {
        //@ts-ignore
        showTitle: false
      },
      elements: [newAssignElement(true)]
    })
  ];
};
