export type PaymentChannelType = 'weixin' | 'alipay' | 'cash';
export const DefaultPaymentChannelItems: PaymentChannelType[] = ['weixin', 'alipay', 'cash'];
export interface PaymentChannelSelectPopupProps {
  // 金额
  amount: number;
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  availableChanels?: PaymentChannelType[];
  onPaymentChannelChange?: (channel: string) => void;
}
