import { TwCategory } from '@tw/tw-components-rn';
import React, { useMemo } from 'react';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { ProductProductSelectStore } from '@/product/stores/product-product-select/product-select-store';

export const PpsCategory = () => {
  const page = useBfPageStore<ProductProductSelectStore>();
  // 分类
  const categories = useStoreState(page, (state) => state.categoryList);
  const activeCategoryKey = useStoreState(page, (state) => state.activeCategoryKey);
  // 选中的产品
  const selectedProductList = useStoreState(page, (state) => state.selectedProductList);

  const categoryData = useMemo(() => {
    return page.getCategoryUiData(categories, selectedProductList);
  }, [categories, page, selectedProductList]);
  const onCategoryActive = (key: string) => {
    page.onCategorySelect(key);
  };
  return <TwCategory data={categoryData} activeKey={activeCategoryKey} onActive={onCategoryActive} />;
};
