import { TwCart } from '@tw/tw-components-rn';
import { SaleProductOrder } from '@/product/components/sale-product-order/SaleProductOrder';
import React, { useMemo } from 'react';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { ProductProductSelectStore } from '@/product/stores/product-product-select/product-select-store';
import { SelectedProduct } from '@/product/stores/product-select-common/type';
import { ProductProductDTO } from '@tw/lock-api-gen';

export const PpsCart = () => {
  const page = useBfPageStore<ProductProductSelectStore>();
  const visible = useStoreState(page, (state) => state.isProductPopupVisible);
  const selectedProductList = useStoreState(page, (state) => state.selectedProductList);
  const count = useMemo(() => page.getSumOfSelectedProduct(selectedProductList), [page, selectedProductList]);
  const onVisibleChange = (visible: boolean) => {
    page.setProductPopupVisible(visible);
  };
  const onCountChange = (product: ProductProductDTO, count: number) => {
    page.onProductCountChange(product, count);
  };
  const onProductClear = () => {
    page.onClearSelectedProduct();
  };
  const onConfirm = () => {
    page.onConfirmSelect();
  };
  return (
    <TwCart
      popupTitle={'已选产品'}
      count={count}
      isPopupVisible={visible}
      onPopupVisibleChange={onVisibleChange}
      data={selectedProductList}
      onClear={onProductClear}
      onConfirm={onConfirm}
      renderItem={({ item }: { item: SelectedProduct }) => (
        <SaleProductOrder
          quantity={item.product.quantity}
          title={item.product.name}
          description={item.product.specification}
          count={item.count}
          key={item.product.id}
          onCountChange={(count) => onCountChange(item.product, count)}
        />
      )}
    />
  );
};
