import { useSliceState } from '@tw/tw-runtime-react';
import { useCallback } from 'react';
import { SaleOrderDTO } from '@tw/lock-api-gen';
import { ScrollView } from 'react-native';
import { ResHistoryOrderItemCard } from './ResHistoryOrderItemCard';
import { TwEmpty } from '@tw/tw-components-rn';
import { ResHistoryOrderEdit } from './ResHistoryOrderEdit';
import {
  HistoryCompletedOrderSlice,
  HistoryCompletedOrderSliceState,
  HistoryOrderSlice
} from '@/res/stores/res-partner-info-preview/slices';

export const ResHistoryOrder = ({
  slice,
  historyOrderSlice
}: {
  slice: HistoryCompletedOrderSlice<any>;
  historyOrderSlice: HistoryOrderSlice<any>;
}) => {
  const orderDetailStore = slice.getOrderDetailStore();
  const historyOrder = useSliceState(slice, (state: HistoryCompletedOrderSliceState) => state.completedHistoryOrder);
  const hasSelectedOrder = useSliceState(slice, (state: HistoryCompletedOrderSliceState) => !!state.completedOrderInfo);
  const onNewAfterSaleOrder = useCallback(
    (item: SaleOrderDTO) => {
      console.log('创建新服务', item);
      slice.onAfterSaleOrderSelect(item);
    },
    [slice]
  );
  if (!historyOrder?.length) {
    return <TwEmpty />;
  }
  // 有选中的历史工单
  if (hasSelectedOrder) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <ResHistoryOrderEdit slice={slice} historyOrderSlice={historyOrderSlice} />
      </ScrollView>
    );
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {historyOrder?.map((item) => {
        return (
          <ResHistoryOrderItemCard
            orderDetailStore={orderDetailStore}
            key={item.id}
            order={item}
            onNewAfterSaleOrderPress={onNewAfterSaleOrder}
          />
        );
      })}
    </ScrollView>
  );
};
