import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TwBfForm, TwBfFormProps } from '@tw/tw-platform-rn';
import { getNewOrderElements } from './schema';
import { InCallAssignSelect } from '@/sale/components/incall-assign-select';
import { TwSection } from '@tw/tw-components-rn';
import { MaterialIcons } from '@expo/vector-icons';
import { InCallLocation } from '@/sale/pages/in-call-order/viewparts/incall-location/InCallLocation';
import { InCallCustomerGenderInput, InCallCustomerInputField } from '@/sale/components/incall-customer';
import { InCallCustomerNameInputField } from '@/sale/components/incall-customer/InCallCustomerNameInputField';
import { InCallCustomerMobileInputField } from '@/sale/components/incall-customer/InCallCustomerMobileInputField';

export const InCallConsultOrderEdit = () => {
  const page = useBfPageStore<InCallOrderStore>();
  const form = page.getHistoryConsultForm();
  const elements = useMemo(() => getNewOrderElements(), []);
  const onFieldInputValueChange: TwBfFormProps['onFieldInputValueChange'] = useCallback(
    (evt) => {
      page.onConsultOrderInputChange();
    },
    [page]
  );
  const onCancel = () => {
    page.onHistoryChaneCancel();
  };
  return (
    <TwSection
      title={'创建工单'}
      titlePrefix={<MaterialIcons name="arrow-back-ios" size={20} color="black" />}
      onTitlePress={onCancel}
    >
      <TwBfForm
        form={form}
        elements={elements}
        onFieldInputValueChange={onFieldInputValueChange}
        components={{
          InCallAssignSelect,
          InCallLocation,
          InCallCustomerInputField,
          InCallCustomerGenderInput,
          InCallCustomerNameInputField,
          InCallCustomerMobileInputField
        }}
      />
    </TwSection>
  );
};
