import { BfAppContext, BfPageStore } from '@tw/tw-runtime';
import { BfLog4js, BfLogger } from '@tw/tw-log4js';
import { CallModule } from '@/app/native/call';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { UserStore } from '@/app/core/store/user-store';
import { TwNotification } from '@/app/native/notification';
import { OrderNoticeStore } from '@/sale/stores/order-notice-store/order-notice-store';
import { IRnRouteService } from '@tw/tw-runtime-rn';
import { SplashPageStoreState } from '@/home/stores/splash/type';
import { CallPhoneData, RevisitCacheModel } from '@/app/native/call/call-module-defs';

export class SplashStore extends BfPageStore<SplashPageStoreState> {
  constructor() {
    super({
      pluginOptions: {
        devTools: {
          name: 'Splash'
        }
      }
    });
  }

  protected createLogger(): BfLogger {
    return BfLog4js.getLogger('splash');
  }

  async onLoad(param?: any): Promise<void> {
    await super.onLoad(param);
    await this.tryLoad();
  }

  private initSteps: string[] = [];

  private async tryLoad() {
    this.startErrorDisplayTimer();
    const authStorageService = BfAppContext.getInstance().getAuthStorageService();
    const token = authStorageService.getToken();
    this.logger.debug('检查是否有Token:' + token);
    this.initSteps.push('检测token');

    let handled = false;
    handled = await this.handleNoToken();
    if (handled) {
      return;
    }
    handled = await this.handleInCallRecover();
    if (handled) {
      return;
    }
    handled = await this.handleOutCallRecover();
    if (handled) {
      return;
    }

    handled = await this.handleNotificationClick();
    if (handled) {
      return;
    }
    handled = await this.handleInCallCache();
    if (handled) {
      return;
    }
    //
    this.logger.debug('进入主页面');
    this.initSteps.push('进入主页面');
    await (this.routeService as IRnRouteService).reset({
      path: ROUTE_NAMES.Home
    });
    this.initSteps.push('执行完毕');
  }

  private async handleNoToken() {
    const authStorageService = BfAppContext.getInstance().getAuthStorageService();
    const token = authStorageService.getToken();
    const routeService = BfAppContext.getInstance().getRouteService();
    // 没有登录
    if (!token) {
      this.logger.debug('没有Token进入登录页');
      this.initSteps.push('进入登录');
      await routeService.replace({
        path: ROUTE_NAMES.AuthLogin
      });
      return true;
    }
  }

  private async handleInCallRecover() {
    let data;
    try {
      data = await CallModule.getInCallRecoverData();
    } catch (e) {
      this.messageService.showError('获取通话数据出错');
      console.error(e);
    }

    this.logger.debug('检查是否有来电号码:' + JSON.stringify(data));
    if (data && data.phone) {
      this.initSteps.push('检查到来电号码，进入来电页面');
      this.logger.debug('检查到来电号码，进入来电页面');
      try {
        await this.routeService.replace({
          path: ROUTE_NAMES.SaleInCallOrder,
          query: {
            phone: data.phone,
            dataId: data.dataId
          }
        });
        return true;
      } catch (e) {
        console.error(e);
        this.logger.error('跳转到来电录入出错', e);
      }
    }
  }

  private async handleOutCallRecover() {
    let data: RevisitCacheModel | undefined;
    try {
      data = await CallModule.getLatestRevisitCache();
    } catch (e) {
      this.messageService.showError('获取通话数据出错');
      console.error(e);
    }

    this.logger.debug('检查是否有去电号码:' + JSON.stringify(data));
    if (data && data.id) {
      this.initSteps.push('检查到去电号码，进入回访页面');
      this.logger.debug('检查到去电号码，进入回访页面');
      try {
        await this.routeService.replace({
          path: ROUTE_NAMES.OutCall,
          query: {
            orderId: data.orderId,
            phone: data.phone,
            dataId: data.id
          }
        });
        return true;
      } catch (e) {
        console.error(e);
        this.logger.error('跳转到去电录入出错', e);
      }
    }
  }

  private async handleNotificationClick() {
    // 如果有工单通知
    const notification = await TwNotification.getLatestClickedNotification();
    if (notification) {
      this.initSteps.push('检测到点击了通知');
      this.logger.debug('检测到点击了通知');
    }
    const handled = OrderNoticeStore.getInstance().handleNotificationClick(notification);
    if (handled) {
      this.initSteps.push('通知已被处理');
      this.logger.debug('通知已被处理');
      return true;
    }
  }

  private async handleInCallCache() {
    const hasCallingOrderPermission = (
      BfAppContext.getInstance().getUserStore() as UserStore
    ).hasCallingOrderPermission();
    // 如果有
    const cacheCount = hasCallingOrderPermission ? await CallModule.getInCallCacheCount() : null;
    this.logger.debug('检测来电缓存数量：' + cacheCount);

    if (cacheCount && Number(cacheCount)) {
      this.initSteps.push('检测到有来电记录缓存');
      this.logger.debug('检测到有来电记录缓存，进入来电录入页面');
      await (this.routeService as IRnRouteService).reset({
        path: ROUTE_NAMES.SaleInCallCacheList
      });
      return true;
    }
  }

  destroy() {
    super.destroy();
    this.stopErrorDisplayTimer();
  }

  private timer: number | null = null;

  private startErrorDisplayTimer() {
    this.logger.debug('开始页面初始化超时提示');
    this.stopErrorDisplayTimer();
    this.timer = setTimeout(() => {
      this.setError('初始化超时');
      this.setErrorDetail(this.initSteps.join(','));
    }, 2500) as number;
  }

  private stopErrorDisplayTimer() {
    if (this.timer) {
      this.logger.debug('停止页面初始化超时提示');
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private setError(errorMessage: string | undefined) {
    this.setState({
      errorMessage
    });
  }

  private setErrorDetail(errorDetail: string | undefined) {
    this.setState({
      errorDetail
    });
  }

  async onRetry() {
    this.setError(undefined);
    this.setErrorDetail(undefined);
    this.initSteps = [];
    await this.tryLoad();
  }
}
