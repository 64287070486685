import { PaymentMode, PaymentStatus } from '@/fr/stores/payment/type';

import { PaymentChannelType } from '@/fr/components/payment/type';
import { TwResultProps } from '@tw/tw-components-rn';

export type ResultStatus = TwResultProps['status'];

export const getResultStatus = (status: PaymentStatus): ResultStatus => {
  switch (status) {
    case 'waitting':
      return 'info';
    case 'pending':
      return 'info';
    case 'failed':
      return 'error';
    case 'canceled':
      return 'error';
    case 'succeeded':
      return 'success';
    default:
      return 'info';
  }
};

export const getPaymentResultTitle = (status: PaymentStatus): string => {
  switch (status) {
    case 'waitting':
      return '待支付';
    case 'pending':
      return '支付中';
    case 'failed':
      return '支付失败';
    case 'canceled':
      return '支付取消';
    case 'succeeded':
      return '支付成功';
    default:
      return '待支付';
  }
};

// export type PaymentChannelType = 'weixin' | 'alipay' | 'cash';
// Enum: payment_channel[alipay(支付宝 App 支付), alipay_qr(支付宝正扫), alipay_scan(支付宝反扫), wx_pub(微信公众号支付), wx_lite(微信小程序支付), wx_scan(微信反扫)]
export const getPaymentChannel = (paymentType: PaymentChannelType) => {
  switch (paymentType) {
    case 'alipay':
      return 'alipay_qr';
    case 'weixin':
      return 'wx_lite';
    default:
      return 'alipay_qr';
  }
};

export const needShowCode = (mode: PaymentMode, paymentType: PaymentChannelType, status: PaymentStatus) => {
  if (mode === 'other-app') {
    return false;
  } else if (mode === 'custom-code') {
    return status === 'waitting' || status === 'pending';
  } else if (mode === 'inner-code') {
    return status === 'waitting' || status === 'pending';
  } else if (mode === 'cash') {
    return false;
  }
};
