import React from 'react';
import { View } from 'react-native';

import { BfAppContext } from '@tw/tw-runtime';
import { TwButton, TwPage } from '@tw/tw-components-rn';
import { WebView } from 'react-native-webview';
import { HelperWebConfig } from '@/app/core/setup/api-config';

export const HomeTermsAndConditions = (props) => {
  const routeService = BfAppContext.getInstance().getRouteService();

  const onConfirmClick = () => {
    routeService.back();
  };
  return (
    <TwPage translucentStatusBar={true}>
      <View style={{ flex: 1 }}>
        <WebView source={{ uri: `${HelperWebConfig.baseUrl}${HelperWebConfig.termsAndConditionsPath}` }} />
        <TwButton onPress={onConfirmClick}>确认</TwButton>
      </View>
    </TwPage>
  );
};
