import { useStoreState } from '@tw/tw-runtime-react';
import { POSITION_KEYS } from '@/app/constants/position-keys';
import { BfAppContext } from '@tw/tw-runtime';
import { useMemo } from 'react';

export function useOrderPermission() {
  const userStore = BfAppContext.getInstance().getUserStore();
  const canAssignToSelf = useStoreState(userStore, (state) => {
    const allPosition = state.userInfo.allPosition;
    if (!allPosition?.length) {
      return false;
    }
    // 只有话务
    return !allPosition.every((item) => item.id === POSITION_KEYS.TMK);
  });
  // 包含话务的角色，可以录入来电工单
  const hasInCallOrderPermission = useStoreState(userStore, (state) => {
    //
    const allPosition = state.userInfo.allPosition;
    if (!allPosition?.length) {
      return false;
    }
    return allPosition.some((item) => item.id === POSITION_KEYS.TMK);
  });
  return {
    canAssignToSelf: canAssignToSelf,
    hasInCallOrderPermission
  };
}
