import { TwRow, TwTypography } from '@tw/tw-components-rn';
import { Text } from 'react-native';

export const InCallCustomOption = (props: CustomOptionProps) => {
  const { name, gender, mobile } = props;
  return (
    <TwRow space={2} pl={3} pr={3} pt={2} pb={2}>
      <TwTypography.Text
        size={'sm'}
        color={'secondary'}
        style={{ flexWrap: 'nowrap', width: 80 }}
        numberOfLines={2}
        ellipsizeMode={'tail'}
      >{`${name ?? '--'}${gender ?? ''}`}</TwTypography.Text>
      <TwTypography.Text size={'sm'} color={'secondary'}>
        {mobile}
      </TwTypography.Text>
    </TwRow>
  );
};

interface CustomOptionProps {
  name: string;
  mobile: string;
  gender?: string;
}
