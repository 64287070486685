import { TwListItem } from '@tw/tw-components-rn';
import React from 'react';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { BfCodeScanner } from '@/demo/pages/qrscaner/bf-code-scanner';
import { BfAppContext, uid } from '@tw/tw-runtime';
import { OrderProcessDebug } from '@/mine/pages/setting/OrderProcessDebug';
import { BfLog4js } from '@tw/tw-log4js';
import { TwNotification } from '@/app/native/notification';
import { TwSound } from '@/app/native/sound';
import { CallModule } from '@/app/native/call';

export const Debug = () => {
  const routeService = BfAppContext.getInstance().getRouteService();
  const onDevicePress = () => {
    routeService.push({
      path: ROUTE_NAMES.SettingsDeviceInfo
    });
  };
  const onPushPress = async () => {
    // routeService.push({
    //   path: ROUTE_NAMES.GETUI
    // });
    await TwNotification.createChannel({
      channelId: 'test',
      name: '测试',
      description: '描述'
    });
    await TwNotification.sendNotification({
      channelId: 'test',
      // notifyId: 1,
      title: '标题',
      content: '点击进如',
      data: {
        test: '11'
      }
    });
  };
  const onScannerPress = async () => {
    const result = await BfCodeScanner.start();
    console.log('scanResult', result);
  };
  const onImageViewerPress = () => {
    routeService.push({
      path: ROUTE_NAMES.IMAGE_VIEWER
    });
  };

  const onSignPress = () => {
    // BfSignNavigator.start();
    routeService.push({
      path: ROUTE_NAMES.Sign
    });
  };

  /**
   * 来电录入
   */
  const onInCallTest = () => {
    routeService.replace({
      path: ROUTE_NAMES.SaleInCallOrder,
      query: {
        // phone: '17329236850',
        phone: '18840822826',
        dataId: 'test'
      }
    });
  };

  const onPrintLog = () => {
    const logger = BfLog4js.getLogger('logger');
    logger.error('====错误信息====', new Error('xxx错误信息'));
  };
  const onInventoryException = () => {
    routeService.push({
      path: ROUTE_NAMES.WmsInventoryException
    });
  };
  const onPlayAudio = async () => {
    TwSound.playSoundFile('new_order_alert', 'mp3');
  };

  const onGenerateCallRecord = async () => {
    //
    const ringAt = new Date(`2023-07-21T09:47:40.154Z`).valueOf() + '';
    const answeredAt = new Date(`2023-07-21T09:47:46.525Z`).valueOf() + '';
    const hangupAt = new Date(`2023-07-21T09:47:54.519Z`).valueOf() + '';
    for (let i = 0; i < 200; i++) {
      CallModule.createCallCache({
        phone: '18840822827',
        hangupAt: hangupAt,
        answeredAt: answeredAt,
        ringAt: ringAt
      });
    }
  };

  const onClearCallRecord = async () => {
    CallModule.clearAllCallCache();
  };
  const onStartAudioUpload = () => {
    CallModule.uploadAllInCallCacheModel();
  };

  const onStartRevisit = () => {
    routeService.push({
      path: ROUTE_NAMES.OutCall
    });
  };
  return (
    <>
      <OrderProcessDebug />
      <TwListItem pressable={true} showArrow={true} title={'设备信息（调试）'} onPress={onDevicePress}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'推送（调试）'} onPress={onPushPress}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'扫码（调试）'} onPress={onScannerPress}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'签名（调试）'} onPress={onSignPress}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'来电录入（调试）'} onPress={onInCallTest}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'日志测试'} onPress={onPrintLog}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'库存异常页面'} onPress={onInventoryException}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'播放音频'} onPress={onPlayAudio}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'生成来电记录'} onPress={onGenerateCallRecord}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'清空来电记录'} onPress={onClearCallRecord}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'开始上传任务'} onPress={onStartAudioUpload}></TwListItem>
      <TwListItem pressable={true} showArrow={true} title={'回访测试'} onPress={onStartRevisit}></TwListItem>
      <TwListItem
        pressable={true}
        showArrow={true}
        title={'通知（调试）'}
        onPress={() => {
          routeService.push({
            path: ROUTE_NAMES.NotificationTest
          });
        }}
      ></TwListItem>
      <TwListItem
        pressable={true}
        showArrow={true}
        title={'图片预览（调试）'}
        onPress={onImageViewerPress}
      ></TwListItem>
    </>
  );
};
