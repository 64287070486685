import { BfPageStore, IPushMessageEvent, IPushMessagePayload } from '@tw/tw-runtime';
import { AppState, Platform, Vibration } from 'react-native';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { NoticeConsts } from '@tw/lock-api-gen';
import { NotificationService } from '@/app/core/services/notification-service';
import { TwNotification } from '@/app/native/notification';
import { TwSound } from '@/app/native/sound';
import { NotificationModel } from '@/app/native/notification/notification-module-defs';
import { IRnRouteService } from '@tw/tw-runtime-rn';

export class OrderNoticeStore extends BfPageStore {
  private static instance: OrderNoticeStore = null;

  public static getInstance() {
    if (this.instance === null) {
      this.instance = new OrderNoticeStore();
    }
    return this.instance;
  }

  constructor() {
    super({
      pluginOptions: {
        devTools: {
          name: '工单通知'
        }
      }
    });
  }

  handleMessage(message: IPushMessageEvent): boolean {
    if (this.isOrderNotice(message)) {
      this.onOrderPushEvent(message);
      return true;
    }
    return false;
  }

  protected isOrderNotice(message: IPushMessageEvent) {
    return message.message.payload?._noticeBot == NoticeConsts.bot_order;
  }

  protected onOrderPushEvent(message: IPushMessageEvent) {
    // 订单通知
    if (message.message.payload._templateId === NoticeConsts.message_newOrder) {
      this.playNewOrderSound().then();
      // 发送通知
      this.tryGoOrderAlertPage(message);
      return;
    }
    // 话务员催单-工程师催单通知
    if (message.message.payload._templateId === NoticeConsts.message_urgenOrder) {
      //
      this.playOrderUrgentSound().then();
      // 发送通知
      NotificationService.getInstance().scheduleOrderUrgentNotificationAsync(message);
      return;
    }
    //工程师拒接-话务员通知
    if (message.message.payload._templateId === NoticeConsts.message_regectOrder) {
      this.sendOrderNotice(message);
      return;
    }
  }

  private tryGoOrderAlertPage(message: IPushMessageEvent) {
    // 应用处于后台，发送通知
    if (AppState.currentState === 'background') {
      console.log('后台推送通知');
      this.showAlertNotification(message);
      return;
    }
    // 应用处于前台，直接跳转到工单确认页面
    this.goOrderAlertPage(message.message.payload.order?.id);
  }

  private async playNewOrderSound() {
    Vibration.vibrate([0, 500, 200, 500]);
    await TwSound.playSoundFile('new_order_alert', 'mp3');
  }

  private async playOrderUrgentSound() {
    Vibration.vibrate([0, 500, 200, 500]);
    //TODO: 加急工单声音播放
    await TwSound.playSoundFile('order_urgent', 'mp3');
  }
  private showAlertNotification(message: IPushMessageEvent) {
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      console.log('新工单推送通知...');
      NotificationService.getInstance().scheduleNewOrderNotificationAsync(message);
    }
  }

  handleNotificationClick(notification: NotificationModel) {
    if (!notification) {
      return false;
    }
    const payload = notification.data as IPushMessagePayload;
    const templateId = payload._templateId;
    // 新订单
    if (templateId === NoticeConsts.message_newOrder) {
      this.goOrderAlertPage(payload.order?.id);
      return true;
    }
    if ([NoticeConsts.message_urgenOrder, NoticeConsts.message_regectOrder].includes(templateId)) {
      this.routeService.push({
        path: ROUTE_NAMES.SaleDocOrderOrderInfo,
        query: {
          id: payload.order?.id
        }
      });
      return true;
    }
    return false;
  }

  goOrderAlertPage(orderId: string, replace = true) {
    if (replace) {
      (this.routeService as IRnRouteService).reset({
        path: ROUTE_NAMES.CollabOrder,
        query: {
          orderId: orderId
        }
      });
    } else {
      this.routeService.push({
        path: ROUTE_NAMES.CollabOrder,
        query: {
          orderId: orderId
        }
      });
    }
  }

  private async sendOrderNotice(message: IPushMessageEvent) {
    NotificationService.getInstance().scheduleOrderCommonNotificationAsync(message);
  }
}
