import { AbstractRnApiService } from '@tw/tw-runtime-rn';
import { BfAppContext } from '@tw/tw-runtime';
import { ApiConfig } from '@/app/core/setup/api-config';

export class ApiService extends AbstractRnApiService {
  getBaseUrl(): string {
    return ApiConfig.baseUrl;
  }

  onNetworkError(error: any) {
    console.error('ApiService onNetworkError', error);
    super.onNetworkError(error);
  }

  handleResponseError(rawRes: any): any {
    console.log('请求报错', rawRes);
    this.logger.error('请求出错，返回数据' + JSON.stringify(rawRes.data));
    return super.handleResponseError(rawRes);
  }

  protected onTokenInvalidateError() {
    this.authStorageService.removeToken();
    this.messageService.hideLoading();
    this.messageService.hideConfirm();
    BfAppContext.getInstance().getAppStore().logout(true);
  }
}
