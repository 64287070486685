import { FrPaymentDivideDTO, FrRefundDivideDTO } from '@tw/lock-api-gen';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  TwButton,
  TwCheckbox,
  TwColumn,
  TwFormItem,
  TwInput,
  TwInputNumber,
  TwPopup,
  TwPopupHandle,
  TwPortal,
  TwRow,
  TwTextArea,
  TwTypography
} from '@tw/tw-components-rn';

import { EnumValue } from '@tw/tw-runtime';
import { OrderRefundPopupProps } from '@/sale/components/order-refund-popup/type';
import { TwBfInput } from '@tw/tw-platform-rn';
import { getDataSource } from './form-schema';
import { styles } from './styles';

export const OrderRefundPopup: FC<OrderRefundPopupProps> = (props) => {
  const { ammount, visible, onVisibleChange, onSubmit, orderDivideData } = props;

  console.log('OrderRefundPopup', orderDivideData);
  const ref = useRef<TwPopupHandle>();

  const [refundAmount, setRefundAmount] = useState<number>(0);
  //  * - desc: Enum: refund_amount_type[full(全额退款), part(部分退款)]
  const [refundAmountType, setRefundAmountType] = useState<'full' | 'part'>('full');
  const [reason, setReason] = useState<string>();
  const [divides, setDivides] = useState<FrRefundDivideDTO[]>([]);

  useEffect(() => {
    if (visible) {
      ref.current?.open();
    } else {
      ref.current?.close();
    }
  }, [visible]);
  const onRefundConfirm = () => {
    console.log('onRefundConfirm', refundAmountType, refundAmount, divides, reason);
    // TODO: refundAmount 和 分账什么关系
    onSubmit?.(refundAmountType as unknown as EnumValue, refundAmount, divides, reason);
    onVisibleChange?.(false);
  };

  const onCountChange = (item: FrPaymentDivideDTO, value: number) => {
    console.log('onCountChange', item, value);
  };

  return (
    <TwPortal>
      <TwPopup
        withHandle={false}
        adjustToContentHeight={true}
        title={'退款金额'}
        ref={ref}
        onClose={() => onVisibleChange?.(false)}
        footer={
          <TwButton size={'sm'} onPress={onRefundConfirm}>
            确定
          </TwButton>
        }
      >
        <TwColumn space={3} style={styles.contentContailer}>
          <TwTypography.Text size={'4xl'} style={styles.amount}>
            {'¥' + ammount}
          </TwTypography.Text>
          <TwRow space={2}>
            <TwCheckbox checked={refundAmountType === 'full'} onChange={() => setRefundAmountType('full')}>
              <TwTypography.Text size={'sm'}>全额退款</TwTypography.Text>
            </TwCheckbox>
          </TwRow>
          <TwRow space={2}>
            <TwCheckbox checked={refundAmountType === 'part'} onChange={() => setRefundAmountType('part')}>
              <TwTypography.Text size={'sm'}>部分退款</TwTypography.Text>
            </TwCheckbox>
          </TwRow>
          {refundAmountType === 'part' && orderDivideData.length > 0 && (
            <TwColumn style={styles.listContainer} space={1}>
              <TwRow flex={1} alignItems={'center'} justifyItems={'center'}>
                <TwTypography.Text size={'xs'} color={'third'} style={styles.listTitle}>
                  退款方
                </TwTypography.Text>
                <TwTypography.Text size={'xs'} color={'third'} style={styles.listTitle}>
                  交易金额
                </TwTypography.Text>
                <TwTypography.Text size={'xs'} color={'third'} style={styles.listTitle}>
                  退款金额
                </TwTypography.Text>
              </TwRow>
              {orderDivideData.map((item: FrPaymentDivideDTO, index) => {
                return (
                  <TwRow flex={1} alignItems={'center'} justifyItems={'center'} key={index}>
                    <TwTypography.Text size={'xs'} color={'base'} style={styles.listName} numberOfLines={1}>
                      {item.name}
                    </TwTypography.Text>
                    <TwTypography.Text size={'xs'} color={'base'} style={styles.listTitle}>
                      {'¥' + item.amount}
                    </TwTypography.Text>
                    <TwRow style={styles.listTitle}>
                      <TwTypography.Text size={'xs'} color={'base'} style={styles.listTitle}>
                        ¥
                      </TwTypography.Text>
                      <TwInputNumber
                        style={styles.input}
                        step={false}
                        precision={2}
                        inputWidth={20}
                        // value={item.refundAmount}
                        onChange={(value: number) => onCountChange(item, value)}
                      />
                    </TwRow>
                  </TwRow>
                );
              })}
            </TwColumn>
          )}
        </TwColumn>
        <TwFormItem label={'备注'} layout="vertical" labelStyle={styles.label}>
          <TwTextArea placeholder="请输入备注信息" autoCompleteType={undefined}></TwTextArea>
        </TwFormItem>
      </TwPopup>
    </TwPortal>
  );
};
