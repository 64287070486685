import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row'
  }
});

export const filterItemStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal,
    paddingVertical: TwTheme.values.spaces['1'],
    borderBottomWidth: 1,
    borderBottomColor: TwTheme.values.borderColors.base,
    backgroundColor: TwTheme.values.backgroundColors.componentGray
  },
  label: {
    ...TwTheme.values.textSizes.xs,
    color: TwTheme.values.textColors.base
  },
  label__active: {
    color: TwTheme.values.colors.grayBlue['5']
  }
});
