import { CallModule } from '@/app/native/call';
import { RnAuthStorageService } from '@tw/tw-runtime-rn';

export class AuthTokenService extends RnAuthStorageService {
  setToken(token: string) {
    super.setToken(token);
    CallModule.setAuthToken(token);
  }
  removeToken() {
    super.removeToken();
    CallModule.setAuthToken(undefined);
  }

  // getToken(): string | null {
  //   return '48c84b6f-bd99-4c84-aff0-116b799d40d6';
  // }
}
