import { BfAbstractPlaceService, BfDirectionService, BfProperties } from '@tw/tw-runtime';
import { Platform } from 'react-native';
import { LogBox } from 'react-native';
if (Platform.OS === 'web') {
  BfProperties.set(BfDirectionService.PROP_API_PREFIX, '/_AMapService');
  BfProperties.set(BfAbstractPlaceService.PROP_API_PREFIX, '/_AMapService');
} else {
  BfProperties.set(BfDirectionService.PROP_API_PREFIX, 'https://restapi.amap.com');
  BfProperties.set(BfAbstractPlaceService.PROP_API_PREFIX, 'https://restapi.amap.com');
}

if (Platform.OS !== 'web') {
  LogBox.ignoreLogs(['new NativeEventEmitter']);
}
