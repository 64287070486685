import { EnumValue } from '@tw/tw-runtime';
import { InCallAssignTypeValue } from '@/sale/stores/in-call-order/type';

export function getAssignTypeData(
  config: { canAssignToPool: boolean; canAssignToSelf: boolean } = { canAssignToPool: true, canAssignToSelf: true }
) {
  const data: EnumValue[] = [];
  data.push({
    code: InCallAssignTypeValue.manager,
    name: '负责人'
  });
  data.push({
    code: InCallAssignTypeValue.group,
    name: '工单组'
  });
  if (config.canAssignToPool) {
    data.push({
      code: InCallAssignTypeValue.pool,
      name: '工单池'
    });
  }
  if (config.canAssignToSelf) {
    data.push({
      code: InCallAssignTypeValue.self,
      name: '自己'
    });
  }

  return data;
}
