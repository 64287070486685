import { useSliceState } from '@tw/tw-runtime-react';
import React, { useEffect, useMemo } from 'react';
import { TwBfForm, TwBfFormProps } from '@tw/tw-platform-rn';
import { getNewOrderElements } from './schema';
import { InCallAssignSelect } from '@/sale/components/incall-assign-select';
import { TwSection } from '@tw/tw-components-rn';
import { MaterialIcons } from '@expo/vector-icons';
import {
  HistoryCompletedOrderSlice,
  HistoryCompletedOrderSliceState,
  HistoryOrderSlice
} from '@/res/stores/res-partner-info-preview/slices';
import { HistoryLocationSelect } from '@/res/viewparts/history-location-select/HistoryLocationSelect';
import { InCallCustomerGenderInput, InCallCustomerInputField } from '@/sale/components/incall-customer';
import { InCallCustomerNameInputField } from '@/sale/components/incall-customer/InCallCustomerNameInputField';
import { InCallCustomerMobileInputField } from '@/sale/components/incall-customer/InCallCustomerMobileInputField';

export const ResHistoryOrderEdit = ({
  slice,
  historyOrderSlice
}: {
  slice: HistoryCompletedOrderSlice<any>;
  historyOrderSlice: HistoryOrderSlice<any>;
}) => {
  // const page = useBfPageStore<InCallOrderStore>();
  const form = slice.getForm();

  const elements = useMemo(() => getNewOrderElements(), []);
  const data = useSliceState(slice, (state: HistoryCompletedOrderSliceState) => state.completedOrderInfo);
  useEffect(() => {
    form.setValues(data);
  }, [data, form]);
  const onFieldInputValueChange: TwBfFormProps['onFieldInputValueChange'] = (evt) => {};
  const onCancel = () => {
    slice.onAfterSaleOrderCancel();
  };
  return (
    <TwSection
      title={'创建售后工单'}
      titlePrefix={<MaterialIcons name="arrow-back-ios" size={20} color="black" />}
      onTitlePress={onCancel}
      bodyStyle={{ backgroundColor: 'transparent', paddingVertical: 0 }}
    >
      <TwBfForm
        form={form}
        elements={elements}
        onFieldInputValueChange={onFieldInputValueChange}
        components={{
          InCallAssignSelect,
          InCallLocation: HistoryLocationSelect,
          InCallCustomerInputField,
          InCallCustomerGenderInput,
          InCallCustomerNameInputField,
          InCallCustomerMobileInputField
        }}
        scope={{
          historyOrderSlice: historyOrderSlice
        }}
      />
    </TwSection>
  );
};
