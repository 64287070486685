export const millisToStr = (status: any, millis: number): string => {
  if (status === 'missed') {
    return '未接听';
  }
  const totalSeconds = Math.round(millis / 1000);
  if (totalSeconds < 60) {
    return `通话时长: ${totalSeconds}秒`;
  } else if (totalSeconds < 3600) {
    const m = Math.floor(totalSeconds / 60);
    const s = totalSeconds % 60;
    return `通话时长: ${m}分${s}秒`;
  } else {
    const h = Math.floor(totalSeconds / 3600);
    const totalSeconds2 = totalSeconds % 3600;
    const m = Math.floor(totalSeconds2 / 60);
    const s = totalSeconds2 % 60;
    return `通话时长: ${h}小时${m}分${s}秒`;
  }
};
