import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  container: {
    backgroundColor: TwTheme.values.primaryColors.base,
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal,
    justifyContent: 'space-between',
    paddingVertical: TwTheme.values.spaces['2'],
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    color: TwTheme.values.textColors.white
  }
});
