// 测试服务器
export const GaodekeysAlpha = {
  jsKey: '32a44271e877c814a5476c43adbfb4fe',
  webKey: 'f51d7ddfd84e60c35f36609d54a159d5',
  mapKey: 'bd1d2bf9f4da5c5df90513cf96d61aa5'
};
//开发服务器
export const GaodekeysDev = {
  jsKey: '981d27a1dc0d5fbfd5c293a4d3edce7d',
  webKey: '0beabf754486ea4f19671d7a954da7ba',
  mapKey: 'e5767a6d9306f4b54d82b7feef2ea384'
};

export const Gadekeys = GaodekeysAlpha;
