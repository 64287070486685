import React, { FC } from 'react';
import { WorkplaceMenuProps } from '@/home/pages/workplace/viewparts/workplace-menu/type';
import { TwIcon, TwIconButton } from '@tw/tw-components-rn';
import { TwTheme } from '@tw/tw-runtime-rn';

export const WorkPlaceMenu: FC<WorkplaceMenuProps> = (props) => {
  const { menu, onMenuPress } = props;
  return (
    <TwIconButton
      direction={'vertical'}
      fillColor={'#F7F8FA'}
      size={'md'}
      icon={<TwIcon icon={menu.iconDefault} style={{ color: TwTheme.values.primaryColors.base, fontSize: 25 }} />}
      style={{ marginBottom: 20, alignSelf: 'center' }}
      label={menu.name}
      onPress={() => onMenuPress?.(menu)}
    />
  );
};
