import { BfFormElement } from '@tw/tw-platform-react';

export const getSchema = (): BfFormElement[] => {
  return [
    {
      id: 'sceneLinkmanNameWithGender',
      type: 'text',
      name: '联系人',
      editable: false
    },
    {
      id: 'sceneLinkmanPhone',
      type: 'phone',
      name: '联系电话',
      editable: false
    },
    {
      id: 'address',
      type: 'coordinates',
      name: '联系地址'
    }
  ];
};
