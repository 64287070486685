import { TwListItemContent, TwRow } from '@tw/tw-components-rn';
import { Text, View } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { TwTheme } from '@tw/tw-runtime-rn';
import { styles } from './styles';
import { SaleDocOrderDTO } from '@tw/lock-api-gen';
import { FC } from 'react';
import { ElementUtils } from '@tw/tw-platform-react';
import dayjs from 'dayjs';

export const HistoryOrderSummary: FC<HistoryOrderSummaryProps> = (props) => {
  const { order } = props;
  const title = ElementUtils.getText(order, { type: 'coordinates', id: 'address' });
  return (
    <TwRow space={1} style={styles.summaryContainer}>
      <MaterialIcons name="description" size={24} color={TwTheme.values.listItem.avatarBackgroundColor.active} />
      <TwListItemContent title={title} content={<Text style={styles.time}>{formatTime(order.createTime)}</Text>} />
    </TwRow>
  );
};

function formatTime(time: number | undefined) {
  if (!time) {
    return;
  }
  return dayjs(time).format('YYYY-MM-DD HH:mm');
}
export interface HistoryOrderSummaryProps {
  order: SaleDocOrderDTO;
}
