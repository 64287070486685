import React, { FC, useEffect, useRef, useState } from 'react';
import { AuditReasonPopupProps } from './type';
import { TwButton, TwInput, TwPopup, TwPopupHandle, TwPortal } from '@tw/tw-components-rn';
import { View } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
import { isNil } from 'lodash-es';
import { BfAppContext } from '@tw/tw-runtime';

export const AuditReasonPopup: FC<AuditReasonPopupProps> = (props) => {
  const { visible, onVisibleChange, onSubmit } = props;
  const ref = useRef<TwPopupHandle>();
  const [reason, setReason] = useState<string>();
  useEffect(() => {
    if (visible) {
      ref.current?.open();
    } else {
      ref.current?.close();
    }
  }, [visible]);
  const onAbandonConfirm = () => {
    if (isNil(reason) || !reason.trim()) {
      BfAppContext.getInstance().getMessageService().showError('请填写驳回原因');
      return;
    }
    onSubmit?.(reason);
    onVisibleChange?.(false);
  };
  return (
    <TwPortal>
      <TwPopup
        withHandle={false}
        adjustToContentHeight={true}
        title={'驳回原因'}
        ref={ref}
        onClose={() => onVisibleChange?.(false)}
        footer={
          <TwButton size={'sm'} onPress={onAbandonConfirm}>
            确定
          </TwButton>
        }
      >
        <View style={{ paddingHorizontal: TwTheme.values.spaces.pageHorizontal }}>
          <TwInput value={reason} onChangeText={setReason} placeholder={'请输入'} />
        </View>
      </TwPopup>
    </TwPortal>
  );
};
