import { SelectedProduct } from '../product-select-common/type';
import { ProductSelectNavigator } from '@/product/stores/product-product-select/product-select-navigator';
import { ProductSelectBaseStore } from '@/product/stores/product-select-common/product-select-base-store';
import { ProductSelectSearchNavigator } from '@/product/stores/product-select-search/product-select-search-navigator';
import { BfIntentResultType } from '@tw/tw-runtime';

export class ProductProductSelectStore extends ProductSelectBaseStore {
  protected doConfirmSelect(selectedProductList: SelectedProduct[]) {
    ProductSelectNavigator.finish(selectedProductList);
    this.routeService.back();
  }

  protected cancelSelect() {
    ProductSelectNavigator.cancel();
  }

  protected getInitSelectedProducts(): SelectedProduct[] | undefined {
    return ProductSelectNavigator.getIntent()?.products;
  }

  async onSearchFocus() {
    const { selectedProductList } = this.getState();
    const res = await ProductSelectSearchNavigator.start({
      products: selectedProductList
    });
    if (res.type === BfIntentResultType.OK) {
      this.setSelectedProducts(res.data);
    }
  }
}
