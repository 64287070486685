import { useBfPageStore } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { TwPage, TwButton, TwRow } from '@tw/tw-components-rn';

export const NewOrderFooter = () => {
  const store = useBfPageStore<InCallOrderStore>();
  return (
    <TwPage.Footer>
      <TwRow space={1}>
        <TwButton
          variant={'outline'}
          size={'sm'}
          flex={1}
          onPress={() => {
            store.onNewOrderConsult();
          }}
        >
          咨询
        </TwButton>
        <TwButton
          variant={'outline'}
          size={'sm'}
          flex={1}
          onPress={() => {
            store.onNewOrderSendOrder();
          }}
        >
          派单
        </TwButton>
      </TwRow>
    </TwPage.Footer>
  );
};
