import { BfAppContext, EnumValue, newEnumValue } from '@tw/tw-runtime';
import { FC, useEffect, useMemo, useState } from 'react';
import { HrEmployeeDTO, HrTeamDTO, OrderExecutorTypeEnumValue, SaleDocOrderPoolDTO } from '@tw/lock-api-gen';
import { useStoreState } from '@tw/tw-runtime-react';

import { InCallAssignGroupSelect } from '@/sale/components/incall-assign-select/InCallAssignGroupSelect';
import { InCallAssignManager } from '@/sale/components/incall-assign-select/InCallAssignManager';
import { InCallAssignPoolSelect } from '@/sale/components/incall-assign-select/InCallAssignPoolSelect';
import { InCallAssignSelectProps } from '@/sale/components/incall-assign-select/type';
import { InCallAssignSelf } from '@/sale/components/incall-assign-select/InCallAssignSelfDisplay';
import { InCallAssignTypeValue } from '@/sale/stores/in-call-order/type';
import { TwBfEnum } from '@tw/tw-platform-rn';
import { TwColumn } from '@tw/tw-components-rn';
import { View } from 'react-native';
import { getAssignTypeData } from '@/sale/components/incall-assign-select/data';
import { styles } from './styles';
import { useOrderPermission } from '@/app/hooks/use-order-permission';
import { isNil } from 'lodash-es';

/**
 * 指派类型选择
 * @param props
 * @constructor
 */
export const InCallAssignSelect: FC<InCallAssignSelectProps> = (props) => {
  const { canAssignToPool, canAssignToSelf: canAssignToSelfProp, value, orderAddress, onChange } = props;
  const userStore = BfAppContext.getInstance().getUserStore();
  const userInfo = useStoreState(userStore, (state) => state.userInfo);
  const [assignType, setAssignType] = useState<EnumValue>();

  const { canAssignToSelf: canAssignToSelfPermission } = useOrderPermission();
  const canAssignToSelf = useMemo(() => {
    if (!isNil(canAssignToSelfProp)) {
      return canAssignToSelfProp;
    }
    return canAssignToSelfPermission;
  }, [canAssignToSelfPermission, canAssignToSelfProp]);

  const assignData = useMemo(
    () => getAssignTypeData({ canAssignToPool, canAssignToSelf }),
    [canAssignToPool, canAssignToSelf]
  );

  useEffect(() => {
    const first = assignData?.[0];
    if (!first) {
      return;
    }
    setAssignType(first);
  }, [assignData]);
  const onAssignManagerChange = (value: HrEmployeeDTO | undefined) => {
    if (!value) {
      onChange?.(undefined);
      return;
    }
    //指派
    onChange?.({
      executor: value,
      executorType: newEnumValue(OrderExecutorTypeEnumValue.internal)
    });
  };
  const onAssignGroupChange = (value: HrTeamDTO | undefined) => {
    onChange?.({
      teamId: value,
      executorType: newEnumValue(OrderExecutorTypeEnumValue.team)
    });
  };
  const onAssignTypeChange = (type: EnumValue) => {
    setAssignType(type);
    // 指派自己，直接将指派数据变化公布出去
    if (type.code === InCallAssignTypeValue.self) {
      onChange?.({
        executor: userInfo.employee,
        executorType: newEnumValue(OrderExecutorTypeEnumValue.internal)
      });
      return;
    }
    // 切换选项后，清空指派信息
    onChange?.(undefined);
  };
  // 加载到工单池，触发change
  const onGetPool = (pool: SaleDocOrderPoolDTO | undefined) => {
    if (assignType?.code !== InCallAssignTypeValue.pool) {
      return;
    }
    onChange?.({
      orderPoolId: pool,
      executorType: newEnumValue(OrderExecutorTypeEnumValue.pool)
    });
  };

  return (
    <TwColumn>
      <View style={styles.typeContainer}>
        <TwBfEnum
          title={'类型'}
          value={assignType}
          enumList={assignData}
          size={'md'}
          mode={'inline'}
          itemLayout={'blockRow'}
          itemMode={'capsule'}
          onChange={onAssignTypeChange}
        />
      </View>
      {assignType?.code === InCallAssignTypeValue.manager ? (
        <InCallAssignManager value={value?.executor} onChange={onAssignManagerChange} />
      ) : null}
      {assignType?.code === InCallAssignTypeValue.group ? (
        <InCallAssignGroupSelect value={value?.teamId} onChange={onAssignGroupChange} />
      ) : null}
      {/*工单池*/}
      {canAssignToPool && assignType?.code === InCallAssignTypeValue.pool ? (
        <InCallAssignPoolSelect orderAddress={orderAddress} onGetPool={onGetPool} />
      ) : null}
      {/*  指派给自己*/}
      {assignType?.code === InCallAssignTypeValue.self ? <InCallAssignSelf employee={userInfo?.employee} /> : null}
    </TwColumn>
  );
};
