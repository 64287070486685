import React, { FC } from 'react';
import { AuditReasonPopupProps } from './type';

import { AuditReasonPopup as ReasonPopup } from '@/wms/common/components/audit-reason-popup';
import { useStoreState } from '@tw/tw-runtime-react';

export const AuditReasonPopup: FC<AuditReasonPopupProps> = (props) => {
  const { store } = props;
  const visible = useStoreState(store, (state) => state.isAuditReasonVisible ?? false);
  return (
    <ReasonPopup
      visible={visible}
      onVisibleChange={(visible) => {
        store.setAuditPopupVisible(visible);
      }}
      onSubmit={(reason) => {
        store.onAuditSubmit(reason);
      }}
    />
  );
};
