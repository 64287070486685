import { TwPage, TwButton } from '@tw/tw-components-rn';
import { useBfPageStore } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';

export const HangupFooter = () => {
  const store = useBfPageStore<InCallOrderStore>();
  const onHangup = () => {
    store.onHangupPress();
  };
  return (
    <TwPage.Footer>
      <TwButton variant={'outline'} colorScheme="secondary" onPress={onHangup}>
        挂断
      </TwButton>
    </TwPage.Footer>
  );
};
