import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  addressContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  province: {
    flex: 1
  },
  icon: {
    color: TwTheme.values.textColors.third
  },
  addressDetail: {
    ...TwTheme.values.textSizes.sm,
    color: TwTheme.values.textColors.base,
    padding: 0
  }
});
