import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { InCallLocationSelect } from '@/sale/components/incall-location-select/InCallLocationSelect';
import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import { Coordinates } from '@tw/tw-runtime';
import { FC } from 'react';
import { TwFormItem } from '@tw/tw-components-rn';
import { observer, useField } from '@formily/react';
import { Field } from '@formily/core';

export const InCallLocation: FC<TwBfFieldRcProps<Coordinates>> = observer(
  (props) => {
    const { value, onChange } = props;
    const store = useBfPageStore<InCallOrderStore>();
    const historyOrder = useStoreState(store, (state) => state.historyOrder);
    const field = useField() as Field;
    return (
      <TwFormItem
        showTitle={false}
        errors={field.selfErrors}
        showError={(field.selfErrors?.length ?? 0) > 0}
        paddingHorizontal={0}
      >
        <InCallLocationSelect historyOrder={historyOrder} value={value} onChange={onChange} />
      </TwFormItem>
    );
  },
  {
    displayName: 'InCallLocation'
  }
);
