import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { useCallback } from 'react';
import { SaleOrderDTO } from '@tw/lock-api-gen';
import { ScrollView, View } from 'react-native';
import { HistoryOrderItemCard } from './HistoryOrderItemCard';
import { TwEmpty, TwLoadingBlock } from '@tw/tw-components-rn';
import { InCallHistoryOrderEdit } from './InCallHistoryOrderEdit';

export const InCallHistoryOrder = () => {
  const store = useBfPageStore<InCallOrderStore>();
  const orderDetailStore = store.getOrderDetailStore();
  const isLoadingCompletedHistoryOrder = useStoreState(store, (state) => state.isLoadingCompletedHistoryOrder);
  const historyOrder = useStoreState(store, (state) => state.completedHistoryOrder);
  const hasSelectedOrder = useStoreState(store, (state) => !!state.hasCompleteOrderInfo);
  const onNewAfterSaleOrder = useCallback(
    (item: SaleOrderDTO) => {
      console.log('创建新服务', item);
      store.onAfterSaleOrderSelect(item);
    },
    [store]
  );
  if (isLoadingCompletedHistoryOrder) {
    return <TwLoadingBlock />;
  }
  if (!historyOrder?.length) {
    return <TwEmpty />;
  }
  // 有选中的历史工单
  if (hasSelectedOrder) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <InCallHistoryOrderEdit />
      </ScrollView>
    );
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {historyOrder?.map((item) => {
        return (
          <HistoryOrderItemCard
            orderDetailStore={orderDetailStore}
            key={item.id}
            order={item}
            onNewAfterSaleOrderPress={onNewAfterSaleOrder}
          />
        );
      })}
    </ScrollView>
  );
};
