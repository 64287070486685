import { ActionType, HrDocPositionPositionInfoLoadParam, HrDocPositionPositionInfoStore } from './types';
import { BfAppContext, BfPageStore } from '@tw/tw-runtime';
import { HrPositionRestApiService, HrPositionService } from '@tw/lock-api-gen';

export class HrDocPositionPositionInfoPage extends BfPageStore<HrDocPositionPositionInfoStore> {
  private hrPositionFunc: HrPositionService;
  private hrPositionService: HrPositionRestApiService;

  constructor() {
    super({
      pluginOptions: {
        devTools: {
          name: 'HrDocPositionPositionInfoPage'
        }
      }
    });

    const api = BfAppContext.getInstance().getApiService();
    this.hrPositionFunc = new HrPositionService(api);
    this.hrPositionService = new HrPositionRestApiService(api);
  }

  private positionId;

  protected getInitialState(): HrDocPositionPositionInfoStore {
    return {
      ...super.getInitialState(),
      position: {},
      mobileTreeDataSchema: [],
      webTreeDataSchema: [],
      permit: [],
      actionType: 'create'
    };
  }

  async onLoad(params?: HrDocPositionPositionInfoLoadParam) {
    await super.onLoad(params);
    const { positionId } = params || {};

    this.setLoading(true);

    let position;
    let permit;
    if (positionId) {
      this.positionId = positionId;
      position = await this.hrPositionService.info({ id: positionId });
      permit = await this.hrPositionFunc.positionPermission({ menuGroupId: positionId });
    }

    // 获取treeData信息 schema
    const mobileTreeDataSchema = await this.hrPositionFunc.allPermission({ menuGroupId: 'mobile' });
    const webTreeDataSchema = await this.hrPositionFunc.allPermission({ menuGroupId: 'web' });

    this.setState((state) => {
      if (positionId) {
        state.position = position;
        state.actionType = 'update';
        state.permit = permit?.ids || [];
      } else {
        state.actionType = 'create';
      }

      state.mobileTreeDataSchema = mobileTreeDataSchema;
      state.webTreeDataSchema = webTreeDataSchema;

      return state;
    });

    this.setLoading(false);
  }

  async onNameChange(name) {
    this.setState((state) => {
      state.position.name = name;
      return state;
    });
  }

  async onRemarkChange(remark) {
    this.setState((state) => {
      state.position.remark = remark;
      return state;
    });
  }

  async onActiveChange(active) {
    this.setState((state) => {
      state.position.active = active;
      return state;
    });
  }

  async onDataPermitChange(value: string[], type: 'mobile' | 'web') {
    this.setState((state) => {
      state.permit = value;
      return state;
    });
  }

  async onSave() {
    this.messageService.showLoading('保存中...');
    if (this.positionId) {
      try {
        await this.hrPositionFunc.update({
          position: this.getState().position,
          permission: this.getState().permit
        });

        this.messageService.showSuccess('保存角色成功');
        this.getOpenerChannel().emitEvent('update');
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        await this.hrPositionFunc.create({
          position: this.getState().position,
          permission: this.getState().permit
        });
        this.messageService.showSuccess('新增角色成功');
        this.getOpenerChannel().emitEvent('update');
        this.routeService.back();
      } catch (e) {
        console.error(e);
      }
    }
    this.messageService.hideLoading();
  }
}
