import { Avatar, theme } from 'native-base';

import { ItemComponentType } from '@tw/tw-components-rn';
import React from 'react';
import { RoleData } from './type';

export const RoleIcon: ItemComponentType<RoleData> = ({ value: role }) => {
  return (
    <Avatar bg={theme.colors.primary[500]} size={'sm'} source={undefined}>
      {role?.name?.charAt(0).toUpperCase() ?? ''}
    </Avatar>
  );
};
