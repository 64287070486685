import { BfAbstractIntentNavigator, BfIntentResult } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { BfStructureSelectedWithCount } from '@tw/tw-platform-react';
import { ProductProductDTO } from '@tw/lock-api-gen/dist/types/product';

class Navigator extends BfAbstractIntentNavigator<
  BfStructureSelectedWithCount<ProductProductDTO>[],
  BfStructureSelectedWithCount<ProductProductDTO>[]
> {
  get ROUTE_NAME() {
    return ROUTE_NAMES.ProductDocProductProductProductChoose;
  }

  start(
    intent?: BfStructureSelectedWithCount<ProductProductDTO>[] | undefined
  ): Promise<BfIntentResult<BfStructureSelectedWithCount<ProductProductDTO>[]>> {
    console.log('InventoryCompanySelectNavigator start ', intent);
    return super.start(intent);
  }
}

/**
 * 库存产品选择
 */
export const ProductProductChooseNavigator = new Navigator();
