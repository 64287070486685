import { Text, View } from 'react-native';
import { TwButton, TwInput, TwPage } from '@tw/tw-components-rn';
import { CallModule } from '@/app/native/call';
import { useEffect, useState } from 'react';
import { CallEvent, CallEventCallback } from '@/app/native/call/call-module-defs';
import { useGetLatest } from '@tw/tw-runtime-react';
import { BfAppContext } from '@tw/tw-runtime';
import { useRevisitCallListener, useRevisitCallUpload } from '@/app/hooks/use-revisit-call-listener';

export const OutCall = (props) => {
  // 从App启动页面跳转过来，携带有 orderId 参数
  const routeParams = props.route.params;
  const paramOrderId = routeParams.orderId;
  const dataId = routeParams.dataId;
  console.log('params.orderId', props.route.params.orderId);
  const [callDataId, setCallDataId] = useState<string>('53e66323-ce31-4c49-9010-8bcc7de917af');
  const [orderId, setOrderId] = useState('test');
  const [phone, setPhone] = useState<string>('051983666503');
  const messageService = BfAppContext.getInstance().getMessageService();

  useEffect(() => {
    if (dataId) {
      // 加载缓存， 包括录音等
      CallModule.getRevisitCacheById(dataId).then((data) => {
        console.log('加载缓存', data.orderId, data.recordFileUrl);
      });
    }
  }, [dataId]);

  const { upload } = useRevisitCallUpload();
  useRevisitCallListener(async (evt: CallEvent) => {
    messageService.showSuccess('回访挂断');
    setCallDataId(evt.dataId);
    // 挂断后上传录音
    const recordFile = await upload(evt.dataId);
    //
  });
  return (
    <TwPage translucentStatusBar={true}>
      <Text>{callDataId}</Text>
      <TwInput placeholder={'回访电话'} value={phone} onChangeText={setPhone}></TwInput>
      <TwButton
        onPress={() => {
          CallModule.revisitCall(phone, orderId);
        }}
      >
        拨打电话
      </TwButton>
      <TwButton
        onPress={() => {
          //
          CallModule.uploadRevisitCallCacheToServer(callDataId)
            .then(() => {
              messageService.showSuccess('上传成功');
            })
            .catch((e) => {
              messageService.showError('上传失败' + e.message);
            });
        }}
      >
        上传
      </TwButton>
    </TwPage>
  );
};
