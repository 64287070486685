import {
  AudioUploadResult,
  AudioUploadStatus,
  CallCacheModel,
  CallEventCallback,
  CallEventType,
  CallModuleInterface
} from './call-module-defs';
import { EmitterSubscription } from 'react-native';

export const CallModule: CallModuleInterface = {
  setEnableCallingCatch(enableCallingCatch: boolean) {},
  registerReceiver(): void {},
  startForeService(): void {},
  startNewMainActivity(): void {},
  /**
   * 获取来电
   */
  async getInCallRecoverData() {
    return null;
  },

  /**
   * 结束通话
   */
  endCall() {},

  /**
   * 接听电话
   */
  answerCall() {},

  /**
   * 注册监听器
   * @param event
   * @param callback
   */
  addListener(event: CallEventType, callback: CallEventCallback): EmitterSubscription {
    return {
      remove() {}
    } as EmitterSubscription;
  },

  setAuthToken(token: string | undefined) {
    console.log('CallModule setToken', token);
  },
  requestDefaultPhoneApp() {},
  setApiBaseUrl(url: string) {
    //
  },
  getLatestInCallCacheModel(): Promise<CallCacheModel> {
    return Promise.resolve(null);
  },
  getInCallCacheCount(): Promise<string> {
    return Promise.resolve('');
  },
  updateInCallCachePageData(dataId: string, pageData: string): Promise<void> {
    return Promise.resolve();
  },
  uploadInCallCacheToServer(dataId: string): Promise<CallCacheModel> {
    return Promise.resolve(null);
  },
  getInCallCacheById(): Promise<CallCacheModel> {
    return Promise.resolve({ id: '' });
  },
  uploadAllAudioInBackground(): Promise<void> {
    return Promise.resolve();
  },
  getAudioUploadStatus(dataId: string): Promise<AudioUploadResult> {
    return Promise.resolve(null);
  },
  startUpload(dataId: string): Promise<void> {
    return Promise.resolve(null);
  },
  cancelUpload(dataId: string): Promise<void> {
    return Promise.resolve(null);
  },
  findCacheById(dataId: string) {
    return Promise.resolve(null);
  },
  createCallCache(model: CallCacheModel): Promise<void> {
    return Promise.resolve(null);
  },
  uploadAllInCallCacheModel(): Promise<void> {
    return Promise.resolve(null);
  },
  getAllInCallCacheList(): Promise<CallCacheModel[] | null> {
    return Promise.resolve(null);
  },
  clearInCallRecoverData() {
    return Promise.resolve(null);
  },
  isPhoneInUse(): Promise<boolean> {
    return Promise.resolve(false);
  },
  getLatestRevisitCache() {
    return Promise.resolve(null);
  }
};
