import { FC } from 'react';
import { AudioPlayerModalProps } from './type';
import { AudioPlayerModal } from '@/sale/components/audio-player-modal';
import { useStoreState } from '@tw/tw-runtime-react';

export const OrderAudioPlayerModal: FC<AudioPlayerModalProps> = (props) => {
  const { page } = props;
  const orderCallRecord = useStoreState(page, (state) => state.orderCallRecord);
  return (
    <AudioPlayerModal
      visible={!!orderCallRecord}
      fileValue={orderCallRecord}
      onClose={() => {
        page.setOrderCallRecord(null);
      }}
    />
  );
};
