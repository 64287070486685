import { ProductDocProductDTO } from '@tw/lock-api-gen';
import { ProductCartProps } from '@/wms/pages/doc-inventory-company-select/viewparts/product-cart/type';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { TwCart } from '@tw/tw-components-rn';
import { useState } from 'react';
import { SaleProductOrder } from '@/product/components/sale-product-order/SaleProductOrder';
import { WmsDocInventoryCompanySelectPage } from '@/wms/stores/doc-inventory-company-select/wms-doc-inventory-company-select-page';

export const ProductCart = (props: ProductCartProps) => {
  const store = useBfPageStore<WmsDocInventoryCompanySelectPage>();
  const selected = useStoreState(store, (state) => state.list.selected);
  const selectedCount = useStoreState(store, (state) => state.list.selectedCount);

  const [visible, setVisible] = useState(false);
  const onProductClear = () => {
    store.onListItemClearSelect();
    setVisible(false);
  };
  const onConfirm = () => {
    store.onItemSelectConfirm();
    setVisible(false);
  };

  return (
    <TwCart
      popupTitle={'已选择产品'}
      count={selected?.length ?? 0}
      isPopupVisible={visible}
      onPopupVisibleChange={setVisible}
      onClear={onProductClear}
      onConfirm={onConfirm}
      data={selected}
      renderItem={({ item }: { item: ProductDocProductDTO }) => {
        return (
          <SaleProductOrder
            title={item.name}
            count={selectedCount[store.getDataId(item)] ?? 0}
            description={item.specification}
            showQuantity={false}
            onCountChange={(count) => {
              store.onListItemSelectWithCount(item, count);
            }}
          />
        );
      }}
    />
  );
};
