import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    height: 72,
    flex: 1,
    paddingHorizontal: TwTheme.values.spaces[4],
    paddingVertical: TwTheme.values.spaces[2]
  },
  number: {
    ...TwTheme.values.textSizes.md, //16
    color: TwTheme.values.textColors.base,
    fontWeight: TwTheme.values.textWeights.bold,
    alignSelf: 'center'
  },
  card: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    paddingVertical: TwTheme.values.spaces[2],
    alignSelf: 'center'
  }
});
