import { Text, View } from 'react-native';
import { TwAvatar, TwRow } from '@tw/tw-components-rn';
import { TwTheme, theme } from '@tw/tw-runtime-rn';

import { FC } from 'react';
import { StyleSheet } from 'react-native';

export interface SelectedAccount {
  avatarUrl?: string;
  name: string;
  showName?: boolean;
}

export const SelectedAccount: FC<SelectedAccount> = (props) => {
  return (
    <TwRow alignItems={'center'} space={2}>
      {props.avatarUrl && (
        <TwAvatar
          bg={theme.colors.blue[100]}
          source={{
            uri: props.avatarUrl
          }}
          size={'sm'}
        >
          {props.name?.slice(0, 1)}
        </TwAvatar>
      )}

      {props.showName && <Text style={styles.formContentText}>{props.name ?? ''}</Text>}
    </TwRow>
  );
};

const styles = StyleSheet.create({
  formContentText: {
    ...TwTheme.values.textSizes.sm
  }
});
