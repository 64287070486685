import { FC } from 'react';
import { CommandBarProps } from './type';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { HistoryOrderCommandBar } from './HistoryOrderCommandBar';
import { HistoryChanceCommandBar } from './HistoryChanceCommandBar';
import { ResDocPartnerPartnerInfoPage } from '@/res/stores/doc-partner-partner-info/res-doc-partner-partner-info-page';

export const CommandBar: FC<CommandBarProps> = (props) => {
  const {
    info: { commandBar, showQuickEditBar }
  } = props;
  const store = useBfPageStore<ResDocPartnerPartnerInfoPage>();
  const activeTabKey = useStoreState(store, (state) => state.activeTabKey);
  if (activeTabKey === 'basic') {
    return <>{commandBar}</>;
  }
  if (activeTabKey === 'orderHistory') {
    return <HistoryOrderCommandBar slice={store.getSlices().historyCompletedOrderSlice} />;
  }
  if (activeTabKey === 'chanceHistory') {
    return <HistoryChanceCommandBar slice={store.getSlices().historyChanceSlice} />;
  }

  return <>{commandBar}</>;
};
