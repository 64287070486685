import React, { useCallback, useState } from 'react';
import { TwColumn, TwRow, TwTypography } from '@tw/tw-components-rn';
import { usePageStoreLifecycle, useStoreState } from '@tw/tw-runtime-react';

import { BfAppContext } from '@tw/tw-runtime';
import { Divider } from 'native-base';
import { MinePage } from '@/mine/stores/MinePage';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { TouchableOpacity } from 'react-native';
import { styles } from './styles';

interface MyStatisticProps {
  ordersNum: number;
  availableBalance: number;
}

export const MyStatistic = (props: MyStatisticProps) => {
  const [page] = useState(() => new MinePage());
  // usePageStoreLifecycle(page, {});
  const accountInfo = useStoreState(page, (state) => state.accountInfo) || {};
  console.log('MyStatistic render');

  console.log(accountInfo);

  const routeService = BfAppContext.getInstance().getRouteService();

  // 余额卡片点击事件
  const onWalletPress = () => {
    routeService.push({
      path: ROUTE_NAMES.WalletSettings,
      query: {
        fromSetting: true
      }
    });
  };
  // 工单量按钮点击事件
  const onOrderStatusPressed = () => {
    // TODO: 跳转
    console.log();
  };

  return (
    <TwRow style={styles.container}>
      <TouchableOpacity onPress={onOrderStatusPressed} style={styles.card}>
        <TwColumn>
          <TwRow style={styles.title}>
            <TwTypography.Text size={'xs'}>工单量</TwTypography.Text>
            <TwTypography.Text size={'xs'} color={'third'}>
              (本月)
            </TwTypography.Text>
          </TwRow>
          <TwTypography.Text style={styles.number}>{`${props.ordersNum ?? '--'}`}</TwTypography.Text>
        </TwColumn>
      </TouchableOpacity>
      <Divider orientation="vertical"></Divider>
      <TouchableOpacity onPress={onWalletPress} style={styles.card}>
        <TwColumn>
          <TwRow style={styles.title}>
            <TwTypography.Text size={'xs'}>钱包</TwTypography.Text>
          </TwRow>
          <TwTypography.Text style={styles.number}>{`${props?.availableBalance ?? '--'}`}</TwTypography.Text>
        </TwColumn>
      </TouchableOpacity>
    </TwRow>
  );
};
