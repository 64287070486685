import { ScrollView } from 'react-native';
import { useBfPageStore, useSliceState, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { TwEmpty } from '@tw/tw-components-rn';
import { ResHistoryChanceCard } from './ResHistoryChanceCard';
import { ResHistoryChanceEdit } from './ResHistorChanceEdit';
import {
  HistoryChanceSlice,
  HistoryChanceSliceState,
  HistoryOrderSlice
} from '@/res/stores/res-partner-info-preview/slices';

export const ResHistoryChance = ({
  slice,
  historyOrderSlice
}: {
  slice: HistoryChanceSlice<any>;
  historyOrderSlice: HistoryOrderSlice<any>;
}) => {
  const hasSelectedOrder = useSliceState(
    slice,
    (state: HistoryChanceSliceState) => state.historyChanceInfo && state.historyChanceInfo.id
  );
  const historyChanceList = useSliceState(slice, (state: HistoryChanceSliceState) => state.historyChanceList);
  if (!historyChanceList?.length) {
    return <TwEmpty />;
  }
  // 有选中的历史工单
  if (hasSelectedOrder) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <ResHistoryChanceEdit slice={slice} historyOrderSlice={historyOrderSlice} />
      </ScrollView>
    );
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {historyChanceList?.map((item) => {
        return (
          <ResHistoryChanceCard
            value={item}
            key={item.id}
            onNewOrder={(order) => {
              slice.onHistoryChanceCreate(order);
            }}
          />
        );
      })}
    </ScrollView>
  );
};
