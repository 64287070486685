import React, { useEffect, useState } from 'react';
import { BfPageStoreProvider, useStoreState } from '@tw/tw-runtime-react';
import { TwDropdownMaskHost, TwPage, TwPageHeader, TwSearchBar } from '@tw/tw-components-rn';
import { View } from 'react-native';
import { styles } from './styles';

import { PpsCategory } from './viewparts/pps-category/PpsCategory';
import { PpsCart } from './viewparts/pps-cart/PpsCart';
import { PpsBrandFilter } from './viewparts/pps-brand-filter/PpsBrandFilter';
import { usePageStoreLifecycle } from '@/app/hooks/use-page-store-lifecycle';
import { PpsProductList } from './viewparts/pps-product-list/PpsProductList';
import { ProductProductSelectStore } from '@/product/stores/product-product-select/product-select-store';

export const ProductProductSelect = () => {
  const [page] = useState(() => new ProductProductSelectStore());
  usePageStoreLifecycle(page);
  const onFocus = () => {
    page.onSearchFocus();
  };

  return (
    <BfPageStoreProvider store={page}>
      <TwPage translucentStatusBar={true}>
        <TwPageHeader title={'选择产品'} />
        <View style={styles.searchBar}>
          <TwSearchBar placeholder={'搜索商品'} onFocus={onFocus} />
        </View>

        <View style={styles.main}>
          <PpsCategory />
          <TwDropdownMaskHost>
            <PpsBrandFilter />
            <PpsProductList />
          </TwDropdownMaskHost>
        </View>
        <PpsCart />
      </TwPage>
    </BfPageStoreProvider>
  );
};
