import { ScreenProp } from '@/app/navigation/types';
import React, { useState } from 'react';
import { LoginForm } from '@/auth/pages/login/viewparts/login-form/LoginForm';
import { styles } from '@/auth/pages/login-new/styles';
import { LoginHeader } from '@/auth/pages/login-new/login-header/LoginHeader';
import { KeyboardAvoidingView, View, Text } from 'react-native';
import { TwPage, TwRow } from '@tw/tw-components-rn';
import { OtherLogin } from '../login/viewparts/other-login/OtherLogin';
import { LoginFormVerification } from '@/auth/pages/login-new/login-form-verification/LoginForm';
import { LoginFormPassword } from '@/auth/pages/login-new/login-form-password/LoginForm';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { BfAppContext } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

export const AuthLoginNew = ({ navigation, route }: ScreenProp) => {
  const [isPasswordLogin, setIsPasswordLogin] = useState(true);
  const routeService = BfAppContext.getInstance().getRouteService();

  return (
    <TwPage translucentStatusBar={true} style={styles.container}>
      <LoginHeader style={styles.header} />
      <KeyboardAvoidingView>
        {isPasswordLogin ? (
          <LoginFormPassword style={styles.loginFrom} />
        ) : (
          <LoginFormVerification style={styles.loginFrom} />
        )}
      </KeyboardAvoidingView>
      {/* <OtherLogin style={styles.other} /> */}
      {isPasswordLogin ? (
        <TwRow style={styles.loginOther}>
          <TouchableOpacity>
            <Text style={styles.loginChoose} onPress={() => setIsPasswordLogin(false)}>
              验证码登录
            </Text>
          </TouchableOpacity>
          <TouchableOpacity>
            <Text
              style={styles.forgetPwd}
              onPress={() => {
                routeService.push({
                  path: ROUTE_NAMES.AuthChangePasswordPhone
                });
              }}
            >
              忘记密码
            </Text>
          </TouchableOpacity>
        </TwRow>
      ) : (
        <TwRow style={styles.loginOther}>
          <Text style={styles.loginChoose} onPress={() => setIsPasswordLogin(true)}>
            密码登录
          </Text>
        </TwRow>
      )}
    </TwPage>
  );
};
