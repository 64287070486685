import { FC } from 'react';
import { Text, View } from 'react-native';
import { BfLogLevel } from '@tw/tw-log4js';
import { TwTheme } from '@tw/tw-runtime-rn';

export const TwLogLine: FC<TwLogLineProps> = (props) => {
  const levelColor = getColor(props.level);
  return (
    <Text style={{ color: levelColor, paddingHorizontal: TwTheme.values.spaces.pageHorizontal }}>{props.message}</Text>
  );
};

function getColor(level: number) {
  switch (level) {
    case BfLogLevel.INFO.valueOf():
      return TwTheme.values.linkColors.base;
    case BfLogLevel.WARN.valueOf():
      return TwTheme.values.warningColors.base;
    case BfLogLevel.ERROR.valueOf():
      return TwTheme.values.errorColors.base;
    default:
      return TwTheme.values.textColors.base;
  }
}

export interface TwLogLineProps {
  level?: number;
  message?: string;
}
