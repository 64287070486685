import { AbstractDTO, BfAbstractIntentNavigator, BfIntent, BfIntentResult } from '@tw/tw-runtime';

import { ConditionDTO } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { TwSearchModalProps } from '@tw/tw-components-rn';

export interface BfAccountSelectIntent extends BfIntent, Pick<TwSearchModalProps<AbstractDTO[]>, 'title'> {
  selected?: AbstractDTO | AbstractDTO[];

  // 是否多选
  multiple?: boolean;

  disableIds?: string[];

  structure: {
    /**
     * bo Id
     */
    id: string;
    /**
     * boDomain
     */
    domain: string;
    /**
     * 根据类型获取服务
     */
    type: 'doc' | 'dataview' | 'bo';
  };

  condition?: ConditionDTO[];
  // 是否应用分页
  noPaging?: boolean;
}

export class AccountSelectNavigator extends BfAbstractIntentNavigator<
  AbstractDTO | AbstractDTO[],
  BfAccountSelectIntent
> {
  get ROUTE_NAME(): string {
    return ROUTE_NAMES._AssignUserSelect;
  }
  start(intent?: BfAccountSelectIntent): Promise<BfIntentResult<AbstractDTO | AbstractDTO[]>> {
    console.log('intent:', intent);
    return super.start(intent);
  }
}
export const BfAccountSelectNavigator = new AccountSelectNavigator();
