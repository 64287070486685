import { InCallCustomerInputFieldProps } from '@/sale/components/incall-customer/type';
import { observer, RecursionField, useField, useFieldSchema } from '@formily/react';
import { View } from 'react-native';
import { TwButton, TwFormItem, TwRow } from '@tw/tw-components-rn';
import { MaterialIcons } from '@expo/vector-icons';
import { TwTheme } from '@tw/tw-runtime-rn';
import { newEnumValue } from '@tw/tw-runtime';
import { GenderEnumValue } from '@tw/lock-api-gen';
import { Field } from '@formily/core';
import { TwBfInputPreview, TwBfPhonePreview } from '@tw/tw-platform-rn';

const InCallCustomerInputPreview = (props: InCallCustomerInputFieldProps) => {
  const { value } = props;
  const hasLinkman = value?.linkmanPhone || value?.linkman || value?.linkmanGender;
  return (
    <View>
      <TwFormItem label={'联系人'} contentAlign={'right'}>
        <TwBfInputPreview value={`${value?.callman ?? ''}${value?.callmanGender?.name ?? ''}`} />
      </TwFormItem>
      <TwFormItem label={'联系号码'} contentAlign={'right'}>
        <TwBfPhonePreview value={`${value?.callmanPhone ?? ''}`} />
      </TwFormItem>
      {hasLinkman && (
        <TwFormItem
          label={'联系人'}
          contentAlign={'right'}
          itemContainerStyle={{ backgroundColor: TwTheme.values.backgroundColors.componentGray }}
        >
          <TwBfInputPreview value={`${value?.linkman ?? ''}${value?.linkmanGender?.name ?? ''}`} />
        </TwFormItem>
      )}
      {hasLinkman && (
        <TwFormItem
          label={'联系号码'}
          contentAlign={'right'}
          itemContainerStyle={{ backgroundColor: TwTheme.values.backgroundColors.componentGray }}
        >
          <TwBfPhonePreview value={`${value?.linkmanPhone ?? ''}`} />
        </TwFormItem>
      )}
    </View>
  );
};
const InCallCustomerInputEdit = observer(
  (props: InCallCustomerInputFieldProps) => {
    const { value, onChange } = props;
    const field = useField();
    const fieldSchema = useFieldSchema();
    const hasLinkman = value?.linkmanPhone || value?.linkman || value?.linkmanGender;
    //
    const callmanPhoneField = field.query(field.path.concat('.callmanPhone')).take() as Field;
    const linkmanPhoneField = field.query(field.path.concat('.linkmanPhone')).take() as Field;

    const takeFieldSchema = (name) => fieldSchema.properties[name];

    const callmanFieldSchema = takeFieldSchema('callman');

    // 添加联系人，添加默认一个性别
    const handleAddLinkman = () => {
      onChange?.({
        ...value,
        linkmanGender: newEnumValue(GenderEnumValue.male)
      });
      const linkmanPhoneField = field.query(field.path.concat('.linkmanPhone')).take() as Field;
      linkmanPhoneField?.setDisplay('visible');
    };
    const handleRemoveLinkman = () => {
      const { linkmanGender, linkman, linkmanPhone, ...restValue } = value;
      const linkmanPhoneField = field.query(field.path.concat('.linkmanPhone')).take() as Field;
      linkmanPhoneField?.setDisplay('hidden');
      onChange?.({
        ...restValue,
        linkmanPartnerId: null,
        linkman: '',
        linkmanPhone: ''
      });
    };

    return (
      <View>
        <View>
          <TwFormItem label={'联系人'} bordered={true}>
            <TwRow space={2}>
              {/*  姓名*/}
              <View style={{ flex: 1 }}>
                <RecursionField schema={callmanFieldSchema} name={callmanFieldSchema.name} />
              </View>
              <View style={{ flexShrink: 0, minWidth: 120, alignItems: 'center', justifyContent: 'center' }}>
                <RecursionField schema={takeFieldSchema('callmanGender')} name={'callmanGender'} />
              </View>
              {/*  性别*/}
            </TwRow>
          </TwFormItem>
          <TwFormItem
            readOnly={!(callmanPhoneField?.editable ?? true)}
            label={'联系号码'}
            bordered={true}
            required={callmanPhoneField?.required}
            errors={callmanPhoneField?.selfErrors}
            showError={!!callmanPhoneField?.selfErrors?.length}
          >
            <RecursionField schema={takeFieldSchema('callmanPhone')} name={'callmanPhone'} />
          </TwFormItem>
          {/*  客户*/}
          <RecursionField schema={takeFieldSchema('partnerId')} name={'partnerId'} />
        </View>
        <View
          style={{
            paddingHorizontal: TwTheme.values.spaces.pageHorizontal,
            paddingVertical: TwTheme.values.spaces['2'],
            backgroundColor: TwTheme.values.backgroundColors.component
          }}
        >
          {hasLinkman ? (
            <TwButton
              shape={'round'}
              variant={'outline'}
              size={'sm'}
              colorScheme={'error'}
              alignSelf={'flex-start'}
              style={{ paddingVertical: 4 }}
              onPress={handleRemoveLinkman}
              leftIcon={
                <MaterialIcons name="remove-circle-outline" size={20} color={TwTheme.values.errorColors.base} />
              }
            >
              删除联系人
            </TwButton>
          ) : (
            <TwButton
              shape={'round'}
              variant={'outline'}
              size={'sm'}
              alignSelf={'flex-start'}
              style={{ paddingVertical: 4 }}
              onPress={handleAddLinkman}
              leftIcon={<MaterialIcons name="add-circle-outline" size={20} color={TwTheme.values.primaryColors.base} />}
            >
              添加联系人
            </TwButton>
          )}
        </View>
        {hasLinkman && (
          <View>
            <TwFormItem
              label={'联系人'}
              bordered={true}
              itemContainerStyle={{ backgroundColor: TwTheme.values.backgroundColors.componentGray }}
            >
              <TwRow space={2}>
                {/*  姓名*/}
                <View style={{ flex: 1 }}>
                  <RecursionField schema={takeFieldSchema('linkman')} name={'linkman'} />
                </View>
                {/*  性别*/}
                <View style={{ flexShrink: 0, minWidth: 120, alignItems: 'center', justifyContent: 'center' }}>
                  <RecursionField schema={takeFieldSchema('linkmanGender')} name={'linkmanGender'} />
                </View>
              </TwRow>
            </TwFormItem>
            <TwFormItem
              required={linkmanPhoneField?.required}
              label={'联系号码'}
              bordered={true}
              errors={linkmanPhoneField?.selfErrors}
              showError={!!linkmanPhoneField?.selfErrors?.length}
              itemContainerStyle={{ backgroundColor: TwTheme.values.backgroundColors.componentGray }}
            >
              <RecursionField schema={takeFieldSchema('linkmanPhone')} name={'linkmanPhone'} />
            </TwFormItem>
            {/*  客户*/}
            <RecursionField schema={takeFieldSchema('linkmanPartnerId')} name={'linkmanPartnerId'} />
          </View>
        )}
      </View>
    );
  },
  {
    displayName: 'InCallCustomerInputEdit'
  }
);

export const InCallCustomerInputField = observer(
  (props: InCallCustomerInputFieldProps) => {
    const field = useField();
    if (field.editable) {
      return <InCallCustomerInputEdit {...props} />;
    }
    return <InCallCustomerInputPreview {...props} />;
  },
  {
    displayName: 'InCallCustomerInputField'
  }
);
