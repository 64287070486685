import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { SaleDocOrderOrderInfoPage } from '@/sale/stores/doc-order-order-info/sale-doc-order-order-info-page';
import { TwButton, TwPage, TwRow } from '@tw/tw-components-rn';
import { FC } from 'react';
import { InterviewCommandBarProps } from '@/sale/pages/doc-order-order-info/viewparts/interview-command-bar/type';

export const InterviewCommandBar: FC<InterviewCommandBarProps> = (props) => {
  const { commandBar } = props;
  const store = useBfPageStore<SaleDocOrderOrderInfoPage>();
  // 工单需要回访
  const directingStatus = useStoreState(store, (state) => state.data.directingStatus?.code);
  const activeTabKey = useStoreState(store, (state) => state.activeTabKey);
  const callmanPhone = useStoreState(store, (state) => state.data.callmanPhone);
  const orderId = useStoreState(store, (state) => state.data.id);
  const needInterview = directingStatus === 'discard' || directingStatus === 'complete';
  const isInterviewTab = activeTabKey === 'interviewDetail';
  const handleInterviewPress = ()=>{
    store.setActiveTabKey('interviewDetail')
  }
  const handleRefund = ()=>{
    store.setShowRefundPopup(true);
  }

  const handleInterviewCall = ()=>{
    store.onRevisitCall(callmanPhone, orderId);
  }
  if (!needInterview) {
    return commandBar;
  }

  return (
    <TwPage.Footer>
      {isInterviewTab ? (
        <TwRow space={2}>
          <TwButton flex={1} variant={'outline'} colorScheme={'danger'} onPress={handleRefund}>
            退款
          </TwButton>
          <TwButton flex={1} onPress={handleInterviewCall}>拨打电话</TwButton>
        </TwRow>
      ) : (
        <TwRow space={2}>
          <TwButton flex={1} variant={'outline'} colorScheme={'danger'} onPress={handleRefund}>
            退款
          </TwButton>
          <TwButton flex={1} onPress={handleInterviewPress}>回访</TwButton>
        </TwRow>

      )}
    </TwPage.Footer>
  );
};
