import { useEffect, useState } from 'react';
import { SplashStore } from '@/home/stores/splash/splash-store';
import { useStoreState } from '@tw/tw-runtime-react';
import { TwButton, TwLoadingBlock, TwResult } from '@tw/tw-components-rn';
import { Text, View } from 'react-native';
export const HomeSplash = () => {
  const [store] = useState(() => new SplashStore());
  const errorMessage = useStoreState(store, (state) => state.errorMessage);
  const errorDetail = useStoreState(store, (state) => state.errorDetail);
  useEffect(() => {
    store.onLoad();
  }, [store]);
  if (errorMessage) {
    return (
      <TwResult
        style={{ justifyContent: 'center' }}
        status={'error'}
        title={errorMessage}
        extra={
          <View style={{ flexDirection: 'column' }}>
            <Text style={{ fontSize: 12 }}>{errorDetail}</Text>
            <TwButton
              variant={'outline'}
              style={{ minWidth: 90, marginTop: 8 }}
              onPress={() => {
                store.onRetry();
              }}
            >
              重试
            </TwButton>
          </View>
        }
      />
    );
  }
  return <TwLoadingBlock tip={'初始化'} />;
};
