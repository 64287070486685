import ImageViewer from './ImageViewer';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Image, View, useWindowDimensions, Modal } from 'react-native';
import { useState } from 'react';
import { TwButton, TwModal, TwPortal } from '@tw/tw-components-rn';
// import { TwImageViewer } from './TwImageViewer';
import { TwImageViewer } from '@tw/tw-components-rn';
export const ImageViewerDemo = () => {
  const dimension = useWindowDimensions();
  const imageUrl =
    'https://images.pexels.com/photos/994605/pexels-photo-994605.jpeg?auto=compress&cs=tinysrgb&w=2726&h=2047&dpr=1';
  const [width, setWidth] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState<number>(0);
  Image.getSize(imageUrl, (width, height) => {
    setWidth(width);
    setHeight(height);
    setLoading(false);
    console.log('SIZE', width, height);
  });
  const [show, setShow] = useState(false);
  return (
    <View style={{ flex: 1, paddingTop: 50 }}>
      <TwButton onPress={() => setShow(true)}>打开</TwButton>
      {/*<Modal style={{ flex: 1, backgroundColor: 'red' }} visible={show} onRequestClose={() => setShow(false)}></Modal>*/}
      <TwImageViewer visible={show} onRequestClose={() => setShow(false)} imageUrl={imageUrl} />
    </View>
  );
};
