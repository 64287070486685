import { FC } from 'react';
import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import { TwCard, TwColumn, TwTypography } from '@tw/tw-components-rn';
import { WmsPickingItemDTO } from '@tw/lock-api-gen';
import { View } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { WmsDocSubmitPickingAppliedInfoPage } from '@/wms/stores/doc-submit-picking-applied-info/wms-doc-submit-picking-applied-info-page';

export const PickingItemList: FC<TwBfFieldRcProps<WmsPickingItemDTO[]>> = (props) => {
  const { value } = props;
  const page = useBfPageStore<WmsDocSubmitPickingAppliedInfoPage>();
  // 申领
  const isApply = useStoreState(page, (state) => state.data?.type?.code === 'apply' ?? false);
  return (
    <TwColumn space={2}>
      {value?.map((item) => {
        return (
          <View style={{ ...TwTheme.values.shadows['6'], marginHorizontal: 8 }} key={item.id}>
            <TwCard title={item.productId?.name}>
              <TwCard.Content
                content={
                  <TwTypography.Text color={TwTheme.values.textColors.third} size={'sm'}>
                    {item.productId?.specification ?? '--'}
                  </TwTypography.Text>
                }
                middle={
                  isApply ? (
                    <TwTypography.Text color={TwTheme.values.textColors.third} size={'sm'}>{`申请人库存：${
                      item.sourceInventory ?? '--'
                    }`}</TwTypography.Text>
                  ) : undefined
                }
                highlight={
                  <TwTypography.Text color={TwTheme.values.textColors.third} size={'sm'}>{`申请数量：${
                    item.productQty ?? '--'
                  }`}</TwTypography.Text>
                }
              ></TwCard.Content>
            </TwCard>
          </View>
        );
      })}
    </TwColumn>
  );
};
