export const getDataSource = () => {
  return {
    refBO: {
      qname: 'bo://res/choiceReasonData',
      id: 'choiceReasonData',
      name: '原因选择数据',
      domain: 'res',
      dataStructure: 'list',
      pkAttributeId: 'id',
      nameAttributeId: 'name'
    },
    showAttrs: [
      {
        code: '2',
        id: 'name',
        name: '名称',
        type: 'text',
        visible: true,
        multiple: false,
        isName: true,
        validations: [],
        widgetOptions: {}
      },
      {
        code: '6',
        id: 'sortOrder',
        name: '排序号',
        type: 'number',
        visible: true,
        multiple: false,
        validations: [],
        widgetOptions: {}
      }
    ],
    saveAttr: {
      code: '2',
      id: 'name',
      name: '名称',
      type: 'text',
      visible: true,
      multiple: false,
      isName: true,
      validations: [],
      widgetOptions: {}
    },
    conditions: [
      {
        field: 'scene',
        rule: 'EQ',
        value: {
          type: 'value',
          value: 'command://rejectOrder'
        }
      },
      {
        matchType: 'AND',
        field: 'applyTo',
        rule: 'EQ',
        value: {
          type: 'value',
          value: 'bo://sale/order'
        }
      }
    ],
    sorts: [
      {
        field: 'sortOrder',
        sortType: 'ASC'
      }
    ],
    mode: 'customized'
  };
};
