import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { OrderInterviewPopupProp } from '@/sale/components/order-interview-popup/type';
import {
  TwButton,
  TwColumn,
  TwPopup,
  TwPopupHandle,
  TwPortal,
  TwRow,
  TwTypography,
  TwCheckbox,
  TwInputNumber,
  TwFormItem,
  TwTextArea,
  TwRate,
  TwSearchBar,
  TwSection,
  TwInput,
  TwIcon
} from '@tw/tw-components-rn';
import { TwBfInput } from '@tw/tw-platform-rn';
import { getDataSource } from './form-schema';
import { styles } from './styles';
import { Divider } from 'native-base';
import { TwTheme } from '@tw/tw-runtime-rn';
import { MaterialIcons } from '@expo/vector-icons';
export const OrderInterviewPopup: FC<OrderInterviewPopupProp> = (props) => {
  const { visible, onVisibleChange, onSubmit } = props;
  console.log('OrderInterviewPopup visible', visible);

  const ref = useRef<TwPopupHandle>();
  const dataSource = useMemo(() => getDataSource(), []);
  const [reason, setReason] = useState<string>();
  useEffect(() => {
    if (visible) {
      ref.current?.open();
    } else {
      ref.current?.close();
    }
  }, [visible]);
  return (
    <TwPortal>
      <TwPopup
        withHandle={false}
        adjustToContentHeight={true}
        title={'回访结果'}
        ref={ref}
        onClose={() => onVisibleChange?.(false)}
        footer={
          <TwRow flex={1} space={4}>
            <TwButton size={'sm'} flex={1}>
              退回工单
            </TwButton>
            <TwButton size={'sm'} flex={1}>
              完成回访
            </TwButton>
          </TwRow>
        }
      >
        <TwColumn space={2}>
          <TwRow style={styles.rateContainer}>
            <TwRate data={dataItem} onPress={() => console.log()}></TwRate>
          </TwRow>
          <TwSection title={'备注'} bodyStyle={styles.infoContainer}>
            <TwColumn space={3}>
              <TwRow space={2}>
                <TwCheckbox></TwCheckbox>
                <TwTypography.Text size={'sm'}>信息1:待定</TwTypography.Text>
              </TwRow>
              <TwRow space={2}>
                <TwCheckbox></TwCheckbox>
                <TwTypography.Text size={'sm'}>信息2:待定</TwTypography.Text>
              </TwRow>
              <TwRow space={2}>
                <TwCheckbox></TwCheckbox>
                <TwTypography.Text size={'sm'}>信息3:待定</TwTypography.Text>
              </TwRow>
              <TwRow>
                <TwCheckbox></TwCheckbox>
                <TwFormItem label={'其他信息'} layout="vertical" style={styles.remarkContainer}>
                  <TwTextArea placeholder="请输入备注信息" autoCompleteType={undefined}></TwTextArea>
                </TwFormItem>
              </TwRow>
            </TwColumn>
          </TwSection>
          <Divider></Divider>
          <TwColumn style={styles.commissionContainer} space={2}>
            <TwTypography.Text size={'xs'} style={styles.commissionTitle}>
              工程师提成设置
            </TwTypography.Text>
            <TwRow space={2}>
              <TwCheckbox></TwCheckbox>
              <TwTypography.Text size={'sm'}>固定金额</TwTypography.Text>
            </TwRow>
            <TwInput
              InputLeftElement={<TwIcon icon={'icon-icon'} size={16} color={'#8C8E8F'} />}
              placeholder="请输入固定金额"
            />
            <TwRow space={2}>
              <TwTypography.Text size={'xs'} color={TwTheme.values.primaryColors.base}>
                设为默认值
              </TwTypography.Text>
              <TwTypography.Text size={'xs'} color={TwTheme.values.textColors.third}>
                当前默认值
              </TwTypography.Text>
            </TwRow>
            <TwRow space={2}>
              <TwCheckbox></TwCheckbox>
              <TwTypography.Text size={'sm'}>指定比例</TwTypography.Text>
            </TwRow>
            <TwInput
              InputRightElement={<TwIcon icon={'icon-percentage'} size={16} color={'#ADB1B5'} />}
              placeholder="请输入指定比例"
            />
            <TwTypography.Text size={'xs'} color={TwTheme.values.textColors.third}>
              提成=指定比列*工单利润
            </TwTypography.Text>
          </TwColumn>
        </TwColumn>
      </TwPopup>
    </TwPortal>
  );
};

const dataItem = [
  {
    icon: <MaterialIcons style={styles.icon} name="sentiment-satisfied" size={24} color={'inherit'} />,
    label: '非常满意'
  },
  {
    icon: <MaterialIcons style={styles.icon} name="sentiment-satisfied-alt" size={24} color={'inherit'} />,

    label: '满意'
  },
  {
    icon: <MaterialIcons style={styles.icon} name="sentiment-neutral" size={24} color={'inherit'} />,

    label: '一般'
  },
  {
    icon: <MaterialIcons style={styles.icon} name="sentiment-dissatisfied" size={24} color={'inherit'} />,

    label: '不满意'
  },
  {
    icon: <MaterialIcons style={styles.icon} name="sentiment-very-dissatisfied" size={24} color={'inherit'} />,

    label: '非常不满意'
  }
];
