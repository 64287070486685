import { BfAppContext } from '@tw/tw-runtime';
import { FC, useMemo, useState } from 'react';
import { TwButton, TwCheckbox } from '@tw/tw-components-rn';
import { Text, TouchableOpacity, View, ViewProps } from 'react-native';
import { useAppStore, useStoreState } from '@tw/tw-runtime-react';

import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { styles } from './styles';
import { TwBfForm } from '@tw/tw-platform-rn';
import { BfFormElement } from '@tw/tw-platform-react';

export const LoginForm: FC<ViewProps> = (props) => {
  const store = BfAppContext.getInstance().getLoginStore();
  const messageService = BfAppContext.getInstance().getMessageService();
  const routeService = BfAppContext.getInstance().getRouteService();
  const appStore = useAppStore();
  const phone = useStoreState(store, (state) => state.formState.phone);
  const isPolicyAgreed = useStoreState(store, (state) => state.isPolicyAgreed);
  const userLoginLoading = useStoreState(appStore, (state) => state.isDoingLogin);
  const [form] = useState(() => TwBfForm.createForm());
  const elements = useElements();
  const onLoginClick = async () => {
    const { phone } = await form.submit();
    store.updateLoginForm({
      phone: phone
    });
    await messageService.showLoading();
    let exits = false;
    try {
      exits = await store.checkUserNameExits();
    } finally {
      await messageService.hideLoading();
    }

    if (!exits) {
      messageService.showError('手机号未注册');
      await messageService.hideLoading();
      return;
    }
    if (!store.isPolicyAgreed()) {
      const confirm = await messageService.showConfirm({
        title: '登录提示',
        content: '登录代表您同意使用协议'
      });
      if (!confirm) {
        await messageService.hideLoading();
        return;
      }
      store.setPolicyAgree(true);
    }
    await messageService.hideLoading();
    routeService.push({
      path: ROUTE_NAMES.AuthVerify
    });
  };
  const onAgreeCheck = (checked: boolean) => {
    store.setPolicyAgree(checked);
  };

  const onPrivacyPolicyClick = () => {
    routeService.push({
      path: ROUTE_NAMES.HomePrivacyPolicy
    });
  };

  const onTermsAndConditionsClick = () => {
    routeService.push({
      path: ROUTE_NAMES.HomeTermsAndConditions
    });
  };

  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.phoneContainer}>
        <TwBfForm form={form} elements={elements} />
      </View>
      <View style={styles.loginButtonContainer}>
        <TwButton style={styles.loginButton} testID={'LoginBtn'} onPress={onLoginClick} isLoading={userLoginLoading}>
          登录
        </TwButton>
      </View>

      <View style={styles.confirmContainer}>
        <TwCheckbox checked={isPolicyAgreed} onChange={onAgreeCheck} />
        <Text style={styles.confirmText}>我已阅读同意</Text>
        <TouchableOpacity onPress={onPrivacyPolicyClick}>
          <Text style={styles.confirmLink}>隐私政策</Text>
        </TouchableOpacity>
        <Text style={styles.confirmTextWithNoMargin}>和</Text>
        <TouchableOpacity onPress={onTermsAndConditionsClick}>
          <Text style={styles.confirmLink}>用户协议</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

function useElements(): BfFormElement[] {
  return useMemo(
    () =>
      [
        {
          id: 'phone',
          name: '手机号码',
          type: 'phone',
          widgetOptions: {
            phoneType: 'mobile'
          },
          validations: [
            {
              //
            }
          ]
        }
      ] as BfFormElement[],
    []
  );
}

interface FormData {
  phone: string;
}
