import { useEffect, useLayoutEffect, useState } from 'react';
import { NotificationModel } from '@/app/native/notification/notification-module-defs';
import { TwNotification } from '@/app/native/notification/notification-module';

export * from './notification-module';

export function useLatestNotification() {
  const [notification, setNotification] = useState<NotificationModel>();
  useLayoutEffect(() => {
    const subscription = TwNotification.addOnClickListener((notification) => {
      setNotification(notification);
    });
    return () => {
      subscription.remove();
    };
  }, []);
  useEffect(() => {
    TwNotification.getLatestClickedNotification().then((res) => {
      setNotification((current) => res ?? current);
    });
  }, []);
  return notification;
}
