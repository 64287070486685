import {
  INotificationModule,
  NotificationChannel,
  Notification,
  NotificationClickHandler,
  NotificationModel
} from '@/app/native/notification/notification-module-defs';

export const TwNotification: INotificationModule = {
  cancelNotification(notifyId: string): Promise<void> {
    return Promise.resolve(undefined);
  },
  createChannel(channel: NotificationChannel): Promise<string> {
    return Promise.resolve('');
  },
  sendNotification(notification: Notification): Promise<number> {
    return Promise.resolve(0);
  },
  getLatestClickedNotification(): Promise<NotificationModel> {
    return Promise.resolve(undefined);
  },
  addOnClickListener(listener: NotificationClickHandler) {
    return null;
  }
};
