import { BfMenu, BfPageStore, mustNotBeNull } from '@tw/tw-runtime';
import { WorkplaceState } from './type';

export class WorkplaceStore extends BfPageStore<WorkplaceState> {
  constructor() {
    super({});
  }

  onLoad(param?: any): Promise<void> {
    return super.onLoad(param);
  }

  /**
   * 页面点击
   */
  onMenuPress(menu: BfMenu) {
    //
    if (!menu.children?.length) {
      this.messageService.showError('未配置菜单');
      return;
    }
    const entryPage = menu.children[0];
    if (!entryPage.routeName) {
      this.messageService.showError('菜单首页没有生成 routeName');
      return;
    }
    this.logger.debug('尝试进入菜单页面：' + entryPage.routeName);
    this.routeService.push({
      path: entryPage.routeName
    });
    return;
  }
}
