import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: 'column',
    flex: 1,
    paddingBottom: TwTheme.values.spaces.pageHorizontal
  },
  tipImageContainer: {
    minHeight: 200
  }
});
