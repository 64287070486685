import { BfAbstractIntentNavigator, BfIntent, BfIntentResult } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { SelectedProduct } from '../product-select-common/type';

export class ProductSelectNavigatorCls extends BfAbstractIntentNavigator<SelectedProduct[], ProductSelectIntent> {
  get ROUTE_NAME() {
    return ROUTE_NAMES.ProductProductSelect;
  }
}

export interface ProductSelectIntent extends BfIntent {
  products: SelectedProduct[];
}

export const ProductSelectNavigator = new ProductSelectNavigatorCls();
