import { TwFormItem } from '@tw/tw-components-rn';
import { Text } from 'react-native';
import { FC } from 'react';
import { InCallAssignSelfProps } from '@/sale/components/incall-assign-select/type';

export const InCallAssignSelf: FC<InCallAssignSelfProps> = (props) => {
  const { employee } = props;
  return (
    <TwFormItem label={'负责人'} contentAlign={'right'}>
      <Text>{employee.name}</Text>
    </TwFormItem>
  );
};
