import { SaleDocOrderDTO } from '@tw/lock-api-gen';
import { SaleChanceUI, SaleCustomerInfo, SaleOrderUI } from '@/sale/stores/in-call-order/type';
import { SaleChanceDTO } from '@tw/lock-api-gen/dist/types/sale';
/**
 * 将订单转换成 UI 需要的结构
 * @param order
 * @private
 */
export function orderDTO2UI(order: SaleDocOrderDTO | undefined): SaleOrderUI | undefined {
  if (!order) {
    return order as SaleOrderUI;
  }
  const {
    callman,
    callmanPhone,
    callmanGender,
    linkmanGender,
    linkman,
    linkmanPhone,
    partnerId,
    linkmanPartnerId,
    ...restOrder
  } = order;
  const customerInfo: SaleCustomerInfo = {
    partnerId,
    callman,
    callmanPhone,
    callmanGender,
    linkmanGender,
    linkman,
    linkmanPhone,
    linkmanPartnerId
  };
  return {
    customerInfo,
    ...restOrder
  };
}

export function orderUI2DTO(order: SaleOrderUI | undefined): SaleDocOrderDTO | undefined {
  if (!order) {
    return order as SaleDocOrderDTO;
  }
  const { customerInfo, ...restOrder } = order;
  const { partnerId, callman, callmanPhone, callmanGender, linkmanGender, linkman, linkmanPhone, linkmanPartnerId } =
    customerInfo || {};
  return {
    ...restOrder,
    partnerId,
    callman,
    callmanPhone,
    callmanGender,
    linkmanGender,
    linkman,
    linkmanPhone,
    linkmanPartnerId
  };
}

export function chanceDTO2UI(chance: SaleChanceDTO | undefined): SaleChanceUI | undefined {
  return orderDTO2UI(chance);
}
export function changeUI2DTO(chance: SaleChanceUI | undefined): SaleChanceDTO | undefined {
  return orderUI2DTO(chance);
}
