import { TwPage, TwRow } from '@tw/tw-components-rn';
import { NoticeBell } from '@/sale/pages/doc-order-order-index/viewparts/notice-bell/NoticeBell';
import React from 'react';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { SaleDocOrderOrderIndexPage } from '@/sale/stores/doc-order-order-index/sale-doc-order-order-index-page';
import { MaterialIcons } from '@expo/vector-icons';
import { BfAppContext } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { CallModule } from '@/app/native/call';
import { View } from 'react-native';
import { styles } from '@/sale/pages/doc-order-order-index/viewparts/header-actions/styles';
import { useOrderPermission } from '@/app/hooks/use-order-permission';

export const HeaderActions = () => {
  const store = useBfPageStore<SaleDocOrderOrderIndexPage>();
  const count = useStoreState(store, (state) => state.cacheOrderCount);
  const { hasInCallOrderPermission } = useOrderPermission();
  const handleGoCacheOrderInputAction = async () => {
    const routeService = BfAppContext.getInstance().getRouteService();
    routeService.push({
      path: ROUTE_NAMES.SaleInCallCacheList
    });
  };
  if (hasInCallOrderPermission && count > 0) {
    return (
      <View style={styles.container}>
        <NoticeBell />
        <View style={styles.action}>
          <TwPage.HeaderAction
            badgeProps={{
              dot: true,
              count: 0
            }}
            icon={<MaterialIcons name="list-alt" size={24} color="black" />}
            onPress={handleGoCacheOrderInputAction}
          />
        </View>
      </View>
    );
  }
  return <NoticeBell />;
};
