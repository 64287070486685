import { FC } from 'react';
import { InCallCustomNameInputProps } from '@/sale/components/incall-customer/type';
import { useStQuery } from '@tw/tw-runtime-react';
import { newLikeCondition } from '@tw/tw-runtime';
import { ResPartnerDTO, ResPartnerRestApiService } from '@tw/lock-api-gen';
import { TwAutoComplete } from '@tw/tw-components-rn';
import { InCallCustomOption } from '@/sale/components/incall-customer/InCallCustomOption';
import { TwTheme } from '@tw/tw-runtime-rn';

export const InCallCustomNameInput: FC<InCallCustomNameInputProps> = (props) => {
  const { value, enableSearch, onChange, onSelect } = props;
  //
  const { isLoading, items, query } = useStQuery({
    refBO: {
      id: 'partner',
      domain: 'res'
    }
  });

  const handleNameSearch = (text: string) => {
    if (!enableSearch) {
      return;
    }
    query({
      conditions: [newLikeCondition(ResPartnerRestApiService.NAME, text)],
      pageNo: 1,
      pageSize: 5,
      fields: [
        ResPartnerRestApiService.ID,
        ResPartnerRestApiService.NAME,
        ResPartnerRestApiService.MOBILE,
        ResPartnerRestApiService.GENDER
      ]
    });
  };

  // 选择已有客户
  const handleCustomSelect = (customer: ResPartnerDTO) => {
    //
    onSelect?.(customer);
  };
  return (
    <TwAutoComplete
      isLoading={isLoading}
      options={items}
      value={value}
      dropdownWidth={200}
      inputProps={{
        variant: 'underlined',
        style: {
          paddingLeft: 0,
          ...TwTheme.values.textSizes.sm,
          alignSelf: 'flex-start',
          width: '100%'
        }
      }}
      onChange={onChange}
      onSearch={handleNameSearch}
      renderOption={(item: ResPartnerDTO) => (
        <InCallCustomOption name={item.name} gender={item.gender?.name} mobile={item.mobile} />
      )}
      onSelect={handleCustomSelect}
    />
  );
};
