import { BfAppContext, BfPageStore, FileValue, newEnumValue, newEqualCondition, promiseSafeRun } from '@tw/tw-runtime';
import {
  InCallOrderBaseStoreState,
  InCallOrderCachePageData,
  LoadParam,
  RequirementType,
  SaleChanceUI,
  SaleCustomerInfo,
  SaleOrderUI
} from '@/sale/stores/in-call-order/type';
import {
  ActCallingDTO,
  ActCallingRestApiService,
  GenderEnumValue,
  HrEmployeeRestApiService,
  OrderCreateSaveTypeEnumValue,
  OrderExecutiveStatusEnumValue,
  OrderExecutorTypeEnumValue,
  ResCallService,
  ResDocPartnerDTO,
  ResPartnerDTO,
  ResPartnerRestApiService,
  SaleChanceDTO,
  SaleDocOrderDTO,
  SaleOrderBusinessRestApiService,
  SaleOrderDTO,
  SaleOrderPoolService,
  SaleOrderRestApiService,
  SaleOrderService
} from '@tw/lock-api-gen';
import { BfStructureViewPageConfig, IForm } from '@tw/tw-platform-react';
import { chanceDTO2UI, orderDTO2UI, orderUI2DTO } from '@/sale/stores/in-call-order/incall-order-mapper';
import { DefaultOrderSettingService } from '@/app/core/services/default-order-setting-service';
import { debounce } from 'lodash-es';
import { CallCacheModel } from '@/app/native/call/call-module-defs';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { newDefaultGenderValue } from '@/sale/pages/in-call-order/viewparts/common/elements';
import { TwBfForm } from '@tw/tw-platform-rn';

/**
 * 来电录入工单
 */
export class IncallOrderBaseStore<
  State extends InCallOrderBaseStoreState = InCallOrderBaseStoreState
> extends BfPageStore<State> {
  protected resCallService: ResCallService;
  protected saleOrderService: SaleOrderService;
  protected saleOrderRestApiService: SaleOrderRestApiService;
  protected partnerRestApiService: ResPartnerRestApiService;
  protected saleOrderBusinessService: SaleOrderBusinessRestApiService;
  protected actCallService: ActCallingRestApiService;
  protected employeeService: HrEmployeeRestApiService;
  protected saleOrderPoolService: SaleOrderPoolService;
  /**
   * 新建工单表单
   * @private
   */
  protected newOrderForm: IForm;
  /**
   * 进行中工单
   * @private
   */
  protected underwayForm: IForm;
  /**
   * 历史工单
   * @protected
   */
  protected historyForm: IForm;
  /**
   * 历史咨询
   * @protected
   */
  protected historyConsultForm: IForm;

  constructor() {
    super({
      pluginOptions: {
        devTools: {
          name: '来电录入'
        }
      }
    });
    const api = BfAppContext.getInstance().getApiService();
    this.resCallService = new ResCallService(api);
    this.actCallService = new ActCallingRestApiService(api);
    this.saleOrderService = new SaleOrderService(api);
    this.employeeService = new HrEmployeeRestApiService(api);
    this.saleOrderBusinessService = new SaleOrderBusinessRestApiService(api);
    this.saleOrderPoolService = new SaleOrderPoolService(api);
    this.saleOrderRestApiService = new SaleOrderRestApiService(api);
    this.partnerRestApiService = new ResPartnerRestApiService(api);

    this.newOrderForm = TwBfForm.createForm();
    this.underwayForm = TwBfForm.createForm();
    this.historyForm = TwBfForm.createForm();
    this.historyConsultForm = TwBfForm.createForm();
  }

  protected getInitialState(): State {
    return <State>{
      ...super.getInitialState(),
      partner: {
        gender: newEnumValue(GenderEnumValue.male)
      },
      orderInfo: {
        linkmanGender: newEnumValue(GenderEnumValue.male)
      },
      underwayOrderInfo: {},
      //当前是否可以挂断
      canHangup: true,
      isLoadingPartnerInfo: true,
      isLoadingUnderwayOrder: true,
      isLoadingHistoryChance: true,
      isLoadingCompletedHistoryOrder: true,
      isLoadingHistoryOrder: true,
      requirementType: RequirementType.newOrder
    };
  }

  setNewOrderForm(form: IForm) {
    this.newOrderForm = form;
  }

  getNewOrderForm() {
    return this.newOrderForm;
  }

  setUnderwayForm(form: IForm<any, any>) {
    this.underwayForm = form;
  }

  getUnderwayForm() {
    return this.underwayForm;
  }

  setHistoryOrderForm(form: IForm) {
    this.historyForm = form;
  }

  getHistoryOrderForm() {
    return this.historyForm;
  }

  setHistoryConsultForm(form: IForm) {
    this.historyConsultForm = form;
  }

  getHistoryConsultForm() {
    return this.historyConsultForm;
  }

  /**
   * 页面加载
   * @param param
   */
  async onLoad(param?: LoadParam): Promise<void> {
    this.logger.debug('加载页面：' + JSON.stringify(param));
    await super.onLoad(param);
    if (param?.loadFromCache) {
      this.loadFromCache(param);
      return;
    }
    this.setLoading(true);
    const tasks: Promise<void>[] = [];
    if (param?.phone) {
      tasks.push(this.initPartnerInfoByPhone(param.phone));
      // 加载剩余的工单数量
      tasks.push(this.loadRemainOrderCount());
      this.setDataId(param.dataId);
    }
    try {
      this.setState(<State>{
        isLoadingPartnerInfo: true
      });
      await Promise.all(tasks);
    } catch (e) {
      this.logger.error(e);
    } finally {
      this.setState(<State>{
        isLoadingPartnerInfo: false
      });
    }
    try {
      this.initOldPartnerInfoIfNeeded().then();
    } catch (e) {
      this.logger.error('加载老客户信息失败', e);
    }
    // 加载默认数据
    try {
      this.initDefaultData().then();
    } catch (e) {
      this.logger.error('请求默认数据失败', e);
    }
    try {
      this.initHistoryOrderByPhone(param.phone).then();
    } catch (e) {
      this.logger.error('请求历史工单数据失败', e);
    }

    this.setLoading(false);
    return super.onLoad(param);
  }

  private async loadFromCache(param: LoadParam) {
    this.clearState();
    this.setLoading(true);
    this.setDataId(param.dataId);
    this.setLoadFromList(!!param.loadFromList);
    this.setCanHangup(false);
    await this.initPartnerInfoByPhone(param.phone);
    const tasks: Promise<void>[] = [];
    tasks.push(this.loadRemainOrderCount());
    tasks.push(this.initDefaultData());
    tasks.push(this.initHistoryOrderByPhone(param.phone));
    tasks.push(this.initOldPartnerInfoIfNeeded(false));
    try {
      await Promise.all(tasks);
      await this.initPageDataFromCache();
      this.setLoading(false);
      this.setState(<State>{
        isLoadingPartnerInfo: false
      });
    } catch (e) {
      console.error(e);
      this.messageService.showError('加载失败：' + e.message);
      return;
    }
    // 上传缓存的录音
    await this.doUpload(param.dataId);
  }

  protected async initOldPartnerInfoIfNeeded(lodUnderwayOrder = true) {
    const partner = this.newOrderForm.getValues()?.customerInfo?.partnerId;
    if (!partner?.id) {
      this.setState(<State>{
        isLoadingHistoryOrder: false,
        isLoadingUnderwayOrder: false,
        isLoadingHistoryChance: false,
        isLoadingCompletedHistoryOrder: false
      });
      return;
    }

    const tasks: Promise<void>[] = [];
    if (lodUnderwayOrder) {
      tasks.push(this.loadUnderwayOrder(partner));
    } else {
      this.setState(<State>{
        isLoadingUnderwayOrder: false,
        hasUnderwayOrder: false
      });
    }
    tasks.push(this.loadCompletedHistoryOrder(partner));
    tasks.push(this.loadHistoryConsult(partner));
    await Promise.all(tasks);
  }

  /**
   * 初始化客户信息
   * @param phone
   * @protected
   */
  protected async initPartnerInfoByPhone(phone: string) {
    // 获取用户信息
    const res = await this.resCallService.lyncPartnerInfo({
      phone
    });
    //老客户
    if (res && res.partnerId) {
      //@ts-ignore
      this.updateOrderCustomerInfo({
        partnerId: res.partnerId,
        callman: res.partnerId.name,
        callmanGender: res.partnerId.gender,
        callmanPhone: phone
      });

      return;
    }
    // 新客户
    //@ts-ignore
    this.updateOrderCustomerInfo({
      callmanPhone: phone,
      callmanGender: newDefaultGenderValue()
    });
  }

  private async initDefaultOrderAddr() {
    const addr = await DefaultOrderSettingService.getInstance().getAddr();
    // 已有地址，不更新
    if (this.newOrderForm.getValues().orderInfo?.address) {
      return;
    }
    this.updateOrder({
      address: addr
    });
  }

  protected async initHistoryOrderByPhone(phone: string) {
    // 获取客户历史工单
    const res = await this.resCallService.lyncOrderList({
      phone
    });
    this.setState(<State>{
      historyOrder: res.orderHistory
    });
    if (res.orderHistory?.length) {
      const address = res.orderHistory[0]?.address;
      this.updateOrder({
        address
      });
    }
  }

  /**
   * 需求类型
   * @param key
   */
  onRequirementTypeChange(key: RequirementType) {
    this.updateRequirementType(key);
    // 激活的页签变化，记录到缓存中
    this.updateCache();
  }

  /**
   * 新建工单表单内容变更
   */
  onNewOrderInputChange() {
    this.updateCache();
  }

  /**
   * 进行中工单内容变更
   */
  onUnderwayOrderInputChange() {
    this.updateCache();
  }

  /**
   * 历史工单（新建售后）内容变更
   */
  onHistoryOrderInputChange() {
    this.updateCache();
  }

  /**
   * 咨询工单内容变更
   */
  onConsultOrderInputChange() {
    this.updateCache();
  }

  /**
   * 加防抖动，1s 间隔
   * @private
   */
  updateCache = debounce(() => {
    const { requirementType, hasUnderwayOrder, hasCompleteOrderInfo, hasHistoryChanceInfo } = this.getState();
    // 进行中工单
    const underwayOrderInfo = this.underwayForm?.getValues();
    // 新建工单
    const orderInfo = this.newOrderForm?.getValues();
    // 历史工单-新建售后工单
    const completedOrderInfo = this.historyForm?.getValues();
    // 历史咨询
    const historyChanceInfo = this.historyConsultForm?.getValues();
    //@ts-ignore
    this.doUpdateCache({
      hasUnderwayOrder,
      hasCompleteOrderInfo,
      hasHistoryChanceInfo,
      requirementType,
      underwayOrderInfo,
      orderInfo,
      completedOrderInfo,
      historyChanceInfo
    }).then();
  }, 1000);

  protected async doUpdateCache(data: InCallOrderCachePageData) {}

  /**
   * 需求类型切换
   * @param key
   * @private
   */
  private updateRequirementType(key: RequirementType) {
    this.setState(
      <State>{
        requirementType: key
      },
      false,
      '需求类型切换'
    );
  }

  /**
   * 加载默认数据
   * @private
   */
  protected async initDefaultData() {
    this.loadDefaultOrderBusiness().then();
    this.initDefaultOrderAddr().then();
  }

  /**
   * 加载默认的业务类型
   * @protected
   */
  protected async loadDefaultOrderBusiness() {
    const res = await this.saleOrderBusinessService.info({
      conditions: [newEqualCondition(SaleOrderBusinessRestApiService.CODE, 'lock')],
      fields: [
        SaleOrderBusinessRestApiService.ID,
        SaleOrderBusinessRestApiService.CODE,
        SaleOrderBusinessRestApiService.NAME
      ]
    });
    this.updateOrder({
      business: res
    });
  }

  // /**
  //  * 更新客户信息
  //  * @param partner
  //  * @protected
  //  */
  // protected updatePartner(partner: ResDocPartnerDTO) {
  //   Object.keys(partner).forEach((key) => {
  //     this.partnerMemo.updateField(key, partner[key]);
  //   });
  //   this.setState(
  //     (state) => {
  //       if (!partner) {
  //         return;
  //       }
  //       Object.assign(state.partner, partner);
  //       return state;
  //     },
  //     false,
  //     '更新客户信息'
  //   );
  // }

  /**
   * 更新工单
   * @param order
   * @private
   */
  private updateOrder(order: SaleOrderUI) {
    this.newOrderForm.setValues(order, 'shallowMerge');
  }

  /**
   * 更新工单联系人
   * @param customerInfo
   * @private
   */
  private updateOrderCustomerInfo(customerInfo: SaleCustomerInfo) {
    if (!customerInfo) {
      return;
    }
    const oldCustomInfo = this.newOrderForm.getValues().customerInfo;
    this.newOrderForm.setValues(
      {
        customerInfo: { ...oldCustomInfo, ...customerInfo }
      },
      'shallowMerge'
    );
    // this.setState(
    //   (state) => {
    //     if (!customerInfo) {
    //       return state;
    //     }
    //     if (!state.orderInfo.customerInfo) {
    //       state.orderInfo.customerInfo = {};
    //     }
    //     Object.assign(state.orderInfo.customerInfo, customerInfo);
    //     return state;
    //   },
    //   false,
    //   '更新工单'
    // );
  }

  /**
   * 更新通话记录
   * @param calling
   * @protected
   */
  protected updateCalling(calling: ActCallingDTO) {
    this.setState(
      (state) => {
        if (!calling) {
          return state;
        }
        Object.assign(state.actCalling, calling);
        return state;
      },
      false,
      '更新通话记录'
    );
  }

  /**
   * 更新进行中工单
   * @param order
   * @private
   */
  private updateUnderwayOrder(order: SaleOrderUI) {
    if (order) {
      this.setHasUnderwayOrder(true);
      this.underwayForm.setValues(order, 'shallowMerge');
    } else {
      this.setHasUnderwayOrder(false);
    }
  }

  setHasUnderwayOrder(hasUnderwayOrder: boolean) {
    this.setState(
      <State>{
        hasUnderwayOrder: hasUnderwayOrder
      },
      false,
      '是否有进行中工单'
    );
  }

  /**
   * 更新历史需求
   * @param change
   * @private
   */
  private updateHistoryChance(change: SaleChanceDTO[]) {
    this.setState(
      (state) => {
        if (!change) {
          return state;
        }
        state.historyChanceList = change;
        return state;
      },
      false,
      '更新历史需求'
    );
  }

  /**
   * 加载已完成历史工单信息
   * @private
   */
  private async loadCompletedHistoryOrder(partnerId: ResDocPartnerDTO) {
    this.setState(<State>{
      isLoadingCompletedHistoryOrder: true
    });
    try {
      const historyOrder = await this.saleOrderRestApiService.query({
        noPaging: true,
        conditions: [
          newEqualCondition(SaleOrderRestApiService.PARTNER_ID, partnerId.id),
          newEqualCondition(SaleOrderRestApiService.DIRECTING_STATUS, OrderExecutiveStatusEnumValue.complete)
        ]
      });
      this.setState(
        <State>{
          completedHistoryOrder: historyOrder.rows ?? []
        },
        false,
        '加载已完成历史工单信息'
      );
    } finally {
      this.setState(<State>{
        isLoadingCompletedHistoryOrder: false
      });
    }
  }

  /**
   * 创建通话记录
   * @param calling
   * @protected
   */
  protected async createCallingHistory(calling: ActCallingDTO) {
    const res = await this.actCallService.create(calling);
    this.updateCalling({
      ...calling,
      id: res
    });
  }

  /**
   * 查询进行中的工单
   * @param partnerId
   * @protected
   */
  protected async loadUnderwayOrder(partnerId: ResPartnerDTO) {
    this.setState(<State>{
      isLoadingUnderwayOrder: true
    });
    try {
      const res = await this.saleOrderService.executeOrder({
        id: partnerId.id
      });
      if (res) {
        this.updateRequirementType(RequirementType.underwayOrder);
        // 加载进行中工单时，记录当前 tab
        // this.updateCache();
      }
      this.updateUnderwayOrder(orderDTO2UI(res));
    } finally {
      this.setState(<State>{
        isLoadingUnderwayOrder: false
      });
    }
  }

  /**
   * 加载历史需求
   * @param partnerId
   * @protected
   */
  protected async loadHistoryConsult(partnerId: ResPartnerDTO) {
    this.setState(<State>{
      isLoadingHistoryChance: true
    });
    try {
      const res = await this.saleOrderService.historyChance({
        id: partnerId.id
      });
      this.updateHistoryChance(res);
    } finally {
      this.setState(<State>{
        isLoadingHistoryChance: false
      });
    }
  }

  /**
   * 废弃记录
   */
  async onDeleteRecord() {
    const confirm = await this.messageService.showConfirm({
      title: '提示',
      content: '您确认该通话记录需要丢弃？数据将会移除，无法恢复'
    });
    if (!confirm) {
      return;
    }
    this.removeCacheAndTryLoadNext(true);
  }

  /**
   * 进行中-保存
   */
  async onUnderwayOrderSave() {
    //
    await this.underwayForm.validate();
    const order = orderUI2DTO(this.underwayForm.getValues());
    const { actCalling } = this.getState();
    // 保存工单
    await this.messageService.showLoading();
    await promiseSafeRun(async () => {
      await this.requestUpdateOrderAndBindCalling(order, actCalling, order.partnerId!, true, true, true);
      this.afterSaveSuccess();
    });
    await this.messageService.hideLoading();
    this.messageService.showSuccess('保存成功');
  }

  /**
   * 进行中-废弃
   */
  async onUnderwayOrderDiscard() {
    await this.underwayForm.validate();
    // 用户录入废弃原因（PC 端直接返回废弃原因）
    const remark = await this.askUserInputDiscardRemark();
    const order = orderUI2DTO(this.underwayForm.getValues()) as SaleDocOrderDTO;
    const { actCalling } = this.getState();
    // 如果表单修改，提示保存
    const saveOrder = await this.askUserSaveOrderIfNeeded(this.underwayForm);
    await this.messageService.showLoading();
    await promiseSafeRun(async () => {
      await this.requestUpdateOrderAndBindCalling(order, actCalling, order.partnerId, saveOrder, true, true);
      await this.requestDiscardOrder(order, remark);
      this.messageService.showSuccess('工单废弃成功');
      this.afterSaveSuccess();
    });

    await this.messageService.hideLoading();
  }

  /**
   * 进行中-加急
   */
  async onUnderwayOrderUrgent() {
    await this.underwayForm.validate();
    const confirm = await this.messageService.showConfirm({
      title: '提示',
      content: '确认催单？'
    });
    if (!confirm) {
      return;
    }
    // 加急
    const order = orderUI2DTO(this.underwayForm.getValues()) as SaleDocOrderDTO;
    const { actCalling } = this.getState();
    // 如果表单修改，提示保存
    const saveOrder = await this.askUserSaveOrderIfNeeded(this.underwayForm);
    await this.messageService.showLoading();
    await promiseSafeRun(async () => {
      await this.requestUpdateOrderAndBindCalling(order, actCalling, order.partnerId, saveOrder, true, true);
      await this.requestUrgentOrder(order);
      this.messageService.showSuccess('催单成功');
      this.afterSaveSuccess();
    });
    await this.messageService.hideLoading();
  }

  /**
   * 新建工单-咨询
   */
  async onNewOrderConsult() {
    // await this.newOrderForm.validate();
    const confirm = await this.messageService.showConfirm({
      title: '提示',
      content: '确认新建咨询？'
    });
    if (!confirm) {
      return;
    }
    // let partner = this.customerForm.getValues() as ResPartnerDTO;
    // partner = this.partnerMemo.mergeValueMemo(partner) as ResPartnerDTO;
    const order = orderUI2DTO(this.newOrderForm.getValues()) as SaleDocOrderDTO;
    const appointReq = order.selectAssign;
    const saveType = newEnumValue(OrderCreateSaveTypeEnumValue.chance);
    const { actCalling } = this.getState();
    await this.messageService.showLoading();
    // 新建咨询单
    await promiseSafeRun(async () => {
      await this.saleOrderService.create({
        chance: order,
        appointReq: appointReq,
        saveType,
        callingId: actCalling
      });
      this.messageService.showSuccess('新建咨询成功');
      this.afterSaveSuccess();
    });
    await this.messageService.hideLoading();
  }

  /**
   * 新建工单-发单
   */
  async onNewOrderSendOrder() {
    await this.newOrderForm.validate();
    const confirm = await this.messageService.showConfirm({
      title: '提示',
      content: '确认新建工单？'
    });
    if (!confirm) {
      return;
    }
    const order = orderUI2DTO(this.newOrderForm.getValues());
    const appointReq = order.selectAssign;
    const saveType = newEnumValue(OrderCreateSaveTypeEnumValue.order);
    const { actCalling } = this.getState();
    await this.messageService.showLoading();
    // 新建咨询单
    await promiseSafeRun(async () => {
      await this.saleOrderService.create({
        orderId: order,
        appointReq: appointReq,
        saveType,
        callingId: actCalling
      });
      this.messageService.showSuccess('新建工单成功');
      this.afterSaveSuccess();
    });
    await this.messageService.hideLoading();
  }

  /**
   * 售后工单选中
   * @param item
   */
  onAfterSaleOrderSelect(item: SaleDocOrderDTO) {
    const { id, executor, selectAssign, ...restProps } = item;
    const newOrder: SaleDocOrderDTO & SaleOrderDTO = {
      parentId: {
        id: id
      },
      ...restProps
    };
    // 如果工单有执行人，选择执行人作为指派参数
    if (executor) {
      newOrder.selectAssign = {
        executor: executor,
        executorType: newEnumValue(OrderExecutorTypeEnumValue.internal)
      };
    }
    this.setAfterSaleOrder(chanceDTO2UI(newOrder));
  }

  onAfterSaleOrderCancel() {
    this.setAfterSaleOrder(undefined);
  }

  /**
   * 售后工单发单
   */
  async onHistoryOrderSendOrder() {
    await this.historyForm.validate();
    const confirm = await this.messageService.showConfirm({
      title: '提示',
      content: '确认新建售后工单？'
    });
    if (!confirm) {
      return;
    }
    const order = orderUI2DTO(this.historyForm.getValues());
    const appointReq = order.selectAssign;
    const saveType = newEnumValue(OrderCreateSaveTypeEnumValue.aftersale_order);
    const { actCalling } = this.getState();
    await this.messageService.showLoading();
    // 新建咨询单
    await promiseSafeRun(async () => {
      await this.saleOrderService.create({
        orderId: order,
        appointReq: appointReq,
        saveType,
        callingId: actCalling
      });
      this.messageService.showSuccess('新建售后工单成功');
      this.afterSaveSuccess();
    });
    await this.messageService.hideLoading();
  }

  /**
   * 历史咨询发单
   */
  async onHistoryChanceSendOrder() {
    await this.historyConsultForm.validate();
    const confirm = await this.messageService.showConfirm({
      title: '提示',
      content: '确认新建工单？'
    });
    if (!confirm) {
      return;
    }

    const order = orderUI2DTO(this.historyConsultForm.getValues());
    const appointReq = order.selectAssign;
    const saveType = newEnumValue(OrderCreateSaveTypeEnumValue.order);
    const { actCalling } = this.getState();
    // 新建咨询单
    await this.messageService.showLoading();
    await promiseSafeRun(async () => {
      await this.saleOrderService.create({
        orderId: order,
        appointReq: appointReq,
        saveType,
        callingId: actCalling
      });
      this.messageService.showSuccess('新建工单成功');
      this.afterSaveSuccess();
    });
    await this.messageService.hideLoading();
  }

  /**
   * 历史咨询选中
   * @param order
   */
  onHistoryChanceCreate(order: SaleChanceDTO) {
    this.setHistoryChanceOrder(chanceDTO2UI(order));
  }

  onHistoryChaneCancel() {
    this.setHistoryChanceOrder(undefined);
  }

  private setHistoryChanceOrder(order: SaleChanceUI | undefined) {
    this.setHasHistoryChanceInfo(!!order);
    this.historyConsultForm.setValues(order ?? {}, 'overwrite');
    this.updateCache();
  }

  private setHasHistoryChanceInfo(hasHistoryChanceInfo) {
    this.setState(<State>{
      hasHistoryChanceInfo: hasHistoryChanceInfo
    });
  }

  protected setAfterSaleOrder(order: SaleChanceUI | undefined) {
    this.setHasAfterSaleOrder(!!order);
    this.historyForm.setValues(order ?? {}, 'overwrite');
    this.updateCache();
  }

  private setHasAfterSaleOrder(hasAfterSaleOrder: boolean) {
    this.setState(<State>{
      hasCompleteOrderInfo: hasAfterSaleOrder
    });
  }

  /**
   * 提示用户保存
   * @protected
   * @return 是否保存
   */
  protected async askUserSaveOrderIfNeeded(form: IForm): Promise<boolean> {
    const modified = form.getFormInstance().modified;
    const saveOrder = false;
    if (modified) {
      return await this.messageService.showConfirm({
        title: '提示',
        content: '是否保存工单信息？'
      });
    }
    return saveOrder;
  }

  /**
   * 用户录入废弃原因
   * @protected
   */
  protected async askUserInputDiscardRemark(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * 保存工单信息，并且将工单绑定到通话记录中
   * @param order
   * @param calling
   * @param partner
   * @param saveOrder
   * @param bindCalling
   * @param updatePartner
   * @private
   */
  private async requestUpdateOrderAndBindCalling(
    order: SaleOrderDTO,
    calling: ActCallingDTO,
    partner: ResPartnerDTO,
    saveOrder: boolean,
    bindCalling: boolean,
    updatePartner: boolean
  ) {
    const { actCalling } = this.getState();
    const tasks: Promise<void>[] = [];
    if (saveOrder) {
      tasks.push(
        this.actCallService.update({
          id: actCalling.id,
          orderId: {
            id: order.id
          }
        })
      );
    }
    if (bindCalling) {
      tasks.push(this.saleOrderRestApiService.update(order));
    }
    if (updatePartner) {
      tasks.push(this.partnerRestApiService.update(partner));
    }
    // 保存工单
    await Promise.all(tasks);
  }

  protected async afterSaveSuccess() {
    await this.removeCacheAndTryLoadNext(false);
  }

  async removeCacheAndTryLoadNext(removeCalling: boolean) {
    if (removeCalling && this.getState().actCalling) {
      const calling = this.getState().actCalling;
      this.actCallService
        .delete(calling)
        .then()
        .catch((t) => {});
    }
    // 删除
    try {
      await this.doRemoveCache(this.getState().dataId);
    } catch (e) {
      console.error(e);
      this.messageService.showError('删除缓存失败：' + e.message);
    }
    if (this.getState().loadFromList) {
      this.reloadListAnBack();
      return;
    }
    //
    const count = this.getState().remainOrderCount;
    // 还有缓存需要处理，加载下一个
    if (count > 0) {
      this.loadNextOrderCache().then();
      return;
    }
    this.goHome();
  }

  private reloadListAnBack() {
    this.getOpenerChannel()?.emitEvent(BfStructureViewPageConfig.eventIds.reload);
    this.routeService.back();
  }

  protected goHome() {}

  protected async loadNextOrderCache() {
    const model = await this.getLatestCacheModel();
    if (model == null) {
      this.messageService.showWarn('没有更多工单');
      this.goHome();
      return;
    }
    await this.routeService.replace({
      path: ROUTE_NAMES.SaleInCallOrder,
      query: {
        dataId: model.id,
        phone: model.phone,
        loadFromCache: true
      }
    });
  }

  /***
   * 废弃工单
   * @param values
   * @param remark
   * @protected
   */
  protected async requestDiscardOrder(values: SaleDocOrderDTO, remark: string) {
    await this.saleOrderService.discard({
      orderId: {
        id: values.id
      },
      remark
    });
  }

  /**
   * 请求加急
   * @param values
   * @protected
   */
  protected async requestUrgentOrder(values: SaleDocOrderDTO) {
    await this.saleOrderService.urgent({
      id: values.id
    });
  }

  /**
   * 设置数据 Id
   * @param dataId
   */
  setDataId(dataId: string | undefined | null) {
    this.setState(
      <State>{
        dataId
      },
      false,
      '设置数据Id'
    );
  }

  setLoadFromList(loadFromList: boolean) {
    this.setState(
      <State>{
        loadFromList: loadFromList
      },
      false,
      '从列表进入'
    );
  }

  private async loadRemainOrderCount() {
    const count = await this.doLoadRemainOrderCount();
    this.setState(<State>{
      remainOrderCount: count
    });
  }

  protected async doLoadRemainOrderCount() {
    return 0;
  }

  private async initPageDataFromCache() {
    const data = await this.loadCacheModel();

    const pageData: InCallOrderCachePageData | null = data?.pageData ? JSON.parse(data.pageData) : null;
    if (pageData) {
      const { underwayOrderInfo, orderInfo, completedOrderInfo, historyChanceInfo, ...restState } = pageData;
      this.underwayForm.setValues(underwayOrderInfo, 'overwrite');
      this.newOrderForm.setValues(orderInfo, 'overwrite');
      this.historyForm.setValues(completedOrderInfo, 'overwrite');
      this.historyConsultForm.setValues(historyChanceInfo, 'overwrite');
      this.setState((state) => {
        Object.keys(restState).forEach((key) => {
          if (restState[key]) {
            state[key] = restState[key];
          }
        });
        return state;
      });
    }
  }

  /**
   * 上传
   * @private
   */
  protected async doUpload(dataId: string) {
    // 客户来电， 上传记录
    // await this.messageService.showLoading('上传记录中');
    // try {
    //   const model = await this.doUploadCacheToServer(dataId);
    //   await this.messageService.hideLoading();
    //   this.messageService.showSuccess('上传成功');
    //   this.onUploadSuccess(model);
    // } catch (e) {
    //   await this.messageService.hideLoading();
    //   this.messageService.showError('上传出错:' + e.message);
    //   console.error(e);
    //   const retry = await this.messageService.showConfirm({
    //     title: '提示',
    //     content: '上传出错,是否重试上传？\n' + e.message,
    //     okText: '重试',
    //     cancelText: '取消'
    //   });
    //
    //   if (retry) {
    //     await this.doUpload(dataId);
    //   }
    // }
  }

  protected async doUploadCacheToServer(dataId: string) {
    return Promise.resolve(null);
  }

  protected onUploadSuccess(model: CallCacheModel) {
    this.setAudioRes({
      id: model.recordFileId,
      saveTime: Number(model.recordFileSaveTime),
      url: model.recordFileUrl
    });
    this.setState(<State>{
      actCalling: {
        id: model.callingId
      }
    });
  }

  protected setAudioRes(audioRes: FileValue) {
    this.setState(<State>{
      audioRecord: audioRes
    });
  }

  protected setCanHangup(can: boolean) {
    this.setState(<State>{
      canHangup: can
    });
  }

  protected async loadCacheModel(): Promise<CallCacheModel> {
    return Promise.resolve(null);
  }

  protected async doRemoveCache(dataId: string) {}

  protected async getLatestCacheModel(): Promise<CallCacheModel> {
    return Promise.resolve(null);
  }

  destroy() {
    this.messageService.hideConfirm();
    this.messageService.hideLoading();
    super.destroy();
  }
}
