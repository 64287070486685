import { createContext, useContext, useMemo } from 'react';
import { BfAppContext } from '@tw/tw-runtime';

export const CommandPermissionContext = createContext<CommandPermissionStatus | null>(null);

export interface RolePermissionMap {
  tmk: CommandPermissionStatus;
  companyEngineer: CommandPermissionStatus;
  shopAdmin: CommandPermissionStatus;
  companyAdmin: CommandPermissionStatus;
}

/**
 *
 * @param roleMap 各个角色拥有的指令权限
 * @param allCommands 所有指令Id
 */
export function usePermissionCalc(roleMap: RolePermissionMap, allCommands: string[]): CommandPermissionStatus {
  const allPosition = BfAppContext.getInstance().getUserStore().getState().userInfo?.allPosition;
  return useMemo(() => {
    const result: CommandPermissionStatus = {};
    allCommands.forEach((cmdId) => {
      result[cmdId] = allPosition.some((position) => {
        const posId = position.id;
        return roleMap[posId] && roleMap[posId][cmdId] == true;
      });
    });
    return result;
  }, [allCommands, allPosition, roleMap]);
}

export function usePermission() {
  return useContext(CommandPermissionContext);
}

export interface CommandPermissionStatus {
  assign?: boolean;
  urgent?: boolean;
  discard?: boolean;
  call?: boolean;
  navigation?: boolean;
  historyOrder?: boolean;
  acceptOrder?: boolean;
  reject?: boolean;
  transfer?: boolean;
  execute?: boolean;
  orderDistribution?: boolean;
}

export const PERMISSION_GROUP: RolePermissionMap = {
  tmk: {
    assign: true,
    urgent: true,
    discard: true,
    call: true,
    navigation: true,
    historyOrder: true
  },
  companyEngineer: {
    acceptOrder: true,
    reject: true,
    transfer: true,
    execute: true,
    orderDistribution: true
  },
  shopAdmin: {
    assign: true,
    urgent: true,
    discard: true,
    call: true,
    navigation: true
  },
  companyAdmin: {
    assign: true,
    urgent: true,
    discard: true,
    call: true,
    navigation: true
  }
};

export const ALL_PERMISSIONS = [
  'assign',
  'urgent',
  'discard',
  'call',
  'navigation',
  'historyOrder',
  'acceptOrder',
  'reject',
  'transfer',
  'execute',
  'orderDistribution'
];
