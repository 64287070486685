import { BfAbstractIntentNavigator, BfAppContext } from '@tw/tw-runtime';

export class CodeScanner extends BfAbstractIntentNavigator<string> {
  constructor() {
    super();
  }
  get ROUTE_NAME(): string {
    return '_BarScanner';
  }

  finish(data: string | undefined) {
    console.log('CodeScanner finish', data, this.resolve);
    super.finish(data);
  }
  cancel() {
    console.log('CodeScanner cancel');
    super.cancel();
  }
}
export const BfCodeScanner = new CodeScanner();
