import React, { FC, useCallback, useEffect, useState } from 'react';
import { InCallAssignPoolSelectProps } from '@/sale/components/incall-assign-select/type';
import { TwFormItem } from '@tw/tw-components-rn';
import { ActivityIndicator, StyleSheet, Text } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
import { CoordinatesValue, promiseSafeRun } from '@tw/tw-runtime';
import { SaleOrderPoolDTO, SaleOrderPoolService } from '@tw/lock-api-gen';
import { useGetLatest, useRestService } from '@tw/tw-runtime-react';

export const InCallAssignPoolSelect: FC<InCallAssignPoolSelectProps> = (props) => {
  const { orderAddress, onGetPool } = props;

  // 根据地址查询工单池
  const onOrderPoolChange = async (pool: SaleOrderPoolDTO | undefined) => {
    onGetPool?.(pool);
  };
  const { orderPool, isLoadingPool, hasOrderAddress } = useLoadOrderPool(
    true,
    orderAddress,
    undefined,
    onOrderPoolChange
  );

  const render = () => {
    if (!hasOrderAddress) {
      return <Text style={styles.errorLabel}>请先选择工单地址</Text>;
    }
    if (isLoadingPool) {
      return <ActivityIndicator />;
    }
    if (!orderPool) {
      return <Text style={styles.errorLabel}>没有查询到工单池</Text>;
    }
    return <Text>{orderPool.name}</Text>;
  };
  return (
    <TwFormItem label={'工单池'} contentAlign={'right'}>
      {render()}
    </TwFormItem>
  );
};

function useLoadOrderPool(
  canAssignToPool: boolean,
  orderAddress: CoordinatesValue | undefined,
  orderPoolValue: SaleOrderPoolDTO | undefined,
  onOrderPoolChange: (orderPool: SaleOrderPoolDTO) => void
) {
  // 工单池
  const [orderPool, setOrderPool] = useState<SaleOrderPoolDTO>(orderPoolValue);
  useEffect(() => {
    setOrderPool(orderPoolValue);
  }, [orderPoolValue]);
  const [isLoadingPool, setLoadingPool] = useState<boolean>(false);
  const orderPoolService = useRestService(SaleOrderPoolService);
  const hasOrderAddress = orderAddress && orderAddress.province?.id && orderAddress.city?.id && orderAddress.county?.id;
  const getLatestPool = useGetLatest(orderPool);
  const getOnPoolChange = useGetLatest(onOrderPoolChange);
  const onGetOrderPool = useCallback(
    (pool: SaleOrderPoolDTO) => {
      //
      const lastPool = getLatestPool();
      const onPoolChange = getOnPoolChange();

      if (!pool) {
        setOrderPool(undefined);
        onPoolChange?.(undefined);
        return;
      }
      if (pool.id !== lastPool) {
        setOrderPool(pool);
        onPoolChange?.(pool);
      }
    },
    [getLatestPool, getOnPoolChange]
  );

  //
  const getOrderPoolService = useGetLatest(orderPoolService);
  useEffect(() => {
    if (!canAssignToPool) {
      return;
    }
    if (!hasOrderAddress) {
      return;
    }
    const loadPool = async () => {
      setLoadingPool(true);
      const orderPoolService = getOrderPoolService();
      await promiseSafeRun(async () => {
        const res = await orderPoolService.addressQry({
          province: {
            id: orderAddress.province?.id
          },
          county: {
            id: orderAddress.county?.id
          },
          city: {
            id: orderAddress.city?.id
          }
        });
        onGetOrderPool(res);
      });
      setLoadingPool(false);
    };
    loadPool();
  }, [canAssignToPool, onGetOrderPool, orderAddress, getOrderPoolService, hasOrderAddress]);
  return {
    hasOrderAddress,
    orderPool,
    isLoadingPool
  };
}
export const styles = StyleSheet.create({
  errorLabel: {
    color: TwTheme.values.errorColors.base
  }
});
