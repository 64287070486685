import { ProductItemProps } from './type';
import { ProductItem as Item } from '@/app/components/product-select/product-item/ProductItem';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { ProductProductDTO } from '@tw/lock-api-gen';
import { ProductDocProductProductProductChoosePage } from '@/product/stores/doc-product-product-product-choose/product-doc-product-product-product-choose-page';

export const ProductItem = (props: ProductItemProps) => {
  const { value } = props;
  const store = useBfPageStore<ProductDocProductProductProductChoosePage>();
  const itemKey = store.getDataId(value);
  const count = useStoreState(store, (state) => store.getListItemSelectCount(state, itemKey));
  return (
    <Item
      item={value}
      count={count}
      purchasePrice={(value as any).purchasePrice}
      costPrice={(value as any).costPrice}
      showQuantity={false}
      showPurchasePrice={false}
      showCostPrice={false}
      onCountChange={function (product: ProductProductDTO, count: number): void {
        store.onListItemSelectWithCount(product, count);
      }}
    />
  );
};
