import { BfPageStore, IPushMessageEvent } from '@tw/tw-runtime';
import { PoolNoticeStoreState } from './type';
import { BfLog4js, BfLogger } from '@tw/tw-log4js';

export class OrderPoolNoticeStore extends BfPageStore<PoolNoticeStoreState> {
  private static instance: OrderPoolNoticeStore = null;

  public static getInstance() {
    if (this.instance === null) {
      this.instance = new OrderPoolNoticeStore();
    }
    return this.instance;
  }

  private callbacks: Set<OnPoolOrderCallback> = new Set<OnPoolOrderCallback>();

  constructor() {
    super({
      pluginOptions: {
        devTools: {
          name: '工单池通知'
        }
      }
    });
  }

  protected getInitialState(): PoolNoticeStoreState {
    return {
      ...super.getInitialState(),
      hasNewPoolOrder: false
    };
  }

  /**
   * 通知
   */
  addNoticeListener(callback: OnPoolOrderCallback) {
    //
    this.callbacks.add(callback);
    return () => {
      this.callbacks.delete(callback);
    };
  }

  clearNoticeListener() {
    this.callbacks.clear();
  }

  setHasNewOrder(has: boolean) {
    this.setState({
      hasNewPoolOrder: has
    });
  }

  handleMessage(message: IPushMessageEvent): boolean {
    console.log('全局推送消息', message);
    if (this.isPoolMsg(message)) {
      this.sendPoolMsg(message);
      return true;
    }
    return false;
  }

  private isPoolMsg(message: IPushMessageEvent) {
    return message.message.payload?._noticeBot == 'poolOrder';
  }

  private sendPoolMsg(message: IPushMessageEvent) {
    this.logger.debug('收到工单池消息: ' + JSON.stringify(message));
    const poolMsg = this.getPoolNoticeMsg(message);
    this.handleMsgInner(poolMsg);
  }

  /**
   * 标记红点
   * @param msg
   * @private
   */
  private handleMsgInner(msg: PoolNoticeMsg) {
    // 新工单
    if (msg.type === 'poolOrder') {
      //
      this.setHasNewOrder(true);
    }
  }

  private getPoolNoticeMsg(msg: IPushMessageEvent): PoolNoticeMsg {
    return {
      type: 'poolOrder'
    };
  }

  protected createLogger(): BfLogger {
    return BfLog4js.getLogger('order-pool-notice');
  }
}

export interface PoolNoticeMsg {
  /**
   * 消息类型
   */
  type: PoolNoticeType;
}

export type PoolNoticeType = 'poolOrder' | 'poolGrapOrder';
/**
 * 回调
 */
export type OnPoolOrderCallback = (message: PoolNoticeMsg) => void;
