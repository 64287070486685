import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { ActivityIndicator } from 'react-native';
import { TwAudioPlayer } from '@tw/tw-components-rn';

export const InCallAudio = () => {
  const page = useBfPageStore<InCallOrderStore>();
  const recordUploading = useStoreState(page, (state) => state.recordUploading);
  const audioRecord = useStoreState(page, (state) => state.audioRecord);
  if (recordUploading) {
    return <ActivityIndicator size={'small'} />;
  }
  if (!audioRecord) {
    return null;
  }
  return <TwAudioPlayer autoLoad={true} createdTime={audioRecord.saveTime} source={{ uri: audioRecord.url }} />;
};
