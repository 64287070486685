import { BfPageStoreProvider, usePageStoreLifecycle, useStoreState } from '@tw/tw-runtime-react';
import React, { useMemo, useState } from 'react';
import { Text, View } from 'react-native';
import { TwButton, TwLoadingBlock, TwPage, TwPageHeader, TwTabs, TwTree } from '@tw/tw-components-rn';

import { BasicSetting } from './viewparts/basic-setting/BasicSetting';
import { BfAppContext } from '@tw/tw-runtime';
import { HrDocPositionPositionInfoPage } from '../../stores/doc-position-position-info/hr-doc-position-position-info-page';
import { RoleSetting } from './viewparts/role-setting/RoleSetting';
import { TwBfInfoView } from '@tw/tw-platform-rn';

const tabItems = [
  {
    key: '1',
    label: '基本信息',
    children: () => <BasicSetting />
  },
  {
    key: '2',
    label: '手机端权限',
    children: () => <RoleSetting type="mobile" />
  },
  {
    key: '3',
    label: '桌面端权限',
    children: () => <RoleSetting type="web" />
  }
];

export const HrDocPositionPositionInfo = (props) => {
  const [page] = useState(() => new HrDocPositionPositionInfoPage());
  usePageStoreLifecycle(page, props.route.params);
  const [currentTab, setCurrentTab] = useState('1');

  const isLoading = useStoreState(page, (state) => state.isLoading);
  const position = useStoreState(page, (state) => state.position);

  const [mobilePermit, setMobilePermit] = useState();
  const [pcPermit, setPcPermit] = useState();

  if (isLoading) {
    return <TwLoadingBlock />;
  }

  const renderLazyPlaceHolder = () => {
    return <TwLoadingBlock />;
  };

  const onBack = () => {
    BfAppContext.getInstance().getRouteService().back();
  };

  return (
    <BfPageStoreProvider store={page}>
      <TwPage translucentStatusBar={true}>
        <TwPageHeader
          title={props.route.params?.positionId ? '编辑角色' : '创建角色'}
          showBack={true}
          onBack={onBack}
          actions={
            <TwButton variant={'ghost'} size={'md'} onPress={() => page.onSave()} alignItems="center" padding={0}>
              保存
            </TwButton>
          }
        />

        <TwTabs
          lazy={true}
          items={tabItems}
          tabBarScrollable={false}
          renderLazyPlaceholder={renderLazyPlaceHolder}
          activeKey={currentTab}
          // tabBarWidth={300}
          onTabChange={(index) => {
            setCurrentTab(index);
          }}
          animationEnabled={false}
          swipeEnabled={false}
        />
      </TwPage>
    </BfPageStoreProvider>
  );
};
