import { AppRegistry, Platform } from 'react-native';
import { BfAppContext } from '@tw/tw-runtime';

/**
 * Headless Task，应用启动时，执行该任务初始化 App
 */
if (Platform.OS != 'web') {
  AppRegistry.registerHeadlessTask('appInit', () => {
    return async () => {
      const appStore = BfAppContext.getInstance().getAppStore();
      if (appStore.isInitializing() || appStore.isInitialSucceed()) {
        return;
      }
      if (appStore.getState().initLoadFinished) {
        showAndroidToast('App初始化已经初始化过，不再执行初始化');
        return;
      }
      showAndroidToast('App初始化...');
      await BfAppContext.getInstance().getAppStore().initialize();
    };
  });
}

function showAndroidToast(message: string) {
  if (Platform.OS === 'android') {
    const ToastAndroid = require('react-native').ToastAndroid;
    ToastAndroid.show(message, ToastAndroid.SHORT);
  }
}
