import React, { useMemo, useState } from 'react';

import { ProductProductDTO } from '@tw/lock-api-gen';
import { SaleProductOrder } from '@/product/components/sale-product-order/SaleProductOrder';
import { SelectedProduct } from '@/product/stores/product-select-common/type';
import { TwCart } from '@tw/tw-components-rn';

interface ProductCartProps {
  title: string;
  selectedProductList?: SelectedProduct[];
  count: number;
  onProductCountChange: (product: ProductProductDTO, count: number) => void;
  onClearSelectedProduct: () => void;
  onConfirmSelect: () => void;
}

export const ProductCart = (props: ProductCartProps) => {
  const { title, count, selectedProductList } = props;

  const [visible, setVisible] = useState(false);
  const onVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };
  const onCountChange = (product: ProductProductDTO, count: number) => {
    props.onProductCountChange(product, count);
  };
  const onProductClear = () => {
    props.onClearSelectedProduct();
  };
  const onConfirm = () => {
    props.onConfirmSelect();
  };

  return (
    <TwCart
      popupTitle={title}
      count={count}
      isPopupVisible={visible}
      onPopupVisibleChange={onVisibleChange}
      data={selectedProductList}
      onClear={onProductClear}
      onConfirm={onConfirm}
      renderItem={({ item }: { item: SelectedProduct }) => (
        <SaleProductOrder
          quantity={item.product.quantity}
          title={item.product.name}
          description={item.product.specification}
          count={item.count}
          key={item.product.id}
          onCountChange={(count) => onCountChange(item.product, count)}
        />
      )}
    />
  );
};
