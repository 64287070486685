import { TwButton, TwPage, TwTypography } from '@tw/tw-components-rn';
import { useBfPageStore } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { TwTheme } from '@tw/tw-runtime-rn';
import { TouchableOpacity } from 'react-native';

export const InCallRemoveRecordAction = () => {
  const store = useBfPageStore<InCallOrderStore>();
  return (
    <TwPage.HeaderTextAction
      onPress={() => {
        store.onDeleteRecord();
      }}
    >
      丢弃来电记录
    </TwPage.HeaderTextAction>
  );
};
