import { BfAbstractIntentNavigator } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

export class OrderTransferNavigator extends BfAbstractIntentNavigator<any, { orderId: string }> {
  get ROUTE_NAME() {
    return ROUTE_NAMES.SaleOrderTransferInfo;
  }
}

export const SaleOrderTransferNavigator = new OrderTransferNavigator();
