import { FC } from 'react';
import { PartnerNameFieldProps } from '@/sale/fields/partner-name/type';
import { PartnerNameFieldCommon } from '@/sale/fields/partner-name/PartnerNameFieldCommon';

/**
 * 用户卡片的客户名称
 * @param props
 * @constructor
 */
export const PartnerNameFieldUserCard: FC<PartnerNameFieldProps> = (props) => {
  return <PartnerNameFieldCommon mode={'UserCard'} {...props} />;
};
