import { TwBfListView } from '@tw/tw-platform-rn';
import { useState } from 'react';
import { SaleHistoryOrderListPage } from '../../stores/sale-history-order-list/sale-history-order-list-page';
import { PartnerNameBasicCardContent } from '@/sale/fields/partner-name/PartnerNameBasicCardContent';

export const SaleHistoryOrderList = (props) => {
  const [page] = useState(() => new SaleHistoryOrderListPage());

  return (
    <TwBfListView
      page={page}
      params={props.route?.params}
      components={{
        PartnerName: PartnerNameBasicCardContent
      }}
    />
  );
};
