import { TwBfInfoView } from '@tw/tw-platform-rn';
import { useState } from 'react';
import { SaleOrderTransferInfoPage } from '../../stores/sale-order_transfer-info/sale-order_transfer-info-page';
import { TransferInfo } from './viewparts/transfer-info/TransferInfo';

export const SaleOrderTransferInfo = (props) => {
  const [page] = useState(() => new SaleOrderTransferInfoPage());

  return (
    <TwBfInfoView
      page={page}
      params={props.route?.params}
      components={{
        TransferInfo: TransferInfo
      }}
    />
  );
};
