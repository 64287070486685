export const ROUTE_NAMES_GEN = {
  SaleDocOrderOrderIndex: 'SaleDocOrderOrderIndex',
  SaleDocOrderOrderInfo: 'SaleDocOrderOrderInfo',
  SaleDocOrderOrderCreate: 'SaleDocOrderOrderCreate',
  SaleDocOrderPoolOrderPoolIndex: 'SaleDocOrderPoolOrderPoolIndex',
  HrDocShopShopIndex: 'HrDocShopShopIndex',
  HrDocShopShopInfo: 'HrDocShopShopInfo',
  HrDocShopShopCreate: 'HrDocShopShopCreate',
  HrDocEmployeeEmployeeIndex: 'HrDocEmployeeEmployeeIndex',
  HrDocEmployeeEmployeeInfo: 'HrDocEmployeeEmployeeInfo',
  HrDocEmployeeEmployeeCreate: 'HrDocEmployeeEmployeeCreate',
  HrDocPositionPositionIndex: 'HrDocPositionPositionIndex',
  HrDocPositionEmployeePositionPositionEmployee: 'HrDocPositionEmployeePositionPositionEmployee',
  WmsDocAuditPickingWaitIndex: 'WmsDocAuditPickingWaitIndex',
  WmsDocAuditPickingWaitInfo: 'WmsDocAuditPickingWaitInfo',
  WmsDocSubmitPickingAppliedIndex: 'WmsDocSubmitPickingAppliedIndex',
  WmsDocSubmitPickingAppliedInfo: 'WmsDocSubmitPickingAppliedInfo',
  WmsDocInventoryCompanyIndex: 'WmsDocInventoryCompanyIndex',
  WmsDocInventoryCompanyInfo: 'WmsDocInventoryCompanyInfo',
  WmsDocInventoryCompanyProductChoose: 'WmsDocInventoryCompanyProductChoose',
  WmsDocInventoryPersonalPersonalIndex: 'WmsDocInventoryPersonalPersonalIndex',
  WmsDocInventoryPersonalPersonalInfo: 'WmsDocInventoryPersonalPersonalInfo',
  WmsDocInventoryPersonalPersonalProductChoose: 'WmsDocInventoryPersonalPersonalProductChoose',
  WmsDocWarehouseWarehouseIndex: 'WmsDocWarehouseWarehouseIndex',
  WmsDocWarehouseWarehouseInfo: 'WmsDocWarehouseWarehouseInfo',
  WmsDocWarehouseWarehouseCreate: 'WmsDocWarehouseWarehouseCreate',
  WmsDocMovementStorageInCreate: 'WmsDocMovementStorageInCreate',
  WmsDocApplyApplyCreate: 'WmsDocApplyApplyCreate',
  WmsDocReturnReturnCreate: 'WmsDocReturnReturnCreate',
  ProductDocProductProductIndex: 'ProductDocProductProductIndex',
  ProductDocProductProductInfo: 'ProductDocProductProductInfo',
  ProductDocProductProductCreate: 'ProductDocProductProductCreate',
  ProductDocProductProductProductChoose: 'ProductDocProductProductProductChoose',
  ProductDocProductServiceProductServiceIndex: 'ProductDocProductServiceProductServiceIndex',
  ProductDocProductServiceProductServiceInfo: 'ProductDocProductServiceProductServiceInfo',
  ProductDocProductServiceProductServiceCreate: 'ProductDocProductServiceProductServiceCreate',
  ProductDocProductBrandBrandIndex: 'ProductDocProductBrandBrandIndex',
  ProductDocProductBrandBrandInfo: 'ProductDocProductBrandBrandInfo',
  ProductDocProductBrandBrandCreate: 'ProductDocProductBrandBrandCreate',
  ProductDocProductCategoryCategoryIndex: 'ProductDocProductCategoryCategoryIndex',
  ProductDocProductCategoryCategoryInfo: 'ProductDocProductCategoryCategoryInfo',
  ProductDocProductCategoryCategoryCreate: 'ProductDocProductCategoryCategoryCreate',
  WmsDocSupplierSupplierIndex: 'WmsDocSupplierSupplierIndex',
  WmsDocSupplierSupplierInfo: 'WmsDocSupplierSupplierInfo',
  WmsDocSupplierSupplierCreate: 'WmsDocSupplierSupplierCreate',
  ResDocPartnerPartnerIndex: 'ResDocPartnerPartnerIndex',
  ResDocPartnerPartnerInfo: 'ResDocPartnerPartnerInfo',
  ResDocPartnerPartnerCreate: 'ResDocPartnerPartnerCreate',
  ResDocBlacklistBlackIndex: 'ResDocBlacklistBlackIndex',
  ResDocBlacklistBlackInfo: 'ResDocBlacklistBlackInfo',
  SaleDocMgrRejectReasonRejectIndex: 'SaleDocMgrRejectReasonRejectIndex',
  SaleDocMgrRejectReasonRejectInfo: 'SaleDocMgrRejectReasonRejectInfo',
  SaleDocMgrRejectReasonRejectCreate: 'SaleDocMgrRejectReasonRejectCreate',
  SaleDocMgrTransferReasonTransferIndex: 'SaleDocMgrTransferReasonTransferIndex',
  SaleDocMgrTransferReasonTransferInfo: 'SaleDocMgrTransferReasonTransferInfo',
  SaleDocMgrTransferReasonTransferCreate: 'SaleDocMgrTransferReasonTransferCreate',
  SaleDocMgrAbandonReasonAbandonIndex: 'SaleDocMgrAbandonReasonAbandonIndex',
  SaleDocMgrAbandonReasonAbandonInfo: 'SaleDocMgrAbandonReasonAbandonInfo',
  SaleDocMgrAbandonReasonAbandonCreate: 'SaleDocMgrAbandonReasonAbandonCreate',
  SaleDocMgrConsultReasonConsultIndex: 'SaleDocMgrConsultReasonConsultIndex',
  SaleDocMgrConsultReasonConsultInfo: 'SaleDocMgrConsultReasonConsultInfo',
  SaleDocMgrConsultReasonConsultCreate: 'SaleDocMgrConsultReasonConsultCreate',
  FrDocRefundRefundIndex: 'FrDocRefundRefundIndex',
  FrDocRefundRefundInfo: 'FrDocRefundRefundInfo',
  FrDocPaymentRefundCreate: 'FrDocPaymentRefundCreate',
  FrDocWithdrawalAuduitWithdrawalIndex: 'FrDocWithdrawalAuduitWithdrawalIndex',
  FrDocWithdrawalAuduitWithdrawalInfo: 'FrDocWithdrawalAuduitWithdrawalInfo',
  FrDocWithdrawalWithdrawalList: 'FrDocWithdrawalWithdrawalList'
};
