import { TwRecursionField } from '@tw/tw-platform-rn';
import { TwFormItem } from '@tw/tw-components-rn';
import { observer, useField, useFieldSchema, useForm } from '@formily/react';
import { Text, TouchableOpacity } from 'react-native';
import { styles } from './styles';
import { useState } from 'react';
import { OrderOffers } from '@/sale/pages/order-process/fields/order-offers/OrderOffers';
import { Field } from '@formily/core';

export const TotalContainer = observer(
  () => {
    const fieldSchema = useFieldSchema();
    const totalSchema = fieldSchema.properties.total;
    const offersSchema = fieldSchema.properties.offers;
    const totalWithNoOffersSchema = fieldSchema.properties.totalWithNoOffers;
    const [offersVisible, setOffersVisible] = useState(false);
    const onMorePress = () => {
      //
      setOffersVisible(true);
    };
    const form = useForm();
    const totalField = form.query('total').take() as Field;
    const totalWithNoOffersField = form.query('totalWithNoOffers').take() as Field;
    const offersField = form.query('offers').take() as Field;

    const onOffersChange = (offers) => {
      offersField.onInput(offers);
    };
    return (
      <>
        <TwFormItem
          label={'合计'}
          contentAlign={'right'}
          helper={
            offersField?.editable && (
              <TouchableOpacity style={styles.moreContainer} onPress={onMorePress}>
                <Text style={styles.more}>更多</Text>
              </TouchableOpacity>
            )
          }
        >
          <TwRecursionField schema={totalSchema} />
          <TwRecursionField schema={totalWithNoOffersSchema} />
          <TwRecursionField schema={offersSchema} />
        </TwFormItem>
        {offersField?.editable && (
          <OrderOffers
            visible={offersVisible}
            totalBefore={totalWithNoOffersField?.value}
            offers={offersField?.value}
            onSubmit={onOffersChange}
            onClose={() => setOffersVisible(false)}
          />
        )}
      </>
    );
  },
  {
    displayName: 'TotalContainer'
  }
);
