import { TwBfEnum } from '@tw/tw-platform-rn';
import { FC } from 'react';
import { InCallLinkManGenderInputProps } from '@/sale/components/incall-customer/type';

export const InCallCustomerGenderInput: FC<InCallLinkManGenderInputProps> = (props) => {
  return (
    <TwBfEnum
      enumId={'gender'}
      mode={'inline'}
      itemMode={'labelCheck'}
      size={'xs'}
      itemLayout={'blockRow'}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
