import { ScrollView, View } from 'react-native';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { TwEmpty, TwLoadingBlock } from '@tw/tw-components-rn';
import { HistoryChanceOrderCard } from '@/sale/pages/in-call-order/viewparts/incall-history-consult/HistoryChanceOrderCard';
import { InCallConsultOrderEdit } from '@/sale/pages/in-call-order/viewparts/incall-history-consult/InCallHistorConsultEdit';

export const InCallHistoryConsult = () => {
  const store = useBfPageStore<InCallOrderStore>();
  const isLoadingHistoryChance = useStoreState(store, (state) => state.isLoadingHistoryChance);
  const hasSelectedOrder = useStoreState(store, (state) => !!state.hasHistoryChanceInfo);
  const historyChanceList = useStoreState(store, (state) => state.historyChanceList);

  if (isLoadingHistoryChance) {
    return <TwLoadingBlock />;
  }

  if (!historyChanceList?.length) {
    return <TwEmpty />;
  }
  // 有选中的历史工单
  if (hasSelectedOrder) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <InCallConsultOrderEdit />
      </ScrollView>
    );
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {historyChanceList?.map((item) => {
        return (
          <HistoryChanceOrderCard
            value={item}
            key={item.id}
            onNewOrder={(order) => {
              store.onHistoryChanceCreate(order);
            }}
          />
        );
      })}
    </ScrollView>
  );
};
