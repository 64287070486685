import { ISoundModule, SoundInfo } from '@/app/native/sound/sound-module-defs';

export const TwSound: ISoundModule = {
  getInfo(): Promise<SoundInfo> {
    return Promise.resolve(undefined);
  },
  loadSoundFile(name: string, type: string): Promise<void> {
    return Promise.resolve(undefined);
  },
  loadUrl(url: string): Promise<void> {
    return Promise.resolve(undefined);
  },
  pause(): Promise<void> {
    return Promise.resolve(undefined);
  },
  playSoundFile(name: string, type: string): Promise<void> {
    return Promise.resolve(undefined);
  },
  playUrl(url: string): Promise<void> {
    return Promise.resolve(undefined);
  },
  resume(): Promise<void> {
    return Promise.resolve(undefined);
  },
  seek(seconds: number): Promise<void> {
    return Promise.resolve(undefined);
  },
  setVolume(volume: number): Promise<void> {
    return Promise.resolve(undefined);
  },
  stop(): Promise<void> {
    return Promise.resolve(undefined);
  }
};
