import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { OrderAbandonPopup } from '@/sale/components/order-abandon-popup/OrderAbandonPopup';

export const InCallOrderDiscardPanel = () => {
  const store = useBfPageStore<InCallOrderStore>();
  const visible = useStoreState(store, (state) => state.isDiscardPanelVisible);
  const onConfirm = (remark) => {
    store.onUserInputDiscardRemarkConfirm(remark);
  };
  const onCancel = () => {
    store.onUserCancelInputDiscardRemark();
  };
  const onVisibleChange = (visible: boolean) => {
    if (!visible) {
      onCancel();
    }
  };

  return <OrderAbandonPopup visible={visible} onVisibleChange={onVisibleChange} onSubmit={onConfirm} />;
};
