import { HistoryCompletedOrderSlice } from '@/res/stores/res-partner-info-preview/slices';
import { TwButton, TwPage } from '@tw/tw-components-rn';
import { useSliceState } from '@tw/tw-runtime-react';

export const HistoryOrderCommandBar = (props: HistoryCommandBarProps) => {
  const { slice } = props;
  const hasHistoryOrderForm = useSliceState(slice, (state) => !!state.completedOrderInfo);

  if (!hasHistoryOrderForm) {
    return null;
  }
  return (
    <TwPage.Footer>
      <TwButton
        variant={'outline'}
        onPress={() => {
          slice.onHistoryOrderSendOrder();
        }}
      >
        发单
      </TwButton>
    </TwPage.Footer>
  );
};

export interface HistoryCommandBarProps {
  slice: HistoryCompletedOrderSlice<any>;
}
