import { BfSlice } from '@tw/tw-runtime';
import { SaleDocOrderDTO } from '@tw/lock-api-gen';

export class HistoryOrderSlice<RootState> extends BfSlice<RootState, HistoryOrderSliceState> {
  constructor(options) {
    super(options);
  }

  setItems(historyOrder: SaleDocOrderDTO[] | undefined) {
    this.setState(
      {
        historyOrder
      },
      false,
      '更新历史工单列表'
    );
  }

  getInitialState(): HistoryOrderSliceState {
    return {
      historyOrder: []
    };
  }
}

export interface HistoryOrderSliceState {
  historyOrder: SaleDocOrderDTO[] | undefined;
}
