import { useBfPageStore } from '@tw/tw-runtime-react';
import { ResHistoryChance } from '@/res/viewparts/history-chance/ResHistoryChance';
import { ResDocPartnerPartnerInfoPage } from '@/res/stores/doc-partner-partner-info/res-doc-partner-partner-info-page';

export const HistoryChance = () => {
  const store = useBfPageStore<ResDocPartnerPartnerInfoPage>();
  const { historyChanceSlice, historyOrderSlice } = store.getSlices();

  return <ResHistoryChance slice={historyChanceSlice} historyOrderSlice={historyOrderSlice} />;
};
