import { FC } from 'react';
import { InCallCustomerNameInputFieldProps } from '@/sale/components/incall-customer/type';
import { InCallCustomNameInput } from '@/sale/components/incall-customer/InCallCustomNameInput';
import { observer, useField } from '@formily/react';
import { Field } from '@formily/core';
import { ResPartnerDTO } from '@tw/lock-api-gen';

/**
 * 客户姓名录入
 */
export const InCallCustomerNameInputField: FC<InCallCustomerNameInputFieldProps> = observer(
  (props) => {
    const { partnerIdAttr, enableSearch, genderAttr, mobileAttr, value, onChange } = props;
    const field = useField();
    const partnerIdField = field.query('.' + partnerIdAttr).take() as Field;

    const partnerValue = partnerIdField?.value as ResPartnerDTO | undefined;
    // const name = partnerValue?.name ?? value;
    //
    const handleSelect = (partner: ResPartnerDTO) => {
      const genderField = field.query('.' + genderAttr).take() as Field;
      const mobileField = field.query('.' + mobileAttr).take() as Field;
      if (!partnerIdField) {
        return;
      }
      //联动修改手机号
      mobileField?.onInput(partner.mobile);
      // 联动修改性别
      genderField?.onInput(partner.gender);
      // 联动修改姓名
      onChange?.(partner.name);
      // 记录选中的客户
      partnerIdField.onInput(partner);
    };
    const handleChange = (text: string) => {
      // partnerIdField.onInput(undefined);
      onChange?.(text);
    };
    return (
      <InCallCustomNameInput
        enableSearch={enableSearch}
        value={value}
        onChange={handleChange}
        onSelect={handleSelect}
      />
    );
  },
  {
    displayName: 'InCallCustomerNameInputField'
  }
);
