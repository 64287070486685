import { FlashList } from '@shopify/flash-list';
import { appendMockData, getMockData } from './mock';
import { Text, SectionList } from 'react-native';
import { useRef, useState } from 'react';
const mockData = getMockData();
const CATEGORY = {};
export const ShopList = () => {
  const times = useRef(0);
  const pageIndex = useRef(10);
  const scrollList = useRef<SectionList>(null);
  const [items, setItems] = useState(mockData);
  const loadBefore = async () => {
    const newItems = appendMockData(items, '附加', pageIndex.current);
    pageIndex.current--;
    setItems(newItems);
    scrollList.current.scrollToLocation({
      sectionIndex: 1,
      itemIndex: 0,
      animated: false
    });
  };
  return (
    <SectionList
      ref={scrollList}
      renderItem={({ item }) => {
        return <Text style={{ height: 300, backgroundColor: 'red' }}>卡片{item.name}</Text>;
      }}
      renderSectionHeader={({ section: { title } }) => {
        return <Text>Header:{title.name}</Text>;
      }}
      sections={items}
      onScrollToTop={(event) => {}}
      onViewableItemsChanged={(info) => {
        console.log('onViewableItemsChanged', info.viewableItems[0]);
        const visibleItem = info.viewableItems[0];
        const section = visibleItem.section;
        if (section.title.name === '常用' && visibleItem.index === null) {
          times.current++;
          if (times.current > 1) {
            loadBefore();
          }
        }
      }}
    />
  );
};
