import { BfAbstractIntentNavigator } from '@tw/tw-runtime';
import { WmsInventoryExceptionModel } from '@tw/lock-api-gen/dist/models/wms';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

class Navigator extends BfAbstractIntentNavigator<any, WmsInventoryExceptionModel[]> {
  get ROUTE_NAME() {
    return ROUTE_NAMES.WmsInventoryException;
  }
}

export const InventoryExceptionNavigator = new Navigator();
