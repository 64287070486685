import { TwEmpty, TwLoadingBlock, TwMessage, TwPage, TwPageHeader } from '@tw/tw-components-rn';
import { BfAppContext, BfNoticeTopic } from '@tw/tw-runtime';
import { useStoreState } from '@tw/tw-runtime-react';
import { FlashList } from '@shopify/flash-list';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

export const CollabTopics = () => {
  const noticeStore = BfAppContext.getInstance().getNoticeStore();
  const topics = useStoreState(noticeStore, (state) => state.topics);
  const isLoadingTopic = useStoreState(noticeStore, (state) => state.isLoadingTopic);
  const onTopicClick = (topic: BfNoticeTopic) => {
    const routeService = BfAppContext.getInstance().getRouteService();
    routeService.push({
      path: ROUTE_NAMES.CollabNotices,
      query: {
        topicId: topic.id
      }
    });
  };
  const onReload = () => {
    noticeStore.loadTopics();
  };
  const renderPageContent = () => {
    if (isLoadingTopic) {
      return <TwLoadingBlock />;
    }
    if (!topics?.length) {
      return <TwEmpty description={'暂无消息,点击刷新'} onPress={onReload} />;
    }
    return (
      <FlashList
        data={topics}
        estimatedItemSize={75}
        renderItem={(info) => {
          const item = info.item as BfNoticeTopic | undefined;
          return (
            <TwMessage
              unreadCount={item.unreadCount}
              key={item.id}
              createdTime={item.updateTime}
              title={item.name}
              describe={item?.latestNoticeTitle}
              onClick={() => onTopicClick(item)}
            />
          );
        }}
      />
    );
  };
  return (
    <TwPage translucentStatusBar={true}>
      <TwPageHeader title={'消息'} />
      {renderPageContent()}

      {/*<TwMessage createdTime={} />*/}
    </TwPage>
  );
};
