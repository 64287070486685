import { BF_PLATFORM_ROUTE, TwBfSignDraw } from '@tw/tw-platform-rn';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import React, { useEffect, useMemo } from 'react';
import { StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';

import { AuthChangePasswordPhone } from '@/auth/pages/chang-password-phone/AuthChangePasswordPhone';
import { AuthLogin } from '@/auth/pages/login/AuthLogin';
import { AuthLoginNew } from '@/auth/pages/login-new/AuthLoginNew';
import { AuthResetPassword } from '@/auth/pages/reset-password/AuthResetPassword';
import { AuthVerify } from '@/auth/pages/verify/AuthVerify';
import { BfAppContext } from '@tw/tw-runtime';
import { CollabNotices } from '@/collab/pages/notices/CollabNotices';
import { CollabOrder } from '@/collab/pages/order/CollabOrder';
import { CollabTopics } from '@/collab/pages/topics/CollabTopics';
import { FileViewer } from '@/app/log/FileViewer';
import { FrPayment } from '@/fr/pages/payment/FrPayment';
// import { FrPaymentResult } from '@/fr/pages/payment/FrPaymentResult';
// import { FrWaitPayment } from '@/fr/components/wait-payment/FrWaitPayment';
import { Home } from '@/home/pages/home/Home';
import { HomePrivacyPolicy } from '@/home/pages/privacy-policy';
import { HomeSplash } from '@/home/pages/splash';
import { HomeTermsAndConditions } from '@/home/pages/terms-and-conditions';
import { HrDocPositionPositionInfo } from '@/hr/pages/doc-position-position-info/HrDocPositionPositionInfo';
import { ImageViewerDemo } from '@/demo/pages/imageviewer/ImageViewerDemo';
import { NotificationTest } from '@/demo/pages/notification/NotificationTest';
import { OutCall } from '@/demo/pages/outcall/OutCall';
import { PermissionHelper } from '@/settings/pages/permission-helper/PermissionHelper';
import { Platform } from 'react-native';
import { ProductDocProductProductSelect } from '@/product/pages/doc-product-product-select/ProductDocProductProductSelect';
import { ProductProductSelect } from '@/product/pages/product-product-select/ProductProductSelect';
import { ProductProductSelectSearch } from '@/product/pages/product-select-search/ProductProductSelectSearch';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { RoleSelect } from './components/role-select/assign/RoleSelect';
import { SaleHistoryOrderList } from '@/sale/pages/sale-history-order-list/SaleHistoryOrderList';
import { SaleInCallCacheList } from '@/sale/pages/in-call-cache-list/SaleInCallCacheList';
import { SaleInCallOrder } from '@/sale/pages/in-call-order/SaleInCallOrder';
import { SaleOrderConfirm } from '@/sale/pages/order-confirm/SaleOrderConfirm';
import { SaleOrderProcess } from '@/sale/pages/order-process/SaleOrderProcess';
import { SaleOrderTransferInfo } from '@/sale/pages/sale-order_transfer-info/SaleOrderTransferInfo';
import { SaleWxQr } from '@/sale/pages/wx-qr/SaleWxQr';
import { SelectListView } from '@/app/components/select-list-view/SelectListView';
import { Setting } from '@/mine/pages/setting/Setting';
import { SettingsBankAccount } from '@/settings/pages/bank-account/SettingsBankAccount';
import { SettingsDeviceInfo } from '@/settings/pages/device-info/SettingsDeviceInfo';
import { SettingsPermissionApply } from '@/settings/pages/permission-apply';
import { SettingsRingoneEmpty } from '@/settings/pages/ringone-empty/SettingsRingoneEmpty';
import { SettingsRingtone } from '@/settings/pages/ringtone';
import { SettingsWithdraw } from '@/settings/pages/withdraw/SettingsWithdraw';
import { ShopList } from '@/demo/pages/shoplist/ShopList';
import { TwAssignUserSelect } from './components/tw-account-select/assign/TwAssignUserSelect';
import { WalletSettings } from '@/settings/pages/bill/WalletSettings';
import { WmsDocApplyApplyCreateSubmit } from '@/wms/pages/doc-apply-apply-create/WmsDocApplyApplyCreateSubmit';
import { WmsDocInventoryCompanySelect } from '@/wms/pages/doc-inventory-company-select/WmsDocInventoryCompanySelect';
import { WmsDocReturnReturnCreateSubmit } from '@/wms/pages/doc-return-return-create/WmsDocReturnReturnCreateSubmit';
import { WmsInventoryException } from '@/wms/pages/inventory-exception/WmsInventoryException';
import { createAppRoutesGen } from '@/app/navigation/_AppRoute';
import { navigationRef } from '@tw/tw-runtime-rn';
import { SettingsSettingPayment } from '@/settings/pages/setting-payment/SettingsSettingPayment';

const Stack = createStackNavigator();
const linking: LinkingOptions<any> = {
  prefixes: ['lock://', 'http://localhost']
};

const SCREEN_OPTIONS: StackNavigationOptions = {
  headerShown: false,
  cardStyle: {
    flex: 1
  }
};

const MODAL_SCREEN_OPTIONS: StackNavigationOptions = {
  ...SCREEN_OPTIONS,
  presentation: 'modal'
};
export const AppRouter = () => {
  const routeService = BfAppContext.getInstance().getRouteService();
  useEffect(() => {
    routeService.trigger('onReady');
  }, [routeService]);

  const platformScreens = useMemo(() => {
    const screens = Object.keys(BF_PLATFORM_ROUTE).map((key) => {
      const routeConfig = BF_PLATFORM_ROUTE[key];
      if (key === 'SELECT_LIST') {
        return (
          <Stack.Screen
            name={routeConfig.path}
            options={MODAL_SCREEN_OPTIONS}
            component={SelectListView}
            key={routeConfig.path}
          />
        );
      }
      return (
        <Stack.Screen
          name={routeConfig.path}
          options={MODAL_SCREEN_OPTIONS}
          component={routeConfig.component}
          key={routeConfig.path}
        ></Stack.Screen>
      );
    });
    return <>{screens}</>;
  }, []);

  return (
    <NavigationContainer ref={navigationRef} linking={linking}>
      <Stack.Navigator
        initialRouteName={ROUTE_NAMES.HomeSplash}
        screenOptions={() => {
          return SCREEN_OPTIONS;
        }}
      >
        <Stack.Screen name={ROUTE_NAMES.HomeSplash} component={HomeSplash} />
        <Stack.Screen name={ROUTE_NAMES.HomePrivacyPolicy} component={HomePrivacyPolicy} />
        <Stack.Screen name={ROUTE_NAMES.HomeTermsAndConditions} component={HomeTermsAndConditions} />
        <Stack.Screen name={ROUTE_NAMES.PermissionHelper} component={PermissionHelper} />
        <Stack.Screen name={ROUTE_NAMES.AuthLogin} component={AuthLogin} />
        <Stack.Screen name={ROUTE_NAMES.AuthLoginNew} component={AuthLoginNew} />
        <Stack.Screen name={ROUTE_NAMES.AuthVerify} component={AuthVerify} />
        <Stack.Screen name={ROUTE_NAMES.CollabOrder} component={CollabOrder} />
        <Stack.Screen name={ROUTE_NAMES.CollabTopics} component={CollabTopics} />
        <Stack.Screen name={ROUTE_NAMES.CollabNotices} component={CollabNotices} />
        <Stack.Screen name={ROUTE_NAMES.SaleInCallOrder} component={SaleInCallOrder} />
        <Stack.Screen name={ROUTE_NAMES.SaleInCallCacheList} component={SaleInCallCacheList} />
        <Stack.Screen name={ROUTE_NAMES.Home} component={Home} />
        <Stack.Screen name={ROUTE_NAMES.Setting} component={Setting} />
        <Stack.Screen name={ROUTE_NAMES.SettingsDeviceInfo} component={SettingsDeviceInfo}></Stack.Screen>
        <Stack.Screen name={'ShopList'} component={ShopList} />
        <Stack.Screen name={ROUTE_NAMES.SaleOrderTransferInfo} component={SaleOrderTransferInfo}></Stack.Screen>
        {platformScreens}
        <Stack.Screen
          name={ROUTE_NAMES._AssignUserSelect}
          options={MODAL_SCREEN_OPTIONS}
          component={TwAssignUserSelect}
        ></Stack.Screen>
        <Stack.Screen
          name={ROUTE_NAMES._PositionSelect}
          options={MODAL_SCREEN_OPTIONS}
          component={RoleSelect}
        ></Stack.Screen>
        <Stack.Screen name={ROUTE_NAMES.LOG} component={FileViewer} />
        <Stack.Screen name={ROUTE_NAMES.IMAGE_VIEWER} component={ImageViewerDemo} />
        <Stack.Screen name={ROUTE_NAMES.ProductProductSelect} component={ProductProductSelect}></Stack.Screen>
        <Stack.Screen
          name={ROUTE_NAMES.ProductProductSelectSearch}
          component={ProductProductSelectSearch}
        ></Stack.Screen>
        <Stack.Screen
          name={ROUTE_NAMES.WmsDocInventoryCompanySelect}
          component={WmsDocInventoryCompanySelect}
        ></Stack.Screen>
        <Stack.Screen name={ROUTE_NAMES._ProductSelect} component={ProductDocProductProductSelect}></Stack.Screen>

        <Stack.Screen name={ROUTE_NAMES.SaleOrderConfirm} component={SaleOrderConfirm} />
        <Stack.Screen name={ROUTE_NAMES.SaleOrderProcess} component={SaleOrderProcess} />
        <Stack.Screen name={ROUTE_NAMES.Sign} component={TwBfSignDraw} />
        <Stack.Screen name={ROUTE_NAMES.NotificationTest} component={NotificationTest} />
        <Stack.Screen name={ROUTE_NAMES.SaleWxQr} options={MODAL_SCREEN_OPTIONS} component={SaleWxQr} />
        <Stack.Screen name={ROUTE_NAMES.SaleHistoryOrderList} component={SaleHistoryOrderList} />
        <Stack.Screen
          name={ROUTE_NAMES.SettingsPermissionApply}
          options={MODAL_SCREEN_OPTIONS}
          component={SettingsPermissionApply}
        />
        <Stack.Screen name={ROUTE_NAMES.SettingsRingtone} component={SettingsRingtone} />
        <Stack.Screen name={ROUTE_NAMES.WmsInventoryException} component={WmsInventoryException} />
        <Stack.Screen name={ROUTE_NAMES.HrDocPositionPositionInfo} component={HrDocPositionPositionInfo} />
        <Stack.Screen name={ROUTE_NAMES.WmsDocApplyApplyCreateSubmit} component={WmsDocApplyApplyCreateSubmit} />
        <Stack.Screen name={ROUTE_NAMES.WmsDocReturnReturnCreateSubmit} component={WmsDocReturnReturnCreateSubmit} />
        <Stack.Screen name={ROUTE_NAMES.OutCall} component={OutCall} />
        <Stack.Screen name={ROUTE_NAMES.SettingsWithdraw} component={SettingsWithdraw} />
        <Stack.Screen name={ROUTE_NAMES.SettingsBankAccount} component={SettingsBankAccount} />
        <Stack.Screen name={ROUTE_NAMES.WalletSettings} component={WalletSettings} />
        <Stack.Screen name={ROUTE_NAMES.AuthChangePasswordPhone} component={AuthChangePasswordPhone} />
        <Stack.Screen name={ROUTE_NAMES.AuthResetPassword} component={AuthResetPassword} />

        {/* 临时 2023-08-24 by yt */}
        <Stack.Screen name={ROUTE_NAMES.FrPayment} component={FrPayment} />
        {/* <Stack.Screen name={ROUTE_NAMES.FrPaymentResult} component={FrPaymentResult} /> */}
        <Stack.Screen name={ROUTE_NAMES.SettingsRingoneEmpty} component={SettingsRingoneEmpty} />
        <Stack.Screen name={ROUTE_NAMES.SettingsSettingPayment} component={SettingsSettingPayment} />

        {createAppRoutesGen(Stack)}
      </Stack.Navigator>
    </NavigationContainer>
  );
};
