import { BfAppContext, CoordinatesValue } from '@tw/tw-runtime';
import { BfPageStoreProvider, usePageStoreLifecycle, useStoreState } from '@tw/tw-runtime-react';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import {
  TwAddressSelectPopup,
  TwButton,
  TwIcon,
  TwListItem,
  TwListItemContainer,
  TwPage,
  TwPageHeader,
  TwPortal,
  TwTypography
} from '@tw/tw-components-rn';

import { Debug } from '@/mine/pages/setting/Debug';
import { DefaultOrderSettingService } from '@/app/core/services/default-order-setting-service';
import { ElementUtils } from '@tw/tw-platform-react';
import { MaterialIcons } from '@expo/vector-icons';
import { MinePage } from '@/mine/stores/MinePage';
import { MyStatistic } from './viewparts/my-statistic/MyStatistic';
import { POSITION_KEYS } from '@/app/constants/position-keys';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { TwUpdate } from '@tw/tw-update-rn';
import { UserInfo } from '@/mine/pages/mine/viewparts/user-info/UserInfo';
import { styles } from '@/mine/pages/setting/styles';

export const Mine = (props) => {
  const [page] = useState(() => new MinePage());
  usePageStoreLifecycle(page, undefined);

  const routeService = BfAppContext.getInstance().getRouteService();
  const messageService = BfAppContext.getInstance().getMessageService();
  const appStore = BfAppContext.getInstance().getAppStore();
  const accountInfo = useStoreState(page, (state) => state.accountInfo) || {};
  const isManager = useIsManager();
  const onPress = () => {
    routeService.push({
      path: 'Message'
    });
  };
  const onLogout = () => {
    appStore.logout();
  };
  const onSettingPress = () => {
    routeService.push({
      path: 'Setting'
    });
  };
  const onPermission = () => {
    routeService.push({
      path: ROUTE_NAMES.SettingsPermissionApply,
      query: {
        fromSetting: true
      }
    });
  };

  const onWalletPress = () => {
    routeService.push({
      path: ROUTE_NAMES.FrDocPaymentRefundCreate,
      query: {
        fromSetting: true
      }
    });
  };

  const onUploadLog = () => {};
  const onDevicePress = () => {
    routeService.push({
      path: ROUTE_NAMES.SettingsDeviceInfo
    });
  };
  const onGoLog = () => {
    routeService.push({
      path: ROUTE_NAMES.LOG
    });
  };
  const [orderAddr, setOrderAddr] = useState<CoordinatesValue>();
  const [versionName, setVersionName] = useState<string>();
  const [isAddrPopupVisible, setAddrPopupVisible] = useState(false);
  const onOrderSetting = () => {
    setAddrPopupVisible(true);
  };

  const handleAddrSelect = async (addr: CoordinatesValue) => {
    await DefaultOrderSettingService.getInstance().setAddr({
      defAddr: addr
    });
    setOrderAddr(addr);
    setAddrPopupVisible(false);
  };
  const handleCheckUpdate = () => {
    messageService.showInfo('检查更新中..');
    TwUpdate.checkNewApp((eventId, error) => {
      if (error) {
        messageService.showError('检查更新出错');
        return;
      }
      if (eventId === 'onNoNewApp') {
        messageService.showSuccess('已经是最新版本');
      }
    });
  };

  useEffect(() => {
    (async function () {
      const addr = await DefaultOrderSettingService.getInstance().getAddr();
      setOrderAddr(addr);
    })();
    (async function () {
      const versionInfo = await TwUpdate.getVersion();
      setVersionName(versionInfo.versionName);
    })();
  }, []);

  return (
    <BfPageStoreProvider store={page}>
      <TwPage
        translucentStatusBar={true}
        footer={
          <View style={styles.footer}>
            <TwButton variant={'outline'} onPress={onLogout}>
              退出登录
            </TwButton>
          </View>
        }
      >
        <TwPageHeader title={'我的'} showBack={false} />
        <UserInfo />

        <ScrollView>
          <TwListItemContainer space={2} style={{ marginTop: 8 }}>
            {/* TODO: 本月订单数量哪里取的 */}
            <MyStatistic ordersNum={1} availableBalance={accountInfo.availableBalance}></MyStatistic>
            <TwListItem
              title={'彩铃设置'}
              icon={(active, size, color) => <MaterialIcons name="switch-account" size={size} color={color} />}
              pressable={true}
              showArrow={true}
            ></TwListItem>

            <TwListItem
              title={'权限管理'}
              icon={(active, size, color) => <MaterialIcons name="construction" size={size} color={color} />}
              pressable={true}
              showArrow={true}
              onPress={onPermission}
            ></TwListItem>
            <TwListItem
              title={'公司账户'}
              icon={(active, size, color) => <MaterialIcons name="construction" size={size} color={color} />}
              pressable={true}
              showArrow={true}
              onPress={() => {
                messageService.showInfo('[公司账户]功能开发中');
              }}
            ></TwListItem>
            <TwListItem
              title={'门店账户'}
              icon={(active, size, color) => <MaterialIcons name="switch-account" size={size} color={color} />}
              pressable={true}
              showArrow={true}
              onPress={() => {
                messageService.showInfo('[门店账户]功能开发中');
              }}
            ></TwListItem>
            {/* 
          <TwListItem
            title={'权限申请'}
            icon={(active, size, color) => <MaterialIcons name="construction" size={size} color={color} />}
            pressable={true}
            showArrow={true}
            onPress={onPermission}
          ></TwListItem> */}
            <TwListItem
              title={'线下收款码'}
              icon={(active, size, color) => <MaterialIcons name="switch-account" size={size} color={color} />}
              pressable={true}
              showArrow={true}
              onPress={onWalletPress}
            ></TwListItem>
            {isManager && (
              <TwListItem
                pressable={true}
                showArrow={true}
                icon={(active, size, color) => <TwIcon icon="icon-location_on" size={size} color={color} />}
                title={'默认接单地址'}
                onPress={onOrderSetting}
                rightContent={
                  <TwTypography.Text color={'forth'} size={'sm'}>
                    {ElementUtils.getTextDirectly(orderAddr, 'coordinates')}
                  </TwTypography.Text>
                }
              ></TwListItem>
            )}
            <TwListItem
              title={'检查更新'}
              icon={(active, size, color) => <MaterialIcons name="refresh" size={size} color={color} />}
              pressable={true}
              showArrow={true}
              onPress={handleCheckUpdate}
              rightContent={
                <TwTypography.Text color={'forth'} size={'sm'}>
                  {versionName ? `v${versionName}` : ''}
                </TwTypography.Text>
              }
            ></TwListItem>

            <TwListItem pressable={true} showArrow={true} title={'运行日志'} onPress={onGoLog}></TwListItem>
          </TwListItemContainer>
          {__DEV__ && <Debug />}
        </ScrollView>
        {isAddrPopupVisible && (
          <TwPortal>
            <TwAddressSelectPopup
              visible={isAddrPopupVisible}
              value={orderAddr}
              requiredAreaLevel={'city'}
              onChange={handleAddrSelect}
              onClose={() => {
                setAddrPopupVisible(false);
              }}
            />
          </TwPortal>
        )}
      </TwPage>
    </BfPageStoreProvider>
  );
};

function useIsManager() {
  const userStore = BfAppContext.getInstance().getUserStore();
  const positions = userStore.getState().userInfo.allPosition;
  return positions?.some((item) => item.id === POSITION_KEYS.COMPANY_ADMIN);
}
