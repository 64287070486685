import { BfAppContext, BfUserLoginParams, IPushMessageEvent } from '@tw/tw-runtime';
import { IRnRouteService, navigationRef, RnAppStore, RnRouterService } from '@tw/tw-runtime-rn';
import { CommonActions } from '@react-navigation/native';
import { CallModule } from '@/app/native/call';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { OrderPoolNoticeStore } from '@/sale/stores/order-pool-notice-store/order-pool-notice-store';
import { OrderNoticeStore } from '@/sale/stores/order-notice-store/order-notice-store';
import { NotificationService } from '@/app/core/services/notification-service';
import { DefaultOrderSettingService } from '@/app/core/services/default-order-setting-service';
import { TwUpdate } from '@tw/tw-update-rn';
import { UpdateConfig } from '@/app/core/setup/api-config';
import { PermissionChecker } from '@/app/helpers/check-all-permissions';

export class AppStore extends RnAppStore {
  protected isPublicPath(path: string): boolean {
    return false;
  }

  async initialize(): Promise<void> {
    await super.initialize();
    //
    TwUpdate.init({
      url: UpdateConfig.checkUrl,
      appId: UpdateConfig.appId,
      env: UpdateConfig.env
    }).then();
  }

  /**
   * 全局推送消息
   * @param message
   * @protected
   */
  protected onGlobalPushMessage(message: IPushMessageEvent) {
    console.log('收到全局推送消息', message);
    OrderNoticeStore.getInstance().handleMessage(message) ||
      OrderPoolNoticeStore.getInstance().handleMessage(message) ||
      NotificationService.getInstance().handleMessage(message);
  }

  /**
   * 解绑推送
   * @param silent
   * @protected
   */
  protected async unBindPushNotice(silent: boolean) {
    super.unBindPushNotice(silent);
  }

  protected showPwdSettingPage(): void {
    this.getMessageService().showError('没有实现：设置密码');
  }

  protected async showSettingPwdSkipConfirm(params: BfUserLoginParams): Promise<boolean> {
    return true;
  }

  protected validateWeekPwd(params: BfUserLoginParams): boolean {
    return false;
  }

  async logout(silent?: boolean): Promise<void> {
    await super.logout(silent);
    // await this.goLoginPage();
  }

  protected async afterLogoutSuccess(): Promise<void> {
    this.logger.debug('退出登录成功');
    this.menuStore.onLogout();
    const routeService = BfAppContext.getInstance().getRouteService() as RnRouterService;
    await (routeService as IRnRouteService).reset({
      path: ROUTE_NAMES.AuthLogin
    });
  }

  protected async initializeServices(): Promise<void> {
    await super.initializeServices();
    const token = this.authStorageService.getToken();
    if (token) {
      CallModule.setAuthToken(token);
    }
  }

  async goLoginPage() {
    navigationRef.current?.dispatch((state) => {
      return CommonActions.reset({
        ...state,
        routes: [
          {
            name: 'Login'
          }
        ],
        index: 0
      });
    });
  }

  protected async afterUserAuthInfoPrepared(): Promise<void> {
    super.afterUserAuthInfoPrepared().then();
    // 初始化接单地址
    DefaultOrderSettingService.getInstance().reloadAddr().then();
    CallModule.uploadAllInCallCacheModel();
  }

  async goEntryPage() {
    const allPosition = this.userStore.getState().userInfo.allPosition;
    const permissionCheckOption = PermissionChecker.getPermissionCheckOption(allPosition);
    const enabled = await PermissionChecker.checkAllPermissionEnabled(permissionCheckOption);
    const routeService = this.getRouteService() as IRnRouteService;
    // 所有权限已开启，进入主页

    await routeService.reset({
      path: ROUTE_NAMES.Home
    });
    if (!enabled) {
      setTimeout(async () => {
        await routeService.push({
          path: ROUTE_NAMES.SettingsPermissionApply
        });
      }, 1000);
    }
  }
}
