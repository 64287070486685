import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  cardContainer: {
    marginHorizontal: TwTheme.values.spaces.pageHorizontal,
    marginVertical: TwTheme.values.spaces['2']
  },
  title: {
    ...TwTheme.values.textSizes.sm,
    color: TwTheme.values.textColors.secondary,
    fontWeight: 'bold'
  },
  text: {
    ...TwTheme.values.textSizes.xs,
    color: TwTheme.values.textColors.secondary
  }
});
