const dayjs = require('dayjs');

export const createDates = (value: number, start: number, end: number) => {
  const today = dayjs();
  const tomorrow = today.add(1, 'day');
};

export const getRelativeTime = (date) => {
  const inputDate = dayjs(date);
  const today = dayjs().startOf('day');
  const yesterday = dayjs().subtract(1, 'day').startOf('day');
  const twoDaysAgo = dayjs().subtract(2, 'day').startOf('day');
  const tomorrow = dayjs().add(1, 'day').startOf('day');
  const twoDaysLater = dayjs().add(2, 'day').startOf('day');

  if (inputDate.isSame(today, 'd')) {
    return `${date.format('M月D日')} 今天`;
  } else if (inputDate.isSame(yesterday, 'd')) {
    return `${date.format('M月D日')} 昨天`;
  } else if (inputDate.isSame(twoDaysAgo, 'd')) {
    return `${date.format('M月D日')} 前天`;
  } else if (inputDate.isSame(tomorrow, 'd')) {
    return `${date.format('M月D日')} 明天`;
  } else if (inputDate.isSame(twoDaysLater, 'd')) {
    return `${date.format('M月D日')} 后天`;
  } else {
    return date.format('YYYY年M月D日');
  }
};

// const times = [
//   {
//     key: '0',
//     list: ['9:00-10:00', '10:00-11:00', '11:00-12:00'],
//   },
//   {
//     key: '1',
//     list: ['9:00-10:00', '10:00-11:00'],
//   },
// ];

export const getTimeSlots = (startTime: string, endTime: string, interval: number): string[] => {
  const start = dayjs(`2021-01-01 ${startTime}`);
  const end = dayjs(`2021-01-01 ${endTime}`);

  const slots: string[] = [];
  let current = start;

  while (current.isBefore(end)) {
    const slot = `${current.format('HH:mm')}-${current.add(interval, 'minute').format('HH:mm')}`;
    slots.push(slot);
    current = current.add(interval, 'minute');
  }

  return slots;
};

export const formatDateToSlot = (startTime: number, endTime: number): string => {
  const start = dayjs(startTime);
  const end = dayjs(endTime);

  return `${start.format('HH:mm')}-${end.format('HH:mm')}`;
};

export const calcInterval = (start: string, end: string): number => {
  const s = start.split(':');
  const e = end.split(':');
  const startHour = parseInt(s[0], 10);
  const startMinute = parseInt(s[1], 10);
  const endHour = parseInt(e[0], 10);
  const endMinute = parseInt(e[1], 10);
  const startMs = startHour * 60 + startMinute;
  const endMs = endHour * 60 + endMinute;
  return endMs - startMs;
};
