import { TouchableOpacity } from 'react-native';
import { styles } from '@/sale/pages/in-call-order/viewparts/incall-remain-order-count/style';
import { TwTypography } from '@tw/tw-components-rn';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import React from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { BfAppContext } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

export const InCallRemainOrderCount = () => {
  const store = useBfPageStore<InCallOrderStore>();
  const count = useStoreState(store, (state) => state.remainOrderCount);
  const loadFromList = useStoreState(store, (state) => state.loadFromList);
  const handlePress = () => {
    BfAppContext.getInstance().getRouteService().replace({
      path: ROUTE_NAMES.SaleInCallCacheList
    });
  };
  if (!count || loadFromList) {
    return null;
  }
  return (
    <TouchableOpacity style={styles.container} onPress={handlePress}>
      <TwTypography.Text size={'xs'} color={'white'}>{`待处理工单: ${count ?? 0}`}</TwTypography.Text>
      <MaterialIcons name="arrow-forward-ios" size={12} color="black" />
    </TouchableOpacity>
  );
};
