import { PermissionModuleInterface } from './permission-module-defs';

export const PermissionModule: PermissionModuleInterface = {
  isAccessibilitySettingsOn(): Promise<boolean> {
    return Promise.resolve(false);
  },
  isFloatWindowOn(): Promise<boolean> {
    return Promise.resolve(false);
  },
  requestFloatWindowPermission() {},
  requestDefaultPhoneApp() {},
  startAccessibilityService() {},
  startForeService() {},
  isManageExternalStorageEnabled(): Promise<boolean> {
    return Promise.resolve(false);
  },
  enableManageExternalStorage() {
    //
  },
  isIgnoringBatteryOptimizations(): Promise<boolean> {
    return Promise.resolve(true);
  },
  requestIgnoreBatteryOptimizations(): Promise<void> {
    return Promise.resolve();
  },
  isNotificationEnabled(): Promise<boolean> {
    return Promise.resolve(false);
  },
  goNotificationSetting(): Promise<void> {
    return Promise.resolve();
  },
  canGoAutoStartManager(): Promise<boolean> {
    return Promise.resolve(false);
  },
  goAutoStartManager(): Promise<void> {
    return Promise.resolve();
  },
  canGoBatterySetting(): Promise<boolean> {
    return Promise.resolve(false);
  }
};
