import { BfAppContext, BfSlice, IMessageService, newEnumValue, promiseSafeRun } from '@tw/tw-runtime';
import { IForm } from '@tw/tw-platform-react';
import { TwBfForm } from '@tw/tw-platform-rn';
import {
  OrderCreateSaveTypeEnumValue,
  ResPartnerDTO,
  SaleChanceDTO,
  SaleDocOrderDTO,
  SaleOrderService
} from '@tw/lock-api-gen';

export class HistoryChanceSlice<RootState> extends BfSlice<RootState, HistoryChanceSliceState> {
  private form: IForm;
  private messageService: IMessageService;
  private saleOrderService: SaleOrderService;

  constructor(options) {
    super(options);
    const api = BfAppContext.getInstance().getApiService();
    this.form = TwBfForm.createForm();
    this.messageService = BfAppContext.getInstance().getMessageService();
    this.saleOrderService = new SaleOrderService(api);
  }

  getForm() {
    return this.form;
  }

  getInitialState(): HistoryChanceSliceState {
    return {
      historyChanceInfo: undefined,
      historyChanceList: [],
      partnerId: undefined
    };
  }

  setItems(chances: SaleChanceDTO[]) {
    this.setState(
      {
        historyChanceList: chances
      },
      false,
      '更新历史机会列表'
    );
  }

  setPartnerId(partner: ResPartnerDTO) {
    this.setState(
      {
        partnerId: partner
      },
      false,
      '设置客户信息'
    );
  }

  /**
   * 历史咨询发单
   */
  async onHistoryChanceSendOrder() {
    await this.form.validate();
    const confirm = await this.messageService.showConfirm({
      title: '提示',
      content: '确认新建工单？'
    });
    if (!confirm) {
      return;
    }

    const partner = this.getState().partnerId;
    const order = this.form.getValues() as SaleDocOrderDTO;
    const appointReq = order.selectAssign;
    const saveType = newEnumValue(OrderCreateSaveTypeEnumValue.order);
    // 新建咨询单
    await this.messageService.showLoading();
    await promiseSafeRun(async () => {
      await this.saleOrderService.create({
        partnerId: partner,
        orderId: order,
        appointReq: appointReq,
        saveType
      });
      this.messageService.showSuccess('新建工单成功');
      this.afterSaveSuccess();
    });
    await this.messageService.hideLoading();
  }

  /**
   * 历史咨询选中
   * @param order
   */
  onHistoryChanceCreate(order: SaleChanceDTO) {
    this.setHistoryChanceOrder(order);
  }

  onHistoryChaneCancel() {
    this.setHistoryChanceOrder(undefined);
  }

  private setHistoryChanceOrder(order: SaleChanceDTO | undefined) {
    this.setState(
      {
        historyChanceInfo: order
      },
      false,
      '历史咨询选中'
    );
  }

  private afterSaveSuccess() {
    this.setHistoryChanceOrder(undefined);
  }
}

export interface HistoryChanceSliceState {
  /**
   *  历史机会
   */
  historyChanceList?: SaleChanceDTO[];
  /**
   * 历史机会-表单
   */
  historyChanceInfo: SaleChanceDTO;
  /**
   * 客户
   */
  partnerId: ResPartnerDTO;
}
