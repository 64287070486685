import React, { useEffect, useMemo } from 'react';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';

import { BfFieldEvent } from '@tw/tw-platform-react';
import { Field } from '@formily/core';
import { OrderLayoutField } from '@/sale/pages/order-process/fields/order-layout-field/OrderLayoutField';
import { OrderOffers } from '@/sale/pages/order-process/fields/order-offers/OrderOffers';
import { OrderPriceField } from '@/sale/pages/order-process/fields/order-price-field/OrderPriceField';
import { OrderProductField } from '@/sale/pages/order-process/fields/order-product-field/OrderProductField';
import { OrderProductListField } from '@/sale/pages/order-process/fields/order-product-list-field/OrderProductListField';
import { OrderProductQuantityField } from '@/sale/pages/order-process/fields/order-product-quantity-field/OrderProductQuantityField';
import { SaleOrderProcessPage } from '@/sale/stores/order-process/sale-order-process-page';
import { TotalContainer } from '@/sale/pages/order-process/fields/order-total-container/TotalContainer';
import { TwBfForm } from '@tw/tw-platform-rn';
import { getFormSchema } from '@/sale/pages/order-process/viewparts/form-content/form-schema';
export const FormContent = () => {
  const page = useBfPageStore<SaleOrderProcessPage>();
  const form = useMemo(() => TwBfForm.createForm(), []);
  page.setContentForm(form);
  const value = useStoreState(page, (state) => state.order);
  useEffect(() => {
    form.setValues(value);
  }, [form, value]);
  const elements = useMemo(() => getFormSchema(), []);
  const onFieldChange = async (event: BfFieldEvent) => {
    const { field } = event;
    const pathArr = field.path.toArr();
    // 已选商品, 数量变为0 后，清空当前选中
    if (
      pathArr[0] === 'productItemDetail' &&
      pathArr.length === 3 &&
      pathArr[2] === 'qtyOrdered' &&
      (field as Field).value == 0
    ) {
      const itemIndex = field.index;
      const values = form.getValues();
      const newValues = JSON.parse(JSON.stringify(values));
      newValues.productItemDetail.splice(itemIndex, 1);
      form.setValues(newValues);
      page.updateOrder(newValues);
      return;
    }
    const values = form.getValues();
    page.updateOrder(values);
  };
  return (
    <TwBfForm
      form={form}
      elements={elements}
      onFieldInputValueChange={onFieldChange}
      components={{
        OrderLayout: OrderLayoutField,
        OrderProductList: OrderProductListField,
        OrderPrice: OrderPriceField,
        OrderProduct: OrderProductField,
        OrderProductQuantity: OrderProductQuantityField,
        TotalContainer,
        OrderOffers
      }}
    />
  );
};
