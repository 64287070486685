/**
 * 库存警告
 * @constructor
 */
import { TwPage, TwPageHeader, TwResult } from '@tw/tw-components-rn';
import { ProductItemInventoryPreview } from '@/wms/common/components/product-item-inventory-preview';
import { ScrollView, View } from 'react-native';
import { InventoryExceptionNavigator } from '@/wms/pages/inventory-exception/inventory-exception-navigator';
import { useIntentFinish } from '@tw/tw-runtime-react';
import { styles } from '@/wms/pages/inventory-exception/styles';

export const WmsInventoryException = () => {
  const exceptionModels = InventoryExceptionNavigator.getIntent();
  useIntentFinish(InventoryExceptionNavigator);
  return (
    <TwPage translucentStatusBar={true}>
      <TwPageHeader title={'库存异常'} />
      <View style={styles.contentContainer}>
        <View style={styles.tipImageContainer}>
          <TwResult status={'warning'} title={'部分产品库存不足'} />
        </View>
        <ScrollView>
          {exceptionModels?.map((item) => {
            return (
              <ProductItemInventoryPreview
                key={item.productId}
                title={item.productName}
                outQuantity={item.needNum}
                remainQuantity={item.relNum}
              />
            );
          })}
        </ScrollView>
      </View>
    </TwPage>
  );
};
