import { ALL_ID, BfFormElement } from '@tw/tw-platform-react';
import { PreferenceConsts } from '@tw/lock-api-gen';
import { BfAppContext } from '@tw/tw-runtime';

export const WmsProductCategorySortHelper = {
  async onListConditionElementItemsSortChange(elementId: string, items: any[]) {
    if (elementId !== 'category') {
      return;
    }
    // 收集排序后的 Id
    const idKeys = items.filter((item) => item.id !== ALL_ID).map((item) => item.id);
    const idKeysStr = JSON.stringify(idKeys);
    // 保存到偏好里
    await this.preferenceService.set(PreferenceConsts.USER_DEFAULT_PRODUCT_PRODUCTCATEGORY_SORT, idKeysStr);
  },

  async postProcessConditionItems(pref, element: BfFormElement, items: any[]) {
    if (!pref) {
      return [{ id: ALL_ID, name: '全部' }].concat(items);
    }
    try {
      const idKeys: number[] = JSON.parse(pref as string);
      const newItems = items.sort((a, b) => {
        // 服务排序到最前面
        if (a.code === '_service_') {
          return -1;
        }
        if (b.code === '_service_') {
          return 1;
        }
        const aIndex = idKeys.indexOf(a.id);
        const bIndex = idKeys.indexOf(b.id);
        if (aIndex > -1 && bIndex > -1) {
          return aIndex - bIndex;
        }
        // a 在，b 不在， a 放前面
        if (aIndex > -1) {
          return 1;
        }
        // b 在，a 不在， a 放后面
        if (bIndex > -1) {
          return -1;
        }
        return 0;
      });
      return [{ id: ALL_ID, name: '全部' }].concat(newItems);
    } catch (e) {
      console.error(e);
      BfAppContext.getInstance()
        .getMessageService()
        .showError('解析分类排序配置异常' + e.message);
    }
    return [{ id: ALL_ID, name: '全部' }].concat(items);
  }
};
