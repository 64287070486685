import { ScreenProp } from '@/app/navigation/types';
import React from 'react';
import { LoginForm } from '@/auth/pages/login/viewparts/login-form/LoginForm';
import { styles } from '@/auth/pages/login/styles';
import { LoginHeader } from '@/auth/pages/login/viewparts/login-header/LoginHeader';
import { KeyboardAvoidingView } from 'react-native';
import { TwPage } from '@tw/tw-components-rn';

export const AuthLogin = ({ navigation, route }: ScreenProp) => {
  return (
    <TwPage translucentStatusBar={true} style={styles.container}>
      <LoginHeader style={styles.header} />
      <KeyboardAvoidingView>
        <LoginForm style={styles.loginFrom} />
      </KeyboardAvoidingView>
      {/*<OtherLogin style={styles.other} />*/}
    </TwPage>
  );
};
