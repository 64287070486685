import { TwBadge, TwButton, TwPage } from '@tw/tw-components-rn';
import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { useStoreState } from '@tw/tw-runtime-react';
import { BfAppContext } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { Text, TouchableOpacity } from 'react-native';

export const NoticeBell = () => {
  const noticeStore = BfAppContext.getInstance().getNoticeStore();
  const hasUnreadNotice = useStoreState(noticeStore, (state) => (state.unReadNoticeList?.length ?? 0) > 0);
  const routeService = BfAppContext.getInstance().getRouteService();
  const onActionPress = () => {
    noticeStore.clearUnReadNotices();
    noticeStore.loadTopics();
    routeService.push({
      path: ROUTE_NAMES.CollabTopics
    });
  };
  return (
    <TwPage.HeaderAction
      badgeProps={{
        dot: hasUnreadNotice,
        count: 0
      }}
      icon={<MaterialIcons name="notifications-none" size={24} color="black" />}
      onPress={onActionPress}
    />
  );
};
