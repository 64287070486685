import {
  BfAppContext,
  CoordinatesValue,
  IMessageService,
  IStructureService,
  promiseSafeRun,
  StructureInfo
} from '@tw/tw-runtime';
import { ResPartnerDTO, ResPartnerService, SaleOrderService } from '@tw/lock-api-gen';
import { PhoneCallUtils } from '@/app/helpers/phone-call-utils';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { isNil } from 'lodash-es';
import { RnPlaceService } from '@tw/tw-runtime-rn';
import { ElementUtils } from '@tw/tw-platform-react';
import { BfLocation } from '@tw/tw-platform-rn';

export class CommonActionProxy {
  private static instance = new CommonActionProxy();
  private partnerService: ResPartnerService | undefined;
  private saleOrderService: SaleOrderService;
  private messageService: IMessageService | undefined;
  private placeService: RnPlaceService | undefined;

  public static getInstance() {
    return this.instance;
  }

  private onLocationSelect: OnLocationOpenInMapListener | undefined;

  public setOnLocationSelect(listener: OnLocationOpenInMapListener) {
    this.onLocationSelect = listener;
  }

  /**
   * 打开 location
   */
  async openLocationInMap(location: CoordinatesValue | undefined) {
    if (!this.onLocationSelect) {
      console.error('onLocationSelect listener is null');
      return;
    }
    if (!location) {
      this.getMessageService().showError('未获取到位置信息');
      return;
    }
    // 没有经纬度
    if (isNil(location.latitude) || isNil(location.longitude)) {
      const address = ElementUtils.getTextDirectly(location, 'coordinates');
      const res = await this.getPlaceService().placeGeo(address);
      if (!res) {
        this.getMessageService().showError('查询地理定位信息出错');
        return;
      }
      location = {
        ...location,
        latitude: res.latitude,
        longitude: res.longitude
      };
    }
    this.onLocationSelect(location);
  }

  /**
   * 拨打电话
   */
  async callPhone(phone: string, partnerId: ResPartnerDTO) {
    await this.getMessageService().showLoading();
    try {
      const res = await this.getPartnerService().RelMobile({
        partnerId: partnerId.id,
        mobile: phone
      });
      if (!res.mobile) {
        this.getMessageService().showError('没有查询到手机号');
        await this.getMessageService().hideLoading();
        return;
      }
      PhoneCallUtils.callPhone(res.mobile);
    } finally {
      await this.getMessageService().hideLoading();
    }
  }

  /**
   * 启用
   */
  async enable(dto: any, dataService: IStructureService, structInfo: StructureInfo) {
    await this.getMessageService().showLoading();
    try {
      //
      await dataService.update(
        {
          id: dto.id,
          active: true
        },
        structInfo
      );
      this.getMessageService().showSuccess('启用成功');
    } finally {
      await this.getMessageService().hideLoading();
    }
  }

  /**
   * 禁用
   * @param dto
   * @param dataService
   * @param structInfo
   */
  async disable(dto: any, dataService: IStructureService, structInfo: StructureInfo) {
    await this.getMessageService().showLoading();
    try {
      await dataService.update(
        {
          id: dto.id,
          active: false
        },
        structInfo
      );
      this.getMessageService().showSuccess('禁用成功');
    } finally {
      await this.getMessageService().hideLoading();
    }
  }

  getPartnerService() {
    if (!this.partnerService) {
      const apiService = BfAppContext.getInstance().getApiService();
      this.partnerService = new ResPartnerService(apiService);
    }
    return this.partnerService;
  }

  getMessageService() {
    if (!this.messageService) {
      this.messageService = BfAppContext.getInstance().getMessageService();
    }
    return this.messageService;
  }

  getSaleOrderService() {
    if (!this.saleOrderService) {
      this.saleOrderService = new SaleOrderService(BfAppContext.getInstance().getApiService());
    }
    return this.saleOrderService;
  }

  getPlaceService(): RnPlaceService {
    if (!this.placeService) {
      const apiService = BfAppContext.getInstance().getApiService();
      const jsApiKey = BfLocation.getInstance().getWebJsKey();
      const webApiKey = BfLocation.getInstance().getWebApiKey();
      this.placeService = new RnPlaceService(apiService, {
        jsApiKey,
        webApiKey
      });
    }
    return this.placeService;
  }

  callPhoneDirectly(departPhone: string | undefined) {
    if (!departPhone) {
      this.getMessageService().showError('没有手机号');
      return;
    }
    PhoneCallUtils.callPhone(departPhone);
  }

  /**
   * 查看历史工单
   */
  goPartnerHistoryOrder(partnerId: string) {
    const routeService = BfAppContext.getInstance().getRouteService();
    routeService.push({
      path: ROUTE_NAMES.SaleHistoryOrderList,
      query: {
        partnerId: partnerId
      }
    });
  }

  /**
   * 执行工单
   */
  executeOrder(orderId: string) {
    const routeService = BfAppContext.getInstance().getRouteService();
    routeService.push({
      path: ROUTE_NAMES.SaleOrderConfirm,
      query: {
        orderId: orderId
      }
    });
  }

  /**
   * 工单上门
   */
  async distributionOrder(orderId: string) {
    await this.getMessageService().showLoading();
    try {
      await this.getSaleOrderService().distribution({
        id: orderId
      });
    } finally {
      await this.getMessageService().hideLoading();
    }
  }
}

export type OnLocationOpenInMapListener = (location: CoordinatesValue) => void;
