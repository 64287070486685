import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';
import { registerRootComponent } from 'expo';
import '@/app/core/setup';
import App from '@/app/App';
import React from 'react';
import { Platform } from 'react-native';

if (Platform.OS === 'web') {
  enableExperimentalWebImplementation();
}
registerRootComponent(App);
