import { BfClListViewPage, BfStructListItemClickEvent } from '@tw/tw-platform-react';
import {
  BoStructure,
  newEqualCondition,
  PageDeviceType,
  PageQueryDTO,
  PageResDTO,
  PageSchemaRequestType
} from '@tw/tw-runtime';
import { SaleHistoryOrderListLoadParam } from './types';
import { BfLog4js, BfLogger } from '@tw/tw-log4js';
import { SaleOrderRestApiService } from '@tw/lock-api-gen';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

export class SaleHistoryOrderListPage extends BfClListViewPage {
  private saleOrderRestApiService: SaleOrderRestApiService;

  constructor() {
    super({
      type: PageSchemaRequestType.listView,
      deviceType: PageDeviceType.mobile,
      uiviewQname: 'uiview://historyOrderList',
      qname: 'doc://sale/docHistoryOrder'
    });
    this.saleOrderRestApiService = new SaleOrderRestApiService(this.apiService);
  }

  private customerId: string;
  async onLoad(params?: SaleHistoryOrderListLoadParam) {
    this.logger.debug('加载参数' + JSON.stringify(params));
    this.customerId = params.partnerId;
    return await super.onLoad(params);
  }

  protected async fetchListData(query: PageQueryDTO): Promise<PageResDTO<BoStructure> | BoStructure[]> {
    return await this.saleOrderRestApiService.query({
      ...query,
      noPaging: true,
      conditions: [newEqualCondition(SaleOrderRestApiService.PARTNER_ID, this.customerId)]
    });
  }
  onListItemClick(event: BfStructListItemClickEvent<BoStructure>) {
    super.onListItemClick(event);
  }

  protected getPreviewPagePath(): string {
    return ROUTE_NAMES.SaleDocOrderOrderInfo;
  }

  protected createLogger(): BfLogger {
    return BfLog4js.getLogger('sale/historyOrderList');
  }
}
