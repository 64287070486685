import { FC, useCallback } from 'react';
import { TwCard, TwColumn, TwRow } from '@tw/tw-components-rn';
import { SaleChanceDTO } from '@tw/lock-api-gen';
import { Text, View } from 'react-native';
import { TwBfCommandItemMiniIconText } from '@tw/tw-platform-rn';
import { ElementUtils } from '@tw/tw-platform-react';
import dayjs from 'dayjs';
import { styles } from './styles';

export const HistoryChanceOrderCard: FC<HistoryChanceOrderCardProps> = (props) => {
  const { value, onNewOrder } = props;
  const chance = value?.chanceType ?? '--';
  const renderContent = useCallback(() => {
    return (
      <View style={{ flexDirection: 'column', flex: 1 }} testID={'HistoryCardColumn'}>
        <Text style={styles.title}>{chance}</Text>
        <TwRow space={1} flexWrap={'wrap'}>
          <Text style={styles.text}>{`${value?.callman ?? ''}${value.callmanGender?.name ?? ''}`}</Text>
          <Text style={styles.text}>
            {value?.address?.province?.id ? ElementUtils.getText(value, { id: 'address', type: 'coordinates' }) : ''}
          </Text>
        </TwRow>
      </View>
    );
  }, [chance, value]);

  return (
    <TwCard
      style={styles.cardContainer}
      showHeader={false}
      footer={
        <TwCard.ContentFooter>
          <TwCard.ContentItem>
            {value.createTime ? dayjs(value.createTime).format('YYYY-MM-DD HH:mm') : '--'}
          </TwCard.ContentItem>
        </TwCard.ContentFooter>
      }
    >
      <TwCard.Content
        content={renderContent()}
        compact={true}
        action={
          <TwBfCommandItemMiniIconText
            command={{ id: 'createOrder', name: '派单', icon: 'icon-add' }}
            onPress={() => onNewOrder(value)}
          />
        }
      />
    </TwCard>
  );
};

export interface HistoryChanceOrderCardProps {
  value: SaleChanceDTO;
  onNewOrder?: (order: SaleChanceDTO) => void;
}
