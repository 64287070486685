import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  label: {
    ...TwTheme.values.textSizes.sm,
    color: TwTheme.values.textColors.base,
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal
    // paddingTop: TwTheme.values.spaces['1']
  }
});
