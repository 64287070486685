import { ProductItem as Item } from '@/app/components/product-select/product-item/ProductItem';
import { ProductProductDTO } from '@tw/lock-api-gen';
import { ProductItemProps } from '@/wms/pages/doc-inventory-company-select/viewparts/product-item/type';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { WmsDocInventoryPersonalPersonalProductChoosePage } from '@/wms/stores/doc-inventory-personal-personal-product-choose/wms-doc-inventory-personal-personal-product-choose-page';

export const ProductItem = (props: ProductItemProps) => {
  const { value } = props;
  const store = useBfPageStore<WmsDocInventoryPersonalPersonalProductChoosePage>();
  const itemKey = store.getDataId(value);
  const count = useStoreState(store, (state) => store.getListItemSelectCount(state, itemKey) ?? null);
  console.log('ProductItem count', count);
  return (
    <Item
      item={value}
      count={count}
      quantity={(value as any).quantity}
      onCountChange={function (product: ProductProductDTO, count: number): void {
        console.log('onListItemSelectWithCount');
        store.onListItemSelectWithCount(product, count);
      }}
    />
  );
};
