import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { ProductSelectNavigatorCls } from '@/product/stores/product-product-select/product-select-navigator';

export class ProductSelectSearchNavigatorCls extends ProductSelectNavigatorCls {
  //
  get ROUTE_NAME() {
    return ROUTE_NAMES.ProductProductSelectSearch;
  }
}

export const ProductSelectSearchNavigator = new ProductSelectSearchNavigatorCls();
