import React, { FC } from 'react';
import { TwInputNumber, TwTypography } from '@tw/tw-components-rn';
import { observer, useField } from '@formily/react';

import { DataField } from '@formily/core';
import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import { TwTheme } from '@tw/tw-runtime-rn';
import { View } from 'native-base';
import { styles } from './styles';

export const OrderPriceField: FC<TwBfFieldRcProps<number>> = observer(
  (props) => {
    const { value, onChange } = props;
    const field = useField<DataField>();
    console.log('field.selfErrors', field.selfErrors);
    return (
      <View style={styles.container}>
        <View style={styles.price}>
          <TwTypography.Text>¥ </TwTypography.Text>
          {field ? (
            <TwInputNumber
              step={false}
              min={0}
              inputWidth={20}
              inputVariant={'outline'}
              value={value}
              precision={2}
              onChange={onChange}
            />
          ) : null}
        </View>
        {field.selfErrors?.length ? (
          <TwTypography.Text size={'xs'} color={TwTheme.values.errorColors.base}>
            {field.selfErrors[0]}
          </TwTypography.Text>
        ) : null}
      </View>
    );
  },
  {
    displayName: 'OrderPriceField'
  }
);
