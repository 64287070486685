import React from 'react';
import { ProductOrderLayout } from '@/sale/components/product-order-layout/ProductOrderLayout';
import { useFieldSchema } from '@formily/react';
import { TwRecursionField } from '@tw/tw-platform-rn';

export const OrderLayoutField = () => {
  const fieldSchema = useFieldSchema();
  const basic = fieldSchema.properties.basic;
  return (
    <>
      <ProductOrderLayout
        product={<TwRecursionField schema={basic?.properties?.productId} />}
        unitPrice={<TwRecursionField schema={basic?.properties?.unitPrice} />}
        qtyOrdered={<TwRecursionField schema={basic?.properties?.qtyOrdered} />}
      />
      <TwRecursionField schema={basic?.properties?.subtotal} />
    </>
  );
};
