import { TwButton, TwPage, TwPageHeader } from '@tw/tw-components-rn';
import { View } from 'react-native';
import * as Notifications from 'expo-notifications';
import type { Notification } from 'expo-notifications/src/Notifications.types';
import { AndroidImportance } from 'expo-notifications/src/NotificationChannelManager.types';
import { NotificationService } from '@/app/core/services/notification-service';
export const NotificationTest = () => {
  const init = () => {
    // Notifications.setNotificationChannelAsync('App', {
    //   name: '默认消息通知',
    //   sound: 'default',
    //   importance: AndroidImportance.HIGH,
    //   vibrationPattern: [0, 250, 250, 250],
    //   enableVibrate: true
    // });
    // Notifications.setNotificationHandler({
    //   handleNotification: async (notification: Notification) => {
    //     console.log('handleNotification', notification);
    //     return {
    //       shouldPlaySound: true,
    //       shouldSetBadge: true,
    //       shouldShowAlert: true
    //     };
    //   },
    //   handleSuccess: (notificationId) => {
    //     console.log('handleNotification-handleSuccess', notificationId);
    //   }
    // });
    // Notifications.addNotificationResponseReceivedListener((response) => {});
  };
  const onSendNotify = async () => {
    //
    NotificationService.getInstance().scheduleNotificationAsync({
      title: '测试通知',
      content: '测试通知'
    });
  };
  return (
    <TwPage translucentStatusBar={true}>
      <TwPageHeader title={'通知测试'} />
      <View>
        <TwButton onPress={init}>初始化通知</TwButton>
        <TwButton onPress={onSendNotify}>发送通知</TwButton>
      </View>
    </TwPage>
  );
};
