import { TwAudioPlayer } from '@tw/tw-components-rn';
import { FC } from 'react';
import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import { FileValue } from '@tw/tw-runtime';
import { Text, View } from 'react-native';
import { styles } from '@/app/fields/audio-player-field/styles';
import { observer, useField } from '@formily/react';
import { Field } from '@formily/core';

export const AudioPlayerField: FC<TwBfFieldRcProps<FileValue>> = observer((props) => {
  const { value } = props;
  if (!value) {
    return (
      <View style={styles.noRecordContainer}>
        <Text style={styles.noRecordText}>暂无录音</Text>
      </View>
    );
  }
  return <TwAudioPlayer createdTime={value?.saveTime} source={{ uri: value?.url }} />;
});
