import { FC, useEffect, useState } from 'react';
import { TwBfLocationOpenInMapActions } from '@tw/tw-platform-rn';
import { CommonActionProxy } from '@/app/components/common-action-proxy/common-action-proxy';
import { BfAppContext, CoordinatesValue } from '@tw/tw-runtime';
import { CommonActionProxyProviderProps } from '@/app/components/common-action-proxy/type';
import { isNil } from 'lodash-es';

export const CommonActionProxyProvider: FC<CommonActionProxyProviderProps> = (props) => {
  const [showMapMenu, setShowMapMenu] = useState(false);
  const [location, setLocation] = useState<CoordinatesValue>();

  useEffect(() => {
    CommonActionProxy.getInstance().setOnLocationSelect((location) => {
      console.log('打开地址: ' + JSON.stringify(location));
      if (isNil(location.latitude) || isNil(location.longitude)) {
        const messageService = BfAppContext.getInstance().getMessageService();
        messageService.showError('没有获取到地址数据，无法打开地图');
        return;
      }
      setLocation(location);
      setShowMapMenu(true);
    });
  }, []);

  const onClose = () => {
    setShowMapMenu(false);
    setLocation(undefined);
  };

  if (!showMapMenu) {
    return null;
  }
  return (
    <>
      {props.children}
      <TwBfLocationOpenInMapActions open={showMapMenu} location={location} onClose={onClose} />
    </>
  );
};
