import React from 'react';
import { TwChip, TwDropdownPanel, TwFlowList } from '@tw/tw-components-rn';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { ProductProductSelectStore } from '@/product/stores/product-product-select/product-select-store';
import { ProductProductBrandDTO } from '@tw/lock-api-gen';
import { FilterItem } from '@/product/pages/product-product-select/viewparts/pps-brand-filter/FilterItem';

export const PpsBrandFilter = () => {
  const page = useBfPageStore<ProductProductSelectStore>();
  const brandList = useStoreState(page, (state) => state.brandList);
  const activeBrandKey = useStoreState(page, (state) => state.activeBrandKey);
  const isCategoryDropdownVisible = useStoreState(page, (state) => state.isCategoryDropdownVisible);
  const onCategoryPress = () => {
    page.setCategoryDropdownVisible(!isCategoryDropdownVisible);
  };
  const onDropdownClose = () => {
    page.setCategoryDropdownVisible(false);
  };
  const onBrandSelected = (item: ProductProductBrandDTO) => {
    page.onBrandSelected(item);
  };
  const renderBrandList = () => {
    if (!brandList) {
      return null;
    }
    return (
      <TwFlowList
        data={brandList}
        space={8}
        renderItem={({ item }: { item: ProductProductBrandDTO }) => {
          return (
            <TwChip
              variant={'tag'}
              text={item.name}
              key={item.id}
              canPress={true}
              selected={item.id === activeBrandKey}
              size={'sm'}
              onPress={() => onBrandSelected(item)}
            />
          );
        }}
      />
    );
  };
  return (
    <TwDropdownPanel
      mask
      dropdown={renderBrandList()}
      closeOnClickOutside
      visible={isCategoryDropdownVisible}
      onClose={onDropdownClose}
    >
      <FilterItem onPress={onCategoryPress} active={isCategoryDropdownVisible} label={'品牌'} />
    </TwDropdownPanel>
  );
};
