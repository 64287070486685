import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Text, View } from 'native-base';
import {
  TwButton,
  TwFlowList,
  TwInputNumber,
  TwPopup,
  TwPopupHandle,
  TwPortal,
  TwRow,
  TwTypography
} from '@tw/tw-components-rn';

import { OrderOffersProps } from '@/sale/pages/order-process/fields/order-offers/type';
import { styles } from './styles';
import { useStateSync } from '@tw/tw-runtime-react';
import { BfAppContext } from '@tw/tw-runtime';

export const OrderOffers: FC<OrderOffersProps> = (props) => {
  const { visible, offers: offersProp = 0, totalBefore = 0, onSubmit: onSubmitProp, onClose } = props;

  const offersRef = useRef<TwPopupHandle>();
  const messageService = BfAppContext.getInstance().getMessageService();
  const [offers, setOffers] = useStateSync(offersProp);

  const totalValueAfter = useMemo(() => {
    return totalBefore - offers < 0 ? 0 : totalBefore - offers;
  }, [offers, totalBefore]);

  useEffect(() => {
    if (visible) {
      offersRef.current?.open();
    } else {
      offersRef.current?.close();
    }
  }, [visible]);

  const onOfferChange = (value: number) => {
    if (value > totalBefore) {
      messageService.showWarn('优惠价格不能低于总价');
      setOffers(totalBefore);
      return;
    }
    setOffers(value);
  };

  const onSubmit = () => {
    onSubmitProp?.(offers);
    onClose?.();
  };

  const presetOffers = useMemo(() => {
    return [10, 20, 50, 70, 100, 200];
  }, []);

  return (
    <TwPortal>
      <TwPopup
        ref={offersRef}
        adjustToContentHeight={true}
        withHandle={false}
        onClose={onClose}
        footer={<TwButton onPress={onSubmit}>确定</TwButton>}
      >
        <View style={{ paddingHorizontal: 16 }}>
          <View style={styles.container}>
            <TwRow alignItems={'center'} justifyContent={'space-between'}>
              <Text style={styles.headerTitle} fontSize="sm">
                优惠
              </Text>
              <View style={styles.headerInfo}>
                <TwTypography.Text style={styles.headerDesc} size={'xl'}>
                  - ¥
                </TwTypography.Text>
                <TwInputNumber
                  style={styles.input}
                  step={false}
                  precision={2}
                  value={offers > 0 ? offers : undefined}
                  onChange={onOfferChange}
                  inputWidth={20}
                />
              </View>
            </TwRow>
            <View style={styles.priceNumberList}>
              <TwFlowList
                space={8}
                data={presetOffers}
                renderItem={(item) => {
                  return (
                    <TwButton
                      key={item.item}
                      variant="unstyled"
                      style={styles.priceNumberButton}
                      onPress={() => onOfferChange(item.item)}
                    >
                      <Text style={styles.priceNumbers}>{item.item}</Text>
                    </TwButton>
                  );
                }}
              />
            </View>
            <View style={styles.border}></View>

            <View style={styles.price}>
              <Text style={styles.priceTitle} fontSize="sm">
                折前价
              </Text>
              <Text style={styles.priceInfo}>{totalBefore}</Text>
            </View>

            <View style={styles.totalPrice}>
              <Text style={styles.totalPriceTitle} fontSize="sm">
                预计总额
              </Text>
              <Text style={styles.totalPriceInfo}>¥{totalValueAfter}</Text>
            </View>
          </View>
        </View>
      </TwPopup>
    </TwPortal>
  );
};
