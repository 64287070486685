import { TwFormItem, TwInput, TwListItem } from '@tw/tw-components-rn';
import React, { useState } from 'react';
import { BfAppContext, isEmpty } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

export const OrderProcessDebug = () => {
  const routeService = BfAppContext.getInstance().getRouteService();
  const [orderId, setOrderId] = useState<string>();
  const onOrderProcess = () => {
    if (isEmpty(orderId)) {
      return;
    }
    routeService.push({
      path: ROUTE_NAMES.SaleOrderProcess,
      query: {
        orderId: orderId
      }
    });
  };
  return (
    <>
      <TwFormItem label={'工单Id'}>
        <TwInput value={orderId} onChangeText={setOrderId} />
      </TwFormItem>

      <TwListItem pressable={true} showArrow={true} title={'工单处理（调试）'} onPress={onOrderProcess}></TwListItem>
    </>
  );
};
