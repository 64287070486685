/**
 * 来电地址录入
 * @constructor
 */
import React, { FC, useState } from 'react';
import { InCallLocationSelectProps } from '@/sale/components/incall-location-select/type';
import { BfLocationNavigator, TwBfLocation } from '@tw/tw-platform-rn';
import { BfIntentResultType, CoordinatesValue } from '@tw/tw-runtime';
import { InCallHistoryLocationSelect } from '@/sale/components/incall-location-select/InCallHistoryLocationSelect';
import { useField } from '@formily/react';
import { DataField } from '@formily/core';
import { TouchableOpacity } from 'react-native';
import { styles } from '@/sale/components/incall-location-select/styles';
import { TwBfLocationActionInfo } from '@tw/tw-platform-rn';
import { TwIcon, TwRow } from '@tw/tw-components-rn';
import { isNil } from 'lodash-es';

/**
 * 来电工单地址选择
 * @param props
 * @constructor
 */
export const InCallLocationSelect: FC<InCallLocationSelectProps> = (props) => {
  const { value, historyOrder, onChange } = props;
  const [visible, setVisible] = useState(false);
  const hasHistoryOrder = !!historyOrder?.length;
  const field = useField() as DataField;
  const onHistoryLocationSelect = (location: CoordinatesValue | undefined) => {
    onChange?.(location);
  };

  const onHistoryPress = () => {
    //
    setVisible(true);
  };
  const onMapPress = async () => {
    //
    const res = await BfLocationNavigator.start();
    // 确定选择
    if (res.type === BfIntentResultType.OK && res.data) {
      onChange?.(res.data);
    }
  };
  const renderActions = (info: TwBfLocationActionInfo) => {
    return (
      <TwRow space={1}>
        {hasHistoryOrder && (
          <TouchableOpacity onPress={onHistoryPress}>
            <TwIcon icon={'icon-history'} size={24} style={styles.icon} />
          </TouchableOpacity>
        )}
        <TouchableOpacity onPress={onMapPress}>
          <TwIcon icon={'icon-location'} size={24} style={styles.icon} />
        </TouchableOpacity>
      </TwRow>
    );
  };

  return (
    <>
      <TwBfLocation
        required={field.required}
        showError={!!field.selfErrors?.length}
        errors={field.selfErrors}
        title={'地址'}
        value={value}
        onChange={onChange}
        renderActions={renderActions}
      />
      {hasHistoryOrder && (
        <InCallHistoryLocationSelect
          visible={visible}
          onVisibleChange={setVisible}
          historyOrder={historyOrder}
          onLocationSelect={onHistoryLocationSelect}
        />
      )}
    </>
  );
};

const hasArea = (area: CoordinatesValue) => {
  return !(!area || isNil(area.province) || isNil(area.city) || isNil(area.county));
};
/**
 * 格式化省市区
 * @param area
 */
const formatArea = (area: CoordinatesValue) => {
  //
  if (!hasArea(area)) {
    return undefined;
  }
  return `${area.province.name}${area.city.name}${area.county.name}`;
};
