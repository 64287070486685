import { useSliceState } from '@tw/tw-runtime-react';
import { TwButton, TwPage } from '@tw/tw-components-rn';
import { HistoryChanceSlice, HistoryChanceSliceState } from '@/res/stores/res-partner-info-preview/slices';
import { FC } from 'react';

export const HistoryChanceCommandBar: FC<HistoryChanceCommandBarProps> = (props) => {
  const { slice } = props;

  const hasSelectedChance = useSliceState(slice, (state: HistoryChanceSliceState) => !!state.historyChanceInfo);
  if (!hasSelectedChance) {
    return null;
  }
  return (
    <TwPage.Footer>
      <TwButton
        variant={'outline'}
        onPress={() => {
          slice.onHistoryChanceSendOrder();
        }}
      >
        发单
      </TwButton>
    </TwPage.Footer>
  );
};

export interface HistoryChanceCommandBarProps {
  slice: HistoryChanceSlice<any>;
}
