import { BfUserStore } from '@tw/tw-runtime';
import { CallModule } from '@/app/native/call';
import { POSITION_KEYS } from '@/app/constants/position-keys';

export class UserStore extends BfUserStore {
  protected setUserToken(token: string | undefined) {
    super.setUserToken(token);
  }

  async prepareByToken(): Promise<void> {
    await super.prepareByToken();
    const { userInfo } = this.getState();
    const enableCallingCatch = this.hasCallingOrderPermission();
    CallModule.setEnableCallingCatch(enableCallingCatch);
  }

  hasCallingOrderPermission() {
    const { userInfo } = this.getState();
    return userInfo?.allPosition?.some((pos) => pos.id === POSITION_KEYS.TMK) ?? false;
  }
}
