import { ActCallingDTO, AnswerStateEnumValue, DirectTypeEnumValue } from '@tw/lock-api-gen';
import { Text, View } from 'react-native';
import { TwAudioPlayer, TwColumn, TwIcon, TwRow } from '@tw/tw-components-rn';

import React from 'react';
import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import dayjs from 'dayjs';
import { getRelativeTime } from '@/utils/utils';
import { millisToStr } from './utils';
import { styles } from './styles';
import { theme } from '@tw/tw-runtime-rn';

export const HistoryCallPreview: React.FC<TwBfFieldRcProps<ActCallingDTO[]>> = (props) => {
  return (
    <View style={styles.container}>
      {props?.value?.map((item, index) => {
        return (
          <View style={styles.item} key={index}>
            <View style={{ flexDirection: 'row' }}>
              <TwIcon
                icon={item.type?.code === DirectTypeEnumValue.out ? 'icon-call_made' : 'icon-call_received'}
                size={20}
                color={
                  item.answerState?.code === AnswerStateEnumValue.answered
                    ? theme.successColors.base
                    : theme.errorColors.base
                }
              />
              {/* <Text style={styles.label}>{item?.startTime ?? '--'}</Text> */}
              <Text style={styles.label}>{item?.startTime ? getRelativeTime(dayjs(item.startTime)) : '--'}</Text>
            </View>

            <View
              style={{
                flex: 1,
                alignItems: 'flex-end',
                minWidth: 100
              }}
            >
              {item.callRecord?.url ? (
                <TwAudioPlayer source={{ uri: item.callRecord?.url }} autoLoad={false} />
              ) : (
                <Text style={styles.label}>{millisToStr(item.answerState, item?.stayTime)}</Text>
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
};
