import { TwIcon, TwListItem, TwListItemContent, TwPopup, TwPopupHandle, TwPortal } from '@tw/tw-components-rn';

import { FC, useCallback, useEffect, useRef } from 'react';
import { InCallHistoryLocationSelectProps } from '@/sale/components/incall-location-select/type';
import { TwTheme } from '@tw/tw-runtime-rn';
import { Text } from 'react-native';
import dayjs from 'dayjs';
import { SaleOrderDTO } from '@tw/lock-api-gen';
import { BfIntentResultType, CoordinatesValue } from '@tw/tw-runtime';
import { BfLocationNavigator } from '@tw/tw-platform-rn';
import { CreateNew } from '@/sale/components/incall-location-select/CreateNew';

export const InCallHistoryLocationSelect: FC<InCallHistoryLocationSelectProps> = (props) => {
  const { visible, historyOrder, onLocationSelect, onVisibleChange } = props;
  const modalRef = useRef<TwPopupHandle>();
  const onNewPress = useCallback(async () => {
    onVisibleChange?.(false);
    //
    const res = await BfLocationNavigator.start();

    if (res.type === BfIntentResultType.OK) {
      onLocationSelect?.(res.data);
    }
  }, [onLocationSelect, onVisibleChange]);
  // 选择历史工单
  const onHistoryOrderPress = useCallback(
    (order: SaleOrderDTO) => {
      onLocationSelect?.(order.address);
      onVisibleChange?.(false);
    },
    [onLocationSelect, onVisibleChange]
  );
  useEffect(() => {
    if (visible) {
      modalRef.current?.open();
    } else {
      modalRef.current?.close();
    }
  }, [visible]);

  const renderItem = useCallback(
    (item) => {
      return (
        <TwListItem
          pressable={true}
          key={item.id}
          onPress={() => onHistoryOrderPress(item)}
          icon={<TwIcon icon={'icon-pin_drop'} size={20} color={TwTheme.values.primaryColors.base} />}
          content={
            <TwListItemContent
              title={item.location}
              content={<Text>{dayjs(item.businessCreateTime).format('YYYY-MM-DD HH:mm')}</Text>}
              subTitle={<Text>{getCoordinateText(item.address)}</Text>}
            ></TwListItemContent>
          }
        ></TwListItem>
      );
    },
    [onHistoryOrderPress]
  );
  return (
    <TwPortal>
      <TwPopup title={'历史地址'} ref={modalRef} withHandle={false} onClose={() => onVisibleChange?.(false)}>
        {historyOrder?.map((item) => renderItem(item))}
      </TwPopup>
    </TwPortal>
  );
};
function getCoordinateText(location: CoordinatesValue | undefined) {
  if (!location) {
    return '--';
  }
  return `${location.province?.name ?? ''}${location.city?.name ?? ''}${location.county?.name ?? ''}${
    location.address ?? ''
  }`;
}
