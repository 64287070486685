import { FC } from 'react';
import { PartnerNameFieldProps } from '@/sale/fields/partner-name/type';
import { PartnerNameFieldCommon } from '@/sale/fields/partner-name/PartnerNameFieldCommon';

/**
 * 用户卡片模式的客户姓名
 * @param props
 * @constructor
 */
export const PartnerNameFieldBasicCard: FC<PartnerNameFieldProps> = (props) => {
  return <PartnerNameFieldCommon mode={'BasicCard'} {...props} />;
};
