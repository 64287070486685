import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal,
    paddingVertical: TwTheme.values.spaces['3'],
    backgroundColor: TwTheme.values.colors.white,
    alignItems: 'center'
  },
  avatar: {
    marginRight: TwTheme.values.spaces['3'],
    backgroundColor: TwTheme.values.listItem.avatarBackgroundColor.active
  },
  content: {
    flex: 1
  },
  titleContainer: {},
  title: {
    ...TwTheme.values.textSizes.md,
    color: TwTheme.values.textColors.base
  },
  account: {
    ...TwTheme.values.textSizes.md,
    color: TwTheme.values.textColors.secondary
  },
  status: {
    width: 58,
    marginTop: 4
  },
  rest: {
    width: 58,
    backgroundColor: '#F25D5F',
    color: '#ffffff'
  },
  work: {
    width: 58,
    backgroundColor: '#21BF3A',
    color: '#FFFFFF'
  }
});
