import { BfEditMode } from '@tw/tw-runtime';
import { BfFormElement } from '@tw/tw-platform-react';
import { PlaceService } from '@/app/services';

export const getSettingsBankAccount = (mode: BfEditMode): BfFormElement[] => {
  return [
    {
      id: 'cardName', // DSL 中字段Id
      name: '持卡人', //DSL 中字段标题
      type: 'text', //DSL 中字段类型
      placeholder: '请输入姓名',
      validations: [{ isNotNull: true }],
      editable: mode === BfEditMode.Edit ? false : true
    },
    {
      id: 'certNo', // DSL 中字段Id
      name: '身份证', //DSL 中字段标题
      type: 'text', //DSL 中字段类型
      validations: [
        {
          //
          pattern: '^([1-6][1-9]|50)\\d{4}(18|19|20)\\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$',
          message: '请输入有效身份证'
        },
        { isNotNull: true }
      ],
      placeholder: '持卡人本人身份证号',
      editable: mode === BfEditMode.Edit ? false : true
    },
    {
      id: 'cardNo', // DSL 中字段Id
      name: '卡号', //DSL 中字段标题
      type: 'text', //DSL 中字段类型
      validations: [
        {
          //
          pattern: '^\\d{16,19}$',
          message: '请输入有效银行卡号'
        },
        { isNotNull: true }
      ],
      placeholder: '持卡人银行卡号'
    },
    {
      id: 'mobileNo', // DSL 中字段Id
      name: '手机号', //DSL 中字段标题
      type: 'phone', //DSL 中字段类型
      placeholder: '银行预留手机号',
      validations: [{ isNotNull: true }]
    }
  ];
};
