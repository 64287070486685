import { TwAutoComplete } from '@tw/tw-components-rn';
import { ResPartnerDTO, ResPartnerRestApiService } from '@tw/lock-api-gen';
import { InCallCustomOption } from './InCallCustomOption';
import { useStQuery } from '@tw/tw-runtime-react';
import { newLikeCondition } from '@tw/tw-runtime';
import { InCallCustomPhoneInputProps } from './type';
import { InputProps } from '@tw/tw-components-rn/lib/typescript/input/tw-auto-complete/type';
import { TwBfPhone, TwBfPhonePreview } from '@tw/tw-platform-rn';

/**
 * 电话
 * @constructor
 */
export const InCallCustomMobileInput = (props: InCallCustomPhoneInputProps) => {
  const { value, disabled, onChange, onSelect } = props;
  //
  const { isLoading, items, query } = useStQuery({
    refBO: {
      id: 'partner',
      domain: 'res'
    }
  });

  const handleMobileSearch = (text: string) => {
    query({
      conditions: [newLikeCondition(ResPartnerRestApiService.MOBILE, text)],
      pageNo: 1,
      pageSize: 5,
      fields: [
        ResPartnerRestApiService.ID,
        ResPartnerRestApiService.NAME,
        ResPartnerRestApiService.MOBILE,
        ResPartnerRestApiService.GENDER
      ]
    });
  };

  // 选择已有客户
  const handleCustomSelect = (customer: ResPartnerDTO) => {
    //
    onSelect?.(customer);
  };
  if (disabled) {
    return <TwBfPhonePreview value={props.value} disabled={true} />;
  }
  return (
    <TwAutoComplete
      isLoading={isLoading}
      options={items}
      value={value}
      dropdownWidth={200}
      onChange={onChange}
      onSearch={handleMobileSearch}
      renderOption={(item: ResPartnerDTO) => (
        <InCallCustomOption name={item.name} gender={item.gender?.name} mobile={item.mobile} />
      )}
      onSelect={handleCustomSelect}
      renderInput={(props: InputProps) => <TwBfPhone value={props.value} onChange={props.onChange} />}
    />
  );
};
