import { TwPage, TwPageHeader } from '@tw/tw-components-rn';
import { View } from 'react-native';
import { WebView } from 'react-native-webview';
import { HelperWebConfig } from '@/app/core/setup/api-config';
import React from 'react';
import * as Device from 'expo-device';
export const PermissionHelper = () => {
  const url = `${HelperWebConfig.baseUrl}${HelperWebConfig.permission}?brand=${Device.brand}`;
  return (
    <TwPage translucentStatusBar={true}>
      <TwPageHeader title={'权限开启说明'} />
      <View style={{ flex: 1 }}>
        <WebView source={{ uri: url }} />
      </View>
    </TwPage>
  );
};
