import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { TwBfForm, TwBfFormProps } from '@tw/tw-platform-rn';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getNewOrderElements } from '@/sale/pages/in-call-order/viewparts/incall-new-order/schema';
import { InCallAssignSelect } from '@/sale/components/incall-assign-select';
import { InCallLocation } from '@/sale/pages/in-call-order/viewparts/incall-location/InCallLocation';
import { ScrollView } from 'react-native';
import { TwLoadingBlock } from '@tw/tw-components-rn';
import { InCallCustomerGenderInput, InCallCustomerInputField } from '@/sale/components/incall-customer';
import { InCallCustomerNameInputField } from '@/sale/components/incall-customer/InCallCustomerNameInputField';
import { InCallCustomerMobileInputField } from '@/sale/components/incall-customer/InCallCustomerMobileInputField';

export const InCallNewOrder = () => {
  const page = useBfPageStore<InCallOrderStore>();

  const isLoadingPartnerInfo = useStoreState(page, (state) => state.isLoadingPartnerInfo);

  if (isLoadingPartnerInfo) {
    return <TwLoadingBlock />;
  }

  return <InCallNewOrderInner />;
};

const InCallNewOrderInner = () => {
  const page = useBfPageStore<InCallOrderStore>();
  const form = page.getNewOrderForm();
  // const [form] = useState(() => TwBfForm.createForm());
  // page.setNewOrderForm(form);
  // const data = useStoreState(page, (state) => state.orderInfo);
  const elements = useMemo(() => getNewOrderElements(), []);
  const onFieldInputValueChange: TwBfFormProps['onFieldInputValueChange'] = useCallback(
    (evt) => {
      page.onNewOrderInputChange();
    },
    [page]
  );

  // useEffect(() => {
  //   form.setValues(data);
  // }, [data, form]);
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <TwBfForm
        form={form}
        elements={elements}
        components={{
          InCallCustomerInputField,
          InCallCustomerGenderInput,
          InCallCustomerNameInputField,
          InCallCustomerMobileInputField,
          InCallAssignSelect,
          InCallLocation
        }}
        onFieldInputValueChange={onFieldInputValueChange}
      />
    </ScrollView>
  );
};
