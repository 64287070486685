import { BfPageStore } from '@tw/tw-runtime';
import { GroupedProduct, ProductSelectBaseStoreState, SelectedProduct } from './type';
import {
  ProductProductBrandDTO,
  ProductProductBrandRestApiService,
  ProductProductDTO,
  WmsInventoryModel,
  WmsInventoryService
} from '@tw/lock-api-gen';
import { Category } from '@tw/tw-components-rn';
import { SectionList, SectionListProps } from 'react-native';
import { MutableRefObject } from 'react';
import { BfLog4js } from '@tw/tw-log4js';

export abstract class ProductSelectBaseStore extends BfPageStore<ProductSelectBaseStoreState> {
  private listRef: MutableRefObject<SectionList> | null;
  // 品牌
  private branRestApiService: ProductProductBrandRestApiService;
  // 库存
  private inventoryService: WmsInventoryService;

  constructor() {
    super({
      pluginOptions: {
        devTools: {
          name: ''
        }
      }
    });
    const api = this.appContext.getApiService();
    this.branRestApiService = new ProductProductBrandRestApiService(api);
    this.inventoryService = new WmsInventoryService(api);
  }

  protected getInitialState(): ProductSelectBaseStoreState {
    return {
      ...super.getInitialState(),
      selectedProductList: []
    };
  }

  setListRef(ref: MutableRefObject<SectionList>) {
    this.listRef = ref;
  }
  /**
   *
   * @param param
   */
  async onLoad(param?: any): Promise<void> {
    this.setLoading(true);
    await super.onLoad(param);

    this.setSelectedProducts(this.getInitSelectedProducts());
    await Promise.all([this.loadGroupedProductList(), this.loadBrandList()]);
    this.setLoading(false);
  }

  /**
   * 初始化时，选择的商品
   * @protected
   */
  protected abstract getInitSelectedProducts(): SelectedProduct[] | undefined;

  /**
   * 加载商品列表
   * @private
   */
  private async loadGroupedProductList() {
    await this.reloadProductList();
  }

  private async reloadProductList() {
    const res = (await this.inventoryService.personLlist({})) as unknown as WmsInventoryModel[];

    const categories = this.getCategoryList(res);
    const groupedProductList = this.getGroupedProductList(res);
    this.setCategoryListInfo(categories);
    this.setGroupedProductList(groupedProductList);
  }
  /**
   * 加载品牌列表
   * @private
   */
  private async loadBrandList() {
    const res = await this.branRestApiService.query({
      noPaging: true
    });
    this.setBrandList(res.rows);
  }

  private getCategoryList(list: WmsInventoryModel[] | undefined): WmsInventoryModel[] {
    // return list?.map((item) => item.category) ?? [];
    return list?.map((item) => {
      return this.getCategory(item);
    });
  }

  private getCategory(item: WmsInventoryModel): WmsInventoryModel {
    return {
      id: item.id,
      name: item.name
    };
  }

  private getGroupedProductList(res: WmsInventoryModel[]): GroupedProduct[] {
    return res.map((item) => {
      return {
        category: this.getCategory(item),
        children: item.children
      };
    });
  }

  /**
   * 分类信息
   * @private
   */
  private setCategoryListInfo(categories: WmsInventoryModel[] | undefined) {
    this.setState(
      {
        categoryList: categories
      },
      false,
      '设置分类列表'
    );
  }

  private setGroupedProductList(list: GroupedProduct[] | undefined) {
    this.setState(
      {
        groupedProductList: list
      },
      false,
      '设置分组产品列表'
    );
  }

  private setBrandList(brandList: ProductProductBrandDTO[] | undefined) {
    this.setState(
      {
        brandList: brandList
      },
      false,
      '设置品牌列表'
    );
  }

  setProductPopupVisible(visible: boolean) {
    this.setState({
      isProductPopupVisible: visible
    });
  }

  getCategoryUiData(
    categories: WmsInventoryModel[] | undefined,
    selectedProductList: SelectedProduct[] | undefined
  ): Category[] {
    if (!categories) {
      return [];
    }
    return categories.map((item) => {
      return {
        key: item.id as unknown as string,
        label: item.name,
        count: this.countByCategory(selectedProductList, item)
      };
    });
  }

  setActiveBrand(key: string | undefined) {
    this.setState(
      {
        activeBrandKey: key
      },
      false,
      '激活的品牌Key'
    );
  }

  setActiveCategory(key: string | undefined) {
    this.setState(
      {
        activeCategoryKey: key
      },
      false,
      '激活的分类Key'
    );
  }

  private countByCategory(
    selectedProductList: SelectedProduct[] | undefined,
    category: WmsInventoryModel | undefined
  ): number {
    let count = 0;
    selectedProductList?.forEach((item) => {
      if (item.product.parentId === category.id) {
        count += item.count;
      }
    });
    return count;
  }

  setCategoryDropdownVisible(visible: boolean) {
    this.setState({
      isCategoryDropdownVisible: visible
    });
  }

  /**
   * 品牌变更，重新加载
   * @param item
   */
  onBrandSelected(item: ProductProductBrandDTO) {
    this.setActiveBrand(item.id);
    this.reloadProductList();
  }

  getSectionsUiData(groupedProductList: GroupedProduct[] | undefined): SectionListProps<WmsInventoryModel>['sections'] {
    return (
      groupedProductList?.map((item) => {
        return {
          category: item.category,
          data: item.children
        };
      }) ?? []
    );
  }

  private scrollToStartTime = 0;
  private isScrollToDoing = false;
  private readonly SCROLL_TO_THRESHOLD_MS = 300;
  onCategorySelect(key: string) {
    const { groupedProductList } = this.getState();
    const index = groupedProductList.findIndex((item) => (item.category.id as unknown as string) === key);

    this.scrollToStartTime = Date.now();
    this.isScrollToDoing = true;
    this.listRef.current.scrollToLocation({
      sectionIndex: index,
      itemIndex: 0,
      // 关闭动画会引起 onViewableItemsChanged 计算错误
      animated: false
    });
    this.setActiveCategory(key);
  }

  onListVisibleSectionChange(category: WmsInventoryModel | undefined) {
    if (this.isScrollToDoing && Date.now() - this.scrollToStartTime < this.SCROLL_TO_THRESHOLD_MS) {
      return;
    }
    this.isScrollToDoing = false;
    this.setActiveCategory(category.id as unknown as string);
  }

  protected createLogger() {
    return BfLog4js.getLogger('sps-store');
  }

  getLogger() {
    return this.logger;
  }

  onProductCountChange(product: WmsInventoryModel, count: number) {
    this.logger.debug('商品数量变化' + product.name + ',' + count);
    this.setState(
      (state) => {
        const productIndex = state.selectedProductList.findIndex((item) => item.product.id === product.id);
        if (productIndex > -1) {
          if (count === 0) {
            state.selectedProductList.splice(productIndex, 1);
          } else {
            state.selectedProductList[productIndex].count = count;
          }
        } else {
          state.selectedProductList.push({
            count: count,
            product
          });
        }
        console.log('onProductCountChange state ', state.selectedProductList.length);
        return state;
      },
      false,
      '商品选择数量变化'
    );
  }

  getSumOfSelectedProduct(product: SelectedProduct[] | undefined) {
    let count = 0;
    product?.forEach((item) => {
      count += item.count;
    });
    return count;
  }

  onClearSelectedProduct() {
    this.setState(
      {
        selectedProductList: []
      },
      false,
      '清空选择商品'
    );
    this.setProductPopupVisible(false);
  }

  getSumOfProduct(product: ProductProductDTO, selectedProductList: SelectedProduct[] | undefined) {
    if (!selectedProductList) {
      return 0;
    }
    const index = selectedProductList.findIndex((item) => (item.product.id as unknown as string) === product.id);
    if (index > -1) {
      return selectedProductList[index].count;
    }
    return 0;
  }

  protected setSelectedProducts(products: SelectedProduct[]) {
    this.setState({
      selectedProductList: products ?? []
    });
  }

  /**
   * 确认选择
   */
  onConfirmSelect() {
    const { selectedProductList } = this.getState();
    this.doConfirmSelect(selectedProductList);
  }

  protected abstract doConfirmSelect(selectedProductList: SelectedProduct[]);

  protected abstract cancelSelect();
}
