import { ProductProductDTO } from '@tw/lock-api-gen';
import { SaleProductOrder } from '@/product/components/sale-product-order/SaleProductOrder';

interface ProductItemProps {
  // 商品
  item: ProductProductDTO;
  // 数量
  count?: number;
  // 库存
  quantity?: number;
  // 采购价
  purchasePrice?: number;
  // 员工结算价
  costPrice?: number;
  // 显示库存
  showQuantity?: boolean;
  // 显示采购价
  showPurchasePrice?: boolean;
  // 显示结算价
  showCostPrice?: boolean;
  // 数量变化事件
  onCountChange: (product: ProductProductDTO, count: number) => void;
}

// 商品列表项
export const ProductItem = (props: ProductItemProps) => {
  const {
    item,
    count,
    quantity,
    costPrice,
    purchasePrice,
    showQuantity,
    showPurchasePrice,
    showCostPrice,
    onCountChange
  } = props;

  return (
    <SaleProductOrder
      title={item.name}
      key={item.id}
      quantity={quantity}
      purchasePrice={purchasePrice}
      costPrice={costPrice}
      showQuantity={showQuantity}
      showCostPrice={showCostPrice}
      showPurchasePrice={showPurchasePrice}
      description={item.specification}
      count={count}
      onCountChange={(count) => onCountChange(item, count)}
    />
  );
};
