/**
 *
 * @param props
 * @constructor
 */
import { TwCard } from '@tw/tw-components-rn';
import { useMemo } from 'react';
import { SaleDocOrderDTO } from '@tw/lock-api-gen';

export const PartnerNameBasicCardContent = (props: { record: SaleDocOrderDTO }) => {
  const { record } = props;
  const name = useMemo(() => {
    if (record.linkmanPhone) {
      return `${record.linkman ?? ''}${record.linkmanGender?.name ?? ''}`;
    }
    return `${record.callman ?? ''}${record.callmanGender?.name ?? ''}`;
  }, [record.callman, record.callmanGender?.name, record.linkman, record.linkmanGender?.name, record.linkmanPhone]);

  return <TwCard.ContentText key={'linkman_gender'} value={name}></TwCard.ContentText>;
};
