import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { getTabBarIcon } from '@/app/helpers/getTabBarIcon';
import { Mine } from '@/mine/pages/mine/Mine';
import React, { useCallback, useMemo } from 'react';
import { HomeWorkplace } from '@/home/pages/workplace';
import { useStoreState } from '@tw/tw-runtime-react';
import { Platform } from 'react-native';
import { OrderPoolNoticeStore } from '@/sale/stores/order-pool-notice-store/order-pool-notice-store';
import { BfAppContext, BfMenu } from '@tw/tw-runtime';
import { MENU_KEYS } from '@/app/constants/menu-keys';
import { SaleDocOrderPoolOrderPoolIndex } from '@/sale/pages/doc-order-pool-order-pool-index/SaleDocOrderPoolOrderPoolIndex';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { SaleDocOrderOrderIndex } from '@/sale/pages/doc-order-order-index/SaleDocOrderOrderIndex';
import { ParamListBase } from '@react-navigation/routers/lib/typescript/src/types';

const BottomTabs = createBottomTabNavigator<ParamListBase>();
const tabBarDotStyle = {
  top: Platform.OS === 'ios' ? 0 : 6,
  minWidth: 14,
  maxHeight: 14,
  borderRadius: 7,
  fontSize: 10,
  lineHeight: 13,
  alignSelf: undefined
};
export const Home = (props) => {
  const { hasOrder, hasOrderPool, hasManage, hasStatics, hasMenus } = useMenusPermissions();
  const poolStore = OrderPoolNoticeStore.getInstance();
  const hasNewPoolOrder = useStoreState(poolStore, (state) => state.hasNewPoolOrder);
  if (!hasMenus) {
    return null;
  }
  return (
    <BottomTabs.Navigator
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          height: 60
        },
        tabBarItemStyle: {
          paddingVertical: 8
        }
      }}
    >
      {hasOrder && (
        <BottomTabs.Screen
          name={ROUTE_NAMES.SaleDocOrderOrderIndex}
          component={SaleDocOrderOrderIndex}
          options={{
            tabBarLabel: '工单',
            tabBarIcon: getTabBarIcon('home')
          }}
        />
      )}

      {/*{hasStatics && (*/}
      {/*  <BottomTabs.Screen*/}
      {/*    name={'Statics'}*/}
      {/*    component={Message}*/}
      {/*    options={{*/}
      {/*      tabBarLabel: '统计',*/}
      {/*      tabBarIcon: getTabBarIcon('addchart')*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}

      {hasOrderPool && (
        <BottomTabs.Screen
          name={ROUTE_NAMES.SaleDocOrderPoolOrderPoolIndex}
          component={SaleDocOrderPoolOrderPoolIndex}
          options={{
            tabBarBadgeStyle: hasNewPoolOrder ? tabBarDotStyle : undefined,
            tabBarBadge: hasNewPoolOrder ? '' : undefined,
            tabBarLabel: '工单池',
            tabBarIcon: getTabBarIcon('plagiarism')
          }}
        />
      )}
      {hasManage && (
        <BottomTabs.Screen
          name={'HomeWorkplace'}
          component={HomeWorkplace}
          options={{
            tabBarLabel: '管理',
            tabBarIcon: getTabBarIcon('dashboard')
          }}
        />
      )}

      <BottomTabs.Screen
        name={'Mine'}
        component={Mine}
        options={{
          tabBarLabel: '我的',
          tabBarIcon: getTabBarIcon('account-circle')
        }}
      />
    </BottomTabs.Navigator>
  );
};

function useMenusPermissions() {
  const menuStore = BfAppContext.getInstance().getMenuStore();
  const appStore = BfAppContext.getInstance().getAppStore();
  const allMenuList = useStoreState(menuStore, (state) => state.allMenuList);
  const hasMenu = useCallback((menuId: string, menuList: BfMenu[]) => {
    return menuList.some((item) => item.id === menuId);
  }, []);
  const hasOrder = useMemo(() => hasMenu(MENU_KEYS.order, allMenuList), [allMenuList, hasMenu]);
  const hasOrderPool = useMemo(() => hasMenu(MENU_KEYS.orderPool, allMenuList), [allMenuList, hasMenu]);
  const hasStatics = useMemo(() => hasMenu(MENU_KEYS.statistic, allMenuList), [allMenuList, hasMenu]);
  const hasManage = useMemo(() => hasMenu(MENU_KEYS.manage, allMenuList), [allMenuList, hasMenu]);
  const hasMenus = useMemo(() => allMenuList?.length ?? 0 > 0, [allMenuList]);
  return {
    hasMenus,
    hasOrder,
    hasStatics,
    hasOrderPool,
    hasManage
  };
}
