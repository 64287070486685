import { Platform } from 'react-native';
// 是否开发服务
export const IS_DEV_SERVER = true;
/*  */
export const ApiConfig = IS_DEV_SERVER
  ? {
      // 3w 服务器
      baseUrl: Platform.OS === 'web' ? '/api' : 'http://120.27.210.39:8900/'
    }
  : {
      // 唐正式服务器
      baseUrl: Platform.OS === 'web' ? '/api' : 'http://121.196.221.173:8050/api/'
    };

export const SocketConfig = IS_DEV_SERVER
  ? {
      appId: '3will',
      appKey: '3will-key',
      useTLS: false,
      // 3w 服务器
      wsHost: '120.27.210.39',
      wsPort: '6001'
    }
  : {
      appId: '3will',
      appKey: '3will-key',
      useTLS: false,
      // 唐 正式服务器
      wsHost: '120.27.233.46',
      wsPort: '6001'
      // logToConsole: true
    };

export const HelperWebConfig = {
  baseUrl: 'http://121.196.221.173:8062/',
  //
  privacyPolicyPath: 'PrivacyPolicy',
  termsAndConditionsPath: 'TermsAndConditions',
  permission: 'PermissionHelper'
};

export const UpdateConfig = {
  // app id 固定不变，每个 app 唯一
  appId: 'lock-app',
  checkUrl: ApiConfig.baseUrl + 'v2/rest/services/service/infra/app/update/check',
  // app 环境： dev, beta, real
  env: 'real'
  // env: 'dev'
};
