import { WmsDocApplyApplySelectPage } from '@/wms/stores/doc-apply-apply-create/wms-doc-apply-apply-select-page';
import { TwCheckbox, TwTypography } from '@tw/tw-components-rn';
import { useStoreState } from '@tw/tw-runtime-react';
import { TwTheme } from '@tw/tw-runtime-rn';
import { View } from 'react-native';
import { WmsDocReturnReturnSelectPage } from '@/wms/stores/doc-return-return-create/wms-doc-return-return-select-page';

export const DefaultWarehouseCheckbox = ({ page }: { page: WmsDocReturnReturnSelectPage }) => {
  const setWarehouseAsDefault = useStoreState(page, (state) => state.setWarehouseAsDefault);
  return (
    <View>
      <TwCheckbox
        checked={setWarehouseAsDefault}
        onChange={(checked) => {
          page.setNeedSetWarehouseAsDefault(checked);
        }}
        variant={'square'}
        size={'sm'}
      >
        <TwTypography.Text size={'sm'} color={TwTheme.values.textColors.secondary}>
          设为默认仓库
        </TwTypography.Text>
      </TwCheckbox>
    </View>
  );
};
