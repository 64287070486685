import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  more: {
    color: TwTheme.values.primaryColors.base,
    ...TwTheme.values.textSizes.xs
  },
  moreContainer: {
    paddingHorizontal: TwTheme.values.spaces['2']
  }
});
