import { Account, TwBfUserSelectProps } from './type';
import { BfAppContext, PageQueryDTO, RefEntity, SortTypeDTO } from '@tw/tw-runtime';
import { FC, useEffect, useMemo } from 'react';
import { SearchResultType, TwPage, TwSearchModal } from '@tw/tw-components-rn';

import { AccountIcon } from './Icon';
import { BfAccountSelectNavigator } from '@/app/components/tw-account-select/bf-account-select-navigator';
import { HrEmployeeDTO } from '@tw/lock-api-gen';
import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { View } from 'react-native';
import { isArray } from 'lodash-es';

export const TwAssignUserSelect: FC<TwBfUserSelectProps> = () => {
  const structure = BfAccountSelectNavigator.getIntent().structure;
  const refEntity: RefEntity = useMemo(() => {
    const base = {
      id: structure.id,
      domain: structure.domain
    };
    if (structure.type === 'doc') {
      return {
        refDoc: base
      } as RefEntity;
    }
    if (structure.type === 'dataview') {
      return {
        refDataview: base
      } as RefEntity;
    }
    return {
      refBO: base
    } as RefEntity;
  }, [structure.domain, structure.id, structure.type]);
  // 获取数据服务
  const dataService = BfAppContext.getInstance().createStructureDataService(refEntity);

  /**
   * 获取人员列表
   * @param executor
   */
  const listAllEmployeeByKeyword = async (
    keyword?: string,
    pageSize = 30,
    pageNo = 1
  ): Promise<SearchResultType<Account>> => {
    const params: PageQueryDTO = keyword
      ? {
          pageNo: pageNo,
          pageSize: pageSize,
          keyword: keyword,
          noPaging: BfAccountSelectNavigator.getIntent().noPaging ?? false,
          sorts: [
            {
              field: 'code',
              sortType: SortTypeDTO.DESC
            }
          ]
        }
      : {
          pageNo: pageNo,
          pageSize: pageSize,
          noPaging: BfAccountSelectNavigator.getIntent().noPaging ?? false,
          sorts: [
            {
              field: 'code',
              sortType: SortTypeDTO.DESC
            }
          ]
        };

    if (BfAccountSelectNavigator.getIntent().condition && BfAccountSelectNavigator.getIntent().condition.length > 0) {
      params.conditions = params.conditions
        ? params.conditions.concat(BfAccountSelectNavigator.getIntent().condition)
        : BfAccountSelectNavigator.getIntent().condition;
    }
    const response = await dataService.query(params, BfAccountSelectNavigator.getIntent().structure);

    const users: Account[] = response.rows;
    return {
      entries: users,
      status: 'success' as const,
      noDataMessage: '没有数据',
      hasMore: response.total > pageNo * pageSize,
      hasMoreMessage: 'load more',
      next: () => listAllEmployeeByKeyword(keyword, pageSize, pageNo + 1)
    };
  };

  const [selected, setSelected] = React.useState<HrEmployeeDTO[]>([]);

  useEffect(() => {
    const selected = BfAccountSelectNavigator.getIntent()?.selected;

    const tempSelected = selected ? (isArray(selected) ? selected : [selected]) : [];
    setSelected(tempSelected);
  }, []);

  const onComplete = (account: HrEmployeeDTO[]) => {
    if (BfAccountSelectNavigator.getIntent().multiple) {
      BfAccountSelectNavigator.finish(account);
    } else {
      BfAccountSelectNavigator.finish(account && account.length > 0 ? account[0] : undefined);
    }
    BfAppContext.getInstance().getRouteService().back();
  };

  const keywordSearch = (keyword: string) => {
    return listAllEmployeeByKeyword(keyword);
  };

  const onCancel = async (account: HrEmployeeDTO[]) => {
    // check if account is equal selected
    let isSame = true;

    if (selected && selected.length === account.length) {
      for (let i = 0; i < selected.length; i++) {
        if (account.includes(selected[i])) {
          continue;
        } else {
          isSame = false;
          break;
        }
      }
    } else {
      isSame = false;
    }

    if (isSame) {
      BfAccountSelectNavigator.cancel();
      BfAppContext.getInstance().getRouteService().back();
    } else {
      const confrim: boolean = await BfAppContext.getInstance().getMessageService().showConfirm({
        title: '提示',
        content: '确定要取消吗?修改将不会被保存'
      });
      if (confrim) {
        BfAccountSelectNavigator.cancel();
        BfAppContext.getInstance().getRouteService().back();
      }
    }
  };

  const selectableTypes = [
    {
      key: 'all',
      IconComponent: () => <MaterialIcons name="search" size={24} color="white" />,
      typePredicate: (item: Account) => true
    }
  ];

  const getKey = (item: Account) => item.id;
  const getPrimaryText = (item: Account) => item?.name ?? '';

  return (
    <TwPage translucentStatusBar={true}>
      <View style={{ backgroundColor: 'white', flex: 1 }}>
        <TwSearchModal
          title={BfAccountSelectNavigator.getIntent()?.title || '选择人员'}
          onComplete={(selected: any[]) => {
            onComplete(selected);
          }}
          onClose={onCancel}
          selected={selected}
          // keyword={keyword}
          keywordSearch={keywordSearch}
          // treeSearch={treeSearch}
          getPrimaryText={getPrimaryText}
          multiple={BfAccountSelectNavigator.getIntent().multiple || false}
          allowEmpty={true}
          Icon={AccountIcon}
          getIconUrl={(item: Account) => item?.avatar?.url || undefined}
          getKey={getKey}
          isExpandable={(item: Account) => false}
          isSelectable={(item: Account) =>
            BfAccountSelectNavigator.getIntent()?.disableIds?.length > 0
              ? !BfAccountSelectNavigator.getIntent()?.disableIds.includes(item.id)
              : true
          }
          placeholder={'搜索'}
          cancelLabel={'取消'}
          completeLabel={'完成'}
          breadcrumbRoot={'Root'}
          resultTitle={'搜索结果'}
          backButtonLabel={'返回列表'}
          selectableTypes={selectableTypes}
          autoCompleteWhenSingleChoice={true}
        />
      </View>
    </TwPage>
  );
};
