import React, { useCallback, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { styles } from './styles';
import { TwAvatar, TwChip, TwIcon, TwRow } from '@tw/tw-components-rn';
import { MaterialIcons } from '@expo/vector-icons';
import { BfAppContext } from '@tw/tw-runtime';
import { useStoreState } from '@tw/tw-runtime-react';
import { ResUserInfoModel } from '@tw/lock-api-gen/dist/models/res/ResUserInfoModel';
import { TwTheme } from '@tw/tw-runtime-rn';
import { backgroundColors } from '@tw/tw-runtime-rn/lib/typescript/theme/base/color';
export const UserInfo = () => {
  const useStore = BfAppContext.getInstance().getUserStore();
  const userInfo = useStoreState(useStore, (state) => state.userInfo) as ResUserInfoModel;
  const avatar = userInfo.avatar;
  const realName = userInfo.realName;

  const onUserInfoPressed = useCallback(() => {}, []);
  return (
    <TouchableOpacity onPress={onUserInfoPressed}>
      <View style={styles.container}>
        <TwAvatar style={styles.avatar} source={{ uri: avatar?.url }}>
          {realName?.[0] ?? ''}
        </TwAvatar>
        <View style={styles.content}>
          <View style={styles.titleContainer}>
            <TwRow space={1}>
              <Text style={styles.title}>{userInfo.name}</Text>
              {userInfo.position && (
                <TwChip
                  variant={'tag'}
                  fillColor={TwTheme.values.primaryColors.base}
                  text={userInfo.position.name}
                  size={'xs'}
                  selected={true}
                />
              )}
            </TwRow>
            <Text style={styles.account}>{userInfo.mobile}</Text>
            <TwChip
              text={
                <TwRow alignItems={'center'}>
                  <TwIcon icon={'icon-add'} size={8}></TwIcon>
                  <Text>状态</Text>
                </TwRow>
              }
              variant={'capsule'}
              size={'xs'}
              style={styles.status}
            ></TwChip>
            {/* <TwChip text={'工作中'} variant={'capsule'} size={'xs'} style={styles.work}></TwChip> */}
            {/* <TwChip text={'休息中'} variant={'capsule'} size={'xs'} style={styles.rest}></TwChip> */}
          </View>
        </View>
        <MaterialIcons name="keyboard-arrow-right" size={24} color="black" />
      </View>
    </TouchableOpacity>
  );
};
