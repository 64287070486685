import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { AuditReasonPopupProps } from '@/sale/components/order-abandon-popup/type';
import { TwButton, TwPopup, TwPopupHandle, TwPortal } from '@tw/tw-components-rn';
import { TwBfInput } from '@tw/tw-platform-rn';
import { getDataSource } from './form-schema';

/**
 * 拒接
 * @param props
 * @constructor
 */
export const OrderRejectPopup: FC<AuditReasonPopupProps> = (props) => {
  const { visible, onVisibleChange, onSubmit } = props;
  const ref = useRef<TwPopupHandle>();
  const dataSource = useMemo(() => getDataSource(), []);
  const [reason, setReason] = useState<string>();
  useEffect(() => {
    if (visible) {
      ref.current?.open();
    } else {
      ref.current?.close();
    }
  }, [visible]);
  const onAbandonConfirm = () => {
    onSubmit?.(reason);
    onVisibleChange?.(false);
  };
  return (
    <TwPortal>
      <TwPopup
        withHandle={false}
        adjustToContentHeight={true}
        title={'拒接原因'}
        ref={ref}
        onClose={() => onVisibleChange?.(false)}
        footer={
          <TwButton size={'sm'} onPress={onAbandonConfirm}>
            确定
          </TwButton>
        }
      >
        <TwBfInput value={reason} dataSource={dataSource as any} onChange={setReason} />
      </TwPopup>
    </TwPortal>
  );
};
