import { Avatar, theme } from 'native-base';

import { Account } from './type';
import { ItemComponentType } from '@tw/tw-components-rn';
import React from 'react';

export const AccountIcon: ItemComponentType<Account> = ({ value: user }) => {
  return (
    <Avatar
      bg={theme.colors.primary[500]}
      size={'sm'}
      source={
        user?.avatar?.url
          ? {
              uri: user?.avatar?.url
            }
          : undefined
      }
    >
      {user?.name?.charAt(0).toUpperCase() ?? ''}
    </Avatar>
  );
};
