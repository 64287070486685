import { BfListViewPageSchema } from '@tw/tw-platform-react';

export const getSchema = (): BfListViewPageSchema => {
  return {
    description: {
      pageType: 'ListView',
      name: '来电记录列表',
      description: '来电记录列表'
    },
    refBO: {
      qname: 'bo://res/choiceReasonData',
      id: 'choiceReasonData',
      name: '原因选择数据',
      domain: 'res',
      dataStructure: 'list',
      pkAttributeId: 'id',
      nameAttributeId: 'name'
    },
    viewOptions: {
      listItemHeight: 53,
      commandBarMode: 'header',
      searchBarEnable: false
    },
    elements: [
      {
        id: 'basic',
        type: 'group',
        elements: [
          {
            id: 'phone',
            name: '名称',
            type: 'text',
            visible: true,
            multiple: false,
            isName: true,
            validations: [],
            widgetOptions: {}
          },
          {
            id: 'answeredAt',
            name: '接听时间',
            type: 'dateTime',
            visible: true,
            multiple: false,
            validations: [],
            widgetOptions: {}
          }
        ]
      },
      {
        id: 'primary',
        type: 'group',
        elements: [],
        widgetCommand: {
          commands: [
            {
              id: 'delete',
              name: '删除',
              icon: 'icon-delete_outline',
              showIcon: true,
              operatorConstrain: 'single',
              alertType: 'danger',
              displayTypes: ['Detail', 'Edit']
            }
          ],
          mode: 'icon'
        }
      }
    ],
    // commandBar: {
    //   commands: [
    //     {
    //       id: 'create',
    //       name: '添加',
    //       showIcon: true,
    //       operatorConstrain: 'none'
    //     }
    //   ]
    // },
    commandContextMenus: {
      commands: []
    },
    listWidget: {
      widget: 'List'
    },
    listItemWidget: {
      widget: 'ListItemUserCard',
      widgetOptions: {
        contentCompacted: true
      }
    },
    sortElements: [],
    conditionElements: [],
    uuid: '46ff0590-2e5e-40ff-ac86-eaf5bae806ab'
  };
};
