import { TwInputNumber } from '@tw/tw-components-rn';
import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import { FC } from 'react';
import { useRecord } from '@tw/tw-platform-rn';

export const OrderProductQuantityField: FC<TwBfFieldRcProps<number>> = (props) => {
  const { value, onChange } = props;
  const record = useRecord();
  return <TwInputNumber value={value} onChange={onChange} min={0} step={1} precision={0} max={record.quantity} />;
};
