import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import { AndroidImportance, AndroidNotificationVisibility } from 'expo-notifications';
import { NotificationContentInput } from 'expo-notifications/build/Notifications.types';
import { IPushMessageEvent } from '@tw/tw-runtime';
import { TwNotification } from '@/app/native/notification';

const DEFAULT_CHANNEL = 'App';
const NEW_ORDER_CHANNEL = 'NewOrder';
const ORDER_URGENT_CHANNEL = 'OrderUrgent';
const ORDER_COMMON = 'OrderCommon';
const APP_START_NOTICE_ID = 'app_start';

export class NotificationService {
  private static instance = null;

  public static getInstance() {
    if (this.instance === null) {
      this.instance = new NotificationService();
    }
    return this.instance;
  }

  async initNotification() {
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      console.log('初始化消息通知');

      await Notifications.setNotificationChannelAsync(DEFAULT_CHANNEL, {
        name: '应用通知',
        description: '所有业务通知',
        sound: 'default',
        importance: AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        enableVibrate: true
      });
      await TwNotification.createChannel({
        channelId: NEW_ORDER_CHANNEL,
        name: '新工单提醒',
        description: '提醒新工单'
      });
      await TwNotification.createChannel({
        channelId: ORDER_URGENT_CHANNEL,
        name: '加急工单提醒',
        description: '提醒工单被加急'
      });
      await TwNotification.createChannel({
        channelId: ORDER_COMMON,
        name: '工单通用通知',
        description: '工单通用通知'
      });
      Notifications.setNotificationHandler({
        handleNotification: async (notification) => {
          console.log('notification', notification);
          return {
            shouldPlaySound: true,
            shouldSetBadge: true,
            shouldShowAlert: true
          };
        },
        handleError() {},
        handleSuccess() {}
      });
    }
  }

  scheduleNotificationAsync(content: NotificationContentInput) {
    if (Platform.OS !== 'android' && Platform.OS !== 'ios') {
      return;
    }
    console.log('发送通知');
    //
    Notifications.scheduleNotificationAsync({
      content: content,
      trigger: {
        seconds: 1,
        channelId: DEFAULT_CHANNEL
      }
    });
  }

  scheduleNewOrderNotificationAsync(message: IPushMessageEvent) {
    if (Platform.OS !== 'android' && Platform.OS !== 'ios') {
      return;
    }
    TwNotification.sendNotification({
      channelId: NEW_ORDER_CHANNEL,
      title: message.message.title,
      content: message.message.content,
      data: message.message.payload
    });
  }

  scheduleOrderUrgentNotificationAsync(message: IPushMessageEvent) {
    if (Platform.OS !== 'android' && Platform.OS !== 'ios') {
      return;
    }
    TwNotification.sendNotification({
      channelId: ORDER_URGENT_CHANNEL,
      title: message.message.title,
      content: message.message.content,
      data: message.message.payload
    });
  }

  scheduleOrderCommonNotificationAsync(message: IPushMessageEvent) {
    if (Platform.OS !== 'android' && Platform.OS !== 'ios') {
      return;
    }
    TwNotification.sendNotification({
      channelId: ORDER_COMMON,
      title: message.message.title,
      content: message.message.content,
      data: message.message.payload
    });
  }

  handleMessage(message: IPushMessageEvent) {
    this.scheduleNotificationAsync({
      title: message.message.title,
      body: message.message.content,
      data: message.message.payload
    });
  }
}
