import { ResOrderSettingService } from '@tw/lock-api-gen';
import { BfAppContext, IStorageService } from '@tw/tw-runtime';
import { isNil } from 'lodash-es';
import { ResOrderAddrSettingModel } from '@tw/lock-api-gen/dist/models';

export class DefaultOrderSettingService {
  private static instance = null;

  private orderSettingService: ResOrderSettingService;
  private storageService: IStorageService;

  constructor() {
    const api = BfAppContext.getInstance().getApiService();
    this.orderSettingService = new ResOrderSettingService(api);
    this.storageService = BfAppContext.getInstance().getStorageService();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new DefaultOrderSettingService();
    }
    return this.instance;
  }

  async reloadAddr() {
    const addr = await this.orderSettingService.defAddrInfo();
    this.storageService.set('orderAddr', addr.defAddr);
  }

  async getAddr() {
    let addr = this.storageService.get('orderAddr', null);
    if (isNil(addr)) {
      const res = await this.orderSettingService.defAddrInfo();
      addr = res.defAddr;
      this.storageService.set('orderAddr', addr);
    }
    return addr;
  }

  async setAddr(res: ResOrderAddrSettingModel) {
    await this.orderSettingService.defAddrSet(res);
    this.storageService.set('orderAddr', res.defAddr);
  }
}
