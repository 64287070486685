import { ActCallingDTO, ResDocPartnerDTO, SaleChanceDTO, SaleOrderDTO } from '@tw/lock-api-gen';
import { BfPageStoreState, CoordinatesValue, EnumValue, FileValue, BfPageStoreLoadParams } from '@tw/tw-runtime';

export interface LoadParam extends BfPageStoreLoadParams {
  /**
   * 电话号
   */
  phone?: string;
  /**
   * 缓存数据Id
   */
  dataId?: string;
  /**
   * 从缓存中加载
   */
  loadFromCache?: boolean;

  /***
   * 从列表进入
   */
  loadFromList?: boolean;
}

/**
 * 来电状态
 */
export interface InCallOrderBaseStoreState extends BfPageStoreState {
  /**
   * 缓存数据Id
   */
  dataId?: string;

  /**
   * 从列表加载过来
   */
  loadFromList?: boolean;
  /**
   * 录音文件
   */
  audioRecord?: FileValue;
  /**
   * 加载客户信息中
   */
  isLoadingPartnerInfo?: boolean;
  /**
   * 客户信息
   */
  partner: ResDocPartnerDTO;
  /**
   * 正在加载进行中工单
   */
  isLoadingUnderwayOrder?: boolean;
  /**
   * 进行中工单信息-表单
   */
  // underwayOrderInfo?: SaleOrderDTO;

  hasUnderwayOrder?: boolean;
  /**
   * 新建工单信息-表单
   */
  // orderInfo: SaleOrderUI;
  /**
   * 正在加载历史工单
   */
  isLoadingHistoryOrder?: boolean;
  /**
   * 历史工单
   */
  historyOrder?: SaleOrderDTO[];

  /**
   * 正在加载已完成的工单
   */
  isLoadingCompletedHistoryOrder?: boolean;
  /**
   *  历史工单
   */
  completedHistoryOrder?: SaleOrderDTO[];
  /**
   * 历史工单-表单
   */
  // completedOrderInfo?: SaleOrderUI;
  hasCompleteOrderInfo?: boolean;

  /**
   * 正在加载历史机会
   */
  isLoadingHistoryChance?: boolean;

  /**
   *  历史机会
   */
  historyChanceList?: SaleChanceDTO[];
  /**
   * 历史机会-表单
   */
  // historyChanceInfo: SaleChanceUI;
  hasHistoryChanceInfo?: boolean;

  /**
   * 是否可以挂断
   */
  canHangup?: boolean;

  /**
   * 需求类型
   */
  requirementType: RequirementType;
  /**
   * 通话记录。
   * 挂断后创建
   */
  actCalling?: ActCallingDTO;
  /**
   * 录音上传中
   */
  recordUploading?: boolean;
  /**
   * 待处理工单
   */
  remainOrderCount?: number;
}

export interface InCallOrderStoreState extends InCallOrderBaseStoreState {
  /**
   * 废弃弹窗是否可见
   */
  isDiscardPanelVisible?: boolean;
}

export interface SaleCustomerInfo
  extends Pick<
    SaleOrderDTO,
    | 'partnerId'
    | 'callman'
    | 'callmanGender'
    | 'callmanPhone'
    | 'linkman'
    | 'linkmanGender'
    | 'linkmanPhone'
    | 'linkmanPartnerId'
  > {}

export interface SaleOrderUI
  extends Omit<
    SaleOrderDTO,
    | 'partnerId'
    | 'callman'
    | 'callmanGender'
    | 'callmanPhone'
    | 'linkman'
    | 'linkmanGender'
    | 'linkmanPhone'
    | 'linkmanPartnerId'
  > {
  customerInfo?: SaleCustomerInfo;
}

export interface SaleChanceUI
  extends Omit<
    SaleChanceDTO,
    | 'partnerId'
    | 'callman'
    | 'callmanGender'
    | 'callmanPhone'
    | 'linkman'
    | 'linkmanGender'
    | 'linkmanPhone'
    | 'linkmanPartnerId'
  > {
  customerInfo?: SaleCustomerInfo;
}

export enum RequirementType {
  /**
   * 进行中工单
   */
  underwayOrder = 'underwayOrder',
  /**
   * 新建工单
   */
  newOrder = 'newOrder',
  /**
   * 历史工单
   */
  historyOrder = 'historyOrder',
  /**
   * 历史咨询
   */
  historyConsult = 'historyConsult'
}

export interface OrderCustomer {
  name?: string;
  sex?: EnumValue;
  phones?: PhoneItem;
}

export interface OrderInfo {
  id?: string;
  /**
   * 工单电话
   */
  phone?: string;
  /**
   * 定位
   */
  address?: CoordinatesValue;
  /**
   * 地址详情
   */
  addressDetail?: string;
  /**
   * 业务类型
   */
  businessType?: EnumValue;
  /**
   * 期望时间
   */
  expectTime?: number;
  /**
   * 备注
   */
  note?: string;
  /**
   *  人员信息
   */
  assign?: any;
}

export interface PhoneItem {
  /**
   * 手机号
   */
  phone?: string;
  /**
   * 是否是主要电话
   */
  isMain?: boolean;
}

export enum Sex {
  male = 'male',
  female = 'female'
}

export enum InCallAssignTypeValue {
  manager = 'manager',
  group = 'group',
  pool = 'pool',
  self = 'self'
}

/**
 * 缓存
 */
export interface InCallOrderCachePageData
  extends Pick<
    InCallOrderBaseStoreState,
    'requirementType' | 'underwayOrderInfo' | 'orderInfo' | 'completedOrderInfo' | 'historyChanceInfo'
  > {}
