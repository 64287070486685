import React, { FC, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { filterItemStyles } from '@/product/pages/product-product-select/viewparts/pps-brand-filter/styles';
import { MaterialIcons } from '@expo/vector-icons';
import { TwTheme } from '@tw/tw-runtime-rn';
export const FilterItem: FC<FilterItemProps> = (props) => {
  const { label, active, onPress } = props;

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={filterItemStyles.container}>
        <Text style={[filterItemStyles.label, active && filterItemStyles.label__active]}>{label}</Text>
        <MaterialIcons
          name={active ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
          size={24}
          color={active ? TwTheme.values.colors.grayBlue['5'] : TwTheme.values.textColors.base}
        />
      </View>
    </TouchableOpacity>
  );
};

export interface FilterItemProps {
  label?: string;
  active?: boolean;
  onPress?: () => void;
}
