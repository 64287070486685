import './override-font';
import React, { useEffect, useLayoutEffect } from 'react';
import { enableFreeze } from 'react-native-screens';
import { AppRouter } from './AppRouter';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import '@/app/core/setup/iconfont-setup';
import { useAppStore, useStoreState } from '@tw/tw-runtime-react';
import { TwButton, TwLoadingBlock, TwProvider, TwResult, TwRow } from '@tw/tw-components-rn';
import { CallModule } from '@/app/native/call';
import { ApiUtils, BfAppContext } from '@tw/tw-runtime';
import { CommonActionProxyProvider } from '@/app/components/common-action-proxy/CommonActionProxyProvider';
import Toast, { ToastProvider } from 'react-native-toast-notifications';
import { OrderNoticeStore } from '@/sale/stores/order-notice-store/order-notice-store';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { CallEvent } from '@/app/native/call/call-module-defs';
import { TwNotification } from '@/app/native/notification';

enableFreeze(true);
export default function App(props) {
  useLayoutEffect(() => {
    SplashScreen.preventAutoHideAsync()
      .then()
      .catch((e) => {
        console.error('PreventAutoHideAsync', e);
      });
  }, []);
  const { initLoadFinished, initLoadError } = useAppInitLoad();
  useOrderNotice();

  useCallListener();

  const renderContent = () => {
    if (initLoadError) {
      const { code, message } = initLoadError;
      const isTokenError = ApiUtils.isInvalidateTokenErrorCode(code);
      const title = isTokenError ? message : '初始化错误';
      const subTitle = isTokenError ? undefined : message;
      return (
        <TwResult
          style={{ justifyContent: 'center' }}
          status={'error'}
          title={title}
          subTitle={subTitle}
          extra={
            <TwRow style={{ marginTop: 8 }} space={2}>
              {!isTokenError && (
                <TwButton
                  variant={'outline'}
                  style={{ minWidth: 90 }}
                  onPress={() => {
                    BfAppContext.getInstance().getAppStore().initialize();
                  }}
                >
                  重试
                </TwButton>
              )}
              <TwButton
                variant={'outline'}
                style={{ minWidth: 90 }}
                onPress={() => {
                  BfAppContext.getInstance().getAuthStorageService().removeToken();
                  BfAppContext.getInstance().getAppStore().initialize();
                }}
              >
                重新登录
              </TwButton>
            </TwRow>
          }
        ></TwResult>
      );
    }
    if (!initLoadFinished) {
      return <TwLoadingBlock tip={'应用加载中'} />;
    }
    return (
      <>
        <CommonActionProxyProvider></CommonActionProxyProvider>
        <AppRouter />
        <Toast duration={1500} ref={(ref) => (global['toast'] = ref)} placement={'center'} />
      </>
    );
  };

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <TwProvider>
        <ToastProvider>{renderContent()}</ToastProvider>
      </TwProvider>
    </GestureHandlerRootView>
  );
}

function useAppInitLoad() {
  const appStore = useAppStore();
  const initLoadFinished = useStoreState(appStore, (state) => state.initLoadFinished);
  const initLoadError = useStoreState(appStore, (state) => state.initLoadError);

  const [fontsLoaded] = useFonts({
    iconfont: require('@/app/assets/fonts/iconfont.ttf')
  });
  useEffect(() => {
    if (appStore.getState().initLoadFinished) {
      return;
    }
    appStore.initialize().then();
  }, [appStore]);
  useEffect(() => {
    if (fontsLoaded) {
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);
  return {
    initLoadFinished: initLoadFinished && fontsLoaded,
    initLoadError
  };
}

export function useCallListener() {
  return useEffect(() => {
    const answerHandle = CallModule.addListener('answer', (evt: CallEvent) => {
      const routeService = BfAppContext.getInstance().getRouteService();
      const messageService = BfAppContext.getInstance().getMessageService();
      messageService.hideConfirm();
      messageService.hideLoading();
      routeService.replace({
        path: ROUTE_NAMES.SaleInCallOrder,
        query: {
          phone: evt.phone,
          dataId: evt.dataId
        }
      });
    });
    return () => {
      answerHandle.remove();
    };
  }, []);
}

function useOrderNotice() {
  useEffect(() => {
    const subscription = TwNotification.addOnClickListener((notification) => {
      // 监听点击通知，进入
      OrderNoticeStore.getInstance().handleNotificationClick(notification);
    });
    return () => subscription.remove();
  }, []);
}
