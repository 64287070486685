function buildItems(indexFrom: number, count: number) {
  const items: any[] = [];
  for (let i = 0; i < count; i++) {
    items.push({
      id: 'add-' + indexFrom + '-' + i,
      name: 'add-' + indexFrom + '-' + i
    });
  }
  return items;
}
export function appendMockData(data: any[], category: string, pageIndex: number) {
  const index = data.findIndex((item) => item.title.name === category);
  if (index == -1) {
    data.unshift({
      title: { name: category },
      data: buildItems(pageIndex, 10)
    });
  } else {
    data[index].data.unshift(buildItems(pageIndex, 10));
  }
  return data.slice(0);
}
export const getMockData = () => {
  return [
    {
      title: { name: '常用' },
      data: [
        {
          id: '1',
          name: '开锁'
        },
        {
          id: '2',
          name: '开锁2'
        },
        {
          id: '3',
          name: '开锁3'
        },
        {
          id: '4',
          name: '开锁4'
        }
      ]
    },
    {
      title: { name: '服务' },
      data: [
        {
          id: '3',
          name: '服务3'
        },
        {
          id: '4',
          name: '服务4'
        },
        {
          id: '5',
          name: '服务5'
        },
        {
          id: '6',
          name: '服务6'
        }
      ]
    }
  ];
};
