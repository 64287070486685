import { BfAbstractListDetailCacheStore, BfAppContext } from '@tw/tw-runtime';
import { SaleDocOrderDTO, SaleDocOrderRestApiService } from '@tw/lock-api-gen';

/**
 * 详情加载
 */
export class OrderDetailCacheStore extends BfAbstractListDetailCacheStore<SaleDocOrderDTO> {
  private saleDocOrderRestApiService: SaleDocOrderRestApiService;
  constructor() {
    super({
      pluginOptions: {
        devTools: {
          name: '历史工单详情'
        }
      }
    });
    const api = BfAppContext.getInstance().getApiService();
    this.saleDocOrderRestApiService = new SaleDocOrderRestApiService(api);
  }

  protected async doLoad(key: string): Promise<SaleDocOrderDTO> {
    return await this.saleDocOrderRestApiService.info({
      id: key
    });
  }
}
