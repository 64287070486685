import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  cardContainer: {
    marginHorizontal: TwTheme.values.spaces.pageHorizontal,
    marginVertical: TwTheme.values.spaces['2']
  },
  time: {
    color: TwTheme.values.listItem.textColor.secondary,
    ...TwTheme.values.listItem.secondaryTextSize.base
  },
  footerText: {
    color: TwTheme.values.listItem.textColor.secondary,
    ...TwTheme.values.listItem.secondaryTextSize.base
  },
  container: {
    maxHeight: 500
  },
  summaryContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  }
});
