import { Text, View } from 'react-native';
import { TwAvatar, TwChip, TwRow } from '@tw/tw-components-rn';
import { TwTheme, theme } from '@tw/tw-runtime-rn';

import { FC } from 'react';
import { StyleSheet } from 'react-native';

export interface SelectedAccount {
  avatarUrl?: string;
  icon?: string;
  name: string;
}

export const SelectedRole: FC<SelectedAccount> = (props) => {
  return (
    // <TwRow alignItems={'center'} space={2}>
    //   <TwAvatar
    //     bg={theme.colors.blue[100]}
    //     source={
    //       props?.avatarUrl
    //         ? {
    //             uri: props.avatarUrl
    //           }
    //         : undefined
    //     }
    //     size={'sm'}
    //   >
    //     {props.name?.slice(0, 1)}
    //   </TwAvatar>
    //   <Text style={styles.formContentText}>{props.name ?? ''}</Text>
    // </TwRow>
    <TwChip text={props.name} />
  );
};

const styles = StyleSheet.create({
  formContentText: {
    ...TwTheme.values.textSizes.sm
  }
});
