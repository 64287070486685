import { BfAppContext, BoCommandItem, PageDeviceType, PageSchemaRequestType } from '@tw/tw-runtime';
import { BfInfoPageSchema, BfInfoViewPage, IForm } from '@tw/tw-platform-react';
import { CommandConsts, SaleDocOrderTransferDTO, SaleOrderRestApiService, SaleOrderService } from '@tw/lock-api-gen';
import { SaleOrderTransferInfoLoadParam, SaleOrderTransferInfoStoreState } from './types';
import { SaleAppointReqModelParams } from '@/sale/components/incall-assign-select/type';
import { SaleOrderTransferNavigator } from '@/sale/stores/sale-order_transfer-info/sale-order-transfer-navigator';

export class SaleOrderTransferInfoPage extends BfInfoViewPage<
  SaleDocOrderTransferDTO,
  BfInfoPageSchema,
  SaleOrderTransferInfoStoreState
> {
  private saleOrderRestService: SaleOrderRestApiService;
  private saleOrderService: SaleOrderService;

  constructor() {
    super({
      type: PageSchemaRequestType.infoView,
      deviceType: PageDeviceType.mobile,
      uiviewQname: 'uiview://mobileInfoBasicTemplate',
      qname: 'doc://sale/docOrderTransfer'
    });
    const api = BfAppContext.getInstance().getApiService();
    this.saleOrderService = new SaleOrderService(api);
    this.saleOrderRestService = new SaleOrderRestApiService(api);
  }

  protected getInitialState(): SaleOrderTransferInfoStoreState {
    return {
      ...super.getInitialState()
    };
  }

  protected buildInitLoadTasks(params?: SaleOrderTransferInfoLoadParam): Promise<any>[] {
    const tasks = super.buildInitLoadTasks(params);
    const orderId = SaleOrderTransferNavigator.getIntent()?.orderId;
    if (orderId) {
      tasks.push(this.loadOrder(orderId));
    }
    return tasks;
  }

  private async loadOrder(orderId: string) {
    const res = await this.saleOrderRestService.info({
      id: orderId
    });
    this.setState({
      order: res
    });
  }

  onCommandBarCommand(command: BoCommandItem, form: IForm) {
    console.log('command', command);
    switch (command.id) {
      case CommandConsts.submit:
        this.doTransferOrder(form);
        return;
    }
    super.onCommandBarCommand(command, form);
  }

  private async doTransferOrder(form: IForm) {
    const values = await form.submit();
    const { order } = this.getState();
    const transferInfo = values.transferInfo as SaleAppointReqModelParams;
    if (!transferInfo) {
      this.messageService.showError('请填写转派信息');
      return;
    }
    await this.saleOrderService.transfer({
      orderId: order,
      executorType: transferInfo.executorType,
      remark: values.transferReason,
      executor: transferInfo.executor,
      teamId: transferInfo.teamId
    });
    this.messageService.showSuccess('转派成功');
    SaleOrderTransferNavigator.finish({});
    await this.routeService.back();
  }

  onDestroy() {
    super.onDestroy();
    SaleOrderTransferNavigator.cancel();
  }
}
