import { BfCodeScanner } from '@/demo/pages/qrscaner/bf-code-scanner';
import { BfSignNavigator } from '@tw/tw-platform-rn';
import { PermissionHelper } from '@/settings/pages/permission-helper/PermissionHelper';
import { ROUTE_NAMES_GEN } from '@/app/navigation/_route-names';
import { SaleInCallCacheList } from '@/sale/pages/in-call-cache-list/SaleInCallCacheList';
import { WmsDocInventoryCompanySelect } from '@/wms/pages/doc-inventory-company-select/WmsDocInventoryCompanySelect';
import { WmsInventoryException } from '@/wms/pages/inventory-exception/WmsInventoryException';

export const ROUTE_NAMES = {
  ...ROUTE_NAMES_GEN,

  /**
   * Auth
   */
  // 登录
  AuthLogin: 'AuthLogin',
  // 认证
  AuthVerify: 'HomeAuthVerify',
  /**
   * Home
   */
  // 主页
  Home: 'Home',
  // 闪屏
  HomeSplash: 'HomeSplash',

  // 隐私政策
  HomePrivacyPolicy: 'HomePrivacyPolicy',
  // 用户协议
  HomeTermsAndConditions: 'HomeTermsAndConditions',
  // 授权帮助
  PermissionHelper: 'PermissionHelper',
  /**
   * Settings
   */
  //设置
  Setting: 'Setting',
  // 权限
  SettingsPermissionApply: 'SettingsPermissionApply',
  //来电响铃
  SettingsRingtone: 'SettingsRingtone',
  SettingsRingoneEmpty: 'SettingsRingoneEmpty',
  SettingsDeviceInfo: 'SettingsDeviceInfo',
  /**
   * Collab
   */
  CollabTopics: 'CollabTopics',
  CollabNotices: 'CollabNotices',
  // 接单通知
  CollabOrder: 'CollabOrder',
  /**
   * Sale
   */
  // 来电工单
  SaleInCallOrder: 'SaleInCallOrder',
  SaleInCallCacheList: 'SaleInCallCacheList',
  SaleOrderTransferInfo: 'SaleOrderTransferInfo',
  // 工单确认
  SaleOrderConfirm: 'SaleOrderConfirm',
  SaleOrderProcess: 'SaleOrderProcess',
  // 工单拒绝列表
  SaleOrderRejectList: 'SaleOrderRejectList',
  SaleOrderRejectInfo: 'SaleOrderRejectInfo',
  SaleOrderRejectInfoPreview: 'SaleOrderRejectInfoPreview',
  SaleWxQr: 'SaleWxQr',
  SaleHistoryOrderList: 'SaleHistoryOrderList',

  // 门店管理
  _LocationSelect: '_LocationSelect',
  // 指派人员选择
  _AssignUserSelect: '_AssignUserSelect',
  _AccountSelect: '_AccountSelect',
  // 角色选择
  _PositionSelect: '_PositionSelect',

  // 产品选择
  // 仓库产品选择
  _InventorySelect: '_InventorySelect',
  _ProductSelect: '_ProductSelect',

  GETUI: 'GETUI',
  SCANNER: BfCodeScanner.ROUTE_NAME,
  IMAGE_VIEWER: 'IMAGE_VIEWER',
  // 商品选择
  ProductProductSelect: 'ProductProductSelect',
  ProductProductSelectSearch: 'ProductProductSelectSearch',

  //签名
  Sign: BfSignNavigator.ROUTE_NAME,
  // 通知
  NotificationTest: 'NotificationTest',

  LOG: '_Logs',
  WmsDocInventoryCompanySelect: 'WmsDocInventoryCompanySelect',
  // 库存警告
  WmsInventoryException: 'WmsInventoryException',
  // 新的角色管理页面
  HrDocPositionPositionInfo: 'HrDocPositionPositionInfo',

  // 提现页面
  SettingsWithdraw: 'SettingsWithdraw',
  // 库存-申领-提交表单页面
  WmsDocApplyApplyCreateSubmit: 'WmsDocApplyApplyCreateSubmit',
  // 库存-退回-提交表单页面
  WmsDocReturnReturnCreateSubmit: 'WmsDocReturnReturnCreateSubmit',

  OutCall: 'OutCall',
  // 登录页面
  AuthLoginNew: 'AuthLoginNew',
  // 忘记密码手机号验证
  AuthChangePasswordPhone: 'AuthChangePasswordPhone',
  // 修挂密码之设置密码
  AuthResetPassword: 'AuthResetPassword',

  // 我的-钱包设置
  WalletSettings: 'WalletSettings',
  // 我的-银行卡设置
  SettingsBankAccount: 'SettingsBankAccount',

  // 支付-线上付款-二维码展示页面
  FrPayment: 'FrPayment',
  // // 支付-付款结果页面
  // FrPaymentResult: 'FrPaymentResult',
  // 收款码设置页面
  SettingsSettingPayment: 'SettingsSettingPayment'
};
