import { FileLogger } from 'react-native-file-logger';
import { useEffect, useState } from 'react';
import { TwButton, TwListItem, TwPage, TwPageHeader } from '@tw/tw-components-rn';
import { Text, View } from 'react-native';
import * as FileSystem from 'expo-file-system';
import { FlashList } from '@shopify/flash-list';
import { TwTheme } from '@tw/tw-runtime-rn';
import { BfLogLevel } from '@tw/tw-log4js';
import { BfAppContext, isEmpty } from '@tw/tw-runtime';
import { TwLogLine } from '@/app/log/TwLogLine';

export const FileViewer = () => {
  const messageService = BfAppContext.getInstance().getMessageService();
  const routeService = BfAppContext.getInstance().getRouteService();
  // 文件列表
  const [files, setFiles] = useState<LogFile[]>();
  // 文本列表
  const [lines, setLines] = useState<LogLine[]>();
  const [curFile, setCurFile] = useState<LogFile>();
  useEffect(() => {
    FileLogger.getLogFilePaths().then((res) => {
      const logFiles: LogFile[] = res.map((item) => {
        const pos = item.lastIndexOf('/');
        const name = item.substring(pos + 1, item.length);
        return {
          path: item,
          name
        };
      });
      setFiles(logFiles);
      if (logFiles.length) {
        onFilePress(logFiles[0]);
      }
    });
  }, []);

  const onFilePress = async (file: LogFile) => {
    setCurFile(file);
    const lines = await readLogLines(file.path);
    setLines(lines);
  };
  const isChecked = (file: LogFile) => {
    return curFile.name === file.name;
  };
  const onLogClear = async () => {
    await FileLogger.deleteLogFiles();
    messageService.showSuccess('删除成功');
    routeService.back();
  };

  return (
    <TwPage translucentStatusBar={true}>
      <TwPageHeader
        title={'日志文件'}
        actions={
          <TwButton size={'sm'} variant={'ghost'} onPress={onLogClear}>
            清空
          </TwButton>
        }
      />
      <View style={{ flex: 1 }}>
        <Text style={{ ...TwTheme.values.textSizes.md, paddingHorizontal: TwTheme.values.spaces.pageHorizontal }}>
          日志列表
        </Text>
        <View style={{ height: 100 }}>
          <FlashList
            data={files}
            renderItem={(item) => {
              return (
                <TwListItem
                  title={item.item.name}
                  key={item.index}
                  pressable={true}
                  showCheck={isChecked(item.item)}
                  onPress={() => onFilePress(item.item)}
                />
              );
            }}
            estimatedItemSize={20}
          />
        </View>
        <Text style={{ ...TwTheme.values.textSizes.md, paddingHorizontal: TwTheme.values.spaces.pageHorizontal }}>
          日志内容
        </Text>
        <FlashList
          data={lines}
          renderItem={(item) => {
            const value = item.item as LogLine;
            return <TwLogLine level={value.level} message={value.message} key={item.index} />;
          }}
          estimatedItemSize={20}
          style={{ flex: 1 }}
        />
      </View>
    </TwPage>
  );
};

async function readLogLines(file: string) {
  const content = await FileSystem.readAsStringAsync(`file:/${file}`);
  const lines: LogLine[] = [];
  const rawLines = content.split('\n');
  rawLines.map((row) => {
    if (isEmpty(row)) {
      return;
    }
    const level = getLogLevel(row);
    lines.push({
      level: level,
      message: row
    });
  });
  return lines;
}

function getLogLevel(content: string): number {
  if (content.includes('[DEBUG]')) {
    return BfLogLevel.DEBUG.valueOf();
  }
  if (content.includes('[INFO]')) {
    return BfLogLevel.INFO.valueOf();
  }
  if (content.includes('[WARN]')) {
    return BfLogLevel.WARN.valueOf();
  }
  if (content.includes('[ERROR]')) {
    return BfLogLevel.ERROR.valueOf();
  }
  return -1;
}

interface LogFile {
  path: string;
  name: string;
}

interface LogLine {
  // LEVEL
  level: number;
  // 内容
  message: string;
}
