import { RnPushService, PusherPushOptions } from '@tw/tw-runtime-rn';
import { IApiService, IAuthStorageService, PushBindParam } from '@tw/tw-runtime';
import { ResSubscribeService } from '@tw/lock-api-gen';

export class PushService extends RnPushService {
  private subscribeService: ResSubscribeService;
  constructor(api: IApiService, authStorageService: IAuthStorageService, options: PusherPushOptions) {
    super(api, authStorageService, options);
    this.subscribeService = new ResSubscribeService(api);
  }

  protected async bindWithServer(param: PushBindParam): Promise<void> {
    await super.bindWithServer(param);
    const res = await this.subscribeService.subChanels();
    if (res && res.chanel) {
      const tasks = res.chanel.map((chanel) => {
        return this.subscribeChannel(chanel);
      });
      await Promise.all(tasks);
    }
  }
}
