import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AudioPlayerModalProps } from './type';
import { TwColumn, TwLoadingBlock, TwModal, TwRow, TwSlider, TwTypography } from '@tw/tw-components-rn';
import { TwTheme } from '@tw/tw-runtime-rn';
import { useGetLatest, useStateSync } from '@tw/tw-runtime-react';
import { Audio, AVPlaybackStatus } from 'expo-av';
import { MaterialIcons } from '@expo/vector-icons';
import { BfAppContext } from '@tw/tw-runtime';
import dayjs from 'dayjs';
import { Pressable } from 'react-native';
import { AVPlaybackStatusSuccess } from 'expo-av/src/AV.types';

export const AudioPlayerModal: FC<AudioPlayerModalProps> = (props) => {
  const { visible, fileValue, onClose } = props;
  return (
    <TwModal open={visible} maskClosable={true} bodyStyle={{ paddingHorizontal: 16 }} onClose={onClose}>
      <Pressable>{visible && fileValue ? <AudioPlayerInner {...props} /> : null}</Pressable>
    </TwModal>
  );
};

const AudioPlayerInner: FC<AudioPlayerModalProps> = (props) => {
  const { isLoading: isLoadingProp = false, fileValue, onClose } = props;
  const [isLoading, setLoading] = useStateSync(isLoadingProp);
  const [isPlaying, setPlaying] = useState(false);
  const [durationMs, setDurationMs] = useState<number>(0);
  const [positionMs, setPositionMs] = useState<number>(0);
  const [sound, setSound] = useState<Audio.Sound>();
  const messageService = BfAppContext.getInstance().getMessageService();
  const getSound = useGetLatest(sound);
  const setPosition = useCallback(
    async (position: number) => {
      const sound = getSound();
      if (!sound) {
        console.log('NoSound');
      }
      await sound.setPositionAsync(position);
      setPositionMs(position);
    },
    [getSound]
  );

  const handleStatusChange = useCallback(
    (state: AVPlaybackStatus) => {
      if (state.isLoaded) {
        // 播放完成
        if (state.didJustFinish) {
          messageService.showSuccess('播放结束');
          const sound = getSound();
          sound.stopAsync().then(() => setPosition(0));
        }
        setPlaying(state.isPlaying);
        setLoading(false);
        setDurationMs(state.durationMillis);
        setPositionMs(state.positionMillis);
      } else {
        setLoading(true);
      }
    },
    [getSound, messageService, setLoading, setPosition]
  );

  const getHandleStatusChange = useGetLatest(handleStatusChange);
  const handlePlayPause = useCallback(async () => {
    isPlaying ? await sound.pauseAsync() : await sound.playAsync();
  }, [isPlaying, sound]);

  useEffect(() => {
    if (!fileValue.url) {
      return;
    }
    (async function () {
      try {
        const { sound } = await Audio.Sound.createAsync(
          { uri: fileValue.url },
          {
            isLooping: false
          },
          getHandleStatusChange()
        );
        try {
          await sound.playAsync();
        } catch (e) {
          console.error(e);
        }

        setSound(sound);
      } catch (e) {
        messageService.showError('音频加载失败');
        onClose?.();
      }
    })();
  }, [fileValue.url, getHandleStatusChange, messageService, onClose, setLoading]);
  useEffect(() => {
    return sound
      ? () => {
          sound.stopAsync();
          sound.unloadAsync();
        }
      : undefined;
  }, [sound]);
  const progress = useMemo(() => {
    if (durationMs === 0 || positionMs === 0) {
      return 0;
    }
    return Math.floor((positionMs / durationMs) * 100);
  }, [durationMs, positionMs]);

  const needResume = useRef(false);

  const handleSeek = useCallback(
    async (value) => {
      const progress = value[0];
      const pos = Math.abs((durationMs * progress) / 100);
      await setPosition(pos);
      if (needResume.current) {
        await sound.playAsync();
        needResume.current = false;
      }
    },
    [durationMs, setPosition, sound]
  );

  const handleSeekStart = useCallback(async () => {
    const status = await sound.getStatusAsync();
    if (!status.isLoaded) {
      return;
    }
    if (status.isPlaying) {
      needResume.current = true;
      await sound.pauseAsync();
    } else {
      needResume.current = false;
    }
  }, [sound]);
  return isLoading ? (
    <TwLoadingBlock height={50} />
  ) : (
    <>
      <TwRow alignItems={'center'}>
        <TwColumn flex={1}>
          <TwTypography.Text size={'lg'} color={TwTheme.values.primaryColors.base}>
            录音
          </TwTypography.Text>
          <TwTypography.Text size={'sm'}>
            {fileValue.saveTime ? dayjs(fileValue.saveTime).format('MM月DD日 HH:mm') : '--'}
          </TwTypography.Text>
        </TwColumn>
        <MaterialIcons
          name={isPlaying ? 'pause-circle-filled' : 'play-circle-filled'}
          size={32}
          color={TwTheme.values.primaryColors.base}
          onPress={handlePlayPause}
        />
      </TwRow>
      <TwSlider
        maximumValue={100}
        minimumValue={0}
        value={progress}
        onSlidingComplete={handleSeek}
        onSlidingStart={handleSeekStart}
      />
      <TwRow justifyContent={'space-between'}>
        <TwTypography.Text size={'xs'} color={isPlaying ? TwTheme.values.primaryColors.base : undefined}>
          {timeFormat(positionMs)}
        </TwTypography.Text>
        <TwTypography.Text size={'xs'}>{timeFormat(durationMs)}</TwTypography.Text>
      </TwRow>
    </>
  );
};

export function timeFormat(timeMs: number) {
  const time = timeMs / 1000;
  const hour = parseInt(String(time / 3600));
  const min = parseInt(String((time % 3600) / 60));
  const seconds = parseInt(String((time % 3600) % 60));
  let result = '';
  if (hour) {
    result += `${hour}:`;
  }
  result += String(min ?? 0).padStart(2, '00') + ':';

  result += String(seconds ?? 0).padStart(2, '00');

  return result;
}
