import { BfAbstractIntentNavigator, BfIntent, BfIntentResult } from '@tw/tw-runtime';

import { ConditionDTO } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { RoleData } from './assign/type';
import { TwSearchModalProps } from '@tw/tw-components-rn';

export interface BfRoleSelectIntent extends BfIntent, Pick<TwSearchModalProps<RoleData[]>, 'title'> {
  // 选中的数据
  selected?: RoleData[];

  // 是否多选
  multiple?: boolean;

  disableIds?: string[];

  structure: {
    /**
     * bo Id
     */
    id: string;
    /**
     * boDomain
     */
    domain: string;
    /**
     * 根据类型获取服务
     */
    type: 'doc' | 'dataview' | 'bo';
  };

  condition?: ConditionDTO[];
}

export class RoleSelectNavigator extends BfAbstractIntentNavigator<RoleData[], BfRoleSelectIntent> {
  get ROUTE_NAME(): string {
    return ROUTE_NAMES._PositionSelect;
  }
  start(intent?: BfRoleSelectIntent): Promise<BfIntentResult<RoleData[]>> {
    return super.start(intent);
  }
}
export const BfRoleSelectNavigator = new RoleSelectNavigator();
