import { TwBfSelectListView } from '@tw/tw-platform-rn';
import { PartnerName } from '@/app/components/select-list-view/viewparts/partner-name/PartnerName';

export const SelectListView = () => {
  return (
    <TwBfSelectListView
      page={undefined}
      components={{
        PartnerName: PartnerName
      }}
    />
  );
};
