import {
  BfAppContext,
  BfSlice,
  IMessageService,
  newEnumValue,
  newEqualCondition,
  promiseSafeRun
} from '@tw/tw-runtime';
import {
  OrderCreateSaveTypeEnumValue,
  OrderExecutiveStatusEnumValue,
  OrderExecutorTypeEnumValue,
  ResDocPartnerDTO,
  ResPartnerDTO,
  SaleDocOrderDTO,
  SaleOrderDTO,
  SaleOrderRestApiService,
  SaleOrderService
} from '@tw/lock-api-gen';
import { IForm } from '@tw/tw-platform-react';
import { TwBfForm } from '@tw/tw-platform-rn';
import { OrderDetailCacheStore } from '@/sale/stores/in-call-order/order-detail-cache-store';

/**
 * 历史工单
 */
export class HistoryCompletedOrderSlice<RootState> extends BfSlice<RootState, HistoryCompletedOrderSliceState> {
  private messageService: IMessageService;
  protected saleOrderService: SaleOrderService;
  protected saleOrderRestApiService: SaleOrderRestApiService;
  private orderDetailStore: OrderDetailCacheStore;
  /**
   * 历史工单
   * @protected
   */
  protected form: IForm | undefined;

  constructor(options) {
    super(options);
    this.form = TwBfForm.createForm();
    const api = BfAppContext.getInstance().getApiService();
    this.messageService = BfAppContext.getInstance().getMessageService();
    this.saleOrderService = new SaleOrderService(api);
    this.saleOrderRestApiService = new SaleOrderRestApiService(api);
    this.orderDetailStore = new OrderDetailCacheStore();
  }

  getForm() {
    return this.form;
  }

  getOrderDetailStore() {
    return this.orderDetailStore;
  }

  getInitialState(): HistoryCompletedOrderSliceState {
    return {
      completedHistoryOrder: [],
      completedOrderInfo: undefined
    };
  }

  setItems(order: SaleOrderDTO[] | undefined) {
    this.setState(
      {
        completedHistoryOrder: order
      },
      false,
      '设置历史工单'
    );
  }

  /**
   * 加载已完成历史工单信息
   * @private
   */
  async loadCompletedHistoryOrder(partnerId: ResDocPartnerDTO) {
    const historyOrder = await this.saleOrderRestApiService.query({
      noPaging: true,
      conditions: [
        newEqualCondition(SaleOrderRestApiService.PARTNER_ID, partnerId.id),
        newEqualCondition(SaleOrderRestApiService.DIRECTING_STATUS, OrderExecutiveStatusEnumValue.complete)
      ]
    });
    this.setItems(historyOrder.rows);
  }

  setPartnerId(partner: ResPartnerDTO) {
    this.setState(
      {
        partnerId: partner
      },
      false,
      '更新客户信息'
    );
  }

  setAfterSaleOrder(order: SaleOrderDTO | undefined) {
    this.setState(
      {
        completedOrderInfo: order
      },
      false,
      '设置售后工单'
    );
  }

  /**
   * 售后工单发单
   */
  async onHistoryOrderSendOrder() {
    await this.form.validate();
    const confirm = await this.messageService.showConfirm({
      title: '提示',
      content: '确认新建售后工单？'
    });
    if (!confirm) {
      return;
    }
    const order = this.form.getValues() as SaleDocOrderDTO;
    const appointReq = order.selectAssign;
    const { partnerId } = this.getState();
    const saveType = newEnumValue(OrderCreateSaveTypeEnumValue.aftersale_order);
    await this.messageService.showLoading();
    // 新建咨询单
    await promiseSafeRun(async () => {
      await this.saleOrderService.create({
        partnerId,
        orderId: order,
        appointReq: appointReq,
        saveType
      });
      this.messageService.showSuccess('新建售后工单成功');
      this.afterSendOrderSuccess();
    });
    await this.messageService.hideLoading();
  }

  protected afterSendOrderSuccess() {
    //
    this.setAfterSaleOrder(undefined);
  }

  /**
   * 售后工单选中
   * @param item
   */
  onAfterSaleOrderSelect(item: SaleDocOrderDTO) {
    const { id, executor, selectAssign, ...restProps } = item;
    const newOrder: SaleDocOrderDTO & SaleOrderDTO = {
      parentId: {
        id: id
      },
      ...restProps
    };
    // 如果工单有执行人，选择执行人作为指派参数
    if (executor) {
      newOrder.selectAssign = {
        executor: executor,
        executorType: newEnumValue(OrderExecutorTypeEnumValue.internal)
      };
    }
    this.setAfterSaleOrder(newOrder);
  }

  onAfterSaleOrderCancel() {
    this.setAfterSaleOrder(undefined);
  }
}

export interface HistoryCompletedOrderSliceState {
  /**
   *  历史工单
   */
  completedHistoryOrder?: SaleOrderDTO[];
  /**
   * 历史工单-表单
   */
  completedOrderInfo?: SaleOrderDTO;

  /**
   * 客户
   */
  partnerId?: ResPartnerDTO;
}
