import { ProductItem as Item } from '@/app/components/product-select/product-item/ProductItem';
import { ProductProductDTO } from '@tw/lock-api-gen';
import { ProductItemProps } from '@/wms/pages/doc-inventory-company-select/viewparts/product-item/type';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { WmsDocInventoryCompanySelectPage } from '@/wms/stores/doc-inventory-company-select/wms-doc-inventory-company-select-page';

export const ProductItem = (props: ProductItemProps) => {
  const { value } = props;
  const store = useBfPageStore<WmsDocInventoryCompanySelectPage>();
  const itemKey = store.getDataId(value);
  const count = useStoreState(store, (state) => store.getListItemSelectCount(state, itemKey));
  return (
    <Item
      item={value}
      count={count}
      quantity={value.quantity}
      onCountChange={function (product: ProductProductDTO, count: number): void {
        store.onListItemSelectWithCount(product, count);
      }}
    />
  );
};
