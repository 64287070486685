import { BfElementParser, BfFormElement } from '@tw/tw-platform-react';
import { ISchema } from '@formily/react';

export class TabParser extends BfElementParser {
  accept(element: BfFormElement, parent?: BfFormElement): boolean {
    return element.type === 'container' && element.widget === 'InfoTabs';
  }

  protected getComponent(element: BfFormElement, parent: BfFormElement | undefined): string {
    return element.widget ?? 'InfoTabs';
  }

  protected getType(element: BfFormElement, parent: BfFormElement | undefined): ISchema['type'] {
    return 'void';
  }
}

export class TabPaneParser extends BfElementParser {
  accept(element: BfFormElement, parent?: BfFormElement): boolean {
    return parent?.type === 'container' && parent.widget === 'InfoTabs';
  }

  protected getComponent(element: BfFormElement, parent: BfFormElement | undefined): string {
    return 'Fragment';
  }

  protected getType(element: BfFormElement, parent: BfFormElement | undefined): ISchema['type'] {
    return 'void';
  }

  protected getComponentProps(element: BfFormElement, parent: BfFormElement | undefined): any {
    return {
      ...super.getComponentProps(element, parent),
      tab: element.name
    };
  }
}
