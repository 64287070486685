import React, { FC } from 'react';
import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import { ProductDocProductDTO } from '@tw/lock-api-gen';
import { Text, View } from 'native-base';
import { styles } from '@/sale/components/product-order-edit/styles';

export const OrderProductField: FC<TwBfFieldRcProps<ProductDocProductDTO>> = (props) => {
  const { value } = props;

  return (
    <View style={styles.info}>
      <Text style={styles.infoTitle}>{value?.name}</Text>
      <Text fontSize={'xs'} style={styles.infoSize}>
        {value?.specification ?? '--'}
      </Text>
    </View>
  );
};
