import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  typeContainer: {
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal,
    borderBottomWidth: TwTheme.values.borderWidths.base,
    borderBottomColor: TwTheme.values.borderColors.base,
    paddingBottom: TwTheme.values.spaces['1']
  },
  assignAccount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 0
  }
});
