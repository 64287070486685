import { BfAppContext, PageQueryDTO, SortTypeDTO } from '@tw/tw-runtime';
import { FC, useEffect } from 'react';
import { HrEmployeeDTO, HrPositionService } from '@tw/lock-api-gen';
import { SearchResultType, TwPage, TwSearchModal } from '@tw/tw-components-rn';

import { BfRoleSelectNavigator } from '../bf-role-select-navigator';
import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { RoleData } from './type';
import { RoleIcon } from './Icon';
import { View } from 'react-native';
import { isArray } from 'lodash-es';

export const RoleSelect = () => {
  /**
   * 获取人员列表
   * @param executor
   */
  const listAllEmployeeByKeyword = async (
    keyword?: string,
    pageSize = 20,
    pageNo = 1
  ): Promise<SearchResultType<RoleData>> => {
    const params: PageQueryDTO = keyword
      ? {
          pageNo: pageNo,
          pageSize: pageSize,
          keyword: keyword,
          sorts: [
            {
              field: 'code',
              sortType: SortTypeDTO.DESC
            }
          ]
        }
      : {
          pageNo: pageNo,
          pageSize: pageSize,
          sorts: [
            {
              field: 'code',
              sortType: SortTypeDTO.DESC
            }
          ]
        };

    if (BfRoleSelectNavigator.getIntent().condition && BfRoleSelectNavigator.getIntent().condition.length > 0) {
      params.conditions = params.conditions
        ? params.conditions.concat(BfRoleSelectNavigator.getIntent().condition)
        : BfRoleSelectNavigator.getIntent().condition;
    }
    // const response = await dataService.query(params, BfRoleSelectNavigator.getIntent().structure);

    const api = BfAppContext.getInstance().getApiService();
    const hrPositionService = new HrPositionService(api);

    const response = await hrPositionService.positionList(params);

    const data: RoleData[] = [];
    response?.positionItems?.forEach((item) => {
      item?.position?.forEach((position) => {
        data.push({
          type: item.group.code,
          typeName: item.group.name,
          ...position
        });
      });
    });

    return {
      entries: data,
      status: 'success' as const,
      noDataMessage: '没有数据',
      hasMore: false,
      hasMoreMessage: 'load more',
      next: () => listAllEmployeeByKeyword(keyword, pageSize, pageNo + 1)
    };
  };

  const [selected, setSelected] = React.useState<HrEmployeeDTO[]>([]);

  useEffect(() => {
    const selected = BfRoleSelectNavigator.getIntent()?.selected;
    const tempSelected = selected ? (isArray(selected) ? selected : [selected]) : [];
    setSelected(tempSelected);
  }, []);

  const onComplete = (role: RoleData[]) => {
    const dealRole = role.map((item) => {
      return {
        id: item.id,
        name: item.name,
        code: item.code
      };
    });

    BfRoleSelectNavigator.finish(dealRole);
    BfAppContext.getInstance().getRouteService().back();
  };

  const keywordSearch = (keyword: string) => {
    return listAllEmployeeByKeyword(keyword);
  };

  const onCancel = async (account: RoleData[]) => {
    // check if account is equal selected
    let isSame = true;

    if (selected && selected.length === account.length) {
      for (let i = 0; i < selected.length; i++) {
        if (account.includes(selected[i])) {
          continue;
        } else {
          isSame = false;
          break;
        }
      }
    } else {
      isSame = false;
    }

    if (isSame) {
      BfRoleSelectNavigator.cancel();
      BfAppContext.getInstance().getRouteService().back();
    } else {
      const confrim: boolean = await BfAppContext.getInstance().getMessageService().showConfirm({
        title: '提示',
        content: '确定要取消吗?修改将不会被保存'
      });
      if (confrim) {
        BfRoleSelectNavigator.cancel();
        BfAppContext.getInstance().getRouteService().back();
      }
    }
  };

  const selectableTypes = [
    {
      key: 'custom', //自定义
      IconComponent: () => <MaterialIcons name="search" size={24} color="white" />,
      typePredicate: (item: RoleData) => item.type === 'false'
    }
    // {
    //   key: 'default', //默认
    //   IconComponent: () => <MaterialIcons name="search" size={24} color="white" />,
    //   typePredicate: (item: RoleData) => item.type === 'true'
    // }
  ];

  const getKey = (item: RoleData) => item.id;
  const getPrimaryText = (item: RoleData) => item?.name ?? '';

  return (
    <TwPage translucentStatusBar={true}>
      <View style={{ backgroundColor: 'white', flex: 1 }}>
        <TwSearchModal
          title={BfRoleSelectNavigator.getIntent()?.title || '选择角色'}
          onComplete={(selected: any[]) => {
            onComplete(selected);
          }}
          onClose={onCancel}
          selected={selected}
          // keyword={keyword}
          keywordSearch={keywordSearch}
          // treeSearch={treeSearch}
          getPrimaryText={getPrimaryText}
          multiple={BfRoleSelectNavigator.getIntent().multiple ?? false}
          allowEmpty={true}
          Icon={RoleIcon}
          // getIconUrl={(item: RoleData) =>  undefined}
          getKey={getKey}
          isExpandable={(item: RoleData) => false}
          isSelectable={(item: RoleData) =>
            BfRoleSelectNavigator.getIntent()?.disableIds.length > 0
              ? !BfRoleSelectNavigator.getIntent()?.disableIds.includes(item.id)
              : true
          }
          placeholder={'搜索'}
          cancelLabel={'取消'}
          completeLabel={'完成'}
          breadcrumbRoot={'Root'}
          resultTitle={'搜索结果'}
          backButtonLabel={'返回列表'}
          selectableTypes={selectableTypes}
          tag={['true', 'false'].map((type) => {
            return {
              key: type.toString(),
              label: type === 'true' ? '默认' : '自定义',
              isThisTag: (item: RoleData) => item.type === type
            };
          })}
        />
      </View>
    </TwPage>
  );
};
