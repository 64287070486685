import { BfAppContext, BfIntentResultType } from '@tw/tw-runtime';
import React, { FC } from 'react';

import { BfAccountSelectNavigator } from '@/app/components/tw-account-select/bf-account-select-navigator';
import { HrTeamRestApiService } from '@tw/lock-api-gen';
import { InCallAssignGroupSelectProps } from '@/sale/components/incall-assign-select/type';
import { MaterialIcons } from '@expo/vector-icons';
import { SelectedAccount } from '@/app/components/tw-account-select/SelectedAccount';
import { TwFormItem } from '@tw/tw-components-rn';
import { TwTheme } from '@tw/tw-runtime-rn';
import { View } from 'native-base';
import { styles } from './styles';

/**
 * 选择工单组
 * @constructor
 */
export const InCallAssignGroupSelect: FC<InCallAssignGroupSelectProps> = (props) => {
  const { value, onChange } = props;

  const onSelectUser = async () => {
    const apiService = BfAppContext.getInstance().getApiService();
    const teamService = new HrTeamRestApiService(apiService);

    const res = await BfAccountSelectNavigator.start({
      selected: value,
      structure: {
        id: teamService.apiStructureId,
        domain: teamService.apiDomainId,
        type: 'bo'
      },
      title: '选择工单组'
    });
    if (res.type === BfIntentResultType.OK) {
      onChange?.(res.data);
    } else {
      return;
    }
  };

  return (
    <TwFormItem label={'工单组'} contentAlign={'right'} bordered={true} pressable={true} onPress={onSelectUser}>
      <View style={styles.assignAccount}>
        {value && <SelectedAccount name={value.name} showName={true} />}
        <MaterialIcons name="keyboard-arrow-right" size={24} color={TwTheme.values.colors.black} />
      </View>
    </TwFormItem>
  );
};
