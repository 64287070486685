import { BfAbstractIntentNavigator, BfIntentResult } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { BfStructureSelectedWithCount } from '@tw/tw-platform-react';
import { ProductProductDTO } from '@tw/lock-api-gen/dist/types/product';
import { WmsWarehouseDTO } from '@tw/lock-api-gen';

class Navigator extends BfAbstractIntentNavigator<
  BfStructureSelectedWithCount<ProductProductDTO>[],
  InventoryPersonalIntent
> {
  get ROUTE_NAME() {
    return ROUTE_NAMES.WmsDocInventoryPersonalPersonalProductChoose;
  }

  start(
    intent?: InventoryPersonalIntent | undefined
  ): Promise<BfIntentResult<BfStructureSelectedWithCount<ProductProductDTO>[]>> {
    return super.start(intent);
  }
}

export interface InventoryPersonalIntent {
  /**
   * 是否显示服务，默认不显示
   */
  showService?: boolean;
  /**
   * 仓库
   */
  warehouse?: WmsWarehouseDTO;
  /**
   * 已选商品
   */
  selectedProducts?: BfStructureSelectedWithCount<ProductProductDTO>[];
}

export const InventoryPersonalSelectNavigator = new Navigator();
