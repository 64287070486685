/**
 * 指派
 * @param canAssignToPool
 */
import { BfFormElement, InputDataSource } from '@tw/tw-platform-react';
import { GenderEnumValue } from '@tw/lock-api-gen';

/**
 * 客户姓名
 */
export const newCustomerNameElement = (widget: string | undefined, decorator: string | undefined): BfFormElement => {
  return {
    id: 'name',
    name: '客户姓名',
    type: 'text',
    description: ' refBo:bo://res/partner',
    visible: true,
    multiple: false,
    refBO: {
      qname: 'bo://res/partner',
      id: 'partner',
      name: '客户',
      description: '客户不一定是公司, 可能只是简单录入的一条信息, 录入的公司是自动成为一个客户',
      domain: 'res',
      dataStructure: 'list',
      pkAttributeId: 'id',
      nameAttributeId: 'name',
      show: 'name'
    },
    widget: widget,
    widgetOptions: {
      decorator: decorator
    },
    displayTypes: ['Create'],
    showAttrs: [
      {
        code: '3',
        id: 'name',
        name: '名称',
        type: 'text',
        visible: true,
        multiple: false,
        isName: true,
        validations: [],
        widgetOptions: {}
      }
    ],
    queryAttrs: [
      {
        code: '3',
        id: 'name',
        name: '名称',
        type: 'text',
        visible: true,
        multiple: false,
        isName: true,
        validations: [],
        widgetOptions: {}
      }
    ],
    selectPage: {
      qname: 'doc://res/docPartnerChoose',
      //@ts-ignore
      type: 'InfoView',
      uiviewQname: 'uiview://partnerChooseList'
    }
  };
};
/**
 * 客户性别
 */
export const newCustomGenderElement = (widget: string | undefined, decorator: string | undefined): BfFormElement => {
  return {
    id: 'gender',
    name: '客户性别',
    type: 'enumValue',
    description: ' refBo:bo://res/partner',
    visible: true,
    multiple: false,
    widget: widget,
    widgetOptions: {
      enumId: 'gender',
      mode: 'inline',
      itemLayout: 'flow',
      decorator: decorator
    },
    refEnum: {
      id: 'gender'
    },
    displayTypes: ['Create']
  };
};
/**
 * 客户电话
 */
export const newCustomerPhoneElement = (): BfFormElement => {
  return {
    id: 'mobile',
    name: '电话',
    type: 'phone',
    validations: [
      {
        isNotNull: true
      }
    ]
  };
};

/**
 * 工单分配
 * @param canAssignToPool
 */
export const newOrderAssignElement = (canAssignToPool: boolean): BfFormElement => {
  return {
    id: 'selectAssign',
    name: '指派人员',
    type: 'reference',
    widget: 'InCallAssignSelect',
    widgetOptions: {
      canAssignToPool: canAssignToPool
    },
    reactions: [
      {
        dependencies: {
          address: 'address'
        },
        fulfill: {
          schema: {
            'x-component-props.orderAddress': '{{$deps.address}}'
          }
        }
      }
    ]
  };
};
/**
 * 业务类型
 */
export const newBusinessElement = (): BfFormElement => {
  return {
    id: 'business',
    name: '业务类型',
    type: 'reference',
    description: ' refBo:bo://sale/orderBusiness',
    visible: true,
    multiple: false,
    refBO: {
      qname: 'bo://sale/orderBusiness',
      id: 'orderBusiness',
      name: '工单-业务类型',
      domain: 'sale',
      dataStructure: 'list',
      pkAttributeId: 'id',
      nameAttributeId: 'name',
      show: 'name'
    },
    widgetOptions: {},
    displayTypes: ['Create'],
    showAttrs: [
      {
        code: '2',
        id: 'name',
        name: '名称',
        type: 'text',
        visible: true,
        multiple: false,
        isName: true,
        validations: [],
        widgetOptions: {}
      }
    ],
    queryAttrs: [
      {
        code: '1',
        id: 'id',
        name: 'ID',
        type: 'text',
        visible: true,
        multiple: false,
        isName: true,
        validations: [],
        widgetOptions: {}
      },
      {
        code: '2',
        id: 'name',
        name: '名称',
        type: 'text',
        visible: true,
        multiple: false,
        isName: true,
        validations: [],
        widgetOptions: {}
      },
      {
        code: '4',
        id: 'code',
        name: '编号',
        type: 'text',
        visible: true,
        multiple: false,
        validations: [],
        widgetOptions: {}
      }
    ]
  };
};
/**
 * 上门时间
 */
export const newDeliveryTimeElement = (): BfFormElement => {
  return {
    id: 'deliveryTime',
    name: '期望时间',
    type: 'dateScope',
    visible: true,
    multiple: false,
    validations: [],
    widgetOptions: {
      showTime: true
    },
    displayTypes: ['Create']
  };
};
/**
 * 备注
 */
export const newRemarkElement = (): BfFormElement => {
  return {
    id: 'remark',
    name: '备注',
    type: 'textarea',
    visible: true,
    multiple: false,
    validations: [],
    widgetOptions: {},
    displayTypes: ['Create']
  };
};
/**
 * 联系人
 */
export const newDefaultGenderValue = () => {
  return {
    code: GenderEnumValue.male,
    name: '先生'
  };
};

export const newLinkmanName = (
  id: string,
  partnerIdAttr: string,
  genderAttr: string,
  mobileAttr: string,
  enableSearch = true,
  editable = true
): BfFormElement => {
  return {
    id: id,
    name: '姓名',
    type: 'text',
    editable: editable,
    widgetOptions: {
      decorator: 'None',
      partnerIdAttr,
      genderAttr,
      mobileAttr,
      enableSearch
    },
    widget: 'InCallCustomerNameInputField'
  };
};

export const newLinkmanGender = (id: string): BfFormElement => {
  return {
    id: id,
    name: '性别',
    type: 'text',
    widgetOptions: {
      decorator: 'None'
    },
    widget: 'InCallCustomerGenderInput',
    default: newDefaultGenderValue()
  };
};
export const newLinkmanPhone = (
  id: string,
  partnerIdAttr: string,
  genderAttr: string,
  nameAttr: string,
  editable = true
): BfFormElement => {
  return {
    id: id,
    name: '手机号',
    type: 'text',
    editable,
    widgetOptions: {
      decorator: 'None',
      partnerIdAttr,
      genderAttr,
      nameAttr
    },
    widget: 'InCallCustomerMobileInputField',
    validations: [
      {
        isNotNull: true
      },
      {
        pattern: /^(((0\d{2,3})[0-9]{7,8})|(1[3-9]\d{9}))$/,
        message: '请输入有效电话号码'
      }
    ]
  };
};

export const newLinkmanPartner = (id: string): BfFormElement => {
  return {
    id: id,
    name: '客户',
    type: 'reference',
    widgetOptions: {
      decorator: 'None'
    },
    widget: 'Fragment'
  };
};

export const newCustomerInfoElement = (isCallNameSearchable = true, isCallPhoneEditable = true): BfFormElement => {
  return {
    id: 'customerInfo',
    name: '联系人',
    type: 'object',
    visible: true,
    multiple: false,
    widget: 'InCallCustomerInputField',
    widgetOptions: {
      decorator: 'None'
    },
    displayTypes: ['Create'],
    elements: [
      newLinkmanPartner('partnerId'),
      newLinkmanName('callman', 'partnerId', 'callmanGender', 'callmanPhone', isCallNameSearchable),
      newLinkmanGender('callmanGender'),
      newLinkmanPhone('callmanPhone', 'partnerId', 'callmanGender', 'callman', isCallPhoneEditable),

      newLinkmanPartner('linkmanPartnerId'),
      newLinkmanName('linkman', 'linkmanPartnerId', 'linkmanGender', 'linkmanPhone'),
      newLinkmanGender('linkmanGender'),
      newLinkmanPhone('linkmanPhone', 'linkmanPartnerId', 'linkmanGender', 'linkman')
    ]
  };
};
/**
 * 性别
 */
export const newLinkmanGenderElement = (visible = true): BfFormElement => {
  return {
    id: 'linkmanGender',
    name: '性别',
    type: 'enumValue',
    description: ' refBo:bo://res/partner',
    visible: visible,
    multiple: false,
    widgetOptions: {
      enumId: 'gender',
      mode: 'inline',
      itemLayout: 'flow'
    },
    refEnum: {
      id: 'gender'
    }
  };
};
/**
 * 联系号码
 */
export const newLinkmanPhoneElement = (): BfFormElement => {
  return {
    id: 'linkmanPhone',
    name: '联系号码',
    type: 'phone',
    visible: true,
    multiple: false,
    widgetOptions: {},
    displayTypes: ['Create']
  };
};
/**
 * 地址
 */
export const newAddressElement = (required = true): BfFormElement => {
  const el: BfFormElement = {
    id: 'address',
    name: '联系地址',
    type: 'coordinates',
    visible: true,
    multiple: false,
    widget: 'InCallLocation',
    widgetOptions: {},
    displayTypes: ['Create']
  };
  if (required) {
    el.validations = [
      {
        isNotNull: true
      }
    ];
  }
  return el;
};
/**
 * 指派人员
 * @param canAssignToPool
 * @param showTitle
 */
export const newAssignElement = (canAssignToPool = true, showTitle = false): BfFormElement => {
  return {
    id: 'selectAssign',
    name: '指派人员',
    type: 'reference',
    widget: 'InCallAssignSelect',
    widgetOptions: {
      canAssignToPool: canAssignToPool,
      showTitle: showTitle,
      decorator: 'None'
    },
    reactions: [
      {
        dependencies: {
          address: 'address'
        },
        fulfill: {
          schema: {
            'x-component-props.orderAddress': '{{$deps.address}}'
          }
        }
      }
    ]
  };
};

/**
 * 咨询内容
 */
export const newOrderConsultElement = (): BfFormElement => {
  return {
    id: 'chanceType',
    name: '来电需求',
    type: 'text',
    widgetOptions: {
      dataSourceDisplay: 'dropdown',
      layout: 'horizontal'
    },
    dataSource: INCALL_CHANCE_TYPE_DATA_SOURCE
  };
};

export const INCALL_CHANCE_TYPE_DATA_SOURCE: InputDataSource = {
  refBO: {
    qname: 'bo://res/choiceReasonData',
    id: 'choiceReasonData',
    name: '原因选择数据',
    domain: 'res',
    dataStructure: 'list',
    pkAttributeId: 'id',
    nameAttributeId: 'name'
  },
  showAttrs: [
    {
      code: '2',
      id: 'name',
      name: '名称',
      type: 'text',
      visible: true,
      multiple: false,
      isName: true,
      validations: [],
      widgetOptions: {}
    },
    {
      code: '6',
      id: 'sortOrder',
      name: '排序号',
      type: 'number',
      visible: true,
      multiple: false,
      validations: [],
      widgetOptions: {}
    }
  ],
  saveAttr: {
    code: '2',
    id: 'name',
    name: '名称',
    type: 'text',
    visible: true,
    multiple: false,
    isName: true,
    validations: [],
    widgetOptions: {}
  },
  conditions: [
    {
      field: 'scene',
      rule: 'EQ',
      value: {
        type: 'value',
        value: 'command://consult'
      }
    },
    {
      matchType: 'AND',
      field: 'applyTo',
      rule: 'EQ',
      value: {
        type: 'value',
        value: 'bo://sale/chance'
      }
    }
  ],
  sorts: [
    {
      field: 'sortOrder',
      sortType: 'ASC'
    }
  ],
  mode: 'customized'
};
