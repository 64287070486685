import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  noRecordContainer: {
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal,
    paddingVertical: TwTheme.values.spaces['2']
  },
  noRecordText: {
    ...TwTheme.values.textSizes.sm,
    color: TwTheme.values.textColors.secondary
  }
});
