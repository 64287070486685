import { BfAbstractIntentNavigator, BfIntentResult } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { BfStructureSelectedWithCount } from '@tw/tw-platform-react';
import { ProductProductDTO } from '@tw/lock-api-gen/dist/types/product';
import { WmsWarehouseDTO } from '@tw/lock-api-gen';

class Navigator extends BfAbstractIntentNavigator<
  BfStructureSelectedWithCount<ProductProductDTO>[],
  InventoryCompanyIntent
> {
  get ROUTE_NAME() {
    return ROUTE_NAMES.WmsDocInventoryCompanySelect;
  }

  start(
    intent?: InventoryCompanyIntent | undefined
  ): Promise<BfIntentResult<BfStructureSelectedWithCount<ProductProductDTO>[]>> {
    console.log('InventoryCompanySelectNavigator start ', intent);
    return super.start(intent);
  }
}

export interface InventoryCompanyIntent {
  /**
   * 仓库
   */
  warehouse?: WmsWarehouseDTO;
  /**
   * 已选商品
   */
  selectedProducts?: BfStructureSelectedWithCount<ProductProductDTO>[];
}

export const InventoryCompanySelectNavigator = new Navigator();
