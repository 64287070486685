import { TwButton, TwPage } from '@tw/tw-components-rn';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';

export const HistoryOrderFooter = () => {
  const store = useBfPageStore<InCallOrderStore>();
  const hasSelectedOrder = useStoreState(store, (state) => state.hasCompleteOrderInfo);
  if (!hasSelectedOrder) {
    return null;
  }
  return (
    <TwPage.Footer>
      <TwButton
        variant={'outline'}
        onPress={() => {
          store.onHistoryOrderSendOrder();
        }}
      >
        发单
      </TwButton>
    </TwPage.Footer>
  );
};
