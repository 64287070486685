import { TwTabs, TwTabsProps } from '@tw/tw-components-rn';
import { useMemo } from 'react';
import { InCallUnderwayOrder } from '@/sale/pages/in-call-order/viewparts/incall-underway-order/InCallUnderwayOrder';
import { InCallNewOrder } from '@/sale/pages/in-call-order/viewparts/incall-new-order/InCallNewOrder';
import { InCallHistoryOrder } from '@/sale/pages/in-call-order/viewparts/incall-history-order/InCallHistoryOrder';
import { InCallHistoryConsult } from '@/sale/pages/in-call-order/viewparts/incall-history-consult/InCallHistoryConsult';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { TwTabItem } from '@tw/tw-components-rn/lib/typescript/display/tw-tabs/type';
import { RequirementType } from '@/sale/stores/in-call-order/type';

export const InCallRequirementTabs = () => {
  const store = useBfPageStore<InCallOrderStore>();
  const hasUnderwayOrder = useStoreState(store, (state) => state.hasUnderwayOrder ?? true);
  const completedOrderHistoryLen = useStoreState(store, (state) => state.completedHistoryOrder?.length ?? 0);
  const historyChanceLen = useStoreState(store, (state) => state.historyChanceList?.length ?? 0);
  const requirementType = useStoreState(store, (state) => state.requirementType);
  const tabItems: TwTabsProps['items'] = useMemo(() => {
    const tabs: TwTabItem[] = [];
    if (hasUnderwayOrder) {
      tabs.push({
        key: RequirementType.underwayOrder,
        label: '进行中',
        children: InCallUnderwayOrder
      });
    }
    tabs.push({ key: RequirementType.newOrder, label: '新建', children: InCallNewOrder });
    tabs.push({
      key: RequirementType.historyOrder,
      label: `历史工单(${completedOrderHistoryLen})`,
      children: InCallHistoryOrder
    });
    tabs.push({
      key: RequirementType.historyConsult,
      label: `历史咨询(${historyChanceLen})`,
      children: InCallHistoryConsult
    });
    return tabs;
  }, [hasUnderwayOrder, completedOrderHistoryLen, historyChanceLen]);
  const onTabChange = (key: string) => {
    store.onRequirementTypeChange(key as RequirementType);
  };
  return (
    <TwTabs
      animationEnabled={false}
      lazy={true}
      tabBarScrollable={true}
      tabBarScrollCountLimit={3}
      activeKey={requirementType}
      items={tabItems}
      onTabChange={onTabChange}
    />
  );
};
