import {
  BfAppContext,
  BfLoginFormState,
  BfLoginStore,
  BfLoginStoreState,
  BfUserLoginParams,
  EnumValue,
  newEnumValue
} from '@tw/tw-runtime';
import { LoginDeviceTypeEnumValue, LoginMethodEnumValue, UserTypeEnumValue } from '@tw/lock-api-gen';
import { ResLoginRequestModel } from '@tw/lock-api-gen/dist/models';

export class LoginStore extends BfLoginStore {
  protected async doLogin(params: BfUserLoginParams): Promise<void> {
    const loginReq: ResLoginRequestModel = {
      userType: newEnumValue(UserTypeEnumValue.company),
      loginMethod: newEnumValue(LoginMethodEnumValue.captcha),
      userName: params.userName,
      captcha: params.captcha,
      deviceType: newEnumValue(LoginDeviceTypeEnumValue.android)
    };
    const appStore = BfAppContext.getInstance().getAppStore();
    await appStore.login(loginReq);
  }

  protected getDefaultUserType(): EnumValue {
    return newEnumValue(UserTypeEnumValue.company);
  }

  protected getInitialState(): BfLoginStoreState {
    return {
      ...super.getInitialState(),
      isPolicyAgreed: true
    };
  }
}
