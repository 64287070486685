import { Permission } from 'react-native';
import * as Notifications from 'expo-notifications';
import { PermissionModule } from '@/app/native/permission';
import { AppPermissions } from '@/settings/stores/permission-apply/type';
import { BfLog4js, BfLogger } from '@tw/tw-log4js';
import { BfAppContext, EnumValue } from '@tw/tw-runtime';
import * as Device from 'expo-device';
import { Platform } from 'react-native';

const STORAGE_PERMISSION: Permission[] = [
  'android.permission.WRITE_EXTERNAL_STORAGE',
  'android.permission.READ_EXTERNAL_STORAGE'
];
const STORAGE_PERMISSION_33: Permission[] = [
  'android.permission.READ_MEDIA_IMAGES',
  'android.permission.READ_MEDIA_VIDEO',
  'android.permission.READ_MEDIA_AUDIO'
];
const getStoragePermission = (): Permission[] => {
  if (Platform.Version >= 33) {
    return STORAGE_PERMISSION_33;
  }
  return STORAGE_PERMISSION;
};
const CAMERA_PERMISSION: Permission[] = ['android.permission.CAMERA'];
const LOCATION_PERMISSION: Permission[] = [
  'android.permission.ACCESS_FINE_LOCATION',
  'android.permission.ACCESS_COARSE_LOCATION'
];
const PHONE_PERMISSION: Permission[] = [
  'android.permission.READ_PHONE_STATE',
  // 'android.permission.READ_SMS',
  // 'android.permission.READ_PHONE_NUMBERS',
  'android.permission.READ_CALL_LOG',
  'android.permission.ANSWER_PHONE_CALLS'
];

const getLogger = () => BfLog4js.getLogger('permission');

/**
 * 检查是否所有权限已经开启
 */
async function checkAllPermissionEnabled(options: PermissionCheckOption) {
  const permissions = await checkAllPermissions(options);
  return Object.keys(permissions).every((item) => permissions[item]);
}

/**
 * 权限检查选项
 */
function getPermissionCheckOption(allPosition: EnumValue[]): PermissionCheckOption {
  // 所有岗位
  // const allPosition = BfAppContext.getInstance().getUserStore().getState().userInfo.allPosition;
  const hasTmk = allPosition.some((pos) => pos.id === 'tmk');
  //
  const options: PermissionCheckOption = {
    phoneState: false,
    notification: true,
    floatWindow: true,
    camera: true,
    storage: true,
    location: true,
    accessibility: false,
    batteryOptimization: true,
    autoStart: true,
    phoneRecord: false
  };
  if (hasTmk) {
    options.phoneState = true;
    options.floatWindow = true;
    options.phoneRecord = true;
  }
  if (Platform.OS === 'android') {
    if (hasTmk && Device.brand.toUpperCase() === 'HUAWEI') {
      //
      options.accessibility = true;
    }
  }
  return options;
}

/**
 * 检查所有权限是否已经开启
 */
async function checkAllPermissions(options: PermissionCheckOption) {
  const getCheckTask = (enable: boolean, task: () => Promise<boolean>) => {
    return enable ? task() : checkForcePass();
  };
  const [phoneState, notification, floatWindow, camera, storage, location, accessibility, batteryOptimization] =
    await Promise.allSettled([
      getCheckTask(options.phoneState, () => checkPhoneState()),
      getCheckTask(options.notification, () => checkNotification()),
      getCheckTask(options.floatWindow, () => checkFloatWindow()),
      getCheckTask(options.camera, () => checkCamera()),
      getCheckTask(options.storage, () => checkStorage()),
      getCheckTask(options.location, () => checkLocation()),
      getCheckTask(options.accessibility, () => checkAccess()),
      getCheckTask(options.batteryOptimization, () => checkBatteryOptimization())
    ]);
  const permissions: AppPermissions = {
    phoneState: getPromiseSettledValue(phoneState),
    notification: getPromiseSettledValue(notification),
    floatWindow: getPromiseSettledValue(floatWindow),
    camera: getPromiseSettledValue(camera),
    storage: getPromiseSettledValue(storage),
    location: getPromiseSettledValue(location),
    accessibility: getPromiseSettledValue(accessibility),
    batteryOptimization: getPromiseSettledValue(batteryOptimization)
  };
  getLogger().debug('检查权限： ' + JSON.stringify(permissions));
  return permissions;
}

/**
 * 检查无障碍服务
 * @private
 */
async function checkAccess() {
  return await PermissionModule.isAccessibilitySettingsOn();
}

/**
 * 检查位置权限
 * @private
 */
async function checkLocation() {
  return await checkPermissionsGroup(LOCATION_PERMISSION);
}

/**
 * 检查储存权限
 * @private
 */
async function checkStorage() {
  const hasPermission = await checkPermissionsGroup(getStoragePermission());
  if (!hasPermission) {
    return false;
  }
  return await PermissionModule.isManageExternalStorageEnabled();
}

async function checkForcePass() {
  return Promise.resolve(true);
}

/**
 * 检查电话状态权限
 */
async function checkPhoneState() {
  return await checkPermissionsGroup(PHONE_PERMISSION);
}

/**
 * 检查通知权限
 */
async function checkNotification() {
  const settings = await Notifications.getPermissionsAsync();
  return settings.granted;
}

async function checkBatteryOptimization() {
  return await PermissionModule.isIgnoringBatteryOptimizations();
}

/**
 *
 * @private
 */
async function checkFloatWindow() {
  return await PermissionModule.isFloatWindowOn();
}

/**
 * 检查相机权限
 * @private
 */
async function checkCamera() {
  return await checkPermissionsGroup(CAMERA_PERMISSION);
}

/**
 * 解析 Promise Value
 * @param result
 */
function getPromiseSettledValue(result: PromiseSettledResult<boolean>) {
  return result.status === 'fulfilled' ? result.value : false;
}

export async function checkPermissionsGroup(permissions: Permission[]) {
  if (Platform.OS == 'web') {
    return true;
  }
  const PermissionsAndroid = require('react-native').PermissionsAndroid;
  const tasks = permissions.map((item) => PermissionsAndroid.check(item));
  const res = await Promise.allSettled(tasks);
  return res.every((result) => result.status === 'fulfilled' && result.value);
}

export const PermissionChecker = {
  getPermissionCheckOption,
  checkAllPermissionEnabled,
  checkAllPermissions,
  checkPhoneState,
  checkNotification,
  checkFloatWindow,
  checkCamera,
  checkStorage,
  checkLocation,
  checkAccess,
  getStoragePermission
};

export interface PermissionCheckOption {
  phoneState: boolean;
  notification: boolean;
  floatWindow: boolean;
  camera: boolean;
  storage: boolean;
  location: boolean;
  accessibility: boolean;
  batteryOptimization: boolean;
  autoStart: boolean;
  phoneRecord: boolean;
}
