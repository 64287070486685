import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { RequirementType } from '@/sale/stores/in-call-order/type';
import { UnderwayFooter } from '@/sale/pages/in-call-order/viewparts/incall-footer/UnderwayFooter';
import { HangupFooter } from '@/sale/pages/in-call-order/viewparts/incall-footer/HangupFooter';
import { NewOrderFooter } from '@/sale/pages/in-call-order/viewparts/incall-footer/NewOrderFooter';
import { HistoryOrderFooter } from '@/sale/pages/in-call-order/viewparts/incall-footer/HistoryOrderFooter';
import { HistoryConsultFooter } from '@/sale/pages/in-call-order/viewparts/incall-footer/HistoryConsultFooter';

export const InCallFooter = () => {
  const store = useBfPageStore<InCallOrderStore>();
  const canHangup = useStoreState(store, (state) => state.canHangup);
  const requirementType = useStoreState(store, (state) => state.requirementType);

  if (canHangup) {
    return <HangupFooter />;
  }
  // 进行中工单
  if (requirementType === RequirementType.underwayOrder) {
    return <UnderwayFooter />;
  }
  if (requirementType === RequirementType.newOrder) {
    return <NewOrderFooter />;
  }
  if (requirementType === RequirementType.historyOrder) {
    return <HistoryOrderFooter />;
  }
  if (requirementType === RequirementType.historyConsult) {
    return <HistoryConsultFooter />;
  }
  return null;
};
