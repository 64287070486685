import { ActCallingDTO, AnswerStateEnumValue, HrEmployeeRestApiService } from '@tw/lock-api-gen';
import {
  BfAppContext,
  ConditionMatchTypeDTO,
  newEnumValue,
  newLikeCondition,
  PageQueryDTO,
  SortTypeDTO
} from '@tw/tw-runtime';

import { Account } from '@/app/components/tw-account-select/assign/type';
import { AudioUploadStatus, CallCacheModel, CallEvent } from '@/app/native/call/call-module-defs';
import { CallModule } from '@/app/native/call';
import { OrderDetailCacheStore } from '@/sale/stores/in-call-order/order-detail-cache-store';
import { EmitterSubscription, Platform } from 'react-native';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { SearchResultType } from '@tw/tw-components-rn';
import { IncallOrderBaseStore } from '@/sale/stores/in-call-order/incall-order-base-store';
import { InCallOrderCachePageData, InCallOrderStoreState, LoadParam } from '@/sale/stores/in-call-order/type';
import { IRnRouteService } from '@tw/tw-runtime-rn';

export class InCallOrderStore extends IncallOrderBaseStore<InCallOrderStoreState> {
  private orderDetailStore: OrderDetailCacheStore;

  private hangupTimer: number | null = null;

  constructor() {
    super();
    this.orderDetailStore = new OrderDetailCacheStore();
    this.addChildStore(this.orderDetailStore);
  }

  getOrderDetailStore() {
    return this.orderDetailStore;
  }

  async onLoad(param?: LoadParam): Promise<void> {
    // 参数有 phone ，dataId
    if (param.phone && param.dataId && !param.loadFromCache) {
      //
      await this.checkCallStatus(param);
      this.registerCallEvent();
    }
    return await super.onLoad(param);
  }

  /**
   * 收到挂断事件
   */
  async onHangupEvt(evt: CallEvent) {
    this.logger.debug('收到挂断');
    try {
      // 收到挂断事件后，清理当前通话状态
      await CallModule.clearCurrentInCall();
    } catch (e) {
      console.error(e);
    }
    this.stopHangupTimer();
    this.setCanHangup(false);
    const confirm = await this.messageService.showConfirm({
      title: '来电确认',
      content: '是否是客户来电？如果选择否，将不保留通话记录',
      cancelText: '否',
      okText: '是'
    });
    // 非客户来电，删除
    if (!confirm) {
      this.removeCacheAndTryLoadNext(true);
      return;
    }
    await this.doUpload(evt.dataId);
  }

  /**
   * 创建通话记录
   * @private
   * @param callingDTO
   */
  private async createCallHistory(callingDTO: ActCallingDTO) {
    //
    const res = await this.actCallService.create(callingDTO);
    //
    this.setState({
      actCalling: {
        ...callingDTO,
        id: res
      }
    });
  }

  /**
   * 获取人员列表
   * @param executor
   */

  async listAllEmployeeByKeyword(
    keyword?: string,
    pageSize?: number,
    pageNo?: number
  ): Promise<SearchResultType<Account>> {
    const params: PageQueryDTO = keyword
      ? {
          pageNo: pageNo || 1,
          pageSize: pageSize || 30,
          conditions: [newLikeCondition(HrEmployeeRestApiService.NAME, keyword, ConditionMatchTypeDTO.AND)],
          sorts: [
            {
              field: 'code',
              sortType: SortTypeDTO.DESC
            }
          ]
        }
      : {
          pageNo: pageNo || 1,
          pageSize: pageSize || 30,
          sorts: [
            {
              field: 'code',
              sortType: SortTypeDTO.DESC
            }
          ]
        };

    const response = await this.employeeService.query(params);

    const users: Account[] = response.rows;

    // Todo:  后端确认 "pageNo": 0, 或者 1 结果一样

    return {
      entries: users,
      status: 'success' as const,
      noDataMessage: 'empty',
      // TODO: deal paging
      hasMore: response.total > pageNo * pageSize,
      hasMoreMessage: 'load more',
      next: () => this.listAllEmployeeByKeyword(keyword, pageSize, pageNo + 1)
    };
  }

  onHangupPress() {
    // web 模式模式下
    if (Platform.OS === 'web') {
      this.debug_createCallingHistory();
      return;
    }
    this.logger.debug('调用结束通话');
    this.startHangupTimer();
    CallModule.endCall();
  }

  private async forceEndCall() {
    const isPhoneInUse = await CallModule.isPhoneInUse();
    //
    if (!isPhoneInUse) {
      this.messageService.showWarn('强制设置挂断状态');
      try {
        await CallModule.forceEndInCall(this.getState().dataId);
      } catch (e) {
        console.error(e);
        this.messageService.showError('强制挂断出错：' + e.message);
      }
    } else {
      this.messageService.showError('当前正在通话无法强制挂断');
    }
  }

  /**
   * 挂断点击后1s内还没有得到挂断事件，强行挂断
   * @private
   */
  private startHangupTimer() {
    if (this.hangupTimer) {
      return;
    }
    this.hangupTimer = setTimeout(() => {
      this.stopHangupTimer();
      //
      this.forceEndCall();
    }, 1500) as unknown as null;
  }

  private stopHangupTimer() {
    if (this.hangupTimer) {
      clearTimeout(this.hangupTimer);
      this.hangupTimer = null;
    }
  }

  private async debug_createCallingHistory() {
    await this.messageService.showLoading('处理中');
    const res = await this.createCallHistory({
      startTime: Date.now() - 10 * 1000,
      stayTime: 10 * 1000,
      answerState: newEnumValue(AnswerStateEnumValue.answered)
    });
    await this.messageService.hideLoading();
    this.setState({
      canHangup: false
    });
  }

  protected async doRemoveCache(dataId: string): Promise<void> {
    await CallModule.removeInCallCache(dataId);
  }

  protected async getLatestCacheModel(): Promise<CallCacheModel> {
    return await CallModule.getLatestInCallCacheModel();
  }

  protected goHome() {
    (this.routeService as IRnRouteService).reset({
      path: ROUTE_NAMES.Home
    });
  }

  private uploadStatusSubscription: EmitterSubscription = null;

  protected async doUpload(dataId: string): Promise<void> {
    if (!this.uploadStatusSubscription) {
      // 监听状态
      this.uploadStatusSubscription = CallModule.addListener('uploadStatus', (evt) => {
        const statusItem = evt?.find((item) => item.dataId === dataId);
        if (!statusItem) {
          return;
        }
        // 上传成功
        if (statusItem.status === AudioUploadStatus.SUCCESS) {
          this.messageService.hideLoading();
          this.afterUploadSuccess(dataId);
          return;
        }
        // 上传失败
        if (statusItem.status === AudioUploadStatus.FAILED) {
          this.messageService.hideLoading();
          this.afterUploadFailed(dataId, statusItem.message);
          return;
        }
        if (statusItem.status === AudioUploadStatus.CANCELED) {
          this.messageService.hideLoading();
          this.messageService.showError('上传已经取消');
        }
      });
    }

    // 检查上传状态
    const result = await CallModule.getAudioUploadStatus(dataId);
    // 上传成功
    if (result.status === AudioUploadStatus.SUCCESS) {
      await this.afterUploadSuccess(dataId);
      return;
    }
    if (result.status === AudioUploadStatus.FAILED) {
      await this.afterUploadFailed(dataId, result.message);
      return;
    }
    await this.tryUpload(dataId);
  }

  private async afterUploadSuccess(dataId: string) {
    if (!this.isActive()) {
      return;
    }
    const dataCache = await CallModule.findCacheById(dataId);
    if (!this.isActive()) {
      return;
    }
    if (!dataCache) {
      this.messageService.showError('上传成功但是数据查询失败');
      return;
    }
    this.onUploadSuccess(dataCache);
  }

  private async afterUploadFailed(dataId: string, message: string) {
    const retry = await this.messageService.showConfirm({
      title: '提示',
      content: '上传出错,是否重试上传？\n' + message,
      okText: '重试',
      cancelText: '取消'
    });
    if (retry) {
      await this.tryUpload(dataId);
    }
  }

  private async tryUpload(dataId: string) {
    await this.messageService.showLoading('上传中');
    // 添加到上传队列
    await CallModule.startUpload(dataId);
  }

  // protected async doUploadCacheToServer(dataId: string): Promise<unknown> {
  //   return await CallModule.uploadCacheToServer(dataId);
  // }

  /**
   * 废弃弹窗是否可见
   * @param visible
   * @protected
   */
  protected setIsDiscardPanelVisible(visible: boolean) {
    this.setState(
      {
        isDiscardPanelVisible: visible
      },
      false,
      '废弃弹窗是否可见'
    );
  }

  private discardRemarkPromiseResolve: any = null;
  private discardRemarkPromiseReject: any = null;

  protected async askUserInputDiscardRemark(): Promise<string> {
    this.setIsDiscardPanelVisible(true);
    return new Promise((resolve, reject) => {
      this.discardRemarkPromiseResolve = resolve;
      this.discardRemarkPromiseReject = reject;
    });
  }

  /**
   * 用户取消录入废弃原因
   */
  onUserCancelInputDiscardRemark() {
    this.discardRemarkPromiseReject?.();
    this.clearDiscardInputHandler();
    this.setIsDiscardPanelVisible(false);
  }

  onUserInputDiscardRemarkConfirm(remark: string) {
    this.discardRemarkPromiseResolve?.(remark);
    this.clearDiscardInputHandler();
    this.setIsDiscardPanelVisible(false);
  }

  private clearDiscardInputHandler() {
    this.discardRemarkPromiseResolve = null;
    this.discardRemarkPromiseReject = null;
  }

  protected async doUpdateCache(data: InCallOrderCachePageData) {
    try {
      await CallModule.updateInCallCachePageData(this.getState().dataId, JSON.stringify(data));
    } catch (e) {
      this.logger.error(e);
      this.messageService.showError('更新缓存出错：' + e.message);
    }
  }

  protected async doLoadRemainOrderCount(): Promise<number> {
    const count = await CallModule.getInCallCacheCount();
    if (!count) {
      return 0;
    }
    const countNum = Number(count);
    return countNum > 1 ? countNum - 1 : 0;
  }

  protected async loadCacheModel(): Promise<CallCacheModel> {
    return await CallModule.getInCallCacheById(this.getState().dataId);
  }

  private hangupHandle: EmitterSubscription | null = null;

  private async checkCallStatus(params: LoadParam) {
    const isPhoneInUse = await CallModule.isPhoneInUse();
    if (!isPhoneInUse) {
      if (!params.dataId || !params.phone) {
        this.messageService.showError('状态错误，页面缺少 dataId 或者 phone 参数');
        return;
      }
      this.onHangupEvt({
        dataId: params.dataId,
        phone: params.phone
      });
    }
  }

  private registerCallEvent() {
    //
    this.unregisterCallEvent();
    // 检查是否已经挂断
    this.hangupHandle = CallModule.addListener('hangup', (evt) => {
      this.onHangupEvt(evt);
    });
  }

  private unregisterCallEvent() {
    if (this.hangupHandle) {
      this.hangupHandle.remove();
      this.hangupHandle = null;
    }
  }

  private unregisterUploadProgressEvent() {
    if (this.uploadStatusSubscription) {
      this.uploadStatusSubscription.remove();
      this.uploadStatusSubscription = null;
    }
  }

  destroy() {
    this.stopHangupTimer();
    this.setActive(false);
    try {
      this.unregisterCallEvent();
    } catch (e) {
      console.error(e);
    }
    try {
      this.unregisterUploadProgressEvent();
    } catch (e) {
      console.error(e);
    }
    super.destroy();
  }

  onBack() {
    const routeService = BfAppContext.getInstance().getRouteService();
    if ((this.loadParams as LoadParam).loadFromList) {
      routeService.back();
    } else {
      (routeService as IRnRouteService).reset({
        path: ROUTE_NAMES.Home
      });
    }
  }
}
