import { NativeModules, DeviceEventEmitter, EmitterSubscription } from 'react-native';

const { CallModule: CallModuleNative } = NativeModules;

export interface CallModuleInterface {
  registerReceiver(): void;

  startForeService(): void;

  startNewMainActivity(): void;

  /**
   * 获取来电
   */
  getInCallRecoverData(): Promise<CallPhoneData | null>;

  /**
   * 获取去电
   */
  getRevisitRecoverData(): Promise<CallPhoneData | null>;

  /**
   * 获取最近一个回访记录
   */
  getLatestRevisitCache(): Promise<RevisitCacheModel | null>;

  /**
   * 清理来电
   * 页面中收到挂断事件后，调用该方法
   */
  clearInCallRecoverData(): Promise<void>;

  /**
   * 清理去电
   * 页面中收到挂断事件后，调用该方法
   */
  clearRevisitRecoverData(): Promise<void>;

  /**
   * 更新页面数据
   * @param dataId
   * @param pageData
   */
  updateInCallCachePageData(dataId: string, pageData: string): Promise<void>;

  /**
   * 获取最近的缓存Id
   */
  getLatestInCallCacheModel(): Promise<CallCacheModel>;

  /**
   * 删除来单历史缓存
   */
  removeInCallCache(dataId: string): Promise<void>;

  /**
   * 根据缓存id获取回访数据
   * @param dataId
   */
  getRevisitCacheById(dataId: string): Promise<RevisitCacheModel>;

  /**
   * 上传到服务器
   */
  uploadInCallCacheToServer(dataId: string): Promise<CallCacheModel>;

  /**
   *
   */
  getInCallCacheById(dataId: string): Promise<CallCacheModel>;

  /**
   * 获取数据
   * RN native 传递数据时，long 被转换成 string
   */
  getInCallCacheCount(): Promise<string>;

  /**
   * 获取所有缓存的记录列表
   */
  getAllInCallCacheList(): Promise<CallCacheModel[] | null>;

  /**
   * 结束通话
   */
  endCall(): void;

  /**
   * 强制来电挂断
   */
  forceEndInCall(dataId: string): Promise<void>;

  /**
   * 清理当前通话
   */
  clearCurrentInCall(): Promise<void>;

  /**
   * 接听电话
   */
  answerCall(): void;

  /**
   * 注册监听器
   * @param event
   * @param callback
   */
  addListener(event: AudioUploadEventType, callback: AudioUploadCallback): EmitterSubscription;

  addListener(event: CallEventType, callback: CallEventCallback): EmitterSubscription;

  /**
   * 保存认证Token到 Native层
   * @param token
   */
  setAuthToken(token: string | undefined);

  /**
   * 是否允许捕获来电
   */
  setEnableCallingCatch(enableCallingCatch: boolean);

  /**
   * 设置为默认通话app
   */
  requestDefaultPhoneApp(): void;

  /**
   * 设置ApiUrl
   * @param url
   */
  setApiBaseUrl(url: string): void;

  /**
   * 电话正在使用
   */
  isPhoneInUse(): Promise<boolean>;

  /**
   * 开始后台上传任务
   */
  uploadAllAudioInBackground(): Promise<void>;

  /**
   * 获取记录数据的上传状态
   */
  getAudioUploadStatus(dataId: string): Promise<AudioUploadResult>;

  /**
   * 开始上传
   */
  startUpload(dataId: string): Promise<void>;

  /**
   * 取消上传
   */
  cancelUpload(dataId: string): Promise<void>;

  /**
   * 查找缓存
   * @param dataId
   */
  findCacheById(dataId: string): Promise<CallCacheModel>;

  /**
   * 插入通话记录缓存
   * @param model
   */
  createCallCache(model: Partial<CallCacheModel>): Promise<void>;

  /**
   * 删除所有通话缓存
   */
  clearAllCallCache(): Promise<void>;

  /**
   * 上传所有缓存模型
   */
  uploadAllInCallCacheModel(): void;

  /**
   * 回访拨打电话
   */
  revisitCall(phone: string, orderId: string): Promise<void>;

  /**
   * 上传呼出电话记录到服务器
   */
  uploadRevisitCallCacheToServer(dataId: string): Promise<RevisitCacheModel>;

  /**
   * 移除本地缓存回访记录
   * 在回访完成接口调用成功后，删除
   * @param dataId
   */
  removeRevisitCache(dataId: string): Promise<void>;
}

export interface AudioUploadResult {
  status: AudioUploadStatus;
  message?: string;
}

export enum AudioUploadStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  UPLOADING = 'UPLOADING',
  IDLE = 'IDLE',
  CANCELED = 'CANCELED'
}

export interface CallPhoneData {
  /**
   * 来电号码
   */
  phone: string;
  /**
   * 数据 Id
   */
  dataId: string;
}

/**
 * 事件： 响铃，接听，挂断
 */
export type CallEventType = 'ring' | 'answer' | 'hangup' | 'revisit_hangup';
export type AudioUploadEventType = 'uploadStatus';

export interface CallEvent {
  /**
   * 缓存记录Id
   */
  dataId: string;
  /**
   * 手机号
   */
  phone: string;
}

export type UploadEvent = ModelUploadStatus[];

export type CallEventCallback = (evt: CallEvent) => void;
export type AudioUploadCallback = (evt: UploadEvent) => void;

export interface CallCacheModel {
  id: string;
  phone?: string;
  recordFileUrl?: string;
  recordFileId?: string;
  /**
   * 录音上传时间，RN Native-> JS 时传输数据，只能传输 int， 为了避免丢失数据，转为 string 传输
   */
  recordFileSaveTime?: string;
  callingId?: string;
  pageData?: string;
  ringAt?: string;
  answeredAt?: string;
  hangupAt?: string;
}

export interface RevisitCacheModel extends CallCacheModel {
  orderId?: string;
}

export interface ModelUploadStatus {
  dataId: string;
  status: AudioUploadStatus;
  message?: string;
}
