import { BfFormElement } from '@tw/tw-platform-react';
import { Field } from '@formily/core';

export const getFormSchema = (): BfFormElement[] => {
  return [
    {
      id: 'section1',
      name: '图片上传',
      type: 'container',
      widget: 'Section',
      elements: [
        {
          id: 'scenePictures',
          type: 'pic',
          name: '现场图片',
          multiple: true
        }
      ]
    },
    {
      id: 'section2',
      name: '产品信息',
      type: 'container',
      widget: 'Section',
      elements: [
        {
          id: 'productItemDetail',
          name: '产品详情',
          type: 'subForm',
          widget: 'OrderProductList',
          widgetOptions: {
            decorator: 'None'
          },
          editable: true,
          validations: [
            {
              isNotNull: true
            }
          ],
          pageSchema: {
            listItemWidget: {
              widget: 'OrderLayout'
            },
            elements: [
              {
                id: 'basic',
                type: 'group',
                elements: [
                  {
                    id: 'productId',
                    type: 'reference',
                    name: '产品',
                    widget: 'OrderProduct'
                  },
                  {
                    id: 'unitPrice',
                    name: '价格',
                    type: 'money',
                    widget: 'OrderPrice',
                    default: undefined,
                    editable: true,
                    validations: [
                      {
                        isNotNull: true
                      }
                    ]
                  },
                  {
                    id: 'qtyOrdered',
                    type: 'number',
                    name: '数量',
                    editable: true,
                    default: 0,
                    widget: 'OrderProductQuantity'
                  },
                  {
                    id: 'subtotal',
                    type: 'money',
                    name: '小计',
                    visible: false,
                    default: 0,
                    reactions: [
                      {
                        dependencies: {
                          productId: '.productId',
                          unitPrice: '.unitPrice',
                          qtyOrdered: '.qtyOrdered'
                        },
                        when: '{{ $deps.productId}}',
                        fulfill: {
                          state: {
                            value: '{{ ($deps.unitPrice || 0) * ($deps.qtyOrdered||0) }}'
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          elements: [
            {
              id: 'commodity',
              type: 'reference',
              name: '产品'
            },
            {
              id: 'unitPrice',
              name: '单价',
              type: 'money',
              default: 0
            },
            {
              id: 'qtyOrdered',
              type: 'number',
              name: '数量',
              default: 0
            },
            {
              id: 'subtotal',
              type: 'money',
              name: '小计',
              default: 0
            }
          ]
        }
      ]
    },
    {
      id: 'section3',
      name: '',
      type: 'container',
      widget: 'Section',
      widgetOptions: {
        showTitle: false
      },
      elements: [
        {
          id: 'totalContainer',
          name: '合计',
          widget: 'TotalContainer',
          type: 'container',
          widgetOptions: {
            decorator: 'None'
          },
          elements: [
            {
              id: 'totalWithNoOffers',
              name: '合计',
              type: 'money',
              visible: false,
              reactions: [
                (field: Field) => {
                  const productItemsField = field.query('productItemDetail').take() as Field;
                  // if (!productItemsField || !productItemsField.value) {
                  //   return;
                  // }
                  let total = 0;

                  productItemsField?.value?.forEach((item) => {
                    console.log('item.subtotal', item.subtotal);
                    total += item.subtotal ?? 0;
                  });
                  console.log('totalWithNoOffers', total);
                  field.onInput(total);
                }
              ]
            },
            {
              id: 'total',
              name: '合计',
              type: 'money',
              editable: false,
              widgetOptions: {
                decorator: 'None'
              },
              reactions: [
                (field: Field) => {
                  const totalWithNoOffersField = field.query('totalWithNoOffers').take() as Field;
                  const offersField = field.query('offers').take() as Field;
                  const totalWithNoOffers = totalWithNoOffersField?.value ?? 0;
                  const offers = offersField?.value ?? 0;
                  let value = totalWithNoOffers - offers;
                  value = Number(value.toFixed(2));
                  console.log(`total 合计: ${value}= ${totalWithNoOffers} -${offers}`);
                  field.onInput(value);
                }
              ]
            },
            {
              id: 'offers',
              name: '优惠金额',
              type: 'money',
              visible: false,
              default: 0
            }
          ]
        },
        {
          id: 'warranty',
          name: '保修信息',
          type: 'enumValue',
          validations: [
            {
              isNotNull: true
            }
          ],
          widgetOptions: {
            mode: 'dropdown',
            itemLayout: 'flow',
            itemMode: 'capsule',
            contentAlign: 'right'
          },
          refEnum: {
            id: 'warranty',
            name: '保修信息'
          }
        }
      ]
    },
    {
      id: 'section4',
      name: '客户确认',
      type: 'container',
      widget: 'Section',
      elements: [
        {
          id: 'partnerSignature',
          name: '客户签字',
          type: 'signature',
          helper: '签字',
          widgetOptions: {
            layout: 'vertical',
            contentPressable: true
            // previewLayout: 'vertical'
          }
        }
      ]
    }
  ];
};
