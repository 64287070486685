import { BfAppContext, BfIntentResultType, ConditionRuleDTO } from '@tw/tw-runtime';

import { BfAccountSelectNavigator } from '@/app/components/tw-account-select/bf-account-select-navigator';
import { FC } from 'react';
import { HrPositionEmployeeViewRestApiService } from '@tw/lock-api-gen';
import { InCallAssignManagerProps } from '@/sale/components/incall-assign-select/type';
import { MaterialIcons } from '@expo/vector-icons';
import { SelectedAccount } from '@/app/components/tw-account-select/SelectedAccount';
import { TwFormItem } from '@tw/tw-components-rn';
import { TwTheme } from '@tw/tw-runtime-rn';
import { View } from 'native-base';
import { styles } from './styles';
import { useStoreState } from '@tw/tw-runtime-react';

/**
 * 负责人选择
 * @constructor
 */
export const InCallAssignManager: FC<InCallAssignManagerProps> = (props) => {
  const { value, onChange } = props;
  const userStore = BfAppContext.getInstance().getUserStore();
  const userInfo = useStoreState(userStore, (state) => state.userInfo);
  const onSelectUser = async () => {
    const apiService = BfAppContext.getInstance().getApiService();
    const positionEmployeeViewService = new HrPositionEmployeeViewRestApiService(apiService);

    const res = await BfAccountSelectNavigator.start({
      selected: value,
      structure: {
        id: positionEmployeeViewService.apiStructureId,
        domain: positionEmployeeViewService.apiDomainId,
        type: 'dataview'
      },
      condition: [
        {
          field: 'code',
          rule: ConditionRuleDTO.EQ,
          value: {
            type: 'value',
            value: 'companyEngineer'
          }
        }
      ],
      title: '选择负责人'
      // 不能选择自己
      // disableIds: [userInfo.employee.id]
    });
    if (res.type === BfIntentResultType.OK) {
      onChange?.(res.data);
    } else {
      return;
    }
  };

  return (
    <TwFormItem label={'指派'} contentAlign={'right'} bordered={true} pressable={true} onPress={onSelectUser}>
      <View style={styles.assignAccount}>
        {value && <SelectedAccount avatarUrl={value?.avatar?.url} name={value.name} showName={true} />}
        <MaterialIcons name="keyboard-arrow-right" size={24} color={TwTheme.values.colors.black} />
      </View>
    </TwFormItem>
  );
};
