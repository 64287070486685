import { useSliceState } from '@tw/tw-runtime-react';
import React, { useEffect, useMemo } from 'react';
import { TwBfForm, TwBfFormProps } from '@tw/tw-platform-rn';
import { getNewOrderElements } from './schema';
import { InCallAssignSelect } from '@/sale/components/incall-assign-select';
import { TwSection } from '@tw/tw-components-rn';
import { MaterialIcons } from '@expo/vector-icons';
import {
  HistoryChanceSlice,
  HistoryChanceSliceState,
  HistoryOrderSlice
} from '@/res/stores/res-partner-info-preview/slices';
import { HistoryLocationSelect } from '@/res/viewparts/history-location-select/HistoryLocationSelect';
import { InCallCustomerGenderInput, InCallCustomerInputField } from '@/sale/components/incall-customer';
import { InCallCustomerNameInputField } from '@/sale/components/incall-customer/InCallCustomerNameInputField';
import { InCallCustomerMobileInputField } from '@/sale/components/incall-customer/InCallCustomerMobileInputField';

export const ResHistoryChanceEdit = ({
  slice,
  historyOrderSlice
}: {
  slice: HistoryChanceSlice<any>;
  historyOrderSlice: HistoryOrderSlice<any>;
}) => {
  const form = slice.getForm();
  const elements = useMemo(() => getNewOrderElements(), []);
  const data = useSliceState(slice, (state: HistoryChanceSliceState) => state.historyChanceInfo);
  useEffect(() => {
    form.setValues(data);
  }, [data, form]);
  const onFieldInputValueChange: TwBfFormProps['onFieldInputValueChange'] = (evt) => {};
  const onCancel = () => {
    slice.onHistoryChaneCancel();
  };
  return (
    <TwSection
      title={'创建工单'}
      titlePrefix={<MaterialIcons name="arrow-back-ios" size={20} color="black" />}
      onTitlePress={onCancel}
    >
      <TwBfForm
        form={form}
        elements={elements}
        onFieldInputValueChange={onFieldInputValueChange}
        components={{
          InCallAssignSelect,
          InCallLocation: HistoryLocationSelect,
          InCallCustomerInputField,
          InCallCustomerGenderInput,
          InCallCustomerNameInputField,
          InCallCustomerMobileInputField
        }}
        scope={{
          historyOrderSlice
        }}
      />
    </TwSection>
  );
};
