import { useBfPageStore } from '@tw/tw-runtime-react';
import { InCallOrderStore } from '@/sale/stores/in-call-order/in-call-order-store';
import { TwPage, TwButton, TwRow } from '@tw/tw-components-rn';

export const UnderwayFooter = () => {
  const store = useBfPageStore<InCallOrderStore>();
  return (
    <TwPage.Footer>
      <TwRow space={1}>
        <TwButton
          variant={'outline'}
          size={'sm'}
          flex={1}
          onPress={() => {
            store.onUnderwayOrderSave();
          }}
        >
          保存
        </TwButton>
        <TwButton
          variant={'outline'}
          size={'sm'}
          flex={1}
          colorScheme={'secondary'}
          onPress={() => {
            store.onUnderwayOrderDiscard();
          }}
        >
          废弃
        </TwButton>
        <TwButton
          variant={'solid'}
          size={'sm'}
          flex={1}
          colorScheme={'primary'}
          onPress={() => {
            store.onUnderwayOrderUrgent();
          }}
        >
          催单
        </TwButton>
      </TwRow>
    </TwPage.Footer>
  );
};
