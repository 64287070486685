import { DefaultPaymentChannelItems, PaymentChannelSelectPopupProps, PaymentChannelType } from './type';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  TwButton,
  TwCheckbox,
  TwColumn,
  TwIcon,
  TwPopup,
  TwPopupHandle,
  TwPortal,
  TwRow,
  TwTypography
} from '@tw/tw-components-rn';

import { TouchableOpacity } from 'react-native-gesture-handler';
import { styles } from './styles';

export const getPaymentChannelItem = (item: PaymentChannelType) => {
  console.log('item', item);
  switch (item) {
    case 'weixin':
      return (
        <>
          <TwIcon icon={'icon-wechat'} size={20} color={'#11B721D9'}></TwIcon>
          <TwTypography.Text size={'md'}>微信支付</TwTypography.Text>
        </>
      );
    case 'alipay':
      return (
        <>
          <TwIcon icon={'icon-alipay'} size={20} color={'#3A89F6'}></TwIcon>
          <TwTypography.Text size={'md'}>支付宝支付</TwTypography.Text>
        </>
      );
    case 'cash':
      return (
        <>
          <TwIcon icon={'icon-icon'} size={20} color={'#ADB1B5'}></TwIcon>
          <TwTypography.Text size={'md'}>现金支付</TwTypography.Text>
        </>
      );
    default:
      return (
        <>
          <TwIcon icon={'icon-wechat'} size={20} color={'#11B721D9'}></TwIcon>
          <TwTypography.Text size={'md'}>微信支付</TwTypography.Text>
        </>
      );
  }
};

export const PaymentChannelSelectPopup: FC<PaymentChannelSelectPopupProps> = (props) => {
  const { visible, onVisibleChange, availableChanels = DefaultPaymentChannelItems } = props;
  const ref = useRef<TwPopupHandle>();

  const [paymentChannel, setPaymentChannel] = useState<PaymentChannelType>(availableChanels[0]);

  useEffect(() => {
    if (visible) {
      ref.current?.open();
    } else {
      ref.current?.close();
    }
  }, [visible]);
  const onPaymentChannelConfirm = () => {
    props.onPaymentChannelChange?.(paymentChannel);
    onVisibleChange?.(false);
  };

  return (
    <TwPortal>
      <TwPopup
        withHandle={false}
        adjustToContentHeight={true}
        title={'待支付'}
        ref={ref}
        onClose={() => onVisibleChange?.(false)}
        footer={
          <TwButton size={'sm'} onPress={onPaymentChannelConfirm}>
            确定
          </TwButton>
        }
      >
        <TwColumn space={4} style={styles.contentContailer}>
          <TwTypography.Text size={'4xl'} style={styles.amount}>
            {'¥' + props.amount}
          </TwTypography.Text>
          <TwColumn space={2}>
            <TwRow justifyContent={'space-between'}>
              <TwTypography.Text size={'md'}>选择支付方式</TwTypography.Text>
              <TwRow space={2}>{getPaymentChannelItem(paymentChannel)}</TwRow>
            </TwRow>
            <TwColumn style={styles.paymentContainer}>
              {availableChanels.map((item) => {
                return (
                  <TouchableOpacity key={item} onPress={() => setPaymentChannel(item as PaymentChannelType)}>
                    <TwRow justifyContent={'space-between'} style={styles.paymentChoose}>
                      <TwRow space={2}>{getPaymentChannelItem(item as PaymentChannelType)}</TwRow>
                      <TwCheckbox checked={paymentChannel === item}></TwCheckbox>
                    </TwRow>
                  </TouchableOpacity>
                );
              })}
            </TwColumn>
          </TwColumn>
        </TwColumn>
      </TwPopup>
    </TwPortal>
  );
};
