import { useGetLatest } from '@tw/tw-runtime-react';
import { useCallback, useEffect, useState } from 'react';
import { CallModule } from '@/app/native/call';
import { CallEvent, CallEventCallback, RevisitCacheModel } from '@/app/native/call/call-module-defs';
import { EmitterSubscription } from 'react-native';
import { useCameraPermissions } from 'expo-image-picker';
import { BfAppContext, FileValue } from '@tw/tw-runtime';
import { setLoading } from '@formily/core/lib/shared/internals';

export function useRevisitCallListener(callback: CallEventCallback) {
  const getCallback = useGetLatest(callback);
  return useEffect(() => {
    // 监听回访挂断
    const handle: EmitterSubscription = CallModule.addListener('revisit_hangup', (evt: CallEvent) => {
      const callback = getCallback();
      callback?.(evt);
      // 清理通话缓存
      CallModule.clearRevisitRecoverData();
    });
    return () => handle.remove();
  }, [getCallback]);
}

/**
 * 回访录音上传
 */
export function useRevisitCallUpload() {
  const messageService = BfAppContext.getInstance().getMessageService();
  // 是否正在上传
  const [uploading, setUploading] = useState(false);
  const [recordFile, setRecordFile] = useState<FileValue>();
  /**
   * 上传录音,
   * @return 上传后的录音数据
   */
  const upload = useCallback(
    async (dataId: string) => {
      // 缓存中已有上传记录，不需要再上传
      const cache = await CallModule.getInCallCacheById(dataId);
      if (cache.recordFileId) {
        setRecordFile(getRecordFileValueFromCache(cache));
        return;
      }
      // 开始上传
      try {
        setUploading(true);
        const result = await CallModule.uploadRevisitCallCacheToServer(dataId);
        const file = getRecordFileValueFromCache(result);
        setRecordFile(file);
        setUploading(false);
        return file;
      } catch (e) {
        console.error('上传失败', e);
        // 提示重试上传
        const confirm = await messageService.showConfirm({
          title: '提示',
          content: '上传失败，是否重试' + e.message,
          okText: '重试'
        });
        if (confirm) {
          upload(dataId).then();
        }
      }
    },
    [messageService]
  );
  return {
    upload,
    recordFile,
    uploading
  };
}

function getRecordFileValueFromCache(cache: RevisitCacheModel | null) {
  if (!cache || cache.recordFileId) {
    return null;
  }
  return {
    url: cache.recordFileUrl,
    id: cache.recordFileId,
    saveTime: cache.recordFileSaveTime ? Number(cache.recordFileSaveTime) : null
  };
}
