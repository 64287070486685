import { TwCard, TwLoadingBlock } from '@tw/tw-components-rn';
import { Text, View } from 'react-native';
import { HistoryOrderSummary } from './HistoryOrderSummary';
import { TwBfCommandItemMiniIconText } from '@tw/tw-platform-rn';
import { SaleDocOrderDTO, SaleOrderDTO } from '@tw/lock-api-gen';
import { FC, useCallback, useMemo, useState } from 'react';
import { styles } from './styles';
import { isNil } from 'lodash-es';
import { OrderDetailCacheStore } from '@/sale/stores/in-call-order/order-detail-cache-store';
import { useStoreState } from '@tw/tw-runtime-react';
import { BfListDetailStatus } from '@tw/tw-runtime';

export const HistoryOrderItemCard: FC<HistoryOrderItemCardProps> = (props) => {
  const { orderDetailStore, order, onNewAfterSaleOrderPress } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  const orderDetail = useStoreState(orderDetailStore, (state) => orderDetailStore.getData(state, order.id));
  const loadingStatus = useStoreState(orderDetailStore, (state) => orderDetailStore.getStatus(state, order.id));
  const serviceDetail = useMemo(() => {
    if (!orderDetail?.productItemDetail?.length) {
      return '--';
    }
    return orderDetail.productItemDetail
      .map((item) => {
        return `${item.productId.name}（${item.subtotal}元）`;
      })
      .join('、');
  }, [orderDetail?.productItemDetail]);

  const onCollapseChange = useCallback(
    (collapsed: boolean) => {
      if (!collapsed && order.id) {
        orderDetailStore.loadIfNeeded(order.id);
      }
      setIsCollapsed(collapsed);
    },
    [order.id, orderDetailStore]
  );

  return (
    <TwCard
      showHeader={false}
      style={styles.cardContainer}
      expandContent={
        loadingStatus === BfListDetailStatus.loading ? (
          <View style={{ height: 100 }}>
            <TwLoadingBlock />
          </View>
        ) : (
          <View>
            <TwCard.ContentItem label={'服务项'}>{serviceDetail}</TwCard.ContentItem>
            <TwCard.ContentItem label={'结算价格'}>
              {isNil(orderDetail?.total) ? '--' : `${orderDetail?.total}元`}
            </TwCard.ContentItem>
            <TwCard.ContentItem label={'工程师'}>{orderDetail?.executor?.name ?? '--'}</TwCard.ContentItem>
          </View>
        )
      }
      collapsed={isCollapsed}
      onCollapseChange={onCollapseChange}
    >
      <TwCard.Content
        content={<HistoryOrderSummary order={order} />}
        action={
          <TwBfCommandItemMiniIconText
            command={{ id: 'callServer', name: '售后', icon: 'icon-add' }}
            showIcon={true}
            onPress={() => onNewAfterSaleOrderPress?.(order)}
          />
        }
      ></TwCard.Content>
    </TwCard>
  );
};

export interface HistoryOrderItemCardProps {
  orderDetailStore: OrderDetailCacheStore;
  order?: SaleDocOrderDTO;
  onNewAfterSaleOrderPress?: (item: SaleOrderDTO) => void;
}
