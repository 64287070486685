import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  action: {
    marginLeft: TwTheme.values.spaces['4']
  }
});
