import { observer } from '@formily/react';
import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { useTabs } from '@tw/tw-platform-react';
import { TwTabItem, TwTabs } from '@tw/tw-components-rn';
import React, { useMemo } from 'react';
import { TwTabsField } from '@tw/tw-platform-rn';
import { SaleDocOrderOrderInfoPage } from '@/sale/stores/doc-order-order-info/sale-doc-order-order-info-page';

export const TabContainer = observer(() => {
  const store = useBfPageStore<SaleDocOrderOrderInfoPage>();
  const tabs = useTabs();
  const activeTabKey = useStoreState(store, (state) => state.activeTabKey);
  const onTabChange = (key: string) => {
    store.setActiveTabKey(key);
  };
  const tabsItems: TwTabItem[] = useMemo(() => {
    return tabs.map((item) => {
      let label = item.props.tab;
      return {
        key: item.name as string,
        label: label,
        data: item,
        children: TwTabsField.TabPane
      };
    });
  }, [ tabs]);
  if (!tabs?.length) {
    return null;
  }
  return (
    <TwTabs
      tabBarScrollable={true}
      tabBarScrollCountLimit={4}
      items={tabsItems}
      activeKey={activeTabKey}
      onTabChange={onTabChange}
    ></TwTabs>
  );
});
