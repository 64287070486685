import { FC } from 'react';
import { useField } from '@formily/react';
import { Field } from '@formily/core';
import { SalePartnerName } from '@/sale/components/partner-name/SalePartnerName';
import { PartnerNameFieldCommonProps } from '@/sale/fields/partner-name/type';

export const PartnerNameFieldCommon: FC<PartnerNameFieldCommonProps> = (props) => {
  const { genderAttr, value, mode, previewTextStyle, readOnly } = props;
  const field = useField();
  const genderField = field.form.query(genderAttr).take() as Field | undefined;
  const genderName = genderField?.value?.name;
  return (
    <SalePartnerName name={value} gender={genderName} mode={mode} textStyle={previewTextStyle} readOnly={readOnly} />
  );
};
