import { ArrayBase, TwFormItemField } from '@tw/tw-platform-rn';
import { TwButton } from '@tw/tw-components-rn';
import { FC } from 'react';
import { BfStructureSelectedWithCount, TwBfFieldRcProps } from '@tw/tw-platform-react';
import { SaleOrderProductItemDTO } from '@tw/lock-api-gen';
import { BfIntentResultType } from '@tw/tw-runtime';
import { RecursionField, useField, useFieldSchema } from '@formily/react';
import { ProductProductDTO } from '@tw/lock-api-gen/dist/types/product';
import { useBfPageStore } from '@tw/tw-runtime-react';
import { SaleOrderProcessPage } from '@/sale/stores/order-process/sale-order-process-page';
import {
  InventoryPersonalIntent,
  InventoryPersonalSelectNavigator
} from '@/wms/stores/doc-inventory-personal-personal-product-choose/inventory-personal-select-navigator';

export const OrderProductListField: FC<TwBfFieldRcProps<SaleOrderProductItemDTO[]>> = (props) => {
  const { value, onChange } = props;
  console.log('OrderProductListField', value);
  const store = useBfPageStore<SaleOrderProcessPage>();
  const fieldSchema = useFieldSchema();
  const field = useField();
  /**
   * 选择到新的产品
   */
  const onGetNewProduct = (selectResult: BfStructureSelectedWithCount<ProductProductDTO>[]) => {
    //
    const newValues = selectResult.map((selectItem) => {
      let unitPrice = undefined;
      if (value) {
        const index = value.findIndex(
          (item) => (item.productId.id as unknown as string) === (selectItem.item.id as unknown as string)
        );
        if (index > -1) {
          unitPrice = value[index].unitPrice;
        }
      }
      return {
        productId: selectItem.item as ProductProductDTO,
        qtyOrdered: selectItem.count,
        quantity: (selectItem.item as any)?.quantity,
        unitPrice: unitPrice,
        subtotal: (unitPrice ?? 0) * (selectItem.count ?? 0)
      } as SaleOrderProductItemDTO;
    });
    onChange?.(newValues);
  };

  const onAddProduct = async () => {
    const result = await InventoryPersonalSelectNavigator.start(dtoToUi(value));
    if (result.type === BfIntentResultType.OK) {
      onGetNewProduct(result.data);
    }
  };
  return (
    <TwFormItemField
      pressable={false}
      bordered={false}
      action={
        field.editable && (
          <TwButton variant={'ghost'} size={'sm'} onPress={onAddProduct}>
            选择
          </TwButton>
        )
      }
    >
      {value?.map((item, index) => {
        return (
          <ArrayBase.Item index={index} record={item} key={index}>
            <RecursionField schema={fieldSchema.properties.listItem} name={index} key={item.productId?.id} />
          </ArrayBase.Item>
        );
      })}
    </TwFormItemField>
  );
};

function dtoToUi(items: SaleOrderProductItemDTO[] | undefined): InventoryPersonalIntent | undefined {
  const selectedProducts = items?.map((item) => {
    return {
      count: item.qtyOrdered,
      item: {
        ...item.productId,
        productId: item.productId
      }
    };
  });
  return {
    selectedProducts,
    showService: true
  };
}

function uiToDto(
  items: BfStructureSelectedWithCount<ProductProductDTO>[] | undefined
): SaleOrderProductItemDTO[] | undefined {
  return items?.map((item) => {
    return {
      qtyOrdered: item.count,
      productId: item.item as ProductProductDTO
    };
  });
}

function getTotalWithNoOffers(items?: SaleOrderProductItemDTO[]) {
  if (!items?.length) {
    return 0;
  }
  let total = 0;
  items.forEach((item) => {
    total += item.subtotal ?? 0;
  });
  return total;
}
