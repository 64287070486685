import {
  BfAppContext,
  BfEnumService,
  BfMenuPermissionControlType,
  BfPushStore,
  BoStateService,
  AreaService,
  BfPreferenceService,
  CacheablePageSchemaService,
  AbstractStructureRestApiService
} from '@tw/tw-runtime';
import { ApiService } from '@/app/core/services/api-service';
import {
  BfGtPushService,
  RnFileService,
  RnMessageService,
  RnStorageService,
  RnSystemInfoService,
  StructureDataService
} from '@tw/tw-runtime-rn';
import { UserStore } from '@/app/core/store/user-store';
import { UserService } from '@/app/core/services/user-service';
import { AppStore } from '../store/app-store';
import { MenuStore } from '../store/menu-store';
import { MenuService } from '@/app/core/services/menu-service';
import { Platform } from 'react-native';
import { BfLocation } from '@tw/tw-platform-rn';
import { AuthTokenService } from '@/app/core/services/auth-token-service';
import { RouteService } from '@/app/core/services/route-service';
import { PushService } from '@/app/core/services/push-service';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { LoginStore } from '@/app/core/store/login-store';
import { NoticeStore } from '@/app/core/store/notice-store';
import { Gadekeys } from './gaodekeysAlpha';
import { SocketConfig } from '@/app/core/setup/api-config';

const messageService = new RnMessageService();

const systemInfoService = new RnSystemInfoService();
const storageService = new RnStorageService();
const authStorageService = new AuthTokenService({
  tokenKey: 'token',
  storageService: storageService
});
const apiService = new ApiService({
  authStorageService: authStorageService,
  messageService: messageService,
  systemInfoService: systemInfoService
});
const enumService = new BfEnumService(apiService, storageService);
const userService = new UserService(apiService);
const routeService = new RouteService();
const boStateService = new BoStateService(apiService);
// const boDataService = new BoDataService(apiService);
// const dataviewDataService = new DataviewDataService(apiService);
// const docDataService = new DocDataService(apiService);

const socketPushService = new PushService(apiService, authStorageService, SocketConfig);
const menuService = new MenuService(apiService, 'mobile');
const fileService = new RnFileService(apiService, {
  uploadUrl: '/v2/rest/services/boService/infra/file/upload',
  fileKey: 'file',
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});
const areaService = new AreaService(apiService);
const pageSchemaService = new CacheablePageSchemaService({
  apiService,
  storageService: storageService
});
const preferenceService = new BfPreferenceService(apiService);
BfAppContext.getInstance().setApiService(apiService);
BfAppContext.getInstance().setAreaService(areaService);
BfAppContext.getInstance().setMessageService(messageService);
BfAppContext.getInstance().setStorageService(storageService);
BfAppContext.getInstance().setRouteService(routeService);
BfAppContext.getInstance().setBoStateService(boStateService);
BfAppContext.getInstance().setFileService(fileService);
BfAppContext.getInstance().setPreferenceService(preferenceService);
BfAppContext.getInstance().setUserService(userService);
BfAppContext.getInstance().setEnumService(enumService);
BfAppContext.getInstance().setPageSchemaService(pageSchemaService);
BfAppContext.getInstance().setAuthStorageService(authStorageService);
BfAppContext.getInstance().setLoginPath('Login');
BfAppContext.getInstance().setStructureDataServiceFactory(
  (apiService, refEntity) => new StructureDataService(apiService, refEntity)
);

const pushStore = new BfPushStore({
  services: [socketPushService]
});
const menuStore = new MenuStore({
  menuService,
  displayMode: 'topSide',
  menuPermissionControl: BfMenuPermissionControlType.server
});
const userStore = new UserStore({
  authStorageService,
  userService
});
const noticeStore = new NoticeStore({
  apiService: apiService
});
const appStore = new AppStore({
  userStore,
  messageService: messageService,
  menuStore,
  authStorageService,
  enumService,
  storageService,
  pushStore,
  noticeStore,
  pageSchemaService
});
BfAppContext.getInstance().setPushStore(pushStore);
BfAppContext.getInstance().setMenuStore(menuStore);
BfAppContext.getInstance().setAppStore(appStore);
BfAppContext.getInstance().setUserStore(userStore);
BfAppContext.getInstance().setNoticeStore(noticeStore);
BfAppContext.getInstance().setDebug(true);
BfAppContext.getInstance().setLoginPath(ROUTE_NAMES.AuthLogin);
BfAppContext.getInstance().setLoginStore(
  new LoginStore({
    userService: userService
  })
);
BfLocation.getInstance().initMapKey(
  Platform.select({
    android: Gadekeys.mapKey,
    ios: Gadekeys.mapKey
  })
);
BfLocation.getInstance().initWebApi(Gadekeys.webKey);
BfLocation.getInstance().initWebJsKey(Gadekeys.jsKey);

// Getui.initPush();
