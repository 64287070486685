// import { HistoryOrderSliceState } from '@/res/stores/res-partner-info-preview/slices';
import { FC, useContext } from 'react';
import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import { Coordinates } from '@tw/tw-runtime';
import { observer, SchemaExpressionScopeContext, useField } from '@formily/react';
import { useSliceState } from '@tw/tw-runtime-react';
import { Field } from '@formily/core';
import { TwFormItem } from '@tw/tw-components-rn';
import { InCallLocationSelect } from '@/sale/components/incall-location-select/InCallLocationSelect';
import { HistoryOrderSliceState } from '@/res/stores/doc-partner-partner-info/slices';

export const HistoryLocationSelect: FC<TwBfFieldRcProps<Coordinates>> = observer(
  (props) => {
    const { value, onChange } = props;
    const scope = useContext(SchemaExpressionScopeContext);
    const historyOrderSlice = scope.historyOrderSlice;

    const historyOrder = useSliceState(historyOrderSlice, (state: HistoryOrderSliceState) => state.historyOrder);
    const field = useField() as Field;
    return (
      <TwFormItem
        showTitle={false}
        errors={field.selfErrors}
        showError={(field.selfErrors?.length ?? 0) > 0}
        paddingHorizontal={0}
      >
        <InCallLocationSelect historyOrder={historyOrder} value={value} onChange={onChange} />
      </TwFormItem>
    );
  },
  {
    displayName: 'InCallLocation'
  }
);
