import { useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { ProductProductSelectStore } from '@/product/stores/product-product-select/product-select-store';
import { SectionList, Text } from 'react-native';
import { useMemo, useRef } from 'react';
import { SaleProductOrder } from '@/product/components/sale-product-order/SaleProductOrder';
import { styles } from '@/product/pages/product-product-select/viewparts/pps-product-list/styles';
import { ProductProductCategoryDTO, ProductProductDTO } from '@tw/lock-api-gen';

export const PpsProductList = () => {
  //
  const page = useBfPageStore<ProductProductSelectStore>();
  const groupedProductList = useStoreState(page, (state) => state.groupedProductList);
  const selectedProductList = useStoreState(page, (state) => state.selectedProductList);
  const sectionList = useMemo(() => {
    return page.getSectionsUiData(groupedProductList);
  }, [groupedProductList, page]);
  const onVisibleItemChange = (item) => {
    const activeItem = item.viewableItems?.[0];
    if (!activeItem) {
      return;
    }
    const section = activeItem.section.category as ProductProductCategoryDTO;
    page.onListVisibleSectionChange(section);
  };
  const listRef = useRef<SectionList>(null);

  page.setListRef(listRef);
  const onCountChange = (product: ProductProductDTO, count: number) => {
    page.onProductCountChange(product, count);
  };
  return (
    <SectionList
      ref={listRef}
      sections={sectionList}
      renderItem={({ item }: { item: ProductProductDTO }) => {
        return (
          <SaleProductOrder
            title={item.name}
            key={item.id}
            quantity={item.quantity}
            description={item.specification}
            count={page.getSumOfProduct(item, selectedProductList)}
            onCountChange={(count) => onCountChange(item, count)}
          />
        );
      }}
      renderSectionHeader={({ section }) => {
        return <Text style={styles.label}>{section.category.name}</Text>;
      }}
      showsVerticalScrollIndicator={false}
      onViewableItemsChanged={onVisibleItemChange}
    />
  );
};
