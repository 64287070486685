import { Text, View } from 'react-native';
import { TwAvatar, TwRow } from '@tw/tw-components-rn';
import { TwTheme, theme } from '@tw/tw-runtime-rn';

import { FC } from 'react';
import { StyleSheet } from 'react-native';

export interface SelectedAccounts {
  value: {
    name: string;
    avatarUrl?: string;
  }[];
}

export const SelectedAccounts: FC<SelectedAccounts> = (props) => {
  return (
    <TwRow alignItems={'center'} space={2}>
      <TwAvatar.Group
        _avatar={{
          size: 'lg'
        }}
        max={3}
        space={-1}
        _hiddenAvatarPlaceholder={{
          bg: theme.colors.blue[100],
          size: 'sm'
        }}
      >
        {props.value?.map((item, index) => {
          return (
            <TwAvatar
              key={index}
              bg={theme.colors.blue[100]}
              source={
                item?.avatarUrl
                  ? {
                      uri: item.avatarUrl
                    }
                  : undefined
              }
              size={'sm'}
            >
              {item.name?.slice(0, 1)}
            </TwAvatar>
          );
        })}
      </TwAvatar.Group>
    </TwRow>
  );
};

const styles = StyleSheet.create({
  formContentText: {
    ...TwTheme.values.textSizes.sm
  }
});
