import { ResPartnerDTO, SaleDocOrderDTO, SaleOrderDTO } from '@tw/lock-api-gen';
import { EnumValue } from '@tw/tw-runtime';

function dto2Ui(order: SaleOrderDTO): SaleOrderScenePartnerUI {
  if (!order) {
    return order;
  }

  const result: SaleOrderScenePartnerUI = { ...order };
  if (order.linkmanPartnerId) {
    result.scenePartnerId = order.linkmanPartnerId;
    result.sceneLinkman = order.linkman ?? order.linkmanPartnerId?.name;
    result.sceneLinkmanGender = order.linkmanGender ?? order.linkmanPartnerId.gender;
    result.sceneLinkmanPhone = order.linkmanPhone ?? order.linkmanPartnerId?.mobile;
  } else {
    result.scenePartnerId = order.partnerId;
    result.sceneLinkman = order.callman ?? order.partnerId?.name;
    result.sceneLinkmanPhone = order.callmanPhone ?? order.partnerId?.mobile;
    result.sceneLinkmanGender = order.callmanGender ?? order.partnerId.gender;
  }
  result.sceneLinkmanNameWithGender = `${result.sceneLinkman ?? ''}${result.sceneLinkmanGender?.name ?? ''}`;
  return result;
}

export interface SaleOrderScenePartnerUI extends SaleDocOrderDTO {
  scenePartnerId?: ResPartnerDTO;
  sceneLinkman?: string;
  sceneLinkmanNameWithGender?: string;
  sceneLinkmanPhone?: string;
  sceneLinkmanGender?: EnumValue;
}

/**
 * 判断订单中，如果有 现场联系人信息，则将现场联系人合并到 linkman 中，如果有
 */
export const OrderLinkmanMapper = {
  dto2Ui
};
