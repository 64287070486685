export const COMMON_ACTION_KEYS = {
  //打电话
  call: 'call',
  //打开地图
  navigation: 'navigation',
  //启用
  enabled: 'enabled',
  //禁用
  forbidden: 'forbidden',
  //移入黑名单
  moveBlak: 'moveBlak'
};
