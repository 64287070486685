import React, { useState } from 'react';

import { AudioPlayerField } from '@/app/fields/audio-player-field/AudioPlayerField';
import { HistoryCallPreview } from '@/app/fields/history-call/HistoryCallPreview';
import { SaleDocOrderOrderInfoPage } from '@/sale/stores/doc-order-order-info/sale-doc-order-order-info-page';
import { TwButton } from '@tw/tw-components-rn';
import { View } from 'react-native';
import { useBfPage } from '@tw/tw-platform-react';
import { useBfPageStore } from '@tw/tw-runtime-react';

export const InterviewDetail = (props) => {
  const page: SaleDocOrderOrderInfoPage = useBfPage();
  console.log('InterviewDetail render props: ', props, page);

  return (
    <View>
      <AudioPlayerField />
      InterviewDetail
      <TwButton onPress={() => page.onVisitComplete2()}>完成回访</TwButton>
    </View>
  );
};
