import { FC } from 'react';
import { InCallCustomerMobileInputFieldProps } from './type';
import { observer, useField } from '@formily/react';
import { Field } from '@formily/core';
import { ResPartnerDTO } from '@tw/lock-api-gen';
import { InCallCustomMobileInput } from './InCallCustomMobileInput';

export const InCallCustomerMobileInputField: FC<InCallCustomerMobileInputFieldProps> = observer(
  (props) => {
    const { partnerIdAttr, disabled, genderAttr, nameAttr, value, onChange } = props;
    const field = useField();

    const partnerIdField = field.query('.' + partnerIdAttr).take() as Field;
    const partnerValue = partnerIdField?.value as ResPartnerDTO | undefined;
    const mobile = partnerValue?.mobile ?? value;
    //
    const handleSelect = (partner: ResPartnerDTO) => {
      const nameField = field.query('.' + nameAttr).take() as Field;
      const genderField = field.query('.' + genderAttr).take() as Field;
      if (!partnerIdField) {
        return;
      }
      // 联动修改姓名
      nameField?.onInput(partner.name);
      // 联动修改性别
      genderField?.onInput(partner.gender);
      // 联动修改手机号
      onChange?.(partner.mobile);
      // 记录选中的客户
      partnerIdField.onInput(partner);
    };
    const handleChange = (text: string) => {
      partnerIdField.onInput(undefined);
      onChange?.(text);
    };
    return (
      <InCallCustomMobileInput
        disabled={!field.editable}
        value={mobile}
        onChange={handleChange}
        onSelect={handleSelect}
      />
    );
  },
  {
    displayName: 'InCallCustomerMobileInputField'
  }
);
